<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <v-card-title class="display-2 mb-4">{{$t('profile.activities.entry.title')}}</v-card-title>
      <v-card-text>
        {{$t('profile.activities.entry.description')}}
      </v-card-text>
      <v-card-text>
        {{$t('profile.activities.entry.tip-time-of-day')}}
      </v-card-text>

      <v-card-title class="subtitle py-0">{{$t('profile.activities.entry.activity-details')}}</v-card-title>
      <v-alert v-if="error || !valid" type="error">{{error || $t('shared.validation-error-message') }}</v-alert>
      <v-form ref="form" v-model="valid" lazy-validation v-if="activity">
        <v-card-text>    
          <div v-if="showAllActivityTypes">  
          <v-btn-toggle v-model="activity.type" @change="activityTypeChanged">
            <v-btn v-for="type in siteData.activities.filter(x => x.core)" :key="type.type" :value="type.type">
              <v-icon :title="type.text" >{{$helpers.getActivityIcon(type.type)}}</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">{{ $t('profile.activities.types.'+type.type) }}</span>
            </v-btn>
          </v-btn-toggle>
            <v-menu offset-y max-height="400">
              <template v-slot:activator="{ on, attrs }">
                <div class="v-item-group theme--light v-btn-toggle">
                <v-btn v-bind="attrs" v-on="on" :color="siteData.activities.some(x => x.core && x.type == activity.type)?'':'grey lighten-1'">
                  <v-icon title="More options" >fa-ellipsis-h</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp" class="ml-2">{{$t('shared.more')}}</span>
                </v-btn>
                </div>
              </template>
              <v-list>
                <v-list-item-group v-model="activity.type" @change="activityTypeChanged">
                <v-list-item v-for="(item, idx) in siteData.activities.filter(x => !x.core)" :key="idx" :value="item.type">
                  <v-list-item-icon><v-icon v-text="$helpers.getActivityIcon(item.type)"></v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('profile.activities.types.'+item.type) }}</v-list-item-title>
                </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
              {{ activity.type ? $t('profile.activities.types.'+activity.type) : '' }}
            </div>
            <div v-else>
              <v-btn-toggle v-model="activity.type" @change="activityTypeChanged">
                <v-btn v-for="type in availableActivityTypes" :key="type" :value="type">
                  <v-icon :title="type" >{{$helpers.getActivityIcon(type)}}</v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">{{ $t('profile.activities.types.'+type) }}</span>
                </v-btn>
              </v-btn-toggle>
              <v-btn text color="primary" @click="forceShowAllActivityTypes=true">{{ $t('profile.activities.show-all-type') }}</v-btn>
              {{ activity.type ? $t('profile.activities.types.'+activity.type) : '' }}
            </div>
          <!--
          <v-btn-toggle v-model="activity.type" mandatory @change="activityTypeChanged">
          <v-slide-group multi show-arrows>
            <v-slide-item v-for="type in activityTypeItems" :key="type.type">
              <v-btn :value="type.type" tile class="pa-2">
                <v-icon :title="type.text" >{{$helpers.getActivityIcon(type.type)}}</v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">{{type.text}}</span>
              </v-btn>
            </v-slide-item>
          </v-slide-group>
          </v-btn-toggle>
          -->
          <br/><br/>

          <DateAndTimeWithTimeZonePicker v-model="activity.start" ref="timePicker" :timeZone="timeZoneOlson" :label="$t('profile.activities.entry.activity-date-start')" :timeLabel="$t('profile.activities.entry.activity-time-start')" />
          <v-row>
          <v-col v-if="showDistance" cols="12" sm="6">
          <DistanceTextArea
            
            v-model="activity.dist" 
            icon="fa-ruler"
            :unit="profile.unit"
            :label="$t('profile.activities.entry.distance-label', { unit: unitTypeDiplay, requirement: activityService.requireDistance(activity) ? $t('shared.required') : $t('shared.optional') } )" 
            :rules="numberRules"
            >
          </DistanceTextArea>
            </v-col>
            <v-col v-if="showElevation || showElevationLoss" cols="12" sm="6">

              <DistanceTextArea
                v-if="showElevation && !isDownhill"
                v-model="computedElevationGain" 
                icon="fa-mountain"
                :mode="$helpers.UnitType.ELEVATION"
                :unit="profile.unit"
                :label="$t('profile.activities.entry.elevation-label', {unit: elevationUnitTypeDiplay})" 
                >
              </DistanceTextArea>
              <DistanceTextArea
                v-if="showElevationLoss"
                v-model="computedElevationLoss" 
                icon="fa-mountain"
                :mode="$helpers.UnitType.ELEVATION"
                :unit="profile.unit"
                :label="$t('profile.activities.entry.elevation-loss-label', {unit: elevationUnitTypeDiplay})" 
                >
              </DistanceTextArea>
            </v-col>
            <v-col cols="12" sm="6" v-if="activity.type == 'STAIR_CLIMBING'">

              <DistanceTextArea
                v-if="activity.type == 'STAIR_CLIMBING'"
                v-model="activity.custom" 
                icon="fa-sort-amount-up-alt fa-flip-horizontal"
                :mode="$helpers.UnitType.NUMBER"
                :unit="profile.unit"
                :label="$t('profile.activities.entry.climbed-stairs')" 
                >
              </DistanceTextArea>
              <p class="text-muted">{{$t('profile.activities.entry.climbed-stairs-tip')}}</p>
            </v-col>
            <v-col cols="12" sm="6" v-if="showCustomField">

              <DistanceTextArea
                v-model="activity.custom" 
                icon="fa-tally"
                :mode="$helpers.UnitType.NUMBER"
                :unit="profile.unit"
                :label="$t('profile.activities.entry.custom')" 
                >
              </DistanceTextArea>
            </v-col>
          </v-row>
          <DurationTextArea
            v-model="activity.time_s" 
            icon="fa-clock"
            :label="$t('profile.activities.entry.duration')" 
            >
          </DurationTextArea>

          <br/>


        </v-card-text>      
        
        <v-card-text>   
          <v-alert v-if="infoMessage" class="mt-4" type="info">
            <vue-markdown class="markdown" :html="false" :source="infoMessage" />
          </v-alert>
          <div v-if="geoRestrictedRaces && geoRestrictedRaces.length > 0">
            <h4>Select the areas where your (entire) activity took place</h4>
            <p>You can view the exact allowed geographical areas on the event page.</p>
            <div v-for="(item, idx) in geoRestrictedRaces" :key="item.id">
              <v-checkbox v-model="approvedRaces" multiple :value="item.id" :label="item.name" hide-details/>
            </div>
          </div>
          <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || $t('shared.validation-error-message') }}</v-alert>
          <div v-if="validationError" class="mt-8 mb-8 d-flex">
            <v-btn :disabled="!valid" color="primary" outlined :loading="$store.getters.isLoading" @click="submit(true)">{{$t('profile.activities.entry.save-anyway')}}</v-btn>
            <v-spacer/>
            <v-btn :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('profile.activities.entry.validate-again')}}</v-btn>
          </div>
          <v-btn v-else class="mt-8 mb-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('shared.save')}}</v-btn>
        </v-card-text>   
      </v-form>

      <!-- <div id="ga_20278199"> </div><div style="text-align:right; width:300px; padding:5px 0;">
        <img src="https://bcdn.grmtas.com/images/healthyads-logo.png" alt="logo" style="float:right; border:none;" />
        <div style="width:auto; padding:1px 5px 0 0; float:right; display:inline-block; font-family:Verdana, Geneva, sans-serif; font-size:11px; color:#333;">
            <a href="https://www.healthyads.com" target="_blank" title="Health Ads" style="text-decoration:none; color:#333;">Health Ads</a> by
        </div>
      </div> -->

    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import moment from 'moment-timezone'
import siteData from '@/data/site.json'
import profileService from "@/services/profileService";
import activityService from "@/services/activityService";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";
import Header from './_Header.vue';
import VueMarkdown from 'vue-markdown'

export default {
  name: "ManualActivityEntry",
  components: {
    VueMarkdown,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
    Header,
  },
  props: {
  },
  data() {
    return {
      activityService: activityService,
      activity: null,
      profile: null,
      availableActivityTypes: ['_ALL'],
      forceShowAllActivityTypes: false,
      geoRestrictedEvents: null,
      approvedRaces: null,
      timeZoneOlson: null,
      error: null,
      valid: true,
      siteData: siteData,
      validationError: false,
      infoMessage: null,
      numberRules: [
        () => activityService.validateDistance(this.activity),//(this.activity.dist > 0 || this.activity.type === 'OTHER' || this.activity.type === 'YOGA' || this.activity.type === 'INDOOR_CARDIO' || this.activity.type === 'INDOOR_CYCLING') || "Please enter a positive number",
      ],
      breadcrumbItems: [
        { text: this.$t('profile.title'), href: '/profile' },
        { text: this.$t('profile.activities.title'), href: '/profile/activities' },
        { text: this.$t('profile.activities.entry.title'), disabled: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    this.timeZoneOlson = moment.tz.guess();

    const id = this.$route.query.id;
    if (id) {
      var model = (await activityService.get(id)).data;
      console.log('Activity loaded', model);
      if (model) {
        this.activity = {
          id: id,
          start: model.start,
          type: model.type,
          dist: model.dist,
          elevation_gain: model.elevation_gain,
          elevation_loss: model.elevation_loss,
          kcal: model.kcal,
          custom: model.custom,
          time_s: model.time_s,
        }
      }
    }
    if (!this.activity) {
      this.activity = {
        start: moment().startOf('day').toISOString(/*keep offset*/true),
        type: this.showAllActivityTypes ? 'RUNNING' : this.availableActivityTypes[0],
        time_s: 0,
      }
      if (this.$route.query.start) {
        this.activity.start = moment(this.$route.query.start).startOf('day').toISOString(/*keep offset*/true);
      }
      if (this.$route.query.duration) {
        this.activity.time_s = parseInt(this.$route.query.duration);
      }
      if (this.$route.query.distance) {
        this.activity.dist = parseInt(this.$route.query.distance);
      }
    }
    console.log('Initiated manual entry', this.activity.start, this.activity.time_s);
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

  },
  methods: {
    getTodayDate() {
      var date = new Date();
      date.setHours(0,0,0,0);
      return date;
    },

    async loadData() {
      if (this.user) {
        this.profile = (await profileService.get(/*full:*/false)).data;
        this.availableActivityTypes = (await activityService.getActivityTypes()).data.data;
        console.log('this.availableActivityTypes', this.availableActivityTypes);
      }
    },

    activityTypeChanged() {
      console.log('this.activity.type',this.activity.type);
      if (this.activity.type == 'STAIR_CLIMBING') {
        this.activity.dist = 1;
      }
      if (this.$refs.form.value === false) {
        // validation depends on activity type, so revalidate pls
        // only do when currently invalid to prevent that switching activity type shows 
        this.$refs.form.validate(); 
      }
    },

    async submit(ignoreValidation) {
      if (this.$refs.form.validate()) {
        this.error = null;
        this.infoMessage = null;
        this.validationError = false;
        try {
          //var validation = (await activityService.validate(this.activity)).data;
          console.log('Storing manual entry', this.activity);
          var model = {
            approved_race_ids: this.approvedRaces,
            ...this.activity,
          };
          var data = (await activityService.manualEntry(model, ignoreValidation)).data;
          if (data.status === "OK"){
            this.$router.push({ name: 'activity', params: { id: data.id } });
          }
          else if (data.status === "INFO") {
            this.infoMessage = data.msg;
            this.validationError = true;

            if (data.id === "GEO_MISSING") {
              this.geoRestrictedEvents = (await profileService.getGeoRestrictedEvents(this.activity)).data.data;
              console.log('geoRestrictedEvents', this.geoRestrictedEvents);
              console.log('geoRestrictedRaces', this.geoRestrictedRaces);
            }
          }
          else {
            this.error = data.msg;
            this.validationError = true;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    showAllActivityTypes() {
      return this.forceShowAllActivityTypes || !this.availableActivityTypes || this.availableActivityTypes.some(x => x === '_ALL') || this.availableActivityTypes.length > 5;
    },
    showCustomField() {
      return this.activity && (['VOLUNTEERING', 'OTHER'].some(x => x === this.activity.type))
    },
    geoRestrictedRaces() {
      return this.geoRestrictedEvents == null ? [] : this.geoRestrictedEvents.reduce((acc, item) => {
        const races = item.races.map(x => ({ id: `${item.id}_${x.id}`, name: `${item.name}: ${x.name}`}));
        return [...acc, ...races];
      }, []);
    },
    unitTypeDiplay() {
      return this.profile == null || this.profile.unit == 'METRIC' ? 'km' : 'mi';
    },
    elevationUnitTypeDiplay() {
      return this.profile == null || this.profile.unit == 'METRIC' ? 'm' : 'ft';
    },
    isDownhill() {
      return this.activity && (this.activity.type == 'ALPINE_SKIING' || this.activity.type == 'SNOWBOARDING');
    },
    showDistance() {
      return this.activity && !activityService.zeroDistanceActivities(this.activity);
    },
    showElevation() {
      return this.activity && !['VOLUNTEERING', 'SWIMMING', 'STAIR_CLIMBING'].some(x => x === this.activity.type);
    },
    showElevationLoss() {
      return this.activity && (this.isDownhill || this.activity.type == 'CYCLING');
    },
    computedElevationGain:{
      get () {
        return this.activity ? this.activity.elevation_gain : null
      },
      set (value) {
        this.activity.elevation_gain = value === /*must be triple!*/ 0 ? null /* store 0 as null */ : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },
    computedElevationLoss:{
      get () {
        return this.activity ? this.activity.elevation_loss : null
      },
      set (value) {
        this.activity.elevation_loss = value === /*must be triple!*/ 0 ? null /* store 0 as null */ : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },

  },

};
</script>
<style lang="scss">
</style>

