<template>
  <div v-if="event && race">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <Header :event="event"/>
      <RaceResultFilter :meta="meta" :event="event" :race="race" title="Manage race results" @change="updateResults"/>
      <v-card-text>
        Use this page to view results and disqualify invalid entries.
      </v-card-text>
      <RaceResultsGrid :event="event" :race="race" :results="results" :meta="meta" :filter="resultFilter" adminMode @refresh="loadData" @show-profile-details="showProfileDetails"/>
      <ProfileDetailsDialog :eventId="event.id" :event="event" ref="profileDetailsDialog" />
    </v-card>
  </div>
</template>

<script>
import eventService from "@/services/eventService";
import RaceResultFilter from "@/components/RaceResultFilter.vue";
import RaceResultsGrid from "@/components/RaceResultsGrid.vue";
import ProfileDetailsDialog from "@/components/ProfileDetailsDialog.vue";
import SodispRules from '@/components/SodispRules.vue';
import Header from './_Header.vue';

export default {
  name: "RaceResults",
  components: {
    SodispRules,
    RaceResultsGrid,
    RaceResultFilter,
    ProfileDetailsDialog,
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      race: null,
      meta: null,
      resultPage: null,
      resultFilter: null,
      results: null,
      showPointInfo: false,
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      var id = this.$route.params.id;
      var raceId = this.$route.params.raceId;
      this.event = (await eventService.get(id)).data;
      this.race = this.event.races.find(x => x.id === raceId);
      const response = (await eventService.getRaceResults(id, raceId, this.resultFilter, this.resultPage)).data;
      this.meta = response.meta;
      this.results = response.data;
    },
    async updateResults(filter) {
      this.resultFilter = filter;
      this.loadData();
    },
    async showProfileDetails(result) {
      var id = result.id.startsWith('p_') ? result.id.substring(2) : result.id;
      this.$refs.profileDetailsDialog.open(id);
    }

  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'results', disabled: true },
      ];
    },
  },

};
</script>
<style lang="scss">
</style>

