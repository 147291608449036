<template>
  <div>
    <v-scroll-y-transition>
      <v-alert v-show="voted" type="success" border="left" outlined icon="fa-thumbs-up">
        Thanks for sharing your feedback! Please <a :href="`mailto:hello@sodisp.com?subject=${encodeURIComponent('[Feedback] ' + question)}`">send us a message with additional feedback</a> if you have more in-depth feedback you'd like to share.
      </v-alert>
    </v-scroll-y-transition>
    <v-scroll-y-reverse-transition>
      <v-alert v-if="!isHidden" v-show="!voted" type="info" border="left" outlined>
        <p class="grey--text mb-2">We would like to ask one quick question. It only takes one click and would help us optimize sodisp. Thanks!</p>
        <h3 class="mb-4">{{question}}</h3>
        <v-btn v-for="(item, idx) in items" :key="idx" small class="mr-4 mb-4" @click="processClick(item.value)">{{item.text}}</v-btn>
        <v-btn outlined small color="grey darken-2" class="mr-4 mb-4" @click="hide"><v-icon small class="mr-2">fal fa-times-circle</v-icon> Hide</v-btn>
      </v-alert>
    </v-scroll-y-reverse-transition>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies'

export default {
  name: "QuickSurvey",
  props: {
    name: String,
    question: String,
    items: Array,
  },
  data: function() {
    return {
      hidden: false,
      voted: false,
    };
  },
  mounted() {
    //VueCookies.remove(`survey-${this.name}`);
  },
  methods: {

    processClick(option) {
      this.$gtag.event(this.name, { 'event_category': 'survey', 'event_label': option });
      console.log('Clicked on ', option);
      //this.hidden = true;
      this.voted = true;
      this.setHideCookie();
    },

    hide() {
      this.$gtag.event(this.name, { 'event_category': 'survey', 'event_label': 'hide' });
      this.setHideCookie();
      this.hidden = true;
    },

    setHideCookie() {
      VueCookies.set(`survey-${this.name}`, true, '30d');
    },

  },
  computed: {
    isHidden() {
      const cookieValue = VueCookies.get(`survey-${this.name}`);
      return this.hidden || cookieValue;
    },
  },

};
</script>

<style scoped>
</style>