
export default {
    customProperties: null,

    arrayEquals(array1, array2) {
        if (array1 == null && array2 == null) {
            return true;
        }
        return array1 != null && array2 != null && array1.length === array2.length && array1.every((value, index) => value === array2[index]);
    },
    enableCustomTrackers(self, properties) {
        if (this.arrayEquals(properties, this.customProperties)) {
            // nothing to do
            //console.log('[analytics] ignoring: ', properties, this.customProperties);
            return;
        }
        //console.log('[analytics] enabling properties: ', properties, this.customProperties);
        this.customProperties = properties;

        if (!this.gaEnabled()) {
            return;
        }

        for (const property of properties) {
            //console.log('[analytics] enabling property: ', property);
            self.$gtag.query('config', property, {'send_page_view': true});
            window.enableAnalyticsProperty(property);
        }
    },
    disableCustomTrackers(self) {
        if (!this.gaEnabled() || this.customProperties == null || this.customProperties.length == 0){
            return;
        }

        //console.log('[analytics] disabling properties: ', this.customProperties);
        for (const property of this.customProperties) {
            //console.log('[analytics] disabling property: ', property);
            window.disableAnalyticsProperty(property);
        }
        this.customProperties = null;
        
    },
    gaEnabled() {
        //console.log('[analytics] ga enabled: ', window.isAnalyticsEnabled());
        return window.isAnalyticsEnabled();
    },
}
