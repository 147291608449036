<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <v-card-title class="subtitle">
        Virtual Event Simulator
      </v-card-title>
      <v-card-text>
        This Simulator allows you to see for yourself how your event will look. It's based on test data and after you've prepared your virtual simulation run you can run it day by day and see how the virtual leaderboard exactly works.
      </v-card-text>
      <v-toolbar dark>
        <v-btn class="" :disabled="event != null" @click="showOptions=true">
          <v-icon>mdi-magnify</v-icon>
          Create
        </v-btn>
        <v-btn class="" :disabled="event == null" @click="run" :loading="$store.getters.isLoading">
          <v-icon class="mr-2">fa-play</v-icon>
          Run one day
        </v-btn>
        <v-select 
          v-if="event && event.races.length > 1"
          :items="event.races" 
          item-text="name" 
          item-value="id" 
          solo
          v-model="raceId" 
          hide-details
          style="max-width:250px" 
          class="mr-4 mt-0 pt-0 " 
          />

        <v-spacer></v-spacer>
        <v-btn v-if="!$store.getters.isLoading" class="" :disabled="event == null" @click="restart">
          <v-icon class="mr-2">fa-redo</v-icon>
          Restart
        </v-btn>
        <v-btn v-if="!$store.getters.isLoading" class="" :disabled="event == null" @click="clean">
          <v-icon class="mr-2">fa-trash</v-icon>
          Clean up
        </v-btn>
      </v-toolbar>
      <div v-if="event && race">
        <RaceResultFilter :meta="meta" :event="event" :race="race" title="Simulated Leaderboard" @change="updateResults" :showRaceDropdown="false"/>
        <RaceResultsMap ref="resultMap" :event="event" :race="race" :badges="badges" :meta="meta" @showResultDetails="openResultPopup"/>
        <v-card-text>
          This list shows the simulated leaderboard.
        </v-card-text>
        <RaceResultsGrid :event="event" :race="race" :results="results" :meta="meta" :filter="resultFilter" @refresh="loadData"/>

        <div v-if="badges && badges.length > 0">
          <v-card-title class="sutitle">Badges</v-card-title>
          <RaceBadgesGrid :event="event" :race="race" :badges="badges" />
          <v-divider></v-divider>
        </div>

        <v-card-text>
          Save this link to continue with this simulation at a later stage (simulations are stored for 7 days):
          <pre>https://sodisp.com/profile/eventmanager/simulator?id={{simulationId}}</pre>
        </v-card-text>
        
      </div>
    </v-card>

    <RaceResultDetailsDialog ref="resultPopup" :event="event" :race="race" :meta="meta" :filter="resultFilter" />

    <v-dialog v-if="profile" v-model="showOptions" max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">Simulation Configuration</span>
        </v-card-title>
        <v-card-text>
          <v-alert type="success">Configure the simulation to your liking. Please note that there are more options and features available for actual virtual events and challenges.</v-alert>
          <v-row>
            <v-col cols="12">
              <h3>Select your Challenge Type</h3>
              <v-btn-toggle v-model="raceModel.scoring" mandatory class="scoring-group" @change="changeScoringMethod">
                <v-btn v-for="item in siteData.scoring_methods.filter(x => x.type != 'BADGES' && x.type != 'CUSTOM')" :key="item.type" :value="item.type">
                  <v-icon x-large color="primary">fadl fa {{$helpers.getScoringIcon(item.type)}}</v-icon>
                  <span>{{item.text}}</span>
                </v-btn>
              </v-btn-toggle>
              <p class="font-weight-bold">{{ siteData.scoring_methods.find(x => x.type == raceModel.scoring).description }}</p>
            </v-col>
            <v-col cols="12">
              <h3>Is it a team or individual challenge?</h3>
              <v-btn-toggle v-model="raceModel.team" mandatory class="scoring-group" @change="changeTeamToggle">
                <v-btn value="">
                  <v-icon x-large color="primary">fadl fa fa-user</v-icon>
                  <span>Individual</span>
                </v-btn>
                <v-btn value="TEAM">
                  <v-icon x-large color="primary">fadl fa fa-users</v-icon>
                  <span>Team</span>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                :items="siteData.activities"
                v-model="raceModel.activity_types" 
                multiple
                item-value="type"
                item-text="text"
                label="Sports"
                placeholder="All Sports"
                @change="changeRaceType"
              >
                <template v-slot:prepend-item>
                  <v-list-item 
                    @click="checkAllActivities"
                  >
                    <v-list-item-action>
                      <v-icon>{{ allActivitiesSelected ? 'fa-check-square' : '' }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>All sports</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <DistanceTextArea
                v-model="raceModel.dist" 
                :unit="profile.unit"
                :label="distanceLabel" 
                :mode="goalDistanceUnitType"
                :multiplier="goalDistanceMultiplier"
                >
              </DistanceTextArea>
            </v-col>
            <v-col v-if="raceModel.scoring == 'CUSTOM'" cols="4" sm="4" md="4">
              <v-text-field
                v-model="raceModel.custom" 
                label="Custom scoring unit" 
                >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-switch v-model="raceModel.overshoot" class="mt-0" label="Allow overshoot" hint="Allow more than 100% completion of goal." persistent-hint></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch v-if="supportsCollectiveProgress" v-model="raceModel.collective" class="mt-0" label="Collective progress" hint="Enable to show the overall progress of all participants combined." persistent-hint></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch v-if="supportsCourseMap" v-model="raceModel.course_map" class="mt-0" label="Virtual course map" hint="Enable to show a virtual course map. The simulator always uses the same course (which will scale to your distance)." persistent-hint></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch v-if="supportsBadges" v-model="raceModel.badges" class="mt-0" label="Create Badges" hint="Enable to create a few badges for this simulator run." persistent-hint></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch v-model="raceModel.participation" class="mt-0" label="Participation Mode" hint="Check to disable ranking. Leaderboards are sorted by last activity date." persistent-hint></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="raceModel.team" cols="12" class="pb-0">
              <h3>Team configuration</h3>
            </v-col>
            <v-col cols="4" v-if="raceModel.team">
              <v-text-field 
                  v-if="raceModel.team"
                  v-model.number="computedAggregatedResults" 
                  type="number"
                  min="0" 
                  step="1" 
                  label="Aggregated results" 
                  persistent-hint
                  hint="Enter the number of results to aggregate into the team result.">
              </v-text-field>
            </v-col>
            <v-col cols="4" v-if="raceModel.team">
              <v-select
                v-if="raceModel.team"
                :items="siteData.team_gender_results_methods"
                v-model="raceModel.team_gender_results" 
                item-value="type"
                item-text="text"
                label="Gender sub-teams"
                persistent-hint
                hint="Select if and how you want gender based sub-teams."
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-divider class="mb-4"/>
              <h3>Simulation parameters</h3>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-text-field v-model.number="seedModel.participants" label="Number of participants" required/>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-text-field v-if="raceModel.team" v-model.number="seedModel.teams" label="Number of teams" required/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-alert v-if="creatingMessage" tile type="info">{{creatingMessage}}</v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="showOptions = false">Cancel</v-btn>
          <v-btn color="secondary" @click="create" :loading="$store.getters.isLoading">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import profileService from "@/services/profileService";
import eventService from "@/services/eventService";
import simulatorService from "@/services/simulatorService";
import DistanceTextArea from "@/components/DistanceTextArea.vue";
import RaceResultsGrid from "@/components/RaceResultsGrid.vue";
import RaceResultsMap from "@/components/RaceResultsMap.vue";
import RaceResultDetailsDialog from "@/components/RaceResultDetailsDialog";
import RaceResultFilter from "@/components/RaceResultFilter";
import RaceBadgesGrid from "@/components/RaceBadgesGrid";
import siteData from '@/data/site.json'

export default {
  name: "RaceResults",
  components: {
    DistanceTextArea,
    RaceResultsGrid,
    RaceResultsMap,
    RaceResultDetailsDialog,
    RaceBadgesGrid,
    RaceResultFilter,
  },
  props: {
  },
  data() {
    return {
      profile: null,
      eventId: null,
      raceId: 'race',
      creatingMessage: null,
      simulationId: null,
      event: null,
      race: null,
      resultPage: null,
      resultSearch: null,
      results: null,
      badges: null,
      meta: null,
      resultFilter: { },
      markers: null,
      siteData: siteData,
      showOptions: false,
      raceModel: {
        dist: 100000,
        type: 'RUNNING',
        team: false,
        scoring: 'DISTANCE',
      },
      seedModel: {
        teams: 0,
        participants: 25,
      }
    };
  },
  async mounted() {
    await this.loadData();
        
    if (this.$route.query.autorun) {
      await this.run();
    }
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

  },
  methods: {
    async loadData() {
      this.simulationId = this.simulationId || this.$route.query.id;
      if (this.user && this.profile == null) {
        this.profile = (await profileService.get(/*full:*/false)).data;
      }
      if (this.user && this.simulationId) {
        this.eventId = `itx-${this.simulationId}`
        this.event = (await eventService.get(this.eventId)).data;
        this.race = this.event.races.find(x => x.id === this.raceId) || this.event.races[0];
        this.raceId = this.race.id;
        const response = (await eventService.getRaceResults(this.eventId, this.raceId, this.resultFilter)).data;
        this.results = response.data || [];
        this.meta = response.meta;
        this.badges = (await eventService.getRaceBadges(this.eventId, this.raceId)).data.data;
        if (this.race.route) {
          const markerResponse = (await eventService.getRaceMarkers(this.eventId, this.raceId)).data;
          this.markers = markerResponse.data || [];
          if (this.markers.length > 0) {
            this.$refs.resultMap.loadMarkers(this.markers, this.meta);
          }
          else {
            this.$refs.resultMap.loadResults(this.results, this.meta);
          }
        }
      }
      else {
        this.showOptions = true; // open create race dialog
      }
    },
    async updateResults(obj) {
      console.log('Updating results with filter', obj);
      this.resultFilter = obj;
      this.loadData();
    },
    async search() {
      this.resultPage = null;
      this.loadData();
      if (this.race.route) {
        if (this.resultSearch || this.markers.length == 0) {
          this.$refs.resultMap.loadResults(this.results, this.meta);
        }
        else {
          this.$refs.resultMap.loadMarkers(this.markers, this.meta);
        }
      }
    },
    async changeTeamToggle() {
      this.seedModel.teams = this.raceModel.team ? 5 : 0;
    },
    async changeRaceType() {
      //this.raceModel.type = this.raceModel.activity_types && this.raceModel.activity_types.length > 0 ? null : this.raceModel.activity_types[0];

      if (this.isStairClimbing) {
        this.raceModel.dist = 10;
      }
    },
    async changeScoringMethod() {
      //this.raceModel.type = this.raceModel.activity_types && this.raceModel.activity_types.length > 0 ? null : this.raceModel.activity_types[0];
      
      if (this.isStairClimbing == 'STAIR_CLIMBING') {
        // reset race type
        this.raceModel.activity_types = ['RUNNING'];
      }
      if (this.raceModel.scoring == 'RESULT' || this.raceModel.scoring == 'TRACK') {
        this.raceModel.dist = 10000;
      }
      if (this.raceModel.scoring == 'DISTANCE') {
        this.raceModel.dist = 100000;
      }
      if (this.raceModel.scoring == 'ELEVATION') {
        this.raceModel.dist = 8848;
      }
      if (this.raceModel.scoring == 'BADGES') {
        this.raceModel.dist = 5;
      }
      if (this.raceModel.scoring == 'STAIRS') {
        this.raceModel.dist = 50;
        this.raceModel.activity_types = ['STAIR_CLIMBING'];
      }
      if (this.raceModel.scoring == 'CALORIES') {
        this.raceModel.dist = 10000;
      }
      if (this.raceModel.scoring == 'TIME') {
        this.raceModel.dist = 3600 * 10;
      }
    },
    async create() {
      this.creatingMessage = 'Creating simulation...';
      const event = (await simulatorService.create()).data;
      if (event && event.id) {
        this.eventId = event.id;
        this.simulationId = event.id.replace('itx-', '');
        console.log('Simulation created', this.eventId, this.simulationId);
        this.creatingMessage = 'Preparing simulation race...';
        await simulatorService.prepare(this.simulationId, this.raceModel);
        this.creatingMessage = 'Creating simulation participants...';
        await simulatorService.seed(this.simulationId, this.seedModel);
        //this.creatingMessage = 'Ready! Loading simulation state now...';
        this.loadData();
        this.creatingMessage = 'All set! Running first simulation day now...';
        await this.run();
        this.$router.replace({ name: 'eventmanagerSimulator', query: { id: this.simulationId }});
        this.showOptions = false;
      }
      else {
        this.creatingMessage = null;
      }
    },
    async run() {
      await simulatorService.run(this.simulationId);
      this.loadData();
    },
    async restart() {
      if (confirm('Are you sure you want to clear the leaderboard for this simulation?')) {
        await simulatorService.restart(this.simulationId);
        this.loadData();
      }
    },
    async clean() {
      if (confirm('Are you sure you want to delete this simulation and start over?')) {
        await simulatorService.clean(this.simulationId);
        this.simulationId = null;
        this.eventId = null;
        this.event = null;
        this.race = null;
        this.results = null;
        // load with fresh new state
        this.$router.push({ name: 'eventmanagerSimulator' });
        await this.loadData();
      }
    },
    async openResultPopup(id) {
      console.log ('opening popup', id, 'filter', this.resultFilter);
      await this.$refs.resultPopup.open(id);
    },
    checkAllActivities() {
      this.raceModel.activity_types = [];
    },
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Simulator', disabled: true },
      ];
    },
    allActivitiesSelected() {
      return !this.raceModel.activity_types || this.raceModel.activity_types.length == 0;
    },
    isStairClimbing() {
      return this.raceModel != null && this.raceModel.activity_types && this.raceModel.activity_types.length == 1 && this.raceModel.activity_types[0] == 'STAIR_CLIMBING';
    },
    unitTypeDiplay() {
      return this.profile == null || this.profile.unit == 'METRIC' ? 'km' : 'mi';
    },
    goalDistanceMultiplier() {
      if (this.raceModel.scoring == "TIME") {
        return 3600;
      }
      return null;
    },
    goalDistanceUnitType() {
      if (this.raceModel.scoring == "CALORIES" || this.raceModel.scoring == "TIME" || this.raceModel.scoring == "STAIRS" || this.isStairClimbing || this.raceModel.scoring == "BADGES" || this.raceModel.scoring == "CUSTOM") {
        return this.$helpers.UnitType.NUMBER;
      }
      else if (this.raceModel.scoring == "ELEVATION"){
        return this.$helpers.UnitType.ELEVATION;
      }
      return this.$helpers.UnitType.DISTANCE;
    },
    supportsBadges() {
      return this.raceModel.scoring == "DISTANCE" || this.raceModel.scoring == "ELEVATION" || this.raceModel.scoring == "STAIRS" || this.raceModel.scoring == "TIME" || this.raceModel.scoring == "CALORIES" || this.raceModel.scoring == "BADGES" || this.raceModel.scoring == "CUSTOM";
    },
    supportsCollectiveProgress() {
      return this.raceModel.scoring == "DISTANCE" || this.raceModel.scoring == "ELEVATION" || this.raceModel.scoring == "CUSTOM";
    },
    supportsCourseMap() {
      return this.raceModel.scoring == "DISTANCE" || this.raceModel.scoring == "ELEVATION" || this.raceModel.scoring == "TRACK" || this.raceModel.scoring == "CUSTOM";
    },
    distanceLabel() {
      if (this.raceModel.scoring == "RESULT" || this.raceModel.scoring == "TRACK") {
        return `Race distance in (in ${this.unitTypeDiplay})`;
      }
      else if (this.raceModel.scoring == "DISTANCE"){
        return `Total distance goal (in ${this.unitTypeDiplay})`;
      }
      else if (this.raceModel.scoring == "ELEVATION"){
        return `Total elevation goal (in ${this.unitTypeDiplay})`;
      }
      else if (this.raceModel.scoring == "CALORIES"){
        return `Total calorie goal (in kcal)`;
      }
      else if (this.raceModel.scoring == "TIME"){
        return `Total time goal (in hours)`;
      }
      else if (this.raceModel.scoring == "BADGES"){
        return `Total badges to earn`;
      }
      else if (this.raceModel.scoring == "STAIRS"){
        return `Total number of stairs goal`;
      }
      else if (this.raceModel.scoring == "CUSTOM"){
        return `Total number of ${this.raceModel.custom || 'points'}`;
      }
      return null;
    },
    computedAggregatedResults:{
      get () {
        return this.raceModel.aggregated;
      },
      set (value) {
        this.raceModel.aggregated = value === /*must be triple!*/ 0 ? null : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    raceId(val) {
      if (this.race && val != this.race.id) {
        this.loadData();
      }
    }
  }

};
</script>
<style lang="scss" >
  .scoring-group {
    width: 100%;
    flex-flow: row wrap; 
    button { 
      flex-grow: 1; flex-basis: 0; height: 100px !important;
      .v-btn__content {
        flex-direction: column;
        * {display: flex !important;}
        i { margin-bottom: 10px;}
      }
      //i { display: block !important; margin-bottom: 10px;}
      //.v-btn__content span { display: block !important; background: red!important; }
    }
    button:nth-child(n + 5) {
      order: 1;
    }
    button:nth-child(n + 8) {
      order: 2;
    }
  }
  .scoring-group::before/*, .scoring-group::after*/ {
    content: '';
    /* from https://stackoverflow.com/questions/29732575/how-to-specify-line-breaks-in-a-multi-line-flexbox-layout */
    flex-basis: 100%; height: 0; margin: 0; border: 0;
    order: 1;
  }
</style>

