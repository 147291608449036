<template>
  <v-row>
    <v-col cols="6">
    <v-menu
      ref="till"
      v-model="showPicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="formatted"
          :disabled="disabled"
          :label="hideDetails ? null : label"
          :hint="hideDetails ? null : formattedHint"
          :persistent-hint="!hideDetails"
          :hide-details="hideDetails"
          prepend-icon="fa-calendar"
          readonly
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker 
        no-title 
        :show-current="false"
        :disabled="disabled"
        v-bind:value="value"
        v-on:input="handleChange"
        >
      </v-date-picker>
    </v-menu>
    </v-col>
    <v-col cols="6">
        <v-input
          :disabled="disabled"
          prepend-icon="fa-clock"
          :hide-details="hideDetails"
          class="time-picker v-text-field "
        >
          <label v-if="!hideDetails" class="text-muted ">{{ timeLabel || 'Time of Day' }}</label>
          <input v-if="disabled" disabled="disabled" readonly="readonly" type="text" :value="timeFormatted"/>
          <vue-timepicker v-else v-model="timeFormatted" :format="timeFormat" :minute-interval="10" @change="timeChangeHandler"></vue-timepicker>
        </v-input>

    </v-col>
  </v-row>
</template>



<script>
import moment from 'moment-timezone'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: "DateAndTimeWithTimeZonePicker",
  components: {
    VueTimepicker,
  },
  props: {
    value: String,
    label: String,
    timeLabel: String,
    timeZone: String,
    hideDetails: Boolean,
    forceShowTime: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      showPicker: false,
      showTimePicker: false,
      time: null,
      valueWithTime: null,
      timeFormat: 'hh:mm A',
    };
  },
  async mounted() {
  },
  methods: {
    timeChangeHandler(e){
      console.log('Time changed to', e);
      var m = moment(this.value).hours(e.data.HH).minutes(e.data.mm).seconds(0);
      this.valueWithTime = m.toISOString(/*keep offset*/true);
      this.$emit('input', this.valueWithTime); 
    },
    handleChange(e) {
      this.valueWithTime = moment(e).startOf('day').toISOString(/*keep offset*/true);
      console.log('Storing raw date value', e, 'time part',  this.time, 'parsed', this.valueWithTime);
      this.$emit('input', this.valueWithTime); 
//      this.$emit('input', e); 
      this.showPicker = false;
      this.showTimePicker = false;
    },
    getTodayAsString() {
      return moment().startOf('day').toISOString(/*keep offset*/true);
    },
  },
  computed: {
    valueAsIsoDate() {
      console.log('DATE formatted', moment(this.currentValue).format('yyyy-mm-dd'));
      return this.currentValue == null ? null : moment(this.currentValue).format('yyyy-mm-dd');
    },
    formatted() {
      return this.currentValue == null ? null : moment(this.currentValue).format('L');
    },
    formattedHint() {
      return this.currentValue == null || this.timeZone == null ? null : moment.tz(this.currentValue, this.timeZone).format(this.hasTime ? 'L LT Z z' : 'L Z z');
    },
    timeFormatted: {
      //return this.currentValue == null || !this.hasTime  ? null : moment(this.currentValue).format('LT');
      get: function() {
        return this.currentValue == null || !this.hasTime  ? null : moment(this.currentValue).format(this.timeFormat);
      },
      set: function(v) {
        console.log('Changing time to', v);
      }
    },
    hasTime() {
      return this.currentValue != null && (this.forceShowTime || !(moment(this.currentValue).hours() == 0 && moment(this.currentValue).minutes() == 0));
    },
    currentValue() {
      return this.valueWithTime || this.value;
    }
  },
  watch: {
    value(newValue) {
      console.log('Loading date+time', newValue);
    },
    time(newTimeString) {
      console.log('Time changing to ' + newTimeString, moment.duration(newTimeString+ ":00").asMinutes());
      var m = moment(this.value).hours(0).minutes(0).seconds(0).add(moment.duration(newTimeString+":00").asMinutes(), 'minutes');
      this.valueWithTime = m.toISOString(/*keep offset*/true);
      this.$emit('input', this.valueWithTime); 
    }
  }
};
</script>

<style lang="scss">
.time-picker {
  label {position: absolute; top: -15px;}
  .vue__time-picker {display: flex; flex: 1 1 auto; margin-top:0px; top: 0px; }
  .vue__time-picker input.display-time { 
    border: 0; 
    line-height: 20px;
    padding: 8px 0 8px;
  }
  .vue__time-picker input.display-time.is-empty { 
    height: 32px;;
  }
  /*.v-text-field { border-bottom: ;}*/
}
</style>

