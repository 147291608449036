import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    getConfig() {
        //console.log('TOKEN', store.getters.jwtToken);
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    slugify(value) {
        return services.http.post(`/v1.0/eventmanager/slugify`, qs.stringify({ value: value}), this.getConfig());
    },
    getOwnedEvents() {
        return services.http.get(`/v1.0/eventmanager`, this.getConfig());
    },
    getRecentEvents() {
        return services.http.get(`/v1.0/eventmanager/recent`, this.getConfig());
    },
    create(model) {
        return services.http.post(`/v1.0/eventmanager/`, model, this.getConfig());
    },
    get(id) {
        return services.http.get(`/v1.0/eventmanager/${id}`, this.getConfig());
    },
    put(model) {
        return services.http.put(`/v1.0/eventmanager/${model.id}`, model, this.getConfig());
    },
    addTrainingPlan(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/training-plans`, model, this.getConfig());
    },
    submitForApproval(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/submit`, model, this.getConfig());
    },
    duplicate(id, newName) {
        return services.http.post(`/v1.0/eventmanager/${id}/duplicate`, qs.stringify({ newName: newName}), this.getConfig());
    },
    duplicateAsSimulation(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/create-simulator`, null, this.getConfig());
    },
    upload(id, type, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/upload/${type}`, model, this.getConfig());
    },
    uploadRaceGpx(id, raceId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/upload/gpx`, model, this.getConfig());
    },
    uploadRaceGeofence(id, raceId, geofencePoly) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/upload/geofence`, qs.stringify({ poly: geofencePoly}), this.getConfig());
    },
    uploadRaceResults(id, raceId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/upload/results`, model, this.getConfig());
    },
    uploadRegistrations(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/upload/registrations`, model, this.getConfig());
    },
    uploadPreloadedActivities(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/upload/preloadedactivities`, model, this.getConfig());
    },
    getPreloadedActivitiesInfo(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/preloadedactivities`, this.getConfig());
    },
    getRegistrations(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/registrations`, this.getConfig());
    },
    getParticipants(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants`, this.getConfig());
    },
    getParticipant(id, profileId) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants/${profileId}`, this.getConfig());
    },
    putParticipant(id, profileId, raceIds) {
        return services.http.put(`/v1.0/eventmanager/${id}/participants/${profileId}`, qs.stringify({ raceIds: raceIds}), this.getConfig());
    },
    getProfile(id, profileId) {
        return services.http.get(`/v1.0/eventmanager/${id}/profiles/${profileId}`, this.getConfig());
    },
    disconnectParticipant(id, profileId, deleteResults) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/disconnect`, qs.stringify({ deleteResults: deleteResults}), this.getConfig());
    },
    reprocessParticipant(id, profileId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/reprocess`, {}, this.getConfig());
    },
    syncTeamsForParticipant(id, profileId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/syncteams`, {}, this.getConfig());
    },
    getParticipantActivityDetails(id, profileId, activityId) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}`, this.getConfig());
    },
    addParticipantActivity(id, profileId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/activities`, model, this.getConfig());
    },
    editParticipantActivity(id, profileId, activityId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}`, model, this.getConfig());
    },
    deleteParticipantActivity(id, profileId, activityId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}`, this.getConfig());
    },
    approveParticipantActivityDetails(id, profileId, activityId, raceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}/approve/${raceId}`, this.getConfig());
    },
    deleteResult(id, raceId, resultId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/${resultId}/disqualify`, {}, this.getConfig());
    },
    recalculateResult(id, raceId, resultId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/${resultId}/recalculate`, {}, this.getConfig());
    },
    deleteProfileFromTeamResult(id, raceId, resultId, profileId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/${raceId}/${resultId}/profiles/${profileId}`, this.getConfig());
    },
    getGroupParticipants(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/groupparticipants`, this.getConfig());
    },
    getAllowlist(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/allowlist?raceId=${raceId||''}`, this.getConfig());
    },
    putAllowlist(id, raceId, data) {
        return services.http.put(`/v1.0/eventmanager/${id}/allowlist?raceId=${raceId||''}`, qs.stringify({ allowlist: data}), this.getConfig());
    },
    getDenylist(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/denylist`, this.getConfig());
    },
    releaseCode(id, code) {
        return services.http.delete(`/v1.0/eventmanager/${id}/denylist/${code||''}`, this.getConfig());
    },

    getPermissions(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/permissions`, this.getConfig());
    },
    addPermissions(id, profileId) {
        return services.http.put(`/v1.0/eventmanager/${id}/permissions/${profileId}`, {}, this.getConfig());
    },
    deletePermissions(id, profileId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/permissions/${profileId}`, this.getConfig());
    },

    exportReport(id, report) {
        return services.http.get(`/v1.0/eventmanager/${id}/export/${report}`, { ...this.getConfig(), responseType: 'blob' });
    },
    exportRaceReport(id, raceId, report) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/export/${report}`, { ...this.getConfig(), responseType: 'blob' });
    },
    sendRaceCompletedEmail(id, raceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/email/completed`, {}, this.getConfig());
    },
    sendRaceCertificateEmail(id, raceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/email/certificate`, {}, this.getConfig());
    },
    sendBibEmail(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/bib`, {}, this.getConfig());
    },
    sendInviteEventEmail(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/invite`, {}, this.getConfig());
    },
    sendFriendInviteEmail(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/friendinvite`, {}, this.getConfig());
    },
    sendInviteEventEmailToCode(id, code) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/invite/${code}`, {}, this.getConfig());
    },
    reinviteUnconnected(id, code) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/invite/unconnected`, {}, this.getConfig());
    },

    moderatePhoto(id, photoId, action) {
        return services.http.post(`/v1.0/eventmanager/${id}/photos/${photoId}/${action}`, this.getConfig());
    },
    putPhotoOverlays(id, overlays) {
        return services.http.put(`/v1.0/eventmanager/${id}/overlays`, overlays, this.getConfig());
    },
    uploadPhotoOverlay(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/overlays/image`, model, this.getConfig());
    },

    getOAuthClient(id, provider) {
        return services.http.get(`/v1.0/eventmanager/${id}/oauthclients/${provider}`, this.getConfig());
    },
    putOAuthClient(id, provider, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/oauthclients/${provider}`, model, this.getConfig());
    },

    getActivityConversions(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/conversions`, this.getConfig());
    },
    putActivityConversions(id, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/conversions`, model, this.getConfig());
    },

    putWebhooks(id, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/webhooks`, model, this.getConfig());
    },

    getBibPreview(id, participantId) {
        return services.http.get(`/v1.0/eventmanager/${id}/bib/preview?participantId=${participantId||''}`, { ...this.getConfig(), responseType: 'blob' });
    },

    getCertificatePreview(id, raceId, resultId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/certificate/preview?resultId=${resultId||''}`, { ...this.getConfig(), responseType: 'blob' });
    },

    getRelaySlots(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/relayslots`, this.getConfig());
    },
    putRelaySlots(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/relayslots`, model, this.getConfig());
    },

    getResultColumns(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/columns`, this.getConfig());
    },
    putResultColumns(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/columns`, model, this.getConfig());
    },
    cloneResultColumns(id, raceId, raceIds) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/columns/clone`, qs.stringify({ raceIds: raceIds}), this.getConfig());
    },

    getBadges(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/badges`, this.getConfig());
    },
    putBadge(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/badges`, model, this.getConfig());
    },
    previewBadge(id, raceId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/preview`, model, this.getConfig());
    },
    deleteBadge(id, raceId, badgeId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}`, this.getConfig());
    },
    sendBadgeEmail(id, raceId, badgeId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/email`, {}, this.getConfig());
    },
    uploadBadgeImage(id, raceId, badgeId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/image`, model, this.getConfig());
    },
    uploadBadgeDetailsImage(id, raceId, badgeId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/detailsimage`, model, this.getConfig());
    },
    copyBadges(id, fromRaceId, toRaceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${fromRaceId}/badges/copy?toRaceId=${toRaceId}`, {}, this.getConfig());
    },

}