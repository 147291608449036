<template>
  <div v-if="event && race">
    <v-breadcrumbs v-if="!isEmbedded" :items="breadcrumbItems"/>
    <v-card :elevation="isEmbedded ? 0 : 2">
      <EventHeader v-if="!isEmbedded" :event="event" :title="race.name"/>
      <EventFriendReferral :event="event" :race="race"/>
      <v-card-title v-if="race.route || true" class="headline">
        <span class="mr-2">{{$t('events.race.leaderboard')}}</span>
        <span class="mr-2">{{ race.name }}</span>
        <span v-if="meta && meta.total_count" class="" style="font-size:70%;">({{meta.total_count}} {{$t('events.race.results-label')}})</span>
      </v-card-title>
      <RaceResultFilter :meta="meta" :event="event" :race="race" :title="$t('events.race.title')" :filter="resultFilter" :showRace="!isEmbedded" @change="updateResults" ref="filter"/>
      <v-card-text v-if="event.distancing">
        {{$t('events.race.social-distancing-message')}}
        <router-link to="/socialdistancing" color="accent">{{$t('shared.read-more')}} &raquo;</router-link> 
      </v-card-text>
      <RaceResultsMap ref="resultMap" :event="event" :race="race" :badges="badges" :meta="meta" @showResultDetails="openResultPopup" @showBadgeDetails="openBadgeDetails"/>
      <RaceResultsTileMap ref="tileResults" v-if="tiles && tiles.length > 0" :event="event" :race="race" />
      <v-alert v-if="race.geofence" type="info" tile>
        {{$t('events.rules.geofence')}}
        <RaceGeoFenceDialog :event="event" :race="race" color="white" class="float-right" />
      </v-alert>      
      <vue-markdown v-if="race.result_desc" class="markdown mx-4" :html="false" :source="race.result_desc" />
      <RaceResultsGrid :event="event" :race="race" :results="results" :meta="meta" :badges="badges" :filter="resultFilter" @search="updateSearch"/>
      <v-pagination 
        v-if="meta && meta.total_count > 50 && !hasFilter" 
        v-model="resultPage" 
        circle 
        :length="Math.ceil(meta.total_count / 50)" 
        @input="loadPage"
        />
      <div v-if="meta && meta.has_more && hasFilter" class="full-width-separator text-center mt-6">
        <v-btn class="" rounded outlined @click="loadNextPage">{{$t('shared.load-more-data')}}</v-btn>
      </div>
      
      <div v-if="race.scoring === 'TRAINING_PLAN'">
        <v-card-title class="sutitle">{{$t('events.race.training-plan-progress')}}</v-card-title>
        <v-card-text>{{$t('events.race.training-plan-description')}}</v-card-text>
        <TrainingPlanProgress v-if="trainingPlan" :event="event" :race="race" :training-plan="trainingPlan" :meta="meta" :badges="badges" />
      </div>
      <div v-else-if="badges && badges.length > 0">
        <v-card-title class="sutitle">{{$t('events.race.badges')}}</v-card-title>
        <v-card-text>{{$t('events.race.badges-description')}}</v-card-text>
        <RaceBadgesGrid :event="event" :race="race" :badges="badges" @click="badgeSelected" />
        <v-divider></v-divider>
      </div>

      <v-divider></v-divider>
      <v-container v-if="!isEmbedded">
        <v-row>
          <v-col cols="12" sm="6" md="8" class="pl-0">
            <v-card-title class="subtitle">{{$t('events.about', {event: event.name})}}</v-card-title>
            <v-card-text>
              <vue-markdown class="markdown" :html="false">{{event.description }}</vue-markdown>
              <p>
                <v-icon small>{{ $helpers.getScoringIcon(race.scoring) }}</v-icon> {{ $t('events.rules.scoring-method', {method: $t('events.rules.scoring-methods.'+ race.scoring) }) }} 
                <span v-if="race.goal_desc">{{race.goal_desc}}</span>
                <span v-else>
                  {{ $t('events.rules.goal-is', {goal: $helpers.getGoalDistanceForDisplay($options, race, race.dist, event.unit)}) }}
                </span>
                <span v-if="event.distancing">{{$t('events.rules.results-compensated-social-distancing')}}</span>
              </p>
              <p v-if="race.min_elevation && race.min_elevation > 0"><v-icon small>fa-mountain</v-icon> {{$t('events.rules.minimum-elevation', { min_elevation : $options.filters.elevation(race.min_elevation, event.unit)})}}</p>
              <p v-if="race.max_dist && race.max_dist > 0"><v-icon small>fa-ruler</v-icon> {{$t('events.rules.distance', { min_dist : $options.filters.distance(race.min_dist, event.unit), max_dist : $options.filters.distance(race.max_dist, event.unit) })}}</p>
              <p v-if="race.geofence">
                <v-icon small>fa-draw-polygon</v-icon> {{$t('events.rules.geofence')}}
                <RaceGeoFenceDialog :event="event" :race="race"/>
              </p>
            </v-card-text>
          </v-col>
          <v-col v-if="race.type !== 'EXTERNAL'" cols="12" sm="6" md="4">
            <EventJoinCard :event="event"/>
          </v-col>
        </v-row>
      </v-container>
      <SodispRules v-if="!isEmbedded"/>
      <RaceResultDetailsDialog ref="resultPopup" :event="event" :race="race" :badges="badges"/>
      <BadgeDetailsDialog :event="event" :race="race" ref="badgeDetails" />
      <v-dialog v-if="highlightedBadge" v-model="highlightedBadge" width="600">
        <v-card>
          <v-card-title>
            {{ highlightedBadge.name }}
            <v-spacer/>
            <ShareButton color="primary" outlined/>
          </v-card-title>
          <v-card-text style="text-align:center;">
            <img :src="highlightedBadge.img" style="max-width: 100%; max-height:300px;"/>
          </v-card-text>
          <BadgeDetailsDialog :event="event" :race="race" :badgeId="highlightedBadge.id" :detailsType="highlightedBadge.details_type" />
        </v-card>
      </v-dialog>
      <v-dialog v-model="showShareCompleted" width="600">
        <v-card>
          <v-card-title>
            {{ event.name }}
            <v-spacer/>
            <ShareButton color="primary" outlined/>
          </v-card-title>
          <img :src="event.img" style="max-width: 100%;"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showShareCompleted = false">{{$t('shared.close')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showShareCertificate" width="600">
        <v-card>
          <v-card-title>
            {{ event.name }}
            <v-spacer/>
            <ShareButton color="primary" outlined/>
          </v-card-title>
          <img :src="certificateImageUrl" style="max-width: 100%;"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showShareCertificate = false">{{$t('shared.close')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import assetsService from "@/services/assetsService";
import eventService from "@/services/eventService";
import VueMarkdown from 'vue-markdown'
import EventHeader from "@/components/EventHeader.vue";
import RaceResultsGrid from "@/components/RaceResultsGrid.vue";
import RaceResultsMap from "@/components/RaceResultsMap.vue";
import RaceResultsTileMap from "@/components/RaceResultsTileMap.vue";
import RaceResultFilter from "@/components/RaceResultFilter.vue";
import RaceResultDetailsDialog from "@/components/RaceResultDetailsDialog";
import RaceBadgesGrid from "@/components/RaceBadgesGrid";
import SodispRules from '@/components/SodispRules.vue';
import EventJoinCard from '@/components/EventJoinCard.vue';
import siteData from '@/data/site.json'
import ShareButton from "@/components/ShareButton";
import BadgeDetailsDialog from "@/components/BadgeDetailsDialog";
import RaceGeoFenceDialog from "@/components/RaceGeoFenceDialog";
import EventFriendReferral from "@/components/EventFriendReferral";
import TrainingPlanProgress from "@/components/TrainingPlanProgress";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Race",
  components: {
    VueMarkdown,
    SodispRules,
    EventJoinCard,
    EventHeader,
    EventFriendReferral,
    RaceResultsGrid,
    RaceResultsTileMap,
    RaceResultsMap,
    RaceResultFilter,
    RaceResultDetailsDialog,
    BadgeDetailsDialog,
    RaceBadgesGrid,
    RaceGeoFenceDialog,
    TrainingPlanProgress,
    ShareButton,
  },
  props: {
  },
  data() {
    return {
      eventId: null,
      raceId: null,
      event: null,
      races: null,
      race: null,
      meta: null,
      markers: null,
      badges: null,
      tiles: null,
      trainingPlan: null,
      highlightedBadge: null,
      results: null,
      resultFilter: null,
      resultPage: 1,
      showPointInfo: false,
      showShareCompleted: false,
      showShareCertificate: false,
      certificateImageUrl: null,
      scoringMethods: siteData.scoring_methods,
    };
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    this.badges = null;
    this.$refs.resultMap.clear();
    await this.loadRace(to.params.id, to.params.raceId);
    console.log('TO', to.params.raceId, this.race, this.breadcrumbItems);
    next();
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      await this.loadRace(this.$route.params.id, this.$route.params.raceId);
    },
    async loadRace(eventId, raceId) {
      this.eventId = eventId;
      this.raceId = raceId;
      this.event = this.event || (await eventService.get(this.eventId)).data;
      this.races = this.event.races;
      this.race = this.event.races.find(x => x.id === this.raceId);
      console.log('Loading state', this.event, this.race);
      if (this.$route.query.q || this.$route.query.category || this.$route.query.gender) {
        console.log('Loading query:', this.$route.query);
        if (this.resultFilter == null) {
          this.resultFilter = { search: this.$route.query.q, category: this.$route.query.category, gender: this.$route.query.gender };
        }
      }
      const response = (await eventService.getRaceResults(this.eventId, this.raceId, this.resultFilter, this.resultPage)).data;
      this.results = response.data || [];
      this.meta = response.meta;
      if (this.race.route) {
        if (!this.hasFilter) {
          const markerResponse = (await eventService.getRaceMarkers(this.eventId, this.raceId)).data;
          this.markers = markerResponse.data || [];
        }
        //this.markers = [];
        if (this.markers && this.markers.length > 0) {
          this.$refs.resultMap.loadMarkers(this.markers, this.meta);
        }
        else {
          this.$refs.resultMap.loadResults(this.results, this.meta);
        }
      }
      var meta = null;
      if (this.badges == null) {
        this.badges = (await eventService.getRaceBadges(this.eventId, this.raceId)).data.data;
        if (this.race.route) {
          this.$refs.resultMap.ensureBadgesLoaded(this.badges);
        }
        var badgeId = this.$route.query.badge;
        this.highlightedBadge = this.badges.find(x => x.id == badgeId);
        //console.log('Sharing data for badge', highlightedBadge);
        if (this.highlightedBadge) {
          meta = {
            title: `I earned the badge ${this.highlightedBadge.name} at the ${this.event.name}`,
            description: `I earned the badge ${this.highlightedBadge.name} at the ${this.event.name} on ${tenant.title}`,
            image: this.highlightedBadge.img,
          }; 
        }
      }
      if (this.$route.query.share === 'completed') {
        this.showShareCompleted = true;
        meta = {
          title: `I completed the ${this.race.name} at the ${this.event.name}`,
          description: `I completed the ${this.race.name} at the ${this.event.name} on ${tenant.title}`,
          image: this.event.img,
        }; 
      }
      if (this.$route.query.share === 'certificate') {
        this.showShareCertificate = true;
        this.certificateImageUrl = this.$route.query.img;
        meta = {
          title: `I received my finisher certificate for the ${this.race.name} at the ${this.event.name}`,
          description: `I completed the ${this.race.name} at the ${this.event.name} on ${tenant.title}`,
          image: this.certificateImageUrl,
        }; 
      }

      meta = meta || {
        title: this.event.name + ' on ' + tenant.name, 
        description: this.event.description,
        image: this.event.img,
      }; 
      EventBus.$emit('page-header-change', meta);

      if (this.race.scoring === 'TRAINING_PLAN') {
        this.meta.badges = this.badges.map(x => ({ badge_id: x.id, total_count: x.count, first_reward_date: x.first_reward_date })); 
        this.trainingPlan = (await assetsService.getTrainingPlan(this.race.id)).data;
      }
      if (this.race.scoring === 'TILES') {
        this.tiles = (await eventService.getRaceTiles(this.eventId, this.raceId)).data.data;
        await this.$nextTick();
        this.$refs.tileResults.loadResults(this.tiles);
      }
    },

    async badgeSelected(badge) {
      this.highlightedBadge = this.badges.find(x => x.id == badge.id);
      this.$nextTick(() => {
        this.$refs.badgeDialog.open(badge);
      });
    },

    async updateSearch(query) {
      this.$refs.filter.loadFilter({search: query });
      this.$refs.filter.search();
    },

    async updateResults(filter) {
      this.resultPage = 1;
      this.resultFilter = filter;
      if (this.hasFilter) {
        this.markers = [];
      }
      await this.loadRace(this.eventId, this.raceId);
//      this.loadData();
      this.$gtag.event('search', {
        'event_category': 'results',
        'event_label': this.eventId,
      });
    },

    /*async search() {
      this.resultPage = 1;
      const response = (await eventService.getRaceResults(this.eventId, this.raceId, { search: this.resultSearch})).data;
      this.results = response.data;
      this.meta = response.meta;
      if (this.race.route) {
        if (this.resultSearch || this.markers.length == 0) {
          this.$refs.resultMap.loadResults(this.results, this.meta);
        }
        else {
          this.$refs.resultMap.loadMarkers(this.markers, this.meta);
        }
      }
      this.$gtag.event('search', {
        'event_category': 'results',
        'event_label': this.eventId,
      });
    },*/
    
    async openResultPopup(id) {
      console.log ('opening popup', id);
      await this.$refs.resultPopup.open(id);
    },

    async openBadgeDetails(badge) {
      await this.$refs.badgeDetails.open(badge);
    },

    async loadNextPage() {
      await this.loadPage(this.resultPage == null ? 2 : this.resultPage+1);
    },
    
    async loadPage(page) {
      this.resultPage = page;
      const response = (await eventService.getRaceResults(this.eventId, this.raceId, null, this.resultPage)).data;
      this.results = response.data;
      this.meta = response.meta;
      this.$gtag.event('search', {
        'event_category': 'results',
        'event_label': this.eventId,
      });

      /*if (this.race.route) {
        this.$refs.resultMap.loadNewData(this.results);
      }*/
    },

  },
  watch: {
  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.race.name, disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed';
    },
    hasFilter() {
      return this.resultFilter 
          && (this.resultFilter.search || this.resultFilter.gender || this.resultFilter.category);
    },
  },

};
</script>
<style lang="scss">

.result--filter { position: sticky; top: 59px; z-index:4; background-color: white; }
.view--embedded .result--filter { top: 0px;  }

</style>

