<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">
        Geo-fence for <RaceSelector :event="event" :race="race" routeName="eventmanagerRaceGeofence" />
      </v-card-title>

      <v-card-text>      
        Set a geo-fence for this race. Once a geo-fence is defined, only activities with all data points within the geo-fence may count towards the leaderboard. 
        Manual entries are not allowed as they will not have coordinates defined.
        <a v-if="tenant.isDefault" href="https://sodisp.freshdesk.com/en/support/solutions/articles/77000497974-defining-a-geo-fence-geo-restricted-area-" target="_blank"><i class="fadl fa fa-books"/> Documentation</a>

        <v-alert type="info" class="mt-8">This is a BETA feature. Feedback is welcome through our contact form :)</v-alert>
      </v-card-text>

      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/0.2.3/leaflet.draw.css" />
      <div id="map" ref="map" class="leaflet-map dynamic-content" style="height: 500px; width: 100%; z-index: 2;"/>

      <v-card-text>
        <!-- <v-btn color="primary" large @click="save">Save</v-btn> -->
        <v-btn color="primary" text @click="reset">Delete geo-fence</v-btn>
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import { latLng } from "leaflet";
import { leafletdraw } from "leaflet-draw";
import PolylineUtil from "@/plugins/Polyline.encoded.js";
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import RaceSelector from './_RaceSelector.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RelaySlots",
  components: {
    Header,
    RaceSelector,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
      race: null,
      map: null,
      polyline: null,
      poly: [],
      polyString: null,
      editableLayers: null,
      drawControl: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
    this.initMap();
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id, to.params.raceId);
    next();
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.initMap();
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    async reset() {
      if (confirm('Are you sure you want to clear the geo-fence?')){
        this.poly = null;
        this.polyString = null;
        const response = (await eventManagerService.uploadRaceGeofence(this.event.id, this.race.id, this.polyString)).data;
        this.$helpers.toastResponse(this, response, 'Successfully cleared geo-fence.');
        await this.getProfile();
      }
    },

    async save() {
      console.log('Saving', this.poly);
      if (this.polyString) {
        const response = (await eventManagerService.uploadRaceGeofence(this.event.id, this.race.id, this.polyString)).data;
        this.$helpers.toastResponse(this, response, 'Successfully saved geo-fence.');

        await this.getProfile();
      }
    },

    initMap() {
      console.log('Loading custom map now.');

      this.$nextTick(() => {
        console.log('Preparing map.', this.$refs.map);
        if (!this.$refs.map) {
          return;
        }
        if (!this.map) {
          this.map = window.L.map('map', {
              center: [0, 0],
              zoom: 3,
          });
          this.addDefaultTileLayer(this.map);
        }
        this.addEditor(this.race.geofence == null);

        if (this.race.geofence) {
          var polygon = window.L.Polygon.fromEncoded(this.race.geofence);
          console.log('Preloading geofence.', this.race.geofence, polygon);
          this.editableLayers.addLayer(polygon);
          this.map.fitBounds(polygon.getBounds());
        }
      })
    },
    addDefaultTileLayer(map) {
      window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);
    },

    addEditor(enableAdd) {
      if (this.map == null) {
        console.log('Waiting for map to initialize.');
        return;
      }
      if (this.editableLayers != null) {
        this.map.removeLayer(this.editableLayers);
      }
      if (this.drawControl != null) {
        this.map.removeControl(this.drawControl);
      }
            
      // Initialise the FeatureGroup to store editable layers
      this.editableLayers = new window.L.FeatureGroup();
      this.map.addLayer(this.editableLayers);

      var drawPluginOptions = {
        position: 'topright',
        draw: {
          polygon: enableAdd ? {
            shapeOptions: {
              color: '#00B46E',
              weight: 1
            }
          } : false,
          polyline: false,
          circlemarker: false, 
          circle: false, 
          rectangle: false,
          marker: false,
        },
        edit: {
          featureGroup: this.editableLayers, //REQUIRED!!
          remove: false
        }
      };

      // Initialise the draw control and pass it the FeatureGroup of editable layers
      this.drawControl = new window.L.Control.Draw(drawPluginOptions);
      this.map.addControl(this.drawControl);

      var self = this;
      this.map.on('draw:created', function(e) {
        var type = e.layerType, layer = e.layer;

        console.log('DRAWN', type, layer);
        self.editableLayers.addLayer(layer);
        if (type === 'polygon') {
          self.polyString = PolylineUtil.encode(layer.getLatLngs()[0]);
          self.save();
        }
      });
      this.map.on('draw:edited', function(e) {
        e.layers.eachLayer(function (layer) {
            console.log('draw:edited', layer, layer.getLatLngs);
            self.polyString = PolylineUtil.encode(layer.getLatLngs()[0]);
            self.save();
         });
      });
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, disabled: true },
        { text: 'Geo-fence', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

