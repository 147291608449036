<template>
  <v-card>
    <Header :title="$t('profile.heading')" showEdit >
    </Header>
<!--     <v-card-title class="display-2">
      <v-row>
        <v-col class="pa-0 pl-4 grow">{{$t('profile.heading')}}</v-col>
        <v-col class="pa-0 pr-4 shrink">
          <v-btn class="d-none d-sm-flex" color="accent" :to="{name:'profileEdit'}"><v-icon small class="far fa-edit mr-2" /> {{$t('profile.edit-profile')}}</v-btn>
          <v-btn class="d-flex d-sm-none" small color="accent" :to="{name:'profileEdit'}"><v-icon small class="far fa-edit mr-2" /> {{$t('shared.edit')}}</v-btn>
        </v-col>
      </v-row>
    </v-card-title>
 -->
    <v-alert v-if="profile && profile.random_name" tile type="success" prominent>
      <v-row align="center">
        <v-col class="grow" cols="12" md="8">
          <i18n path="profile.name-intro">
            <strong>{{profile.name}}</strong>
          </i18n>
        </v-col>
        <v-col class="shrink" cols="12" md="4">
          <v-btn :to="{name:'profileEdit'}">{{$t('profile.change-name')}}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-alert v-else-if="profile && !profile.email" tile type="success" prominent>
      <v-row align="center">
        <v-col class="grow" cols="12" md="8">{{$t('profile.email-intro')}} </v-col>
        <v-col class="shrink" col2="12" md="4">
          <v-btn :to="{name:'profileEdit'}">{{$t('profile.update-email')}}</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <div v-if="profile && profile.race_results && profile.race_results.length > 0">
      <MyRaceResultsOverview :results="profile.race_results"/>
      <MyTrainingPlanOverview ref="trainingPlans" :results="profile.race_results"/>
    </div>

    <v-card-title class="subtitle">{{$t('profile.activities.title')}}</v-card-title>
    <v-card-text>
      {{$t('profile.activities.intro')}}
    </v-card-text>
    <ActivityGrid v-if="profile" :limit="5" :profile="profile" compact />

    <v-card-text>      
      <v-btn outlined color="primary" v-if="user" :to="{ name: 'activities' }" :exact="true">{{$t('profile.activities.all')}}</v-btn>
      <v-btn text color="primary" :to="{name: 'activityEntry'}" :exact="true">{{$t('profile.activities.manualentry')}}</v-btn>
      <v-btn text color="primary" :to="{name: 'activityUpload'}" :exact="true">{{$t('profile.activities.upload')}}</v-btn>
    </v-card-text>      

    <!--<div id="ga_20278199"> </div><div style="text-align:right; width:300px; padding:5px 0;">
      <img src="https://bcdn.grmtas.com/images/healthyads-logo.png" alt="logo" style="float:right; border:none;" />
      <div style="width:auto; padding:1px 5px 0 0; float:right; display:inline-block; font-family:Verdana, Geneva, sans-serif; font-size:11px; color:#333;">
          <a href="https://www.healthyads.com" target="_blank" title="Health Ads" style="text-decoration:none; color:#333;">Health Ads</a> by
      </div>
    </div>-->

    <v-divider />
    <!-- <v-card-title class="subtitle">Welcome {{ profile.name }}</v-card-title> -->
    <ProfileConnectPanel :profile="profile" :providerUrls="providerUrls" />

    <div v-if="$oauth.hasConnection(profile)">
      <v-card-title class="subtitle">{{$t('profile.connections.status')}}</v-card-title>
      <v-card-text>
        <v-alert type="info">
          <i18n path="profile.connections.configured">
            <strong>{{profile.providers.join(' + ')}}</strong>
          </i18n>
        </v-alert>
        <p v-if="!profile.sync">{{$t('profile.connections.status-empty')}}</p>
        <v-alert v-else-if="profile.sync.status == 'OK'" type="success">{{$t('profile.connections.status-success', { timestamp: $options.filters.moment(profile.sync.timestamp, "L LTS") })}}</v-alert>
        <v-alert v-else-if="profile.sync.status == 'INFO'" type="info">{{$t('profile.connections.status-info', { timestamp: $options.filters.moment(profile.sync.timestamp, "L LTS"), message: profile.sync.msg })}}</v-alert>
        <v-alert v-else type="error">{{$t('profile.connections.status-error', { timestamp: $options.filters.moment(profile.sync.timestamp, "L LTS"), message: profile.sync.msg })}}</v-alert>
      </v-card-text>      
      <v-card-text class="pt-0">
        <v-btn outlined :to="{name: 'profileConnections'}" color="primary" class="">
          {{$t('profile.connections.manage')}}
        </v-btn>
      </v-card-text>      

      <v-divider />
    </div>

    <v-card-title class="subtitle">{{$t('profile.events-joined')}}</v-card-title>
    <v-card-text v-if="profile && (!profile.events || profile.events == 0)">
      <p class="mb-0">{{$t('profile.events-joined-none')}}</p>
    </v-card-text>      
    <EventGrid v-if="profile" :items="profile.events" class="mx-4 mb-4"></EventGrid>
    <p><v-btn outlined color="primary" to="/events" class="ml-4">{{$t('profile.find-event')}}</v-btn></p>
    <v-divider />

    <div v-if="profile">
      <v-card-actions>      
        <v-spacer/>
        <v-btn v-if="user" text  @click.prevent="signOut">{{ $t('nav.account.sign-out') }}</v-btn>
      </v-card-actions>       
    </div>      

  </v-card>
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import EventGrid from "@/components/EventGrid.vue";
import firebase from 'firebase/app'
import 'firebase/auth'
import ActivityGrid from '@/components/ActivityGrid.vue';
import ShareButton from '@/components/ShareButton.vue';
import MyRaceResultsOverview from '@/components/MyRaceResultsOverview.vue';
import MyTrainingPlanOverview from '@/components/MyTrainingPlanOverview.vue';
import ProfileConnectPanel from '@/components/ProfileConnectPanel.vue';
import Header from './_Header.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    EventGrid,
    ActivityGrid,
    ShareButton,
    MyRaceResultsOverview,
    MyTrainingPlanOverview,
    ProfileConnectPanel,
    Header,
  },
  props: {
  },
  data() {
    return {
      profile: null,
      providerUrls: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async udateAvatar() {
      await this.getProfile();
    },

    async signOut() {
      await firebase.auth().signOut();
      this.$store.dispatch('setUser', null);
      this.$router.replace({ name: "home" });
    },

    async getProfile() {
      if (this.user) {
        try{
          var response = await profileService.get(/*full:*/true);
        }
        catch {
          // network error occur after api has been deployed causing tokens to be invalidated
          // reload the page to prevent empty profile page from showing
          //window.location.reload(true);
          return;
        }
        this.profile = response.data;
        this.name = this.profile.name;
        this.email = this.profile.email;

        this.$nextTick(() => {
          this.$refs.trainingPlans.load();
        });

        this.providerUrls = this.$oauth.getProviderUrls({ profileId: this.profile.id });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
  
</style>

