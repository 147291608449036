import { render, staticRenderFns } from "./_RaceSelector.vue?vue&type=template&id=4d2e6b73&scoped=true&"
import script from "./_RaceSelector.vue?vue&type=script&lang=js&"
export * from "./_RaceSelector.vue?vue&type=script&lang=js&"
import style0 from "./_RaceSelector.vue?vue&type=style&index=0&id=4d2e6b73&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d2e6b73",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
