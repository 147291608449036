<template>
    <v-card
      :href="redirectToExternalUrl ? data.external_url : null"
      :to="redirectToExternalUrl ? null : (routeResolver ? routeResolver(data.id) : {name: 'event', params: {id: data.id}})"
      >
      <v-img
        v-if="data.img"
        :src="data.img"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <v-img v-if="showLogo && data.logo" :src="data.logo" height="80" width="80" class="mt-4 ml-4" />
        <v-card-title>{{data.name | trim(50)}}<v-icon v-if="icon" color="white" class="ml-2">{{icon}}</v-icon></v-card-title>
      </v-img>
      <v-sheet v-else :color="$helpers.getGradientColor(index)" height="200">
        <v-card-title>{{data.name}}<v-icon v-if="icon" class="ml-2">{{icon}}</v-icon></v-card-title></v-sheet>
      <div v-if="!minimal">
        <v-card-text v-if="isActive">
          <v-chip color="primary darken-0" class="white--text">Active now!</v-chip>
          till {{data.till | moment("L")}}
        </v-card-text>
        <v-card-text v-else-if="isFuture">
          <span class="py-1 d-inline-block">Opens in {{getStartDuration | duration('humanize', true)}}</span>
        </v-card-text>
        <v-card-text v-else>
          <span class="py-1 d-inline-block">{{data.from | moment("L")}} till {{data.till | moment("L")}}</span>
        </v-card-text>
      </div>
    </v-card>
</template>

<script>
import Vue from "vue";

export default {
  name: "EventCard",
  props: {
    item: Object,
    route: Object,
    minimal: Boolean,
    showLogo: Boolean,
    icon: String,
    index: Number,
    routeResolver: Function,
  },
  data: function() {
    return {
      data: null,
      score: null,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    redirectToExternalUrl() {
      return this.item &&  !this.routeResolver && this.item.external_url && this.isFuture;
    },
    isActive() {
      var from = Vue.moment(this.data.from);
      var till = Vue.moment(this.data.till);
      var now = Vue.moment();
      return now > from && now < till;
    },
    isFuture() {
      var from = Vue.moment(this.data.from);
      var now = Vue.moment();
      return from > now;
    },
    getStartDuration() {
      var from = Vue.moment(this.data.from);
      var now = Vue.moment();
      console.log( [from.diff(now, 'seconds'), 'seconds']);
      return from.diff(now, 'seconds');
    },
  },
  watch: {
    item: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        //console.log('Prop changed: ', val, ' | was: ', oldVal)
        if (val.o) {
          // scored list
          this.data = val.o;
          this.score = val.d || val.s;
        }
        else {
          this.data = val;
        }
      }
    }
  }
};
</script>

<style scoped>
</style>