<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>

      <div >
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-card-text>      
          <v-alert type="info">Please note that only teams of who at least one member has joined the race will show in this overview.</v-alert>
        </v-card-text>

        <div v-if="groupParticipants && groupParticipants.length > 0">

          <v-card-title class="subtitle pt-4">
            {{groupParticipants.length}} joined teams
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table v-if="groupParticipants"
            :headers="groupHeaders"
            :items="groupParticipants"
            :items-per-page="15"
            :search="search"
            >
            <template v-slot:item.join_date="{ item }">
              {{ item.join_date | moment('L LT') }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn text color="primary" :to="{name: 'profileGroupsEvent', params: {id: item.group_id, eventId: event.id}}">
                Details
              </v-btn>
            </template>

          </v-data-table>
        </div>
        <v-card-text v-else>
          No groups or teams have been created yet for this event. You can manage teams by importing registration data with team information assigned to it. Please read our Knowledge Base for more information on how to setup team races.
        </v-card-text>
      </div>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import Header from './_Header.vue'

export default {
  name: "Participants",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      groupParticipants: null,
      search: null,
      groupHeaders: [
        { text: 'Joined at', align: 'start', filterable: false, sortable: true, value: 'join_date',},
        { text: 'Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Verification Code', align: 'start', sortable: true, value: 'verification_code',},
        { text: 'Races', align: 'start', sortable: true, value: 'race_ids',},
        { text: 'Actions', align: 'start', sortable: false, filterable: false, value: 'actions',},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.groupParticipants = (await eventManagerService.getGroupParticipants(id)).data.data;
      console.log('data loaded', this.participants, this.groupParticipants);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Registration data', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

