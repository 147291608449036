<template>
  <div>
    <l-map
      ref="map"
      class="leaflet-map dynamic-content"
      :zoom="zoom"
      :center="startCoord"
      :options="mapOptions"
      style="height: 600px; width: 100%; z-index: 2;"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
    </l-map>
  </div>
</template>

<script>
// Todo: add swipe/scroll notification from https://codepen.io/surisdziugas/pen/LzXPwz

import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import tileService from "@/services/tileService";
import { latLng } from "leaflet";
import { polygon } from "@turf/helpers"; 
import turfDifference from "@turf/difference";
import {
  LMap,
  LTileLayer,
} from "vue2-leaflet";
import PolylineUtil from "@/plugins/Polyline.encoded.js";
import axios from "axios";

export default {
  name: "TileMap",
  components: {
    LMap,
    LTileLayer,
  },
  props: {
    country: String,
    preventAutoLoad: Boolean,
    focusTile: Object,
    focusTiles: Array,
    tileOptionCreator: Function,
    tileSelectedEventName: String,
  },
  data() {
    return {
      tiles: null,
      tile: null,
      showZoomAlert: true,
      minZoom: 7,
      zoom: 8,
      polygons: [],
      mapOptions: {
        zoomSnap: .25,
        dragging: !window.L.Browser.mobile,
        tap: !window.L.Browser.mobile,
        touchZoom: true,
      },
      map: null,
      startCoord: null,
      mapInitialized: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },
  async mounted() {
    window.EventBus = EventBus; // so we can access it from leaflet popup
    var location = this.$store.state.ipCoord || this.$cookies.get('tilemapcenter');
    var bounds = null;
    if (!this.preventAutoLoad) {
      if (this.country) {
        var countryInfo = (await tileService.getCountry(this.country)).data;
        bounds = window.L.latLngBounds([countryInfo.sw.lat, countryInfo.sw.lng], [countryInfo.ne.lat, countryInfo.ne.lng]);
        //console.log('Country', country, ' info:', countryInfo, 'bounds', bounds);
        location = bounds.getCenter();
      }
      else if (!location) {
        //console.log('Looking up ip');
        var ip = (await axios.get('https://api.ipify.org?format=json')).data.ip;
        console.log('Requesting location for IP', ip);
        location = (await tileService.me(ip)).data;
        this.$store.commit('setIpCoord', location); 
        this.$cookies.set('tilemapcenter', location);
      }
      this.startCoord = [location.lat, location.lng];
      console.log('Location loaded: ', this.startCoord);
      this.mapInitialized = true;
    }
    this.$nextTick(async () => {
      this.map = this.$refs.map.mapObject // work as expected
      //this.addTileLayers(this.map);
      if (bounds) {
        this.map.fitBounds(bounds);
      }
      else if (this.startCoord) {
        this.map.setView(this.startCoord, this.zoom);
      }
      await this.loadTilesInMap();
      this.map.on('moveend', this.loadTilesInMap);
      this.$refs.map.$el.addEventListener("touchstart", this.onTwoFingerDrag);
      this.$refs.map.$el.addEventListener("touchend", this.onTwoFingerDrag);
    });
  },
  methods: {
    addTileLayers(map) {
      window.L.tileLayer('http://tile.stamen.com/watercolor/{z}/{x}/{y}.jpg', {
          attribution: this.attribution,
          minZoom: 0,
          maxZoom: 7
      }).addTo(map);
      window.L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: this.attribution,
          minZoom: 7,
          maxZoom: 16
      }).addTo(map);
    },

    async loadTiles(tiles) {
      //console.log('Loading custom tiles', tiles, 'turfDifference', 'polygon',polygon);
      // find center
      // find bounds
      if (tiles && tiles.length > 0) {
        var bounds = window.L.latLngBounds();
        const earth = [[[90, -180],  [90, 180],  [-90, 180],  [-90, -180], [90, -180]]];
        var earthWithTileHoles = polygon(earth);
        for (var tile of tiles) {
          bounds.extend(tile.v);
          //console.log('PUSHING HOLE', tile.v);
          const coords = tile.v.map(x => [x.lng, x.lat]);
          const poly = polygon([[...coords, [tile.v[0].lng, tile.v[0].lat]]]);
          earthWithTileHoles = turfDifference(earthWithTileHoles, poly);
        }

        //console.log('earthWithTileHoles', earthWithTileHoles);
        //window.L.polygon(earthWithTileHoles.geometry.coordinates, { color: '#000000', opacity: 1, fillOpacity: 0.3, weight: 5, stroke: true, fill: true }).addTo(this.map);

        this.map.fitBounds(bounds);
        this.map.setMinZoom(this.map.getZoom());
        this.map.setMaxBounds(bounds.pad(.1 /* 10% */));

        this.tiles = tiles;
        await this.addTilesToMap();

        // background layer is added as last, as addTileToMap will clear all layers
        var backgroundStyle = {
            color: "#fff",
            weight: 3,
            opacity: 1,
            fillColor: "#000",
            fillOpacity: 0.7,
        };
        var bufferedLayer = L.geoJSON(null, { style: backgroundStyle });
        bufferedLayer.addData(earthWithTileHoles);
        bufferedLayer.addTo(this.map);
      }
    },
    async loadTilesInMap() {
      if (!this.mapInitialized || (this.focusTile = null && this.focusTiles == null)) {
        return;
      }
      this.$cookies.set('tilemapcenter', this.map.getCenter());
      var bounds = this.map.getBounds().pad(.1 /* 10% */);
      var zoom = this.map.getZoom();
      this.$emit('mapPanned', this.map); 
      
      console.log('Map view port changed to', bounds, 'with zoom', zoom);
      this.showZoomAlert = zoom <= this.minZoom;
      if (this.showZoomAlert) {
        this.$refs.map.$el.classList.add('zoom-in-message')
        this.$refs.map.$el.classList.add('with-message')
      } else {
        this.$refs.map.$el.classList.remove('zoom-in-message')
        this.$refs.map.$el.classList.remove('swiping-message')
      }
      if (!this.showZoomAlert) {
        var options = {minLng: bounds.getWest(), maxLng: bounds.getEast(), minLat: bounds.getSouth(), maxLat: bounds.getNorth(), limit: 500};
        this.tiles = (await tileService.search(options)).data.data;
        await this.addTilesToMap();
      }
    },

    async addTilesToMap() {
      //var options = {minLng:0, maxLng:300, minLat: 50, maxLat: 55, limit: 500};
      //this.tiles = (await tileService.search(options)).data.data;
      this.clearMap(this.map);
      console.log(`Loading ${this.tiles.length} tiles...`);
      this.polygons = [];
      var self = this;
      for (var i = 0; i < this.tiles.length; i++) {
        var tile = this.tiles[i];
        var hexagon = tile.v.map(x => window.L.latLng(x.lat, x.lng));
        const fillOpacity = this.getFillOpacity(tile);
        const fillColor =  'white';
        window.L.polygon(hexagon, { color: 'white', weight: this.getStrokeWeight(tile)*3, stroke: true, fill: false }).addTo(this.map);
        var baseOptions = { customId: tile.id, defaultFillOpacity: fillOpacity };
        var options = this.tileOptionCreator ? { ...baseOptions, ...this.tileOptionCreator(tile)} : { ...baseOptions, color: 'black', weight: this.getStrokeWeight(tile), stroke: true, fill: true, fillOpacity: fillOpacity, fillColor: fillColor };
        console.log('tile style', options, 'MERGE WITH', this.tileOptionCreator(tile));
        var polygon = window.L.polygon(hexagon, options);//.addTo(this.map);
        polygon.addTo(this.map);
        polygon.on('click', async (e) => await self.loadTile(e.target.options.customId, e.target));
        this.polygons.push(polygon);
      }
    },

    getStrokeWeight(tile) {
      return this.isInFilter(tile) ? 1 : .3
    },
    getFillOpacity(tile) {
      return this.isInFilter(tile) ? .05 : .1
    },
    isInFilter(tile) {
      const cc = tile.cc || tile.country_code;
      return this.country && (cc && cc.toLowerCase() == this.country.toLowerCase());
    },

    async loadTile(id, polygon) {
      if (this.tile == null || this.tile.id !== id) {
        console.log(`Loading tile ${id}...`);
        this.tile = (await tileService.get(id)).data;
      }
      this.$emit('selected', this.tile, polygon); 
    },

    async highlightTile(tile, highlightOn = true) {
      var polygon;
      for (var poly of this.polygons) {
        if (tile && poly.options.customId == tile.id) {
          poly.setStyle({ fillOpacity: this.getFillOpacity(tile) });
          polygon = poly;
        }
        else {
          poly.setStyle({ fillOpacity: highlightOn ? .8 : poly.options.defaultFillOpacity });
        }
      }
      if (polygon) {
        const content = this.tileSelectedEventName 
                        ? `<h3 class="mb-2">${tile.name || 'Tile'}</h3><p class="my-0"><a class="v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default primary--text" onclick="EventBus.$emit('${this.tileSelectedEventName}', '${tile.id}');"><span class="v-btn__content">View &raquo;</span></a></p>`
                        : `<h3 class="mb-2">${tile.name || 'Tile'}</h3><p class="my-0"><a class="v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default primary--text" href="/tiles/${tile.id}"><span class="v-btn__content">View &raquo;</span></a></p>`;

        var popup = window.L.popup()
          .setLatLng(polygon.getCenter())
          .setContent(content)
          .openOn(polygon);
        var self = this;
        this.map.openPopup(popup);
        this.map.on('popupclose', async (e) => await self.highlightTile(null, false));
      }
    },

    closePopups: function() {
      this.map.closePopup();
    },

    clearMap: function(m) {
        for(const i in m._layers) {
            if(m._layers[i]._path != undefined) {
                try {
                    m.removeLayer(m._layers[i]);
                }
                catch(e) {
                    console.log("problem with " + e + m._layers[i]);
                }
            }
        }
    },
    onTwoFingerDrag: function (e) {
      //console.log('map?s', e);
      if (e.type === 'touchstart' && e.touches.length === 1) {
        e.currentTarget.classList.add('swiping-message')
        e.currentTarget.classList.add('with-message')
      } else {
        e.currentTarget.classList.remove('with-message')
        e.currentTarget.classList.remove('swiping-message')
      }
    },
  },
  watch: {
    async focusTile() {
      this.tile = this.focusTile;
      this.clearMap(this.map);

      var hexagon = this.focusTile.vertices.map(x => window.L.latLng(x.lat, x.lng));
      var polygon = window.L.polygon(hexagon, {color: 'white', weight: 5, stroke: true, fill: false});//.addTo(this.map);
      polygon.addTo(this.map);
      this.map.fitBounds(polygon.getBounds());
      this.map.setMinZoom(this.map.getZoom());
      this.map.setMaxBounds(polygon.getBounds().pad(.1 /* 10% */));
      window.L.polygon(hexagon, {color: 'black', weight: 2, stroke: true, fill: false}).addTo(this.map);

      this.tiles = (await tileService.getAround(this.focusTile.id)).data.data;
//console.log('around', this.tiles);
      for (const tile of this.tiles) {
        hexagon = tile.v.map(x => window.L.latLng(x.lat, x.lng));
        window.L.polygon(hexagon, {fill: true, fillColor: 'white', fillOpacity: 1, stroke: false, color: '#F46524', weight: 1 })
          .addTo(this.map)
          .on('click', () => this.$router.push({ name: 'tile', params: { id: tile.id } }));
      }
    },
  },
  computed: {
    /*...mapGetters({
      user: "user"
    })*/
  },

};
</script>
<style lang="scss">

.leaflet-map { 
  position: relative;
  z-index:10;
   &.swiping-message::after {
    content: 'Use two fingers to move the map';
   }
   &.zoom-in-message::after {
    content: 'Zoom in to load the tiles';
   }
   &.with-message::after {
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 300;
    justify-content:center;
    display: flex;
    align-items: center;
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    pointer-events:none;
  }
}

</style>

