<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <v-card-title class="display-2 mb-4">{{$t('profile.activities.edit.title')}}</v-card-title>
      <v-card-text>
        {{$t('profile.activities.edit.description')}}
      </v-card-text>

      <v-form ref="form" v-model="valid" lazy-validation v-if="activity">
        <v-card-text>      
          <v-select 
            :label="$t('profile.activities.edit.activity-type')"
            v-model="activity.type"
            :items="siteData.activities"
            item-value="type"
            :item-text="x => $t('profile.activities.types.'+x.type)"
            />

          <DistanceTextArea
            v-if="showCustomField"
            v-model="activity.custom" 
            :mode="$helpers.UnitType.NUMBER"
            :unit="profile.unit"
            :label="$t('profile.activities.entry.custom')" 
            />            
        </v-card-text>      
        
        <v-card-text>   
          <v-alert v-if="infoMessage" class="mt-4" type="info">{{infoMessage }}</v-alert>
          <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || $t('shared.validation-error-message') }}</v-alert>
          <div v-if="validationError" class="mt-8 mb-8 d-flex">
            <v-btn :disabled="!valid" color="primary" outlined :loading="$store.getters.isLoading" @click="submit(true)">{{$t('profile.activities.entry.save-anyway')}}</v-btn>
            <v-spacer/>
            <v-btn :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('profile.activities.entry.validate-again')}}</v-btn>
          </div>
          <v-btn v-else class="mt-8 mb-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('shared.save')}}</v-btn>
        </v-card-text>   
      </v-form>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import profileService from "@/services/profileService";
import activityService from "@/services/activityService";
import DistanceTextArea from "@/components/DistanceTextArea";
import Header from './_Header.vue';

export default {
  name: "ActivityEdit",
  components: {
    DistanceTextArea,
    Header,
  },
  props: {
  },
  data() {
    return {
      activity: null,
      profile: null,
      error: null,
      valid: true,
      siteData: siteData,
      validationError: false,
      infoMessage: null,
      breadcrumbItems: [
        { text: this.$t('profile.title'), href: '/profile' },
        { text: this.$t('profile.activities.title'), href: '/profile/activities' },
        { text: this.$t('profile.activities.edit.title'), disabled: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();

    const id = this.$route.query.id;
    if (id) {
      var model = (await activityService.get(id)).data;
      console.log('Activity loaded', model);
      if (model) {
        this.activity = {
          id: id,
          start: model.start,
          type: model.type,
          dist: model.dist,
          elevation_gain: model.elevation_gain,
          elevation_loss: model.elevation_loss,
          kcal: model.kcal,
          custom: model.custom,
          time_s: model.time_s,
        }
      }
    }
    if (!this.activity) {
    }
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

  },
  methods: {
    async loadData() {
      if (this.user) {
        this.profile = (await profileService.get()).data;
      }
    },

    activityTypeChanged() {
      console.log('this.activity.type',this.activity.type);
      if (this.activity.type == 'STAIR_CLIMBING') {
        this.activity.dist = 1;
      }
      if (this.$refs.form.value === false) {
        // validation depends on activity type, so revalidate pls
        // only do when currently invalid to prevent that switching activity type shows 
        this.$refs.form.validate(); 
      }
    },

    async submit(ignoreValidation) {
      if (this.$refs.form.validate()) {
        this.error = null;
        this.infoMessage = null;
        this.validationError = false;
        try {
          //var validation = (await activityService.validate(this.activity)).data;
          console.log('Storing manual entry', this.activity);
          var data = (await activityService.edit(this.activity.id, this.activity)).data;
          if (data.status == "OK"){
            this.$router.push({ name: 'activity', params: { id: data.id } });
          }
          else if (data.status == "INFO") {
            this.infoMessage = data.msg;
            this.validationError = true;
          }
          else {
            this.error = data.msg;
            this.validationError = true;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

  },
  computed: {
    showCustomField() {
      return this.activity && (['VOLUNTEERING', 'OTHER'].some(x => x === this.activity.type))
    },
    ...mapGetters({
      user: "user"
    }),

  },

};
</script>
<style lang="scss">
</style>

