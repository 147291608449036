import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    impersonationProfileId: null,
    getConfig() {
        //console.log('TOKEN', store.getters.jwtToken);
        return {
            headers: {
//                authorization: `Bearer ${store.getters.jwtToken}`,
                "x-impersonation-profile-id": this.impersonationProfileId || '',
            }
        };
    },
    all(limit) {
        return services.http.get(`/v1.0/activity/?limit=${limit || 25}`, this.getConfig());
    },
    allPaged(limit, continuationToken) {
      return services.http.post(`/v1.0/activity/?limit=${limit || 25}`,qs.stringify({continuationToken:continuationToken}), this.getConfig());
    },
    get(id) {
        return services.http.get("/v1.0/activity/"+id, this.getConfig());
    },
    getQuickEntry(profileId, id) {
        return services.http.get(`/v1.0/activity/public/${profileId}/${id}`);
    },
    getActivityTypes() {
        return services.http.get("/v1.0/activity/activity_types", this.getConfig());
    },
    connect(id, connectEventIds) {
        return services.http.post(`/v1.0/activity/${id}/connect`, { event_ids: connectEventIds }, this.getConfig());
    },
    delete(id) {
        return services.http.delete("/v1.0/activity/"+id, this.getConfig());
    },
    getTilescores(id, type) {
        return services.http.get(`/v1.0/activity/${id}/tilescores?activityType=${type}`, this.getConfig());
    },
    upload(model) {
        return services.http.post("/v1.0/activity/upload", model, this.getConfig());
    },
    validate(model) {
        return services.http.post("/v1.0/activity/validate", model, this.getConfig());
    },
    manualEntry(model, ignoreValidation) {
        return services.http.post(`/v1.0/activity/manualentry?ignoreValidation=${ignoreValidation}`, model, this.getConfig());
    },
    edit(id, model) {
        return services.http.put(`/v1.0/activity/${id}`, model, this.getConfig());
    },

    // non rest methods
    zeroDistanceActivities(activity) {
        return activity.type === 'OTHER' || activity.type === 'YOGA' || activity.type === 'INDOOR_CARDIO' || activity.type === 'VOLUNTEERING' || activity.type === 'STAIR_CLIMBING';
    },
    requireDistance(activity) {
        return !(activity.type === 'INDOOR_CYCLING' || this.zeroDistanceActivities(activity));
    },
    validateDistance(activity) {
        return (activity.dist > 0 || !this.requireDistance(activity)) || "Please enter a positive number";
    },
}