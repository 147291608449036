<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>

      <div v-if="event">
        <v-container class="info-container grey lighten-4 py-0">
          <v-row>
            <v-col cols="12" sm="6">
              <div class="label">ID</div>
              <div class="value"><pre>{{event.id}}</pre></div>
              
              <div v-if="event.published" class="label">Invite Link <a v-if="tenant.isDefault" href="https://sodisp.freshdesk.com/support/solutions/articles/77000482539-inviting-participants-to-event" target="_blank">(how to use)</a></div>
              <div v-if="event.published" class="value">{{tenant.homeUrl}}register?event={{event.id}}</div>

              <div class="label">Name</div>
              <div class="value">{{event.name}}</div>

              <div class="label">Opens (included)</div>
              <div class="value">
                {{event.from | moment("L")}}
                <v-chip  v-if="isActive" outlined color="accent darken-0" class="white--text">Active now!</v-chip>
                <v-chip  v-else-if="isFuture" outlined color="accent darken-0" class="white--text">Opens in {{getStartDuration | duration('humanize', true)}}</v-chip>
              </div>

              <div class="label">Closes (included)</div>
              <div class="value">{{event.till | moment("L")}}</div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="label">Manual Entry Allowed</div>
              <div class="value"><v-icon color="accent">fal {{ event.allow_manual_entry ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon></div>

              <div class="label">Validate pace/speed</div>
              <div class="value"><v-icon color="accent">fal {{ event.validate_activities ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon></div>

              <div v-if="event.published" class="label">Verification Required to Join</div>
              <div v-if="event.published" class="value"><v-icon color="accent">fal {{ event.verification ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon></div>

              <div v-if="event.published" class="label">Visibility</div>
              <div v-if="event.published" class="value">{{ event.public ? 'Public' : 'Private (with link only)' }}</div>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-alert v-if="event.conversions && event.conversions.length" type="info" tile outlined border="left">
                <v-row dense align="center">
                  <v-col class="grow">Activity conversions are configured for this event.</v-col>
                  <v-col class="shrink">
                    <v-btn class="shrink" text color="blue" :to="{name:'eventmanagerActivityConversions', params: {id: event.id}}">Manage</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
        <v-alert v-if="!event.published && event.validation.status != 'ERROR'" tile type="info" class="mb-0">
          <p>This event is not yet published. An event must be published before it can be used or viewed.</p>
          <v-btn @click="publish" :loading="$store.getters.isLoading">Publish Now</v-btn>
        </v-alert>
        <v-alert v-if="!event.published && event.validation.status != 'ERROR'" tile type="info" color="info darken-2" icon="fa-question-circle">
          <p>Need a little help to get your event ready to go?</p>
          <v-btn @click="assistance" :loading="$store.getters.isLoading">Get Assistance</v-btn>
        </v-alert>
        <v-alert v-if="event.validation.status == 'ERROR'" tile type="error">
          <p>There are one or more validation issues. Please resolve these before going live:</p>
          <p class="font-weight-bold">{{event.validation.msg}}</p>
        </v-alert>
        <v-alert v-if="event.validation.status == 'INFO'" tile type="info">
          <p>There are one or more settings that might need your attention. Please have a look before going live:</p>
          <p class="font-weight-bold">{{event.validation.msg}}</p>
        </v-alert>
        
        <v-data-table
          :headers="headers"
          :items="races"
          sort-by="dist"
          :items-per-page="25"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white" class="mx-4">
              <v-toolbar-title class="ml-0 subtitle">Leaderboards</v-toolbar-title>
              <v-spacer/>
              <v-btn outlined color="primary" class="mr-0" @click="$refs.raceDialog.addRace()">
                <v-icon class="mr-2">fa-plus</v-icon> Add Leaderboard
              </v-btn>
              <v-menu offset-y max-height="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" color="primary" class="px-2" style="min-width:0;">
                    <v-icon title="More options" >fa-caret-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                  <v-list-item @click="$refs.raceDialog.addInPersonRace()">
                    <v-list-item-title>Add In-Person Race</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$refs.trainingPlanDialog.open()">
                    <v-list-item-title>Add Training Plan</v-list-item-title>
                  </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>

            </v-toolbar>
          </template>
          <template v-slot:item.activity_types="{ item }">
            <span v-if="!item.activity_types || item.activity_types.length==0">All</span>
            <span v-else v-for="(type, idx) in item.activity_types" :key="idx">
              <v-icon :title="$t('profile.activities.types.'+type)">{{ $helpers.getActivityIcon(type) }}</v-icon>
              <span v-if="idx != item.activity_types.length-1" class="mx-1">{{ $t('shared.or') }}</span>
            </span>
          </template>
          <template v-slot:item.scoring="{ item }">
            <v-icon :title="item.scoring">{{$helpers.getScoringIcon(item.scoring)}}</v-icon>
          </template>
          <template v-slot:item.dist="{ item }">
            {{ $helpers.getGoalDistanceForDisplay($options, item, item.dist, event.unit) }}
          </template>
          <template v-slot:item.name="{ item }">
            {{ item.name }} 
            <div v-if="item.team" class="font-weight-bold">({{$helpers.displayText('team_types', item.team, 'UNKNOWN') }})</div>
            <div v-if="item.type === 'HYBRID'" class="font-weight-bold">(hybrid)</div>
            <div v-if="item.type === 'EXTERNAL'" class="font-weight-bold">(in-person)</div>
          </template>
          <template v-slot:item.filter="{ item }">
            <div v-if="item.min_dist" class="text-muted">
              Activities: {{ $helpers.getDistanceForDisplay($options, item, item.min_dist, event.unit) }} - {{ $helpers.getDistanceForDisplay($options, item, item.max_dist, event.unit) }}
            </div>
            <div v-if="item.from" class="text-muted">
              {{item.from | moment("L")}} - {{item.till | moment("L")}}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small color="primary" class="mr-4" @click="$refs.raceDialog.editRace(item)">
              fa-pencil-alt
            </v-icon>
            <v-btn v-if="item.type !== 'EXTERNAL'" small color="primary" text class="" :to="{name: 'eventmanagerRaceBadges', params: { id:event.id, raceId: item.id }}">
              <v-icon small class="mr-2">fadl fa fa-shield</v-icon> Badges
            </v-btn>
            <v-btn v-if="item.type === 'EXTERNAL' || item.type === 'HYBRID'" small color="primary" text class="" @click="uploadResults(item)">
              <v-icon small class="mr-2">fadl fa fa-upload</v-icon> Upload
            </v-btn>
          </template>
          <template v-slot:item.more="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>fa-ellipsis-v</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-if="item.team == 'RELAY_TIME_SLOTS' || item.team == 'RELAY_DISTANCE_SLOTS'" :to="{name: 'eventmanagerRaceRelaySlots', params: { id:event.id, raceId: item.id }}">
                  <v-list-item-icon><v-icon>fa-users-class</v-icon></v-list-item-icon>
                  <v-list-item-title>Relay Slots</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name: 'eventmanagerRaceResultColumns', params: { id:event.id, raceId: item.id }}">
                  <v-list-item-icon><v-icon>fa-line-columns</v-icon></v-list-item-icon>
                  <v-list-item-title>Customize Leaderboard</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.type !== 'EXTERNAL'" :to="{name: 'eventmanagerRace', params: { id:event.id, raceId: item.id }}">
                  <v-list-item-icon><v-icon>fa-poll-people</v-icon></v-list-item-icon>
                  <v-list-item-title>Manage results</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.type !== 'EXTERNAL'" :to="{name: 'eventmanagerRaceGeofence', params: { id:event.id, raceId: item.id }}">
                  <v-list-item-icon><v-icon>fa-draw-polygon</v-icon></v-list-item-icon>
                  <v-list-item-title>Geo-fence</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.type !== 'EXTERNAL' && (item.scoring == 'DISTANCE'||item.scoring == 'ELEVATION'||item.scoring == 'TRACK'||item.scoring == 'CUSTOM')" @click="uploadGpxTrack(item)">
                  <v-list-item-icon><v-icon>fa-route</v-icon></v-list-item-icon>
                  <v-list-item-title>Upload GPX track</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.type === 'EXTERNAL' || item.type === 'HYBRID'" @click="uploadResults(item)">
                  <v-list-item-icon><v-icon>fa-upload</v-icon></v-list-item-icon>
                  <v-list-item-title>Upload Results</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="deleteRace(item)" >
                  <v-list-item-icon><v-icon>fa-trash</v-icon></v-list-item-icon>
                  <v-list-item-title>Delete Leaderboard</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>            
          </template>
          <template v-slot:no-data>
            <i>No leaderboards created, add at least one leaderboard to this event.</i>
          </template>
        </v-data-table>
      </div>   
      <v-card-actions>
        <v-btn text color="primary" @click="duplicate">Clone</v-btn>
        <v-btn text color="primary" @click="duplicateAsSimulation">Create copy in simulator</v-btn>
      </v-card-actions>   
    </v-card>
    <SupportButton/>
    <RaceDialog ref="raceDialog" :event="event" @save="saveEvent"/>
    <v-dialog v-if="event" v-model="gpxDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload virtual course</span>
        </v-card-title>
        <v-card-text>
          <p>Upload a virtual course track. You can upload any gpx file. Please make sure to select a gpx file containing a track matching the leaderboard distance.</p>
          <v-alert v-if="activeRace && activeRace.route" type="info">Note: uploading a track will overwrite the current track.</v-alert>
          
          <ActivityUploadForm :event-id="event.id" :race-id="activeRace ? activeRace.id : null" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeGpxDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    <v-dialog v-if="event && activeRace" v-model="resultsDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload race results for {{activeRace.name}}</span>
        </v-card-title>
        <v-card-text>
          <p>Upload an Excel or csv file with race results.</p>
          
          <ExcelUploadForm :event="event" :event-id="event.id" :race-id="activeRace.id" @uploaded="resultsDialog=false;" type="RACE_RESULTS"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resultsDialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    <SelectTrainingPlanDialog ref="trainingPlanDialog" :event="event" @addPlan="addTrainingPlan" />
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import siteData from '@/data/site.json'
import SupportButton from '@/components/SupportButton.vue'
import Header from './_Header.vue'
import RaceDialog from './_RaceDialog.vue'
import SelectTrainingPlanDialog from './_SelectTrainingPlanDialog.vue'
import ActivityUploadForm from "@/components/ActivityUploadForm";
import ExcelUploadForm from "@/components/ExcelUploadForm";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    SupportButton,
    Header,
    RaceDialog,
    ActivityUploadForm,
    ExcelUploadForm,
    SelectTrainingPlanDialog,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      error: null,
      breadcrumbItems: [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'View event', disabled: true },
      ],
      scoringTypeItems: siteData.scoring_methods,
      event: null,
      activeRace: null,
      gpxDialog: false,
      resultsDialog: false,
      races: [],
      eventManagerService: eventManagerService,
      headers: [
        { text: 'Activities', align: 'start', sortable: false, value: 'activity_types',},
        { text: 'Scoring', align: 'start', sortable: false, value: 'scoring',},
        { text: 'Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Goal', value: 'dist', sortable: true },
        { text: 'Filter', value: 'filter', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Options', value: 'more', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.races = this.event.races || [];
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async addTrainingPlan(plan) {
      console.log('Adding new plan', plan);
      var request = {
        training_plan_id: plan.id,
      }
      var response = (await eventManagerService.addTrainingPlan(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added training plan.');
      if (response.status === 'OK') {
        this.getProfile();
      }
    },

    async deleteRace (item) {
      const index = this.races.indexOf(item);
      confirm('Are you sure you want to DELETE THIS RACE AND ALL RESULTS?') && this.races.splice(index, 1) && await this.saveEvent();
    },

    uploadGpxTrack(item) {
      this.activeRace = item;
      this.gpxDialog = true
    },
    closeGpxDialog() {
      this.gpxDialog = false;
    },

    uploadResults(item) {
      this.activeRace = item;
      this.resultsDialog = true
    },

    async publish() {
      //if (confirm('Are you sure you want to publish this event? Some settings might not be available .')){
        this.event.published = true;
        this.saveEvent();
      //}
    },

    async assistance() {
      //if (confirm('Are you sure you want to publish this event? Some settings might not be available .')){
        //this.event.published = true;
        //this.saveEvent();
      //}
      var profile = (await profileService.get()).data;
      console.log('this.user', this.user, profile);
      const data = { 
        name: profile.name,
        email: this.event.email,
        comments: `Event: ${this.event.name}
Id: ${this.event.id}
Link: ${tenant.homeUrl}eventmanager/view/${this.event.id}
Remarks: ${this.event.remarks || ''}
Question: <please add your question here>`,
      };
      this.$router.push({ name: 'contact', query: data });
    },

    async saveEvent() {
      this.error = null;
      try {
        this.races = this.event.races;
        var response = await eventManagerService.put(this.event);
        await this.getEvent(response.data.id);
        this.$toast.success('Event is saved successfully!');
      }
      catch (ex) {
        this.error = ex.response.data.msg;
      }
    },
    async duplicate() {
      var newName = prompt('This will create a copy of this event without results or registration data. This can be useful to have a small trial event or test some features out before going live.\n\nEnter the name of the duplicate:', this.event.name + ' (copy)');
      if (newName) {
        const response = (await eventManagerService.duplicate(this.event.id, newName)).data;
        if (response.id) {
          this.$router.push({ name: 'eventmanagerView', params: { id: response.id } });
          //await this.getProfile();
        }
      }
    },
    async duplicateAsSimulation() {
      if (confirm('Are you sure you want to create a copy of the current setup and load this in the simluator?')) {
        const response = (await eventManagerService.duplicateAsSimulation(this.event.id)).data;
        if (response.id) {
          this.$router.push({ name: 'eventmanagerSimulator', query: { id: response.id.replace('itx-', ''), autorun: true } });
          //await this.getProfile();
        }
      }
    },
  },
  computed: {
    distanceLabel() {
      if (this.editedItem == null || this.editedItem.scoring == null || this.editedItem.scoring == "RESULT") {
        return `Race distance in (in ${this.unitTypeDiplay})`;
      }
      else if (this.editedItem.scoring == "DISTANCE"){
        return `Total distance goal (in ${this.unitTypeDiplay})`;
      }
      else if (this.editedItem.scoring == "ELEVATION"){
        return `Total elevation goal (in ${this.unitTypeDiplay})`;
      }
      return null;
    },
    formattedFrom() {
      return this.event == null ? null : moment(this.event.from).format('L');
    },
    formattedTill() {
      return this.event == null ? null : moment(this.event.till).format('L');
    },
    formattedFromHint() {
      return this.event == null ? null : moment.tz(this.event.from, this.event.timeZoneOlson).format('L Z z');
    },
    formattedTillHint() {
      return this.event == null ? null : moment.tz(this.event.till, this.event.timeZoneOlson).format('L Z z');
    },
    unitTypeDiplay() {
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    },
    isActive() {
      var from = Vue.moment(this.event.from);
      var till = Vue.moment(this.event.till).add(1, 'days');
      var now = Vue.moment();
      return now > from && now < till;
    },
    isFuture() {
      var from = Vue.moment(this.event.from);
      var now = Vue.moment();
      return from > now;
    },
    getStartDuration() {
      var from = Vue.moment(this.event.from);
      var now = Vue.moment();
      console.log( [from.diff(now, 'seconds'), 'seconds']);
      return from.diff(now, 'seconds');
    },
    ...mapGetters({
      user: "user"
    })
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .info-container {
    .label { font-size: 80%; opacity: .8; }
    .value { margin-bottom: 10px; ;}
    .value:last-child { margin-bottom: 0px; ;}
  }
</style>

