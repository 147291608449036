<template>
  <div v-if="event && race">
    <TileMap ref="tileMap" v-if="tiles && tiles.length > 0" :tileOptionCreator="getTileStyle" preventAutoLoad tile-selected-event-name="tile-show-details" @selected="tileSelected"/>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/eventbus.js';
import eventService from "@/services/eventService";
import TileMap from "@/components/TileMap.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RaceResultsTileMap",
  components: {
    TileMap,
  },
  props: {
      event: Object,
      race: Object,
  },
  data() {
    return {
      tiles: null,
    };
  },
 
  async mounted() {
    console.log('LISTENING TO tile-show-details');
    window.EventBus = EventBus; // so we can access it from leaflet popup
    EventBus.$on('tile-show-details', async id => {
      console.log('showing details of ', id);
      //const result = (await eventService.getRaceResultDetails(this.event.id, this.race.id, id)).data;
      this.$refs.tileMap.closePopups();
      //this.$emit('showResultDetails', result); 
    });

  },
  methods: {
    async loadResults(tiles) {
      if (this.race.scoring === 'TILES') {
        //this.tiles = (await eventService.getRaceTiles(this.eventId, this.raceId)).data.data;
        //this.tiles = tileBadges.map(x => (x));
        this.tiles = tiles;
        console.log('TILES', this.tiles); 
        await this.$nextTick();
        this.$refs.tileMap.loadTiles(this.tiles);
      }

    },

    getTileStyle(tile) {
      console.log('stlying tile', tile);
      return tile.fog ? { fillColor: 'red', fill: true, color: 'white' } : { fillColor: 'green', fill: true, color: 'white' } ;
    },
    async tileSelected(tile) {
      this.$refs.tileMap.highlightTile(tile);
    },

  },
  watch: {
  },
  computed: {
    
  },

};
</script>
<style lang="scss">


</style>

