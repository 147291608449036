<template>
    <div>
      <v-file-input accept="*/text" label="Select a .xlsx or .csv file" v-model="file" v-on:change="fileSelected"></v-file-input>
      <v-alert v-if="syncResult && syncResult.status == 'OK'" type="success"><strong>{{ syncResult.msg || 'All good! Registration data is saved.' }}</strong></v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'INFO'" type="info">
        <p><strong>{{ syncResult.msg }}</strong></p>
        <v-btn color="white" outlined @click="forceUpload" :loading="$store.getters.isLoading">Force Upload</v-btn>
      </v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'ERROR'" type="error">Unfortunately, we couldn't process your file: <strong>{{ syncResult.msg }}</strong></v-alert>
      <v-btn color="primary" outlined @click="uploadFile" :loading="$store.getters.isLoading">Upload file</v-btn>
      <span class="ml-4 text-muted">Please select a full import file, uploading a new file will <strong>replace</strong> all existing data.</span>
    </div>
</template>

<script>

import eventManagerService from "@/services/eventManagerService";

export default {
  name: "CsvUploadForm",
  props: {
    eventId: String,
  },
  data: function() {
    return {
      file: null,
      syncResult: null,
      forceImport: false,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async fileSelected() {
      this.syncResult = null;
    },

    async forceUpload() {
      this.forceImport = true;
      await this.uploadFile();
    },

    async uploadFile() {
      this.syncResult = null;
      let formData = new FormData()
      if (this.file) {
        formData.append("file", this.file, this.file.name);
        formData.append("eventId", this.eventId);
        formData.append("raceId", this.raceId);
        formData.append("force_upload", this.forceImport);
        this.syncResult = (await eventManagerService.uploadRegistrations(this.eventId, formData)).data;
        if (this.syncResult.status == 'OK'){
          this.forceImport = false;
          this.$emit('uploaded'); 
        }
      }
      else {
        this.syncResult = {
          status: "ERROR",
          msg: "Please select a file to upload."
        };
      }
    },
  },
  computed: {
  },
};
</script>

<style scoped>
</style>