<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>

      <div >
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-card-text>      
          <p v-if="tenant.isDefault">
            Please read our <a href="https://sodisp.freshdesk.com/support/solutions/folders/77000137491" target="_blank">Frequently asked questions</a> on creating and managing events for tips and tricks.
          </p>
          <p>
            Click on a row to open the details screen of each participant for additional information and options.
          </p>
        </v-card-text>

        <v-card-title class="subtitle pt-4">
          {{ !participants ? 0 : participants.length}} joined users
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table v-if="participants"
          :headers="headers"
          :items="participants"
          :items-per-page="15"
          :search="search"
          @click:row="item => showProfileDetails(item.profile_id)"
          >
          <template v-slot:item.join_date="{ item }">
            {{ item.join_date | moment('L LT') }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small color="primary" class="mr-2" title="Profile details" @click.stop="showProfileDetails(item.profile_id)">fa-info-circle</v-icon>
          </template>

        </v-data-table>
      </div>      
      <ProfileDetailsDialog :eventId="event.id" :event="event" ref="profileDetailsDialog"/>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import ProfileDetailsDialog from "@/components/ProfileDetailsDialog.vue";
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Participants",
  components: {
    ProfileDetailsDialog,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      participants: null,
      search: null,
      headers: [
        { text: 'Actions', align: 'start', sortable: false, filterable: false, value: 'actions',},
        { text: 'Joined at', align: 'start', sortable: true, filterable: false, value: 'join_date',},
        { text: 'Profile Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Profile Id', align: 'start', sortable: true, value: 'profile_id_last_digits',},
        { text: 'Code', align: 'start', sortable: true, value: 'verification_code',},
        { text: 'Bib', align: 'start', sortable: true, value: 'reg.b',},
        { text: 'Full name', align: 'start', sortable: true, value: 'reg.n',},
        { text: 'First name', align: 'start', sortable: true, value: 'reg.gn',},
        { text: 'Last name', align: 'start', sortable: true, value: 'reg.fn',},
        { text: 'Team', align: 'start', sortable: true, value: 'reg.g',},
        { text: 'Leaderboard', align: 'start', sortable: true, value: 'reg.r',},
        { text: 'Gender', align: 'start', sortable: true, value: 'reg.ge',},
        { text: 'Category', align: 'start', sortable: true, value: 'reg.ca',},
        { text: 'Email', align: 'start', sortable: true, value: 'reg.e',},
        { text: 'Phone', align: 'start', sortable: true, value: 'reg.p',},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.participants = (await eventManagerService.getParticipants(id)).data.data;
      this.groupParticipants = (await eventManagerService.getGroupParticipants(id)).data.data;
      console.log('data loaded', this.participants, this.groupParticipants);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async showProfileDetails(id) {
      this.$refs.profileDetailsDialog.open(id);
    }

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Registration data', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

