<template>
  <div v-if="result && event && race">  

    <v-toolbar dark extended extension-height="24" class="profile--header">
      <v-avatar v-if="result.img" size="110" class="profile--img"><img :src="result.img+'?w=250&h=250'"/></v-avatar>
      <v-toolbar-title class="pl-4">
        {{ result.name }}
      </v-toolbar-title>
      <v-spacer/>
      <ShareButton :text="`Results at the ${event.name} - Check sodisp.com`" color="white" :route="{name: 'raceResult', params: { id: event.id, raceId: race.id, resultId: result.id}}" />
    </v-toolbar>    
    <div style="margin-top:-20px" :class="'pl-4 profile--actions ' + (result.img ? 'with-img' : '')">
      <v-btn v-if="!liked" rounded :color="event.color || 'primary'" class="white--text" @click="like" :loading="$store.getters.isLoading">
        <v-icon class="mr-2">fa-thumbs-up</v-icon>{{$t('results.details.like')}}{{ result.likes ? ` (${result.likes})` :'' }}
      </v-btn>
      <v-btn v-if="liked" outlined rounded :color="event.color || 'primary'" disabled style="background:white;">
        <v-icon class="mr-2">fa-thumbs-up</v-icon>{{$t('results.details.liked')}}
      </v-btn>
      <v-btn outlined rounded class="ml-4" :color="event.color || 'primary'" style="background:white;" @click="$refs.comments.scrollIntoView()">
        <v-icon class="mr-2">fa-comments</v-icon>{{$t('results.details.comment')}}{{ result.comments ? ` (${result.comments})` :'' }}
      </v-btn>
      <v-btn v-if="result.photos" outlined rounded class="ml-4" :color="event.color || 'primary'" style="background:white;" @click="$refs.photos.scrollIntoView()">
        <v-icon class="mr-2">fa-images</v-icon> {{ result.photos }} {{$t('results.details.photos')}}
      </v-btn>
      <v-btn v-if="result.can_claim" outlined rounded class="ml-4" :color="event.color || 'primary'" style="background:white;" @click="claimResult">
        <v-icon class="mr-2">fa-user</v-icon> {{$t('results.details.claim')}}
      </v-btn>
    </div>

    <v-alert v-if="result.can_claim && claimVisible" type="info" outlined prominent class="ma-4">
      <p>Is this your result? You can claim this to add it to your profile and to upload photos and selfies to it.</p>
      <div v-if="user">
        <v-btn color="info" :to="{name: 'raceClaimResult', params: { id:event.id, raceId: race.id, resultId: result.id }}">Claim now</v-btn>        
      </div>
      <div v-else>
        <p>Before you can claim your result you'll need to log in or create an account.</p>
        <v-btn color="info" class="mr-4" :to="{ name: 'register', query: { redirect:this.$router.resolve({name: 'raceClaimResult', params: { id:event.id, raceId: race.id, resultId: result.id }}).href }}">{{$t('nav.account.register')}}</v-btn>
        <v-btn outlined color="info" :to="{name: 'login', query: { redirect:this.$router.resolve({name: 'raceClaimResult', params: { id:event.id, raceId: race.id, resultId: result.id }}).href } }">{{$t('nav.account.login')}}</v-btn>
      </div>
    </v-alert>

    <v-card-text class="mt-4 pb-0 px-2">
      <span v-if="result.pos" class="ml-2 font-weight-bold">{{$t('results.cols.position')}} #{{ result.pos }}</span>
      <span v-if="result.gender && !race.team" class="ml-2">
          • <span class="grey--text">{{$t('results.cols.gender')}}</span> {{ result.gender }}:
          <span v-if="result.gender_pos" class="ml-0">#{{ result.gender_pos }}</span>
      </span>
      <span v-if="result.cat && !race.team" class="ml-2">
          • <span class="grey--text">{{$t('results.cols.category')}}</span> {{ result.cat }}:
          <span v-if="result.cat_pos" class="ml-0">#{{ result.cat_pos }}</span>
      </span>
      <span v-if="result.last_activity" class="ml-2">
        • <span class="grey--text">{{$t('results.cols.last-activity')}}:</span> {{ result.last_activity | localDate('L') }}
      </span>
      <span v-if="result.group && !race.team" class="ml-2">
        • <span class="grey--text">{{$t('results.cols.group-team')}}:</span> {{ result.group }}
      </span>
    </v-card-text>

<!--     <v-card-title class="display-1 px-3">
      Result details for {{ result.name }}
      <v-spacer/>
      <ShareButton :text="`Results at the ${event.name} - Check sodisp.com`" color="primary" :route="{name: 'raceResult', params: { id: event.id, raceId: race.id, resultId: result.id}}" />
    </v-card-title>
 -->    
    <div class="my-4">
      <v-card-text v-if="race.result_completed_desc" class="pt-0">
        <vue-markdown class="markdown" :html="true">{{ race.result_completed_desc | replace(result) }}</vue-markdown>
      </v-card-text>
      <v-alert v-if="result.completion_date" type="success" class="my-0" prominent tile>{{$t('results.details.completed-at')}} {{ result.completion_date | moment('ddd L') }}</v-alert>
      <v-card-text v-if="result.certificate_img" class="pt-0 mt-4">
        <v-sheet elevation="1" class="pa-1 d-inline-block"><img :src="result.certificate_img" style="max-width:100%; max-height:300px;" /></v-sheet>
      </v-card-text>
      <v-card-text v-if="result.certificate_img" class="pt-0">
        <v-btn outlined :color="event.color || 'primary'" :href="result.certificate_img" target="_blank" >
          <v-icon class="mr-2">fa-trophy</v-icon> Download certificate
        </v-btn>
        <ShareButton v-if="result.certificate_img" :text="`Finisher Certificate at the ${event.name} - Check sodisp.com`" :color="event.color || 'primary'" :url="result.certificate_img" />
      </v-card-text>
    </div>
    <v-card-text v-if="race.result_desc && !popupMode">
      <vue-markdown class="markdown" :html="false">{{race.result_desc }}</vue-markdown>
    </v-card-text>

    <v-card-text v-if="race && race.dist > 0 && result && showProgressBar" class="mt-0 pb-0">
      <div class="d-flex flex-row">
        <div class="shrink mt-4" style="white-space:nowrap;" >{{ formatChallengeValue(0) }}</div>
        <v-progress-linear striped class="ma-4" height="25" color="green" :value="Math.round(result.score_value / race.dist * 100)">
          <template v-slot="{ value }">
            <strong style="background:rgba(255,255,255,.6); padding: 2px 2px;">{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
<!--         <v-progress-linear class="ma-4 progress-bg" height="15" color="#097a00" background-opacity="0" :value="result.score_value / race.dist * 100">
        </v-progress-linear>
 -->        <div class="shrink mt-4" style="white-space:nowrap;">{{ formatChallengeValue(race.dist) }}</div>
      </div>
    </v-card-text>
    <v-container v-if="!showTrainingPlanProgress" class="mt-0 ml-0">
      <v-row>
        <v-col v-if="scoreColumn" cols="12" sm="4" md="4">
          <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, 0) : 'primary darken-1'">
            <v-card-text class="white--text pb-0">{{ resultColumns.getColumnCaption(scoreColumn) }}</v-card-text>
            <v-card-title class="subtitle pt-0">{{ $helpers.getValueForDisplay($options, race, result.score_value, event.unit) }}</v-card-title>
          </v-card>
        </v-col>
        <v-col v-if="secondaryScoreColumn" cols="12" sm="4" md="4">
          <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, -10) : 'primary darken-2'">
            <v-card-text class="white--text pb-0">{{ resultColumns.getColumnCaption(secondaryScoreColumn) }}</v-card-text>
            <v-card-title class="subtitle pt-0">{{ $helpers.getSecondaryValueForDisplay($options, race, result.secondary_value, event.unit) }}</v-card-title>
          </v-card>
        </v-col>
        <v-col v-if="showProgressBar && race.dist > 0" cols="12" sm="4" md="4">
          <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, -20) : 'primary darken-3'">
            <v-card-text class="white--text pb-0">{{$t('results.details.percentage-completed')}}</v-card-text>
            <v-card-title class="subtitle pt-0">{{ Math.round((result.score_value / race.dist) * 100) }}%</v-card-title>
          </v-card>
        </v-col>
        <v-col v-if="showProgressBar && result.score_value_day" cols="12" sm="4" md="4">
          <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, -30) : 'primary darken-4'">
            <v-card-text class="white--text pb-0">{{$t('results.details.avg-day')}}</v-card-text>
            <v-card-title class="subtitle pt-0">{{ $helpers.getGoalDistanceForDisplay($options, race, result.score_value_day, event.unit) }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    
    <div v-if="result.badges && result.badges.length > 0 && !showTrainingPlanProgress" class="mt-0 mx-4">
      <h3>{{$t('results.details.earned-badges')}}</h3>
      <v-row>
        <v-col
          v-for="(item, idx) in result.badges"
          :key="idx"
          cols="6"
          sm="4"
          md="2"
          lg="2"
          class="text-center pa-4"
        >
          <v-sheet :elevation="0" color="white">
            <strong class="py-2 d-block">{{ item.name }}</strong>
            <img :src="item.img" style="max-width: 100%;"/>
            <div class="py-1 text-muted">{{ item.reward_date | localDate('L')}}</div>
            <v-chip v-if="item.value" outlined :color="event.color || 'primary'" class="py-1">{{$t('results.details.badge-result')}}: {{ $helpers.getBadgeValueForDisplay($options, item.format, item.value, event.unit) }}</v-chip>
            <BadgeDetailsDialog :event="event" :race="race" :badgeId="item.id" :detailsType="item.details_type" color="grey darken-2"/>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <div v-if="showTrainingPlanProgress">
      <v-card-title class="subtitle px-3">
        {{$t('results.details.training-plan-progress')}}
      </v-card-title>
      <TrainingPlanProgress :training-plan="trainingPlan" :result="result" :badges="badges" :meta="meta" :event="event" :race="race" />
    </div>

    <div v-if="showRelaySlots">
      <v-card-title class="subtitle px-3">
        {{$t('results.details.relay-slot-breakdown')}}
      </v-card-title>
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-if="hasSlotSports" class="text-left">{{$t('profile.activities.sport')}}</th>
              <th class="text-left">{{$t('events.cols.slot')}}</th>
              <th class="text-left">{{$t('events.cols.participant')}}</th>
              <th v-if="race.team == 'RELAY_TIME_SLOTS'" class="text-left">{{$t('results.cols.distance')}}</th>
              <th v-if="race.team == 'RELAY_DISTANCE_SLOTS'" class="text-left">{{$t('results.cols.time')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in result.slots" :key="idx" :class="getResultRowClass(item)">
              <td v-if="hasSlotSports">
                <v-icon v-if="item.slot_type">{{$helpers.getActivityIcon(item.slot_type)}}</v-icon>
              </td>
              <td>{{ item.slot_name }}</td>
              <td>{{ item.participant }}</td>
              <td v-if="!item.value"></td>
              <td v-if="item.value && race.team == 'RELAY_TIME_SLOTS'">{{ item.value | distance(event.unit) }} </td>
              <td v-if="item.value && race.team == 'RELAY_DISTANCE_SLOTS'">{{ item.value | duration }} </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-if="race.type !== 'EXTERNAL' && !showRelaySlots && hasDetails && !showTrainingPlanProgress">
      <v-card-title v-if="race.team" class="subtitle px-3">
        {{$t('results.details.breakdown-team-member')}}
      </v-card-title>
      <v-card-title v-if="!race.team" class="subtitle px-3">
        {{$t('results.details.breakdown-activity')}}
      </v-card-title>
      <v-card-text v-if="result.score_value == 0 && race.team && result.data.length > 0">
        <v-alert type="info">{{$t('results.details.team-requirements-not-met')}}</v-alert>
      </v-card-text>
      <v-skeleton-loader v-if="$store.getters.loading" type="table"></v-skeleton-loader>
      <v-simple-table fixed-header >
        <template v-slot:default>
          <thead>
            <tr>
              <th v-if="!race.team" class="text-left">{{$t('profile.activities.sport')}}</th>
              <th v-if="!race.team" class="text-left">{{$t('profile.activities.source')}}</th>
              <th v-if="!race.team" class="text-left">{{$t('results.cols.date')}} *</th>
              <th v-if="race.team" class="text-left">{{$t('events.cols.participant')}}</th>
              <th v-if="race.team && race.team_gender_results" class="text-left">{{$t('results.cols.gender')}}</th>
              <th v-if="race.team && filter && filter.category" class="text-left">{{$t('results.cols.category')}}</th>
              <th v-if="race.team && race.scoring == 'RESULT'" class="text-left">{{$t('results.cols.date')}} *</th>
              <th v-if="race.team && race.scoring != 'RESULT'" class="text-left">{{$t('results.cols.last-activity')}} *</th>
              <th v-if="race.team && race.scoring == 'BADGES'" class="text-left font-weight-bold">{{$t('results.cols.badges')}}</th>
              <th v-if="race.scoring == 'RESULT' || race.scoring == 'TRACK'" class="text-left font-weight-bold">{{$t('results.cols.corrected-time')}}</th>
              <th v-if="race.scoring == 'CALORIES'" class="text-left font-weight-bold">{{$t('events.cols.calories')}}</th>
              <th v-if="race.scoring == 'TIME'" class="text-left font-weight-bold">{{$t('events.cols.total-time')}}</th>
              <th v-if="race.scoring == 'CUSTOM'" class="text-left font-weight-bold">
                {{race.custom||$t('profile.activities.points')}}
                <TooltipDialog v-if="race.scoring_desc" title="Scoring for this race" :text="race.scoring_desc" />
              </th>
              <th v-if="showStairs" class="text-left font-weight-bold">{{$t('results.cols.stairs')}}</th>
              <th v-if="showDuration" class="text-left">{{$t('results.cols.time')}}</th>
              <th v-if="showDistance" class="text-left">{{$t('events.cols.distance')}}</th>
              <th v-if="showElevation" class="text-left">{{$t('results.cols.elevation')}}</th>
              <th v-if="showCustom && !showStairs" class="text-left">{{ race.custom }}</th>
              <th v-if="showPace" class="text-left">{{$t('results.cols.pace')}}</th>
              <th v-if="showSpeed" class="text-left">Strokes per min</th>
              <th class="text-left px-0">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in result.data" :key="idx" :class="getResultRowClass(item)">
              <td v-if="!race.team" class="pr-0" style="width:30px;"><v-icon>{{$helpers.getActivityIcon(item.type)}}</v-icon></td>
              <td v-if="!race.team" class="pr-0" style="width:30px;"><v-icon :title="item.prov">{{$helpers.getProviderIcon(item.prov)}}</v-icon></td>
              <td v-if="race.team">{{ item.name }}</td>
              <td v-if="race.team && race.team_gender_results">{{$helpers.getGenderForDisplay(item.gender)}}</td>
              <td v-if="race.team && filter && filter.category">{{item.cat}}</td>
              <td>{{ item.start | localDate('L') }}</td>
              <td class="font-weight-bold" v-if="race.team && race.scoring == 'BADGES'">{{ item.v }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'RESULT' || race.scoring == 'TRACK'">{{ -1*item.v | duration }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'DISTANCE'">{{ item.d | distance(event.unit) }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'CALORIES'">{{ item.v }} kcal </td>
              <td class="font-weight-bold" v-if="race.scoring == 'TIME'">{{ item.v | duration }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'STAIRS'">{{ item.v }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'CUSTOM'">{{ item.v }} </td>
              <td class="font-weight-bold" v-if="race.scoring != 'STAIRS' && showStairs">{{ item.custom }} </td>
              <td v-if="showDuration">{{ item.t | duration }} </td>
              <td v-if="showDistance">{{ item.d | distance(event.unit) }} </td>
              <td v-if="showElevation">{{ item.e | elevation(event.unit) }} </td>
              <td v-if="showCustom && !showStairs">{{ item.custom }} </td>
              <td v-if="showPace">{{ item.t / (item.d/1000) | pace(event.unit, race.activity_types && race.activity_types.length == 1 ? race.activity_types[0] : null) }} </td>
              <td v-if="showSpeed">{{ item.custom | speed(event.unit, race.activity_types && race.activity_types.length == 1 ? race.activity_types[0] : null) }} </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-text v-if="race.type !== 'EXTERNAL'" class="text-muted">{{$t('results.details.local-time-message')}}</v-card-text>
    </div>
    <v-card-title class="subtitle px-3" ref="comments">
      {{$t('results.details.comments-description')}}
    </v-card-title>
    <CommentBox :comments="comments" :selfId="result.id" @post="postComment" @delete="deleteComment" publicVisible/>
    <v-card-title v-if="result.photos" class="subtitle px-3" ref="photos">
      {{$t('results.details.photos-title')}}
    </v-card-title>
    <PhotoGallery v-if="result.photos" :photos="photos" />
  </div>
</template>

<script>
import assetsService from "@/services/assetsService";
import eventService from "@/services/eventService";
import PointsInfoDialog from "@/components/PointsInfoDialog.vue";
import ActivityScoreChip from "@/components/ActivityScoreChip";
import ActivityBonusScoreChip from "@/components/ActivityBonusScoreChip";
import ActivityPenaltyScoreChip from "@/components/ActivityPenaltyScoreChip";
import TrainingPlanProgress from "@/components/TrainingPlanProgress";
import BadgeDetailsDialog from "@/components/BadgeDetailsDialog";
import ShareButton from "@/components/ShareButton";
import CommentBox from "@/components/CommentBox";
import PhotoGallery from "@/components/PhotoGallery";
import TooltipDialog from "@/components/TooltipDialog";
import VueMarkdown from 'vue-markdown'
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import resultColumns from '@/util/resultColumns.js'

export default {
  name: "RaceResultDetails",
  components: {
    PointsInfoDialog,
    ActivityScoreChip,
    ActivityBonusScoreChip,
    ActivityPenaltyScoreChip,
    TrainingPlanProgress,
    BadgeDetailsDialog,
    TooltipDialog,
    VueMarkdown,
    ShareButton,
    CommentBox,
    PhotoGallery,
    TrainingPlanProgress,
  },
  props: {
      event: Object,
      race: Object,
      badges: Array,
      meta: Object,
      result: Object,
      filter: Object,
      popupMode: Boolean,
  },
  data() {
    return {
      details: null,
      columns: [],
      liked: false,
      comments: null,
      photos: null,
      trainingPlan: null,
      resultColumns: resultColumns,
      claimVisible: true,
    };
  },
  async mounted() {
    await this.tryLoadComments();
    await this.tryLoadPhotos();
    await this.tryLoadTrainingPlan();
    this.loadCustomColumns();
  },
  methods: {
    getResultRowClass(item) {
      if (item.included === true) {
        return 'font-weight-bold'
      }
      if (item.included === false) {
        return 'grey--text'
      }
      return null;
    },
    async claimResult() {
      this.claimVisible = !this.claimVisible;
    },
    async like() {
      const response = (await eventService.likeResult(this.event.id, this.race.id, this.result.id, this.filter)).data;
      if (response.status == 'OK') {
        this.liked = true;
        this.$gtag.event('liked', { 'event_category': 'result', 'event_label': `${this.event.id}.${this.race.id}.${this.result.id}` });
      }
      this.$helpers.toastResponse(this, response, 'Thanks! Your 👍 has been delivered!');
    },
    async postComment(data) {
      //console.log('Adding comment', message);
      const response = (await eventService.commentResult(this.event.id, this.race.id, this.result.id, this.filter, data.comment)).data;
      if (response.status == 'OK') {
        this.$gtag.event('commented', { 'event_category': 'result', 'event_label': `${this.event.id}.${this.race.id}.${this.result.id}` });
        this.comments = (await eventService.getRaceResultComments(this.event.id, this.race.id, this.result.id, this.filter)).data.data;
      }
      this.$helpers.toastResponse(this, response, 'Thanks! Your 💬 has been delivered!');
      data.done();
    },
    async deleteComment(commentId) {
      const response = (await eventService.deleteResultComment(this.event.id, this.race.id, this.result.id, this.filter, commentId)).data;
      if (response.status == 'OK') {
        this.comments = (await eventService.getRaceResultComments(this.event.id, this.race.id, this.result.id, this.filter)).data.data;
      }
      this.$helpers.toastResponse(this, response, 'Your comment has been deleted.');
    },
    async tryLoadComments() {
      this.comments = null;
      if (this.result == null) {
        return;
      }
      //console.log('Loading comments', this.result.comments);
      if (this.result.comments > 0) {
        this.comments = (await eventService.getRaceResultComments(this.event.id, this.race.id, this.result.id, this.filter)).data.data;
      }
    },
    async tryLoadPhotos() {
      this.photos = null;
      if (this.result == null) {
        return;
      }
      if (this.result.photos > 0) {
        this.photos = (await eventService.getRaceResultPhotos(this.event.id, this.race.id, this.result.id)).data.data;
      }
      console.log('Loading photos', this.photos);
    },
    async tryLoadTrainingPlan() {
      this.trainingPlan = null;
      if (this.result == null) {
        return;
      }
      if (this.race.scoring === 'TRAINING_PLAN') {
        this.trainingPlan = (await assetsService.getTrainingPlan(this.race.id)).data;
      }
      if (this.badges == null) {
        this.badges = (await eventService.getRaceBadges(this.event.id, this.race.id)).data.data;
      }
      console.log('Loaded training plan', this.trainingPlan);
    },
    formatChallengeValue(val) {
      if (this.race.scoring == 'DISTANCE') {
        return this.$options.filters.distance(val, this.event.unit);
      }
      if (this.race.scoring == 'ELEVATION') {
        return this.$options.filters.elevation(val, this.event.unit);
      }
      if (this.race.scoring == 'TIME') {
        return this.$options.filters.duration(val, /*compact:*/true);
      }
      if (this.race.scoring == 'STAIRS') {
        return `${val} stairs`;
      }
      if (this.race.scoring == 'CALORIES') {
        return `${val} kcal`;
      }
      if (this.race.scoring == 'TRAINING_PLAN') {
        return `${val} workouts`;
      }
      if (this.race.scoring == 'CUSTOM') {
        return `${val} ${this.race.custom || 'points'}`;
      }
      return val;
    },
    loadCustomColumns() {
      if (this.race) {
        this.columns = resultColumns.mergeAndFilterResultColumns(this.race, this.meta, this.race.cols);
      }
    },
    hasColumn(type) {
      if (this.columns) {
        var col = this.columns.find(x => x.type === type);
        console.log('hasColumn config', type, col && col.visible, col);
        return col && col.visible;
      }
      console.log('hasColumn WITHOUT config', type);
      return false;
    },

  },
  computed: {
    showProgressBar: function() {
      return this.race.scoring == 'DISTANCE'
          || this.race.scoring == 'ELEVATION'
          || this.race.scoring == 'STAIRS'
          || this.race.scoring == 'BADGES'
          || this.race.scoring == 'TRAINING_PLAN'
          || this.race.scoring == 'TIME'
          || this.race.scoring == 'CUSTOM'
          || this.race.scoring == 'CALORIES';
    },
    hasDetails() {
      return this.result.data && this.result.data.length > 0;
    },
    showStairs: function() {
      return this.race.scoring == 'STAIRS' || this.race.type == 'STAIR_CLIMBING';
    },
    showDuration: function() {
      return this.race.scoring != 'TIME' &&  !(this.race.team && this.race.scoring == 'BADGES')  && this.hasColumn('TIME');
    },
    showDistance: function() {
      return !(this.race.team && this.race.scoring == 'BADGES') && this.race.scoring != 'STAIRS' && this.race.type != 'STAIR_CLIMBING' && this.hasColumn('DISTANCE');
    },
    isRowing: function() {
      return this.race.activity_types && this.race.activity_types.length == 1 && this.race.activity_types[0] === 'ROWING';
    },
    showElevation: function() {
      return this.showDistance && !this.isRowing && this.hasColumn('ELEVATION');
    },
    showCustom: function() { return this.hasColumn('CUSTOM'); },
    showPace: function() { return this.hasColumn('PACE'); },
    showSpeed: function() { return this.hasColumn('SPEED'); },
    showTrainingPlanProgress() {
      return this.race.scoring === 'TRAINING_PLAN';
    },
    showRelaySlots() {
      return (this.race.team == 'RELAY_TIME_SLOTS' || this.race.team == 'RELAY_DISTANCE_SLOTS') && this.result.slots;
    },
    hasSlotSports() {
      return this.showRelaySlots && this.result.slots.some(x => x.slot_type);
    },
    scoreColumn() {
      return this.columns == null ? nul : this.columns.find(x => x.type == 'SCORE');
    },
    secondaryScoreColumn() {
      return this.columns == null ? nul : this.columns.find(x => x.type == 'SECONDARY_SCORE');
    },
    
    ...mapGetters({
      user: "user"
    }),

  },
  watch: {
    async result(val) {
      await this.tryLoadComments();
      await this.tryLoadPhotos();
      await this.tryLoadTrainingPlan();
    },
    async race(val) {
      this.badges = null;
      this.loadCustomColumns();
    }
  }

};
</script>
<style lang="scss">
  .progress-bg {
      background: linear-gradient(90deg, rgba(255,122,62,1) 0%, rgba(255,235,0,1) 35%, rgba(18,255,0,1) 100%); 

  }
  .profile--header {
    .profile--img { position: relative; top: 40px; left: 10px; margin-right:10px; }
  }
  .profile--actions.with-img { margin-left: 120px; margin-bottom: 25px;}
  .profile--actions {
  }
</style>

