<template>
  <div>
    <v-breadcrumbs :items="[
      { text: 'Solutions', exact: true, to: { name: 'solutions' } },
      { text: 'Corporate Challenges', disabled: true },
    ]" />
    <div :class="`my-10 ${$vuetify.breakpoint.xs?'':''}`">
      <h1>Engaging and Effortless Corporate Challenges</h1>
      <p>
        Use the Sodisp Challenge Platform to create motivating challenges to keep your employees fit and active.
      </p>
    </div>



    <div class="pb-6 pb-md-12">
      <v-row >
        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(0)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-watch-fitness</v-icon></v-card-text>
            <v-card-title class="headline">All major fitness trackers supported</v-card-title>
            <v-card-text>
              Allow your employees to use the app or device they already love! 
              We support {{ providers.getSupportedProviders().join(', ') }}, plus Apple Watch and Samsung Health.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(1)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-users</v-icon></v-card-text>
            <v-card-title class="headline">Build and Strengthen Connections</v-card-title>
            <v-card-text>
              Build and strengthen inter-team connections within your organization. Host team challenges to promote your divisions, facilities or country departments to work together towards a common goal.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(2)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-medal</v-icon></v-card-text>
            <v-card-title class="headline">Everyone wins!</v-card-title>
            <v-card-text>
              We offer various challenge types to promote physical and mental wellbeing within teams and organizations.
              Award Badges to keep your employees motivated and active throughout the entire challenge!
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(3)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-shield-check</v-icon></v-card-text>
            <v-card-title class="headline">Private and secure</v-card-title>
            <v-card-text>
              We understand that health and fitness data of employees needs to be carefully protected. We have various options to configure which devices and options you want to offer to your employees.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-muted text-center">
          <div><v-icon class="my-6" color="grey">fal fa-chevron-double-down</v-icon></div>
          Scroll down for more features.
        </v-col>
      </v-row>
    </div>


    <div id="page-customers" class="mt-0 white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by world-famous brands</h1>
            <p>
              The Sodisp Platform is used by world-renowned organizations all across the world. Some of our key partners are:
            </p>
            <v-row>
            <v-col v-for="(item, idx) in [{id: 'siemens', name: 'Siemens'}, {id: 'campbells', name: 'Campbell\'s'}, {id: 'synergium', name: 'Synergium'}]" :key="idx" cols="6" md="4">
              <v-sheet :elevation="1" class="text-center pa-4" height="90" color="transparent" :title="item.name" style="background-color: rgba(255,255,255,0.1) !important; line-height:85px;">
                <img :src="`/images/customers/${item.id}-white.png`" :alt="item.name" class="d-inline-block" style="max-width:100%; max-height:60px;"/>
              </v-sheet>
            </v-col>
            </v-row>

          </v-col>
        </v-row>
      </div>
    </div>
    <div class="grey lighten-4">
    <v-container class="pb-0">
      <v-row>
        <v-col class="pb-0">
          <h3 class="title">Integration with all major fitness trackers</h3>
          <v-row>
          <v-col 
            v-for="(img, idx) in ['strava-color.png', 'garmin-color.png', 'mapmyfitness-color.png', 'fitbit-color.jpg', 'polar-color.png', 'suunto-color.png', 'coros-color.png', 'apple-watch-samsung-health.png']"
            :key="idx"
            cols="6" 
            md="4">
            <v-sheet :elevation="1" class="text-center align-center pa-4" height="80">
              <img :src="`/images/${img}`" :alt="img" class="d-inline-block" style="max-width:100%; max-height:40px;"/>
            </v-sheet>
          </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    </div>

    <div :class="`mt-10 text-center ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Discover our key features</h1>
      <p>
      </p>
    </div>
    <v-container class="features">
      <v-row>
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-watch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fab fa-strava</v-icon>
          <h4>Auto-sync with Garmin, Strava, Fitbit, Polar and more</h4>
          <p>
            Activities tracked with Garmin, Polar, Suunto, Fitbit or COROS devices and the Strava app are automatically synced and added to results making it extremely easy for your participants to make every km/mile count!
          </p>

          <p>
            Next to our native integrations we also support manual entry or upload of any other gpx file.  
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-stopwatch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-ruler</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-mountain</v-icon>
          <h4>Best result, total distance, total elevation and more</h4>
          <p>
            We support all popular challenge modes: best result, total distance or total elevation. Even better: you can combine them in the same virtual challenge!
          </p>
          <p> 
            Whether your virtual event/challenge runs for a day, a week or even multiple months, whether it is a single sport or a multi-sport event, you can host it on sodisp.
          </p>
        </v-col>
        

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-swimmer fa-flip-horizontal</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-bicycle</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-running</v-icon>
          <h4>All sports and combinations</h4>
          <p>
            We support all popular sports and you can combine them all in the same challenge.
          </p>
          <p> 
            Use our platform to host virtual triathlons, multi-sport events or any combinations of different activity types.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-map</v-icon>
          <h4>Progress on map</h4>
          <p>
            Make your challenge more fun by mapping the progress on a real map. Simply upload your virtual course map and we'll map the progress on the map.
          </p>
          <p>
            We show all participants on the same map and have a powerful search option to find your own or team members' results easily!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-shield</v-icon>
          <h4>Badges</h4>
          <p>
            Create your own intermediate goals based on distance or elevation completed and award badges to keep your participants motivated to make it to the (virtual) finish line! Use our consecutive challenge to motivate your participants to keep going and our location based badges for virtual treasure hunts or embed local content into your challenge.
          </p>
          <p>
            Easily customize the layout or even use custom images to make the badges unique to your challenge. You can optionally enable and customize email messages to be sent whenever a badge is awarded and view overall statistics of each badge on your race result page.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-bullseye</v-icon>
          <h4>Collective progress &amp; Participation Mode</h4>
          <p>
            We're into this together, so let's work together in reaching our common goals!
            Easily set a collective target and everyone's effort will count toward reaching this collective goal!
          </p>
          <p> 
            Enable our participation mode for non-competitive challenges to change the leaderboard into a ticker board where the latest activity 
            is shown at the top to give everyone their moment in the spotlights, regardless of distance, pace, or speed!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-user</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-users</v-icon>
          <h4>Individual or team races</h4>
          <p>
            Create both individual or team challenges, or combinations of both! With the team functionality any group can work together to reach their common goals and compete with the other registered teams for first place!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-list</v-icon>
          <h4>Connect with your official race registration data</h4>
          <p>
            Easily load your registration data to create official results and allow only registered participants to join your virtual event.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-people-arrows</v-icon>
          <h4>Unique social distancing tools</h4>
          <p> 
            Our unique social distancing scoring techniques makes it possible to reward your participant for their responsible behavior. The more social distance they keep, the more points they score!
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-paint-roller</v-icon>
          <h4>Customize design</h4>
          <p> 
            Make it yours by customizing the design of your virtual event/challenge. Easily upload your logo and artwork to make your virtual event look beautiful.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tools</v-icon>
          <h4>Powerful Management Tools</h4>
          <p> 
            Use our easy to use, yet powerful management tools to manage your virtual challenge and results. All features can be configured using our self-service tools. Need help? No worries, we have extensive knowledge base articles available or can help you with an onboarding trainings session.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-cogs</v-icon>
          <h4>White-label and SSO integrations</h4>
          <p> 
            We can offer our entire platform as a white-label solution with custom branding. One step further is to enable Single Sign-On (SSO) for an effortless integration with your corporate identity platform.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-cogs</v-icon>
          <h4>API integrations</h4>
          <p> 
            Use our REST APIs for custom integration with your systems and services. We provide APIs for various integration scenarios such as automated result or registration data sync. We have Excel import/export options available to help you get started without development work needed.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tags</v-icon>
          <h4>Custom Pricing Available</h4>
          <p> 
            We understand every organization has different goals and needs. Our flexible pricing can adjust from a self-service to a full-service model and everything in between. <router-link to="/contact">Contact us</router-link> to discuss how sodisp can help your corporate challenge.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Get in touch</h2>
            <h1 class="subtitle">Host engaging and beautiful corporate challenges!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4" color="white" href="/contact">Contact Us</v-btn>
              <v-btn class="ml-4 mb-4" color="white" outlined href="/request-demo">Request Free Demo</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import siteData from '@/data/site.json'
import providers from '@/util/oauthProviders.js'

export default {
  name: "Contact",
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      providers: providers,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>