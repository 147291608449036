<template>
  <div>
    <v-dialog v-model="isModal">
      <v-btn id="closeDialog" text large color="white" @click="isModal = false"><v-icon class="mr-2">fa-times-circle</v-icon> {{$t('shared.close')}}</v-btn>
      <v-card v-if="resultEvent && resultRace">  
        <RaceResultDetails :event="resultEvent" :race="resultRace" :meta="meta" :result="result" :filter="filter" :badges="resultBadges" popupMode/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="isModal = false">{{$t('shared.close')}}</v-btn>
        </v-card-actions>      
      </v-card>
    </v-dialog>    
  </div>
</template>

<script>
import eventService from "@/services/eventService";
import RaceResultDetails from "@/components/RaceResultDetails.vue";

export default {
  name: "RaceResultDetailsDialog",
  components: {
    RaceResultDetails,
  },
  props: {
      event: Object,
      race: Object,
      meta: Object,
      badges: Array,
      filter: Object,
  },
  data() {
    return {
      isModal: false,
      result: null,
      resultRace: null,
      resultEvent: null,
      resultBadges: null,
    };
  },
  async mounted() {
  },
  methods: {
    async open(result, event, race, badges) {
      this.isModal = true;
      this.resultEvent = event || this.event;
      this.resultRace = race || this.race;
      this.resultBadges = badges || this.badges;
      this.result = null;
      if (result.data == null) {
        result = (await eventService.getRaceResultDetails(this.resultEvent.id, this.resultRace.id, result.id, this.filter)).data;
      }
      this.result = result;
    }
  },
  computed: {
  },

};
</script>
<style lang="scss">
#closeDialog {
  position: absolute;
  margin-top: -40px;
  right: 15px;
  width: auto;
}
</style>

