<template>
  <div>
    <v-card>
      <v-card-title class="headline">Admin tile map</v-card-title>

      <TileMap :country="this.$route.params.country" :tileOptionCreator="tileOptionsCreator" @selected="tileSelected" @mapPanned="mapPanned"/>
      
      <v-alert v-if="tile" type="info">
        <h3><router-link class="white--text" target="_blank" :to="{name: 'tile', params: { id:tile.id, type: activityType.toLowerCase() }}">{{tile.name}}</router-link> (<span :class="`flag-icon flag-icon-${tile.country_code.toLowerCase() }`"></span> {{tile.country_code}} - {{tile.id}})</h3>
        <p class="mb-0">Time: {{ new Date() | moment('timezone', tile.time_zone, 'LTS Z')}} ({{ tile.time_zone }})</p>
        <p class="mb-0">Loc (lat, lng): {{ tile.vertices[0].lat.toFixed(5) }}, {{ tile.vertices[0].lng.toFixed(5) }}</p>
      </v-alert>
      <v-alert v-if="debugInfo" type="info">{{debugInfo}}</v-alert>
    </v-card>
  </div>
</template>

<script>
// Todo: add swipe/scroll notification from https://codepen.io/surisdziugas/pen/LzXPwz

import { mapGetters } from "vuex";
import TileMap from "@/components/TileMap";

export default {
  name: "AdminMap",
  components: {
    TileMap,
  },
  props: {
  },
  data() {
    return {
      profile: null,
      debugInfo: null,
      activityType: 'RUNNING',
      tile: null,
      polygon: null,
    };
  },
  async mounted() {
  },
  methods: {
    async tileSelected(tile, polygon) {
      this.tile = tile;
      console.log(`${polygon.getCenter().lat.toFixed(5)},${polygon.getCenter().lng.toFixed(5)}`)
      this.debugInfo = `Tile: ${tile.id}, center: ${polygon.getCenter().lat},${polygon.getCenter().lng}`;
    },

    tileOptionsCreator(tile) {
      if (tile.name) {
        return { customId: tile.id, color: 'black', weight: 1, stroke: true, fill: true, fillOpacity: .1, fillColor: 'white' };
      }
      return { customId: tile.id, color: 'black', weight: 1, stroke: true, fill: true, fillOpacity: .2, fillColor: 'red' };
    },

    mapPanned(map) {
      //this.debugInfo = `Zoom: ${map.getZoom()}, bounds: ${ map.getBounds() }`;
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

