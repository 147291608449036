<template>
  <div v-if="event && race && result">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <EventHeader :event="event" :title="race.name" />

      <v-container class="mt-0 ml-0">
        <v-row>
          <v-col v-if="scoreColumn" cols="12" sm="4" md="4">
            <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, 0) : 'primary darken-1'">
              <v-card-text class="white--text pb-0">{{$t('results.details.badge-result')}}</v-card-text>
              <v-card-title class="subtitle pt-0">{{ result.name }}</v-card-title>
            </v-card>
          </v-col>
          <v-col v-if="scoreColumn" cols="12" sm="4" md="4">
            <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, -10) : 'primary darken-2'">
              <v-card-text class="white--text pb-0">{{ resultColumns.getColumnCaption(scoreColumn) }}</v-card-text>
              <v-card-title class="subtitle pt-0">{{ $helpers.getValueForDisplay($options, race, result.score_value, event.unit) }}</v-card-title>
            </v-card>
          </v-col>
          <v-col v-if="secondaryScoreColumn" cols="12" sm="4" md="4">
            <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, -20) : 'primary darken-3'">
              <v-card-text class="white--text pb-0">{{ resultColumns.getColumnCaption(secondaryScoreColumn) }}</v-card-text>
              <v-card-title class="subtitle pt-0">{{ $helpers.getSecondaryValueForDisplay($options, race, result.secondary_value, event.unit) }}</v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-alert v-if="!user" type="info">You must be logged in to claim a result.</v-alert>
      <v-alert v-if="!result.can_claim" type="info">This result cannot be claimed at the moment.</v-alert>
      <div v-if="user && result.can_claim">
        <v-card-title class="subtitle">Claim your result</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="claimResult" lazy-validation class="">
            <p>Please enter your personal information so we can verify this is really your result.</p>
            <v-alert v-if="!$store.getters.isLoading && response && response.status == 'ERROR'" type="error">
              {{ response.msg }}
            </v-alert>
            <v-text-field 
                v-model="code" 
                :rules="codeRules" 
                :label="$t('events.welcome.verification-code-label')" 
                :required="true"
                />

            <v-text-field 
                v-model="email" 
                :rules="emailRules" 
                :label="$t('events.quickentry.email-label')"
                :required="true"
                />

            <v-btn x-large color="primary" @click="claimResult">Claim this result</v-btn> 
          </v-form>
        </v-card-text>
      </div>

    </v-card>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import eventService from "@/services/eventService";
import EventHeader from "@/components/EventHeader.vue";
import RaceResultDetails from "@/components/RaceResultDetails";
import ShareButton from "@/components/ShareButton";
import resultColumns from '@/util/resultColumns.js'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Race",
  components: {
    EventHeader,
    RaceResultDetails,
    ShareButton,
  },
  props: {
  },
  data() {
    return {
      eventId: null,
      raceId: null,
      resultId: null,
      event: null,
      race: null,
      result: null,
      filter: null,
      meta: {}, // we don't have this here
      columns: [],
      resultColumns: resultColumns,
      code: null,
      email: null,
      response: null,
      valid: true,
      codeRules: [
        v => !!v || this.$t('events.welcome.verification-code-validation-msg'),
      ],
      emailRules: [
        v => !!v || this.$t('account.register.email-is-required'),
        v => /.+@.+/.test(v) || this.$t('account.register.email-must-be-valid')
      ],
    };
  },
  async mounted() {
    await this.loadData();
    this.loadCustomColumns();
  },
  methods: {
    async loadData() {
      this.eventId = this.$route.params.id;
      this.raceId = this.$route.params.raceId;
      this.resultId = this.$route.params.resultId;
      this.event = (await eventService.get(this.eventId)).data;
      this.race = this.event.races.find(x => x.id === this.raceId);
      this.result = (await eventService.getRaceResultDetails(this.eventId, this.raceId, this.resultId)).data;

    },
    loadCustomColumns() {
      if (this.race && this.meta) {
        this.columns = resultColumns.mergeAndFilterResultColumns(this.race, this.meta, this.race.cols);
      }
    },
    async claimResult() {
      if (this.$refs.form.validate()) {
        const model = { code: this.code, email: this.email };
        this.response = (await eventService.claimRaceResult(this.eventId, this.raceId, this.resultId, model)).data;
        this.$helpers.toastResponse(this, this.response, 'Successfully claimed this result.');
        if (this.response.status == 'OK') {
          this.$router.push({name: 'profile'});
        }
      }
    },
    
  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race || !this.result ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'race', params: {id: this.event.id, raceId: this.race.id}} },
        { text: this.result.name, disabled: true },
        { text: 'Claim', disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed';
    },
    scoreColumn() {
      return this.columns == null ? nul : this.columns.find(x => x.type == 'SCORE');
    },
    secondaryScoreColumn() {
      return this.columns == null ? nul : this.columns.find(x => x.type == 'SECONDARY_SCORE');
    },
    ...mapGetters({
      user: "user"
    }),

  },

};
</script>
<style lang="scss">

</style>

