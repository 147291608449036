<template>
  <v-row>
    <v-col cols="4" :class="dense ? 'pa-0' : ''">
      <v-text-field
        v-model.number="hours"
        type="number" 
        step="any"
        min="0"
        max="12"
        required
        persistent-hint
        :disabled="disabled"
        :hint="$t('shared.hours')"
        :prepend-icon="icon"
        :label="label"
        :rules="mergedRules"
        @change="handleChange"
      ></v-text-field>
    </v-col>
    <v-col cols="4" :class="dense ? 'pa-0' : ''">
      <v-text-field
        v-model.number="minutes"
        type="number" 
        step="any"
        min="0"
        max="59"
        required
        persistent-hint
        :disabled="disabled"
        :hint="$t('shared.minutes')"
        :rules="mergedRules"
        @change="handleChange"
      ></v-text-field>
    </v-col>
    <v-col cols="4" :class="dense ? 'pa-0' : ''">
      <v-text-field
        v-model.number="seconds"
        type="number" 
        step="any"
        min="0"
        max="59"
        required
        persistent-hint
        :disabled="disabled"
        :hint="$t('shared.seconds')"
        :rules="mergedRules"
        @change="handleChange"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: "DurationTextArea",
  components: {
  },
  props: {
    value: Number,
    label: String,
    icon: String,
    rules: Array,
    dense: Boolean,
    disabled: Boolean,
    required: Boolean,
    form: Object,
  },
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
      val: 0,
      defaultRules: [
        () => (this.required ? this.val > 0 : true) || 'Please enter the duration.',
      ],
    };
  },
  async mounted() {
  },
  methods: {
    handleChange(e) {
      if (!Number.isFinite(this.hours)) {
        this.hours = 0;
      }
      if (!Number.isFinite(this.minutes)) {
        this.minutes = 0;
      }
      if (!Number.isFinite(this.seconds)) {
        this.seconds = 0;
      }

      this.val = (3600*parseInt(this.hours))+ (60*parseInt(this.minutes)) + parseInt(this.seconds);
      console.log(`[duration] changed to ${this.val}.`);
      if (this.form) {
        this.form.validate();
      }
      this.$emit('input', this.val); 
    },
  },
  computed: {
    mergedRules() {
      return this.rules ? this.rules.concat(this.defaultRules) : this.defaultRules;
    },
    /*hours() {
      var timespan = moment.duration(this.value, 'seconds');
      return timespan.hours();
    },
    minutes() {
      var timespan = moment.duration(this.value, 'seconds');
      return timespan.minutes();
    },
    seconds() {
      var timespan = moment.duration(this.value, 'seconds');
      return timespan.seconds();
    },*/
  },
  watch: {
    value: {
      // the callback will be called immediately after the start of the observation
      immediate: true, 
      handler (val, oldVal) {
        var timespan = moment.duration(val, 'seconds');
        //console.log('Duration changed to ' + timespan, val);
        this.hours = timespan.hours();
        this.minutes = timespan.minutes();
        this.seconds = timespan.seconds();
      }
    },
    /*valueA(val) {
      var timespan = moment.duration(val, 'seconds');
      //console.log('Duration changed to ' + timespan, val);
      this.hours = timespan.hours();
      this.minutes = timespan.minutes();
      this.seconds = timespan.seconds();
      return val;
    }*/
  }

};
</script>
<style lang="scss">
</style>

