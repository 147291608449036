import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    getConfig() {
        //console.log('TOKEN', store.getters.jwtToken);
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`
            }
        };
    },
    getProfile(id) {
        return services.http.get(`/v1.0/admin/profile/${id}`, this.getConfig());
    },
    getTrainingPlan(id) {
        return services.http.get(`/v1.0/admin/training-plans/${id}`, this.getConfig());
    },
    
}