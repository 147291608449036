<template>
    <div v-if="$i18n.locale == 'en'" class="grey lighten-3">
      <v-card-title class="headline mb-0">Social Distancing Rules</v-card-title>
      <v-card-text class="mb-0">
        <span v-if="tenant.isDefault">We're named <strong>so</strong>cial <strong>di</strong>stancing <strong>sp</strong>orts for a reason! </span>
        For our own safety, and the safety and health of others around us, it is critically important we follow social distancing guidelines.
        <router-link to="/socialdistancing" color="accent">More &raquo;</router-link> 
      </v-card-text>
      <v-container class="mt-0 pt-0">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-card class="white--text" color="accent darken-1">
              <v-card-title class="subtitle">Keep a safe distance</v-card-title>
              <p class="pa-4 pt-0">
                Keep the advised safe distance with others around you at all times! Go out solo or with your spouse.
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card class="white--text" color="accent darken-2">
              <v-card-title class="subtitle">Avoid busy places</v-card-title>
              <p class="pa-4 pt-0">
                Try to avoid busy city centers and parks and go explore more quiet areas in your neighborhood!
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-card class="white--text" color="accent darken-3">
              <v-card-title class="subtitle">Avoid busy moments</v-card-title>
              <p class="pa-4 pt-0">
                Why not go out early on the day or late in the evening to give yourself and others more space?
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

</template>

<script>
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "SodispRules",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
    };
  },
  mounted() {
  },
  methods: {
  },
  computed: {
  },
};
</script>

<style scoped>
</style>