<template>
  <div>
    <v-card>
      <v-card-title class="headline">
        Event Wizard
      </v-card-title>
      <v-alert v-if="error || !valid" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>

      <v-expansion-panels v-if="event" v-model="wizardStep" accordion tile>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
            <span>Step 1: <strong class="ml-1">Basic event setup</strong></span>
            <v-spacer/>
            <span v-if="stepInfoValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepInfoValid" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="ml-8">Please enter the generic details of your event.</p>
            <v-form ref="form" v-model="valid" lazy-validation>
              
              <v-text-field 
                  v-model="event.name" 
                  :rules="nameRules" 
                  label="Event name (required)" 
                  hint="The name of your event, preferable with the edition or year included."
                  prepend-icon="fa-pencil-alt"
                  required
                  />
              <v-text-field 
                  v-model="event.url" 
                  :rules="urlRules" 
                  label="Event website (required)" 
                  prepend-icon="fa-link"
                  required
                  />
              <v-radio-group v-model="event.unit" row class="mt-0" persistent-hint hint="Set distance unit for this event. This overwrites user preferences." prepend-icon="fa-ruler">
                <v-radio label="Metric (kilometers)" value="METRIC"></v-radio>
                <v-radio label="Imperial (miles)" value="IMPERIAL"></v-radio>
              </v-radio-group>

            </v-form>
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!stepInfoValid" class="mr-4" outlined color="primary" @click="skip">Skip</v-btn>
              <v-btn v-if="stepInfoValid" class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="next">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-calendar-alt</v-icon>
            <span>Step 2: <strong class="ml-1">Event Window</strong></span>
            <v-spacer/>
            <span v-if="stepDatesValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepDatesValid" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="ml-8">Select the event window. Only activities between open and close dates may count for this event.</p>
            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <DateWithTimeZonePicker v-model="event.from" :timeZone="event.timezone" label="First date (included)" />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <DateWithTimeZonePicker v-model="event.till" :timeZone="event.timezone" label="Last date (included)" />
              </v-col>
            </v-row>
              <v-text-field
                label="Time zone (read only)"
                class="mt-8"
                readonly
                disabled
                prepend-icon="fa fa-globe"
                v-model="event.timezone"
                />

            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!stepDatesValid" class="mr-4" outlined color="primary" @click="skip">Skip</v-btn>
              <v-btn v-if="stepDatesValid" class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="next">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-trophy</v-icon>
            <span>Step 3: <strong class="ml-1">Leaderboards</strong></span>
            <v-spacer/>
            <span v-if="stepRacesValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepRacesValid" class="shrink mr-4"><v-icon color="grey">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>

            <p class="d-flex">
              <span>Add one or more leaderboards to your event.</span>
              <v-spacer/>
              <v-switch v-model="advancedRaceOptions" hide-details label="Show advanced options" class="mt-0"/>
            </p>
              <v-row class="mb-4">
                <v-col
                  v-for="(item, idx) in event.races"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card :color="$helpers.getGradientColor(idx)">
                    <v-card-title class="text--bold subtitle">
                      {{item.name}}
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                      <div>Type: {{ siteData.scoring_methods.find(x => x.type == item.scoring).text }}</div>
                      <div>Goal: {{ $helpers.getGoalDistanceForDisplay($options, item, item.dist, event.unit) }}</div>
                      <div>Sports:             
                      <span v-if="!item.activity_types || item.activity_types.length==0">All</span>
                      <span v-else v-for="(type, idx) in item.activity_types" :key="idx">
                        <v-icon :title="$t('profile.activities.types.'+type)">{{ $helpers.getActivityIcon(type) }}</v-icon>
                        <span v-if="idx != item.activity_types.length-1" class="mx-1">{{ $t('shared.or') }}</span>
                      </span>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn v-if="item.type !== 'EXTERNAL'" text @click="$refs.raceDialog.editRace(item)">
                        <v-icon small class="mr-2">fadl fa fa-pencil-alt</v-icon> Edit
                      </v-btn>
                      <v-spacer/>
                      <v-btn text @click="deleteRace(item)">
                        <v-icon small class="mr-2">fadl fa fa-trash</v-icon> Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            <p v-if="!showAddRacePanel">
              <v-btn color="primary" outlined @click="showAddRacePanel = !showAddRacePanel">
                <v-icon class="mr-2">fa-plus-circle</v-icon> Add Race
              </v-btn>
            </p>
            <div v-if="showAddRacePanel">
              <p>
                Add Standard Distance: <v-btn v-for="(item,idx) in defaultRaces.filter(x => !event.races.some(y => x.dist === y.dist))" :key="idx" color="primary" class="px-2" style="min-width:10px !important;" text @click="$refs.raceDialog.addRaceAndSave(item)">{{item.name}}</v-btn>
              </p>
              <v-btn color="primary" outlined @click="$refs.raceDialog.addRace()">
                <v-icon small class="mr-2">fa-plus-circle</v-icon> Add Custom Leaderboard
              </v-btn>
              <v-btn v-if="false" color="primary" text @click="showAddRacePanel = false">Cancel</v-btn>
            </div>
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!stepRacesValid" class="mr-4" outlined color="primary" @click="skip">Skip</v-btn>
              <v-btn v-if="stepRacesValid" class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="next">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-fill-drip</v-icon>
            <span>Step 4: <strong class="ml-1">Design</strong> (optional)</span>
            <v-spacer/>
            <span v-if="stepDesignValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepDesignValid" class="shrink mr-4"><v-icon color="grey">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Customize the design of your event page. When you leave these empty we'll generate these automatically for you based on your event website graphics.</p>
            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <p>Logo<br/><span class="text-muted">(optional, square ~ 500 x 500 px)</span></p>
                <EventImageUploadForm :event-id="event.id" :img="event.logo" type="logo" @change="loadData"/>
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <p>Header image at the top of the event page<br/><span class="text-muted">(optional, landscape format ~ 1000 x 300 px)</span></p>
                <EventImageUploadForm :event-id="event.id" :img="event.img" type="image" @change="loadData"/>
              </v-col>
            </v-row>

            <h4>Preview</h4>
            <v-dialog v-if="event.img" max-width="800px">
              <template v-slot:activator="{ on }">
                <v-btn outlined color="primary" v-on="on"><v-icon small class="mr-2">fa fa-eye</v-icon> View Preview</v-btn>
              </template>
              <v-card>
                <EventHeader v-if="event.img" :event="event" :title="event.name" />
              </v-card>
            </v-dialog>
            <v-alert v-else type="info">Upload at least a header image to see a preview of the event header.</v-alert>

            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!stepDesignValid" class="mr-4" outlined color="primary" @click="skip">Skip</v-btn>
              <v-btn v-if="stepDesignValid" class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="next">Next Step</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-paper-plane</v-icon>
            <span>Step 5: <strong class="ml-1">Submit</strong></span>
            <v-spacer/>
            <span v-if="readyToSubmit" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-else class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="ml-8">Submit your event details.</p>
            <v-text-field 
              v-model="event.email" 
              label="Your contact email (not visible to users) (optional)" 
              :hint="`Only used by ${tenant.name} to inform you of product updates and in case we need to contact you. This will not be visible to users.`"
              prepend-icon="fa-envelope"
              required
              />

            <v-textarea
              v-model="event.remarks"
              label="Enter any remarks, comments, or optional implementation instructions:"
              prepend-icon="fa-comment"
              />

            <v-alert v-if="!readyToSubmit" type="warning">One or more steps are not yet completed. Please complete all steps before you can submit this event information.</v-alert>

            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn :disabled="!readyToSubmit" class="" color="primary" :loading="$store.getters.isLoading" @click="submit">Submit</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-card>
    
    <RaceDialog ref="raceDialog" :event="event" @save="save" :simple="!advancedRaceOptions"/>
    <SupportButton/>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import EventImageUploadForm from "@/components/EventImageUploadForm";
import ActivityUploadForm from "@/components/ActivityUploadForm";
import SupportButton from '@/components/SupportButton.vue'
import EventHeader from "@/components/EventHeader.vue";
import RaceDialog from './_RaceDialog.vue'
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment-timezone'
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    DateWithTimeZonePicker,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    EventImageUploadForm,
    ActivityUploadForm,
    SupportButton,
    Header,
    RaceDialog,
    EventHeader,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      wizardStep: 0,
      valid: true,
      savedSnackbar: false,
      showColorPickerMenu: false,
      error: null,
      event: null,
      originalEvent: null,
      groups: null,
      advancedRaceOptions: false,
      nameRules: [
        v => !!v || "Please enter the event name",
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      optionalEmailRules: [
        v => !v || /.+@.+/.test(v) || 'Enter a valid email address'
      ],
      urlRules: [
        v => !v || v.startsWith('https://') || "Website must start with 'https://' (http is not supported!)",
      ],
      numberRules: [
        v => v > 0 || "Please enter a positive number",
      ],
      showAddRacePanel: true,
      defaultRaces: [
        { name: '5k', scoring: 'RESULT', dist: 5000, activity_types: ['RUNNING', 'WALKING'] },
        { name: '10k', scoring: 'RESULT', dist: 10000, activity_types: ['RUNNING', 'WALKING'] },
        { name: '10 miles', scoring: 'RESULT', dist: 16093, activity_types: ['RUNNING', 'WALKING'] },
        { name: 'Half marathon', scoring: 'RESULT', dist: 21097, activity_types: ['RUNNING', 'WALKING'] },
        { name: 'Marathon', scoring: 'RESULT', dist: 42195, activity_types: ['RUNNING', 'WALKING'] },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async skip() {
      this.wizardStep++;
    },
    async next() {
      await this.save();
      this.wizardStep++;
    },
    async back() {
      this.wizardStep--;
    },
    async save() {
      if (!this.$refs.form || this.$refs.form.validate()) {
        this.error = null;
        try {
          var response = (await eventManagerService.put(this.event)).data;
          this.$helpers.toastResponse(this, {status: 'OK'}, 'Event Saved Successfully.');
          //await this.getEvent(response.data.id);
          //this.$router.push({ name: 'eventmanagerView', params: { id: response.data.id }});
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },
    async submit() {
      if (!this.$refs.form || this.$refs.form.validate()) {
        this.error = null;
        try {
          var response = (await eventManagerService.put(this.event)).data;
          this.$helpers.toastResponse(this, {status: 'OK'}, 'Event Saved Successfully.');
          //await this.getEvent(response.data.id);
          this.$router.push({ name: 'eventmanagerView', params: { id: response.id }});
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

    async deleteRace (item) {
      const index = this.event.races.indexOf(item);
      confirm('Are you sure you want to DELETE THIS RACE AND ALL RESULTS?') && this.event.races.splice(index, 1) && await this.save();
    },

    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.event.timezone = this.event.timezone || moment.tz.guess();
      this.groups = (await profileService.myManagedGroups()).data.data.filter(x => x.type === 'ORGANIZATION' || x.type === 'SERIES');
      this.originalEvent = { ...this.event}; // clone object
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

  },
  computed: {
    readyToSubmit() {
      return this.stepInfoValid && this.stepDatesValid;
    },
    stepInfoValid() {
      return this.event && this.event.name && this.event.url;
    },
    stepDatesValid() {
      return this.event && this.event.from && this.event.till;
    },
    stepRacesValid() {
      return this.event && this.event.races && this.event.races.length;
    },
    stepDesignValid() {
      return this.event && this.event.logo && this.event.img;
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Edit event', disabled: true },
      ];
    },
    allProvidersSelected() {
      return !this.event.allowed_providers || this.event.allowed_providers.length == 0;
    },
    computedAllowedHistoryDays:{
      get () {
        return this.event ? this.event.allowed_history_days : null
      },
      set (value) {
        this.event.allowed_history_days = value === /*must be triple!*/ 0 ? value : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },
    colorForBinding: {
      get() {
        console.log('GET', this.event.color);
        if (!this.event.color || this.event.color.length == 0) {
          return "#FFFFFF";
        }
        return this.event.color;
      },
      set(value) {
        console.log('SET', value);
        if (value && value.length > 7) {
          value = value.substring(0, 7).toUpperCase();
          if (value === '#FFFFFF') {
            value = null;
          }
        }
        this.event.color = value;
      }
    },
    swatchStyle() {
      const { event, showColorPickerMenu } = this
      return {
        backgroundColor: event.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        border: 'solid 1px #ccc',
        borderRadius: showColorPickerMenu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

