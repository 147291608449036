<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="activity">
      <v-card-title class="display-2">{{$t('profile.activities.delete.title')}}</v-card-title>
      <v-card-text class="mt-4">
        <v-icon>{{$helpers.getActivityIcon(activity.type)}}</v-icon> • {{ activity.start | moment('ddd L • LTS') }}
        <span v-if="activity.elevation_gain">
           • {{$t('profile.activities.details.elevation-gain')}}: {{ activity.elevation_gain | elevation(profile.unit) }}
        </span>
      </v-card-text>
      
      <v-container class="mt-0">
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-card class="white--text" color="primary darken-1">
              <v-card-text class="white--text">{{$t('profile.activities.distance')}}</v-card-text>
              <v-card-title class="subtitle">{{ activity.dist  | distance(profile.unit) }}</v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-card class="white--text" color="primary darken-2">
              <v-card-text class="white--text">{{$t('profile.activities.duration')}}</v-card-text>
              <v-card-title class="subtitle">{{ activity.time_s | duration }}</v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-card class="white--text" color="primary darken-3">
              <v-card-text class="white--text">{{$t('profile.activities.pace')}}</v-card-text>
              <v-card-title class="subtitle">{{ activity.time_s / (activity.dist / 1000) | pace(profile.unit, activity.type) }}</v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-alert tile type="warning" prominent>
        <v-row align="center">
          <v-col class="grow">{{$t('profile.activities.delete.confirm-msg')}}</v-col>
          <v-col class="shrink">
            <v-btn @click="deleteActivity" :loading="$store.getters.isLoading">{{$t('shared.delete')}}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import profileService from "@/services/profileService";
import activityService from "@/services/activityService";
import Header from './_Header.vue';

export default {
  name: "Activity",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      activity: null,
      profile: null,
      breadcrumbItems: [
        { text: this.$t('profile.title'), exact: true, to: {name: 'profile'} },
        { text: this.$t('profile.activities.title'), exact: true, to: {name: 'activities'} },
        { text: this.$t('profile.activities.delete.title'), disabled: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

  },
  methods: {
    async loadData() {
      if (this.user) {
        this.profile = (await profileService.get()).data;
        var response = await activityService.get(this.$route.params.id);
        this.activity = response.data;
      }
    },
    async deleteActivity() {
      if (true || confirm('Are you sure you want to delete this activity? There is no reversion for this operation.')) {
        var response = await activityService.delete(this.$route.params.id);
        this.$router.replace({ name: "profile" });
      }
    },
  },
  computed: {
    
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

