<template>
  <v-card>
    <Header :title="$t('profile.your-feed')" >
      <v-btn text dark :to="{name:'profileUpload'}"><v-icon small class="fa fa-camera-alt" /><span class="d-none ml-1 d-sm-flex">{{$t('profile.upload-photo.photo')}}</span></v-btn>    
    </Header>

    <div v-if="feedItems && feedItems.length > 0">
      <QuickSurvey v-if="feedItems.some(x => x.type == 'RESULT_PHOTO' || x.type == 'EVENT_PHOTO')" class="mx-4 mt-4" name="202103-selfie" question="What do you think of the selfie upload?" :items="[{value: 'very', text: '😀 Brilliant'},  {value: 'like', text: '😊 I like it'}, {value: 'neutral', text: '😐 Not sure'}, {value: 'not-working', text: '🙁 Didn\'t work for me'}]" />

      <v-timeline class="mx-4 pt-0" :dense="$vuetify.breakpoint.xsOnly">
          <template v-for="(group, category) in feedItemsByDate">
            <v-timeline-item :key="category" hide-dot class="date-separator">
              <span>{{group.key | moment("L")}}</span> 
            </v-timeline-item>

            <v-timeline-item
              v-for="(item, idx) in group.data"
              :key="`${category}-${idx}`"
              color="green"
              :small="$vuetify.breakpoint.xsOnly"
              :icon="item.tile ? $helpers.getActivityIcon(item.tile.activity_type) : null"
            >
              <template v-slot:opposite>
                <span>&nbsp;</span>
              </template>
              <v-card v-if="item.type == 'TILE_RANKING'" color="grey lighten-5" class="elevation-1">
                <v-card-title class="subtitle-1 pa-2 pb-0">
                  <strong>#{{ item.tile.pos }}</strong> <span class="grey--text mx-1">(of {{item.tile.cnt}})</span> in
                  <router-link class="ml-1" :to="{name: 'tile', params: { id:item.tile.id, type: item.tile.activity_type.toLowerCase() }, query: { ranking: item.tile.key }}">{{item.tile.name}}</router-link>
                </v-card-title>
                <v-card-text class="pa-2 pb-0">{{ item.msg }}</v-card-text>
                <v-card-actions class="pa-2 pt-0">
                  <v-btn text color="primary" :to="{name: 'tile', params: { id:item.tile.id, type: item.tile.activity_type.toLowerCase()}, query: { ranking: item.tile.key }}">View tile</v-btn>
                  <v-spacer/>
                  <ShareButton :text="`${item.share_msg || item.msg} - Check sodisp.com`" color="primary" :route="{name: 'tile', params: { id:item.tile.id, type: item.tile.activity_type.toLowerCase()}, query: { ranking: item.tile.key }}" />
                </v-card-actions>
              </v-card>
              <v-card v-if="item.race && item.race.result_id" color="grey lighten-5" class="elevation-1 d-flex flex-row">
                <div v-if="item.img" class="flex-shrink-1 pa-2">
                  <img :src="item.img" class="feed-image" />
                </div>
                <div class="flex-grow-1 ">
                  <v-card-title class="subtitle-1 pa-2 pb-0">
                    <strong>{{ item.race.event_name }}</strong>
                    <!-- <router-link class="ml-1" :to="{name: 'raceResults', params: { id:item.race.event_id, raceId: item.race.race_id }}">{{item.race.race_name}}</router-link> -->
                  </v-card-title>
                  <v-card-text class="pa-2 pb-0">{{ item.msg }}</v-card-text>
                  <v-card-actions class="pa-0">
                    <v-btn text color="primary" class="pl-0" :to="{name: 'raceResult', params: { id:item.race.event_id, raceId: item.race.race_id, resultId: item.race.result_id}}">{{$t('shared.view')}}</v-btn>
                    <v-spacer/>
                    <v-btn v-if="item.type == 'RESULT_PHOTO'" icon color="error" @click="deleteFeedItem(item.id)"><v-icon>fa-trash</v-icon></v-btn>
                    <ShareButton icon :text="`${item.share_msg || item.msg} - Check sodisp.com`" color="primary" :route="{name: 'raceResult', params: { id:item.race.event_id, raceId: item.race.race_id, resultId: item.race.result_id}}" />
                  </v-card-actions>
                </div>
              </v-card>
              <v-card v-else-if="item.race && item.race.event_id" color="grey lighten-5" class="elevation-1 d-flex flex-row">
                <div v-if="item.img" class="flex-shrink-1 pa-2">
                  <img :src="item.img" class="feed-image" />
                </div>
                <div class="flex-grow-1 ">
                  <v-card-title class="subtitle-1 pa-2 pb-0">
                    <strong>{{ item.race.event_name }}</strong>
                  </v-card-title>
                  <v-card-text class="pa-2 pb-0">{{ item.msg }}</v-card-text>
                  <v-card-actions class="pa-0">
                    <v-btn text color="primary" class="pl-0" :to="{name: 'event', params: { id:item.race.event_id}}">{{$t('shared.view')}}</v-btn>
                    <v-spacer/>
                    <v-btn v-if="item.type == 'EVENT_PHOTO'" icon color="error" @click="deleteFeedItem(item.id)"><v-icon>fa-trash</v-icon></v-btn>
                    <ShareButton icon :text="`${item.share_msg || item.msg} - Check sodisp.com`" color="primary" :route="{name: 'event', params: { id:item.race.event_id }}" />
                  </v-card-actions>
                </div>
              </v-card>
              <v-card v-else color="grey lighten-5" class="elevation-1">
                <v-card-text class="pa-2">{{ item.msg }}</v-card-text>
              </v-card>
            </v-timeline-item>

          </template>
        </v-timeline>
      <v-divider />
    </div>

  </v-card>
</template>



<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import ShareButton from '@/components/ShareButton.vue';
import Header from './_Header.vue';
import QuickSurvey from "@/components/QuickSurvey";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    Header,
    ShareButton,
    QuickSurvey,
  },
  props: {
  },
  data() {
    return {
      profile: null,
      providerUrls: null,
      feedItems: [],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        try{
          var response = await profileService.get();
        }
        catch {
          // network error occur after api has been deployed causing tokens to be invalidated
          // reload the page to prevent empty profile page from showing
          //window.location.reload(true);
          return;
        }
        this.profile = response.data;
        this.feedItems = (await profileService.getFeed()).data.data;
      }
    },
    async deleteFeedItem(id) {
      if (confirm('Are you sure you want to delete this item?')){
        const response = (await profileService.deleteFeedItem(id)).data;
        this.$helpers.toastResponse(this, response, 'The item has been deleted successfully.');
        await this.getProfile();
      }
    },
  },
  computed: {
    feedItemsByDate(){
      var result = this.feedItems.reduce((acc, item) => {
        const date = Vue.moment(item.timestamp).format('YYYYMMDD');
        (acc[date] = acc[date] || []).push(item)
        return acc
      }, {});
      console.log(result);
      const sorted = [];
      var keys = Object.keys(result);
      keys.sort();; // or loop over the object to get the array
      keys.reverse();
      for (var key of keys) {
        sorted.push({key: key, data: result[key]});
      }
      return sorted;
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
  .v-timeline {
    .v-timeline-item { margin-top: -25px; }
    .v-timeline-item:first-child { margin-top: 0; }
    .date-separator {
      .v-timeline-item__body { 
        max-width: 100% !important; text-align: center; background: white; font-weight: bold; 
        span { border-top: solid 2px rgba(0, 0, 0, 0.12); border-bottom: solid 2px rgba(0, 0, 0, 0.12); }
      }
      .v-timeline-item__divider { display: none; }
    }
    .feed-image { max-height: 100px; max-width: 100px; }
  }
  .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {left: 15px;}
  .v-timeline--dense {
    .v-timeline-item { margin-top: 0; }
    .v-timeline-item__divider { min-width: 30px;}
    .v-timeline-item__body { max-width: calc(100% - 30px); }
    .date-separator { 
      .v-timeline-item__body { 
        text-align: left;
      }
    }
    .feed-image { max-height: 75px; max-width: 75px; }
  }
</style>

