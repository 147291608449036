import axios from "axios";
import httpStore from "@/store";
import firebase from 'firebase/app'
import 'firebase/auth'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

const http = axios.create({
    baseURL: process.env.VUE_APP_API_HOST
});

// Add global headers
http.interceptors.request.use(async config => {
  var user = await firebase.auth().currentUser;
  if (user) {
    // this either gets the current token or refreshes it to retrieve a new token
    var token = await user.getIdToken();
    config.headers.authorization = `Bearer ${token}`
  }
  // set the tenant
  config.headers["x-tenant-id"] = tenant.id;
  //config.headers["x-tenant-id"] = 'eventscom';
  return config
 }, (error) => {
  return Promise.reject(error)
});

export default {
    http: http,
    enableInterceptor() {
        this.axiosInterceptor = http.interceptors.request.use((config) => {
          httpStore.commit('START_LOADING');
        return config;
      }, (error) => {
        httpStore.commit('STOP_LOADING');
        return Promise.reject(error);
      });

      http.interceptors.response.use((response) => {
        httpStore.commit('STOP_LOADING');
        return response;
      }, function (error) {
        httpStore.commit('STOP_LOADING');
        return Promise.reject(error);
      });
    },

    disableInterceptor() {
      http.interceptors.request.eject(this.axiosInterceptor);
    }
};
