import i18n from './i18n'
import Vue from "vue";
import siteData from '@/data/site.json'

const UnitType = Object.freeze({ DISTANCE: 1, ELEVATION: 2, NUMBER: 3 });

export default {
    UnitType: UnitType,

    prefixSelectListWith(array, defaultItem) {
      var copy = [...array];
      copy.unshift(typeof defaultItem === 'string' ? {text: defaultItem, type: null} : defaultItem);
      return copy;
    },

    displayText(lookupListName, type, defaultText) {
      defaultText = defaultText || '';
      if (siteData[lookupListName]) {
        return (siteData[lookupListName].find(x => x.type == type)||{text: defaultText}).text;
      }
      return defaultText;
    },

    toastResponse(self, syncModel, okMessage) {
      if (syncModel.status == 'OK'){
        self.$toast.success(syncModel.msg || okMessage || 'All good!');
      }
      else if (syncModel.status == 'INFO'){
        self.$toast.info(syncModel.msg);
      }
      else if (syncModel.status == 'ERROR'){
        self.$toast.error(syncModel.msg);
      }
    },
    getGradientColor(index) {
      //const colors = ['gradient lighten-1', 'gradient', 'gradient darken-1', 'header lighten-1', 'header', 'header darken-1'];
      const colors = ['gradient lighten-1', 'gradient', 'orange', 'orange darken-1', 'header', 'header darken-1'];
      return colors[index % colors.length];
    },
    randomId() {
      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
      const id = Math.random().toString(36).substr(2, 9);
      // return in format abcd-efghi
      return `${id.substr(0, 4)}-${id.substr(4)}`;

      // note: below wasn't very random at all
      //return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      return 'yyy-yyy'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    getUnitType(metricType) {
      return metricType == 'IMPERIAL' ? 'mi' : 'km';
    },
    getGenderForDisplay(value) {
      if (value === 'M') {
        return i18n.t('shared.gender-m');
      }
      if (value === 'F') {
        return i18n.t('shared.gender-f');
      }
      return null;
    },
    getActivityIcon(type) {
      if (type === 'RUNNING' || type === 'INDOOR_RUNNING') {
        return 'fa-running';
      }
      if (type === 'CYCLING' || type === 'INDOOR_CYCLING') {
        return 'fa-biking';
      }
      if (type === 'WALKING' || type === 'HIKING') {
        return 'fa-walking';
      }
      if (type === 'STAIR_CLIMBING') {
        return 'fa-sort-amount-up-alt fa-flip-horizontal';
      }
      if (type === 'SWIMMING') {
        return 'fa-swimmer fa-flip-horizontal';
      }
      if (type === 'RUN_WALK') {
        return 'fa-shoe-prints';
      }
      if (type === 'WATER_SPORTS') {
        return 'fa-water';
      }
      if (type === 'ROWING') {
        return '$vuetify.icons.rowing';
      }
      if (type === 'KAYAKING' || type === 'CANOEING' || type === 'PADDLEBOARDING') {
        return 'fa-water';
      }
      if (type === 'BACKCOUNTRY_SKIING' || type === 'NORDIC_SKIING') {
        return 'fa-skiing-nordic';
      }
      if (type === 'SNOWBOARDING') {
        return 'fa-snowboarding';
      }
      if (type === 'ALPINE_SKIING') {
        return 'fa-skiing';
      }
      if (type === 'INLINE_SKATING') {
        return 'fa-skating';
      }
      if (type === 'ICE_SKATING') {
        return 'fa-ice-skate';
      }
      if (type === 'WHEELCHAIR') {
        return 'fa-wheelchair';
      }
      if (type === 'MULTI_SPORT') {
        return 'fa-triangle';
      }
      if (type === 'VOLUNTEERING') {
        return 'fa-hands-helping';
      }
      if (type === 'OTHER') {
        return 'fa-ellipsis-h';
      }
      return 'fa-ellipsis-h';
    },
    getGoalLabel(race) {
      if (race.scoring == "CUSTOM"){
        return race.custom || 'Points';
      }
      return i18n.t('events.rules.scoring-methods-short.'+race.scoring);
    },
    isCumulativeScoring(race) {
      return ["DISTANCE", "ELEVATION", "ELEVATION_LOSS", "TIME", "CALORIES", "STAIRS", "CUSTOM"].some(x => x === race.scoring);
    },
    getValueForDisplay(options, race, value, unit) {
      if (race.scoring == "ELEVATION" || race.scoring == "ELEVATION_LOSS"){
        return options.filters.elevation(value, unit);
      }
      if (race.scoring == "DISTANCE"){
        return options.filters.distance(value, unit);
      }
      else if (["RESULT", "TRACK"].some(x => x === race.scoring)){
        return options.filters.duration(-1*value, /*compact:*/false);
      }
      else if (race.scoring == "TIME"){
        return options.filters.duration(value, /*compact:*/false);
      }
      else if (race.scoring == "CALORIES"){
        return `${value} kcal`;
      }
      else if (race.scoring == "STAIRS"){
        return `${value} ${i18n.t('results.cols.stairs').toLowerCase()}`;
      }
      else if (["BADGES", "TRAINING_PLAN"].some(x => x === race.scoring)){
        return `${value}`;
      }
      else if (race.scoring == "CUSTOM"){
        return `${value} ${(race.custom||i18n.t('profile.activities.points')).toLowerCase() }`;
      }
      return `${value}`;
    },
    getSecondaryValueForDisplay(options, race, value, unit) {
      if (race.secondary_scoring == "DURATION"){
        return options.filters.duration(value, /*compact:*/false);
      }
      if (race.secondary_scoring == "DURATION_FASTEST"){
        return options.filters.duration(-1*value, /*compact:*/false);
      }
      if (race.secondary_scoring == "DISTANCE"){
        return options.filters.distance(value, unit);
      }
      if (race.secondary_scoring == "ELEVATION"){
        return options.filters.elevation(value, unit);
      }
      if (race.secondary_scoring == "LAST_ACTIVITY_DATE"){
        return options.filters.localDate(value, 'L');
      }
      if (race.secondary_scoring == null && race.scoring == "BADGES"){
        return options.filters.duration(-1*value, /*compact:*/false);
      }
      return `${value}`;
    },
    getGoalDistanceForDisplay(options, race, value, unit) {
      if (race.scoring == "ELEVATION" || race.scoring == "ELEVATION_LOSS"){
        return options.filters.elevation(value, unit);
      }
      else if (race.scoring == "TIME"){
        return options.filters.duration(value, /*compact:*/true);
      }
      else if (race.scoring == "CALORIES"){
        return `${value} kcal`;
      }
      else if (race.scoring == "STAIRS"){
        return `${value} ${i18n.t('results.cols.stairs').toLowerCase()}`;
      }
      else if (race.scoring == "BADGES"){
        return `${value} ${i18n.t('results.cols.badges').toLowerCase()}`;
      }
      else if (race.scoring == "TRAINING_PLAN"){
        return `${value} ${i18n.t('results.cols.workouts').toLowerCase()}`;
      }
      else if (race.scoring == "CUSTOM"){
        return `${value} ${(race.custom||i18n.t('profile.activities.points')).toLowerCase() }`;
      }
      return options.filters.distance(value, unit);
    },
    getBadgeValueForDisplay(options, format, value, unit) {
      if (format == "ELEVATION" || format == "ELEVATION_LOSS"){
        return options.filters.elevation(value, unit);
      }
      else if (format == "DURATION"){
        return options.filters.duration(value, /*compact:*/true);
      }
      else if (format == "SPEED"){
        return options.filters.speed(value, unit);
      }
      else if (format == "PACE"){
        return options.filters.pace(value, unit);
      }
      return options.filters.distance(value, unit);
    },
    getDistanceForDisplay(options, race, value, unit) {
      if (value === undefined) {
        return '-';
      }
      if (race.type == "STAIR_CLIMBING"){
        return `${value} ${i18n.t('results.cols.stairs').toLowerCase()}`;
      }
      return options.filters.distance(value, unit);
    },
    getScoringIcon(scoringMethod) {
      if (scoringMethod === 'ELEVATION' || scoringMethod == 'ELEVATION_LOSS') {
        return 'fa-mountain';
      }
      if (scoringMethod === 'RESULT') {
        return 'fa-stopwatch';
      }
      if (scoringMethod === 'DISTANCE') {
        return 'fa-ruler';
      }
      if (scoringMethod === 'TRACK') {
        return 'fa-route';
      }
      if (scoringMethod === 'CALORIES') {
        return 'fa-fire-alt';
      }
      if (scoringMethod === 'TIME') {
        return 'fa-hourglass-half';
      }
      if (scoringMethod === 'STAIRS') {
        return 'fa-sort-amount-up-alt fa-flip-horizontal';
      }
      if (scoringMethod === 'BADGES') {
        return 'fa-shield';
      }
      if (scoringMethod === 'TRAINING_PLAN') {
        return 'fa-calendar-alt';
      }
      if (scoringMethod === 'CUSTOM') {
        return 'fa-cogs';
      }
    },
    getProviderIcon(value) {
      if (value === 'manual_entry' || value === 'quick_entry') {
        return 'fa-keyboard';
      }
      if (value === 'result') {
        return 'fa-check-double';
      }
      if (value === 'gpx') {
        return 'fa-route';
      }
      if (value === 'strava') {
        return 'fab fa-strava';
      }
      if (value === 'garmin' || value === 'polar' || value === 'suunto' || value === 'coros' || value === 'underarmour') {
        return 'fa-watch';
      }
      if (value === 'fitbit') {
        return 'fa-watch-fitness';
      }
      return 'fa-ellipsis-h';
    },
    isActiveEvent(event) {
      var from = Vue.moment(event.from);
      var till = Vue.moment(event.till);
      var now = Vue.moment();
      return now > from && now < till;
    },
    isFutureEvent(event) {
      var from = Vue.moment(event.from);
      var now = Vue.moment();
      return from > now;
    },
    getStartDurationForEvent(event) {
      var from = Vue.moment(event.from);
      var now = Vue.moment();
      return from.diff(now, 'seconds');
    },

    isDarkColor(color) {
      //console.log('DARK?', color);
      // Variables for red, green, blue values
      var r, g, b, hsp;
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
          // If RGB --> store the red, green, blue values in separate variables
          color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
          if (color == null) {
            return false;
          }
          
          r = color[1];
          g = color[2];
          b = color[3];
      } 
      else {
          // If hex --> Convert it to RGB: http://gist.github.com/983661
          color = +("0x" + color.slice(1).replace( 
          color.length < 5 && /./g, '$&$&'));
          r = color >> 16;
          g = color >> 8 & 255;
          b = color & 255;
      }
      
      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
      );

      // Using the HSP value, determine whether the color is light or dark
      return hsp<=150;// was: 127.5;
    },
    parseColorToArray(color) {
      var r, g, b;
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        
        r = color[1];
        g = color[2];
        b = color[3];
      } 
      else {
          // If hex --> Convert it to RGB: http://gist.github.com/983661
          if (color.length == 9) {
            // #rrggbbaa
            color = color.slice(0, 7);
          } 
          color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
          r = color >> 16;
          g = color >> 8 & 255;
          b = color & 255;
      }
      return [r,g,b];
    },
    getGradientColorAt(colorCode1, colorCode2, weight) {
      if (isNaN(weight)) weight = 0;
      var w1 = 1-weight;
      var w2 = weight;
      var color1 = this.parseColorToArray(colorCode1);
      var color2 = this.parseColorToArray(colorCode2);
      return `rgba(${Math.round(color1[0] * w1 + color2[0] * w2)}, ${Math.round(color1[1] * w1 + color2[1] * w2)},${Math.round(color1[2] * w1 + color2[2] * w2)})`;
    },
    lightenDarkenColor(col, amt) {
      var usePound = false;
      if (col.length == 9) {
        // #rrggbbaa
        col = col.slice(0, 7);
      } 
      if (col[0] == "#") {
          col = col.slice(1);
          usePound = true;
      }
   
      var num = parseInt(col,16);
   
      var r = (num >> 16) + amt;
   
      if (r > 255) r = 255;
      else if  (r < 0) r = 0;
   
      var b = ((num >> 8) & 0x00FF) + amt;
   
      if (b > 255) b = 255;
      else if  (b < 0) b = 0;
   
      var g = (num & 0x0000FF) + amt;
   
      if (g > 255) g = 255;
      else if (g < 0) g = 0;
   
      return (usePound?"#":"") + String("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);
    }
}
