<template>
  <div>
    <v-row>
      <v-col
        v-for="(item, idx) in items"
        :key="item.o ? item.o.id : item.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <RaceCard :item="item" :event="event" :index="idx" :color="getRaceColor(event.color, idx)"></RaceCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RaceCard from "@/components/RaceCard.vue";

export default {
  name: "RaceGrid",
  components: {
    RaceCard
  },
  props: {
    event: Object,
    items: Array
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    getRaceColor(baseColor, index) {
      if (!baseColor) return null; 
      const isDark = this.$helpers.isDarkColor(baseColor);
      const targetColor = this.$helpers.lightenDarkenColor(baseColor, (isDark ? -1 : 1) * 50);
      const stop = index / (this.event.races.length-1);
      const color = this.$helpers.getGradientColorAt(baseColor, targetColor, stop);
      console.log('Color',baseColor,' dark:', isDark, this.$helpers.parseColorToArray(baseColor), stop, this.$helpers.parseColorToArray(targetColor), color);
      return color;
    }
  }
};
</script>
