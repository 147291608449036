<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Activity conversion matrix for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Customize the scoring per activity type. You can make activities of a certain type count more (or less) than others. Also, this allows you to convert time to distance for 0-distance activities (such as yoga, or indoor cycling).
        </p>
        <p>
          You can set default values by using the 'Default' entry at the top. Those will be applied when no specific sport-specific values are set.
        </p>
        <p>
          Please note that any changes made will only be applied to newly added/synced activities and will not be applied retrospectively. So please make sure to fill this matrix before the event starts.
        </p>

        <v-alert type="info" class="mt-8">This is a BETA feature. Feedback is welcome through our contact form :)</v-alert>
      </v-card-text>


      <v-card-text>      
        <p><strong>Distance Factor:</strong> enter a value between 0-1 to scale distance down. E.g. enter 0.33 in the Cycling row to make 3 cycling miles towards 1 other mile.</p>
        <p><strong>Distance per hour:</strong> enter a value (in {{ event.unit == 'METRIC' ? 'km' : 'mi' }}) how much 1 hour should be worth when no distance in set. This value is only used when the distance is exactly 0.</p>
        <div v-if="showCustom">
        <p><strong>Score per {{ event.unit == 'METRIC' ? 'km' : 'mi' }}:</strong> enter a value how much custom points should be earned for every qualifying {{ event.unit == 'METRIC' ? 'km' : 'mi' }}.</p>
        <p><strong>Score per hour:</strong> enter a value how much custom points should be earned for 1 hour of qualifying activities.</p>
        </div>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="conversions"
        :items-per-page="100"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Activity Type Conversion Matrix</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:item.icon="{ item }">
          <v-icon>{{$helpers.getActivityIcon(item.type)}}</v-icon>
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.type == null ? 'Default' : $t('profile.activities.types.'+item.type) }}
        </template>
        <template v-slot:item.time_to_distance="{ item }">
          <DistanceTextArea
            v-model="item.time_to_distance" 
            :unit="event.unit"
            label="" 
            hide-details
            >
          </DistanceTextArea>
        </template>
        <template v-slot:item.distance_factor="{ item }">
          <v-text-field v-model.number="item.distance_factor" placeholder="1" hide-details />
        </template>
        <template v-slot:item.time_to_custom="{ item }">
          <v-text-field v-model.number="item.time_to_custom" placeholder="0" hide-details />
        </template>
        <template v-slot:item.distance_to_custom="{ item }">
          <v-text-field v-model.number="item.distance_to_custom" placeholder="0" hide-details />
        </template>
      </v-data-table>
      <v-card-text>
        <v-btn color="primary" large @click="save">Save Matrix</v-btn>
        <v-btn color="primary" text @click="reset">Reset Matrix</v-btn>
      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import DistanceTextArea from "@/components/DistanceTextArea";

export default {
  name: "ResultColumns",
  components: {
    Header,
    DistanceTextArea,
  },
  props: {
  },
  data() {
    return {
      event: null,
      conversions: [],
      siteData: siteData,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id)).data;
      const conversions = (await eventManagerService.getActivityConversions(id, raceId)).data.data || [];

      var defaultConversion = conversions.find(x => x.type == null) || {};

      this.conversions = [defaultConversion, ...siteData.activities];
      for (const item of conversions) {
        var match = this.conversions.find(x => x.type == item.type);
        match.distance_factor = isFinite(item.distance_factor) ? item.distance_factor : null;
        match.time_to_distance = isFinite(item.time_to_distance) ? item.time_to_distance : 0;
        match.distance_to_custom = isFinite(item.distance_to_custom) ? item.distance_to_custom : 0;
        match.time_to_custom = isFinite(item.time_to_custom) ? item.time_to_custom : 0;
      }
      console.log('conversions', conversions, 'MERGED', this.conversions);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    async reset() {
      if (confirm('Are you sure you want to reset all changes you\'ve made to the conversion matrix?')){
        await eventManagerService.putActivityConversions(this.event.id, []);
        await this.getProfile();
      }
    },

    async save() {
      const conversions = [];
      for (const item of this.conversions) {
        console.log('item', item);
        if (item.time_to_distance > 0 || item.distance_factor !== 1 || item.distance_to_custom !== 0 || item.time_to_custom !== 0) {
          conversions.push(item);
        }
      }

      console.log('Saving', conversions);
      const response = (await eventManagerService.putActivityConversions(this.event.id, conversions)).data;
      this.$helpers.toastResponse(this, response, 'Successfully saved activity matrix.');

      await this.getProfile();
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Activity Conversion Matrix', disabled: true },
      ];
    },

    headers() {
      return [
        { text: '', value: 'icon', sortable: false},
        { text: 'Activity', value: 'type', sortable: false},
        { text: 'Distance factor', value: 'distance_factor', sortable: false },
        { text: 'Distance per hour (for zero-distance activities)', value: 'time_to_distance', sortable: false },
        this.showCustom ? { text: `Score per ${this.event.unit == 'METRIC' ? 'km' : 'mi'} (for custom scoring)`, value: 'distance_to_custom', sortable: false } : null,
        this.showCustom ? { text: `Score per hour (for custom scoring)`, value: 'time_to_custom', sortable: false } : null,
      ].filter(x => x !=null);
    },

    showCustom() {
      return this.event && this.event.races && this.event.races.some(x => x.scoring == 'CUSTOM');
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
.v-data-table.v-data-table--dense td { padding-top: 4px !important; padding-bottom:  4px !important;}
.v-input.v-input--hide-details { margin-top: 0 !important; }
</style>

