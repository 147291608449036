v<template>
  <v-container class="fill-height fullscreen-bg" fluid> 
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
          <EventHeader v-if="event" :event="event" compact/>
          <v-card-title class="headline">{{$t('account.register.title')}}</v-card-title>
          <div class="provider-container">
            <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('strava'), display: providerDisplay('strava')}">
              <v-btn v-if="providerUrls" :href="providerUrls.strava" block color="#FC4C02">
                <img src="/images/btn_strava_connectwith_orange.svg" style="height:42px"/>
              </v-btn>
            </div>
            
            <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('garmin'), display: providerDisplay('garmin')}">
              <v-btn v-if="providerUrls" :href="providerUrls.garmin" block color="#11BFED">
                <img src="/images/garmin-connect.png" width="26" height="26" alt="Garmin Connect">
                <v-icon  left>mdi-pencil</v-icon> Garmin Connect
              </v-btn>
            </div>
            
            <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('polar'), display: providerDisplay('polar')}">
              <v-btn v-if="providerUrls" :href="providerUrls.polar" block color="#d10027" class="white--text">
                <span style="font-size:70%;">Connect with</span>
                <img src="/images/polar.png" height="22" alt="Polar" class="ml-2">
              </v-btn>
            </div>
            <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('fitbit'), display: providerDisplay('fitbit')}">
              <v-btn v-if="providerUrls" :href="providerUrls.fitbit" block color="#4cc2c4" class="white--text">
                <span style="font-size:70%;">Connect with</span>
                <img src="/images/fitbit.png" height="22" alt="Fitbit" class="ml-2">
              </v-btn>
            </div>
            <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('suunto'), display: providerDisplay('suunto')}">
              <v-btn v-if="providerUrls" :href="providerUrls.suunto" block color="#147eab" class="white--text">
                <span style="font-size:70%;">Connect with</span>
                <img src="/images/suunto.png" height="24" alt="Suunto" class="ml-2">
              </v-btn>
            </div>
            <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('underarmour'), display: providerDisplay('underarmour')}">
              <v-btn v-if="providerUrls" :href="providerUrls.underarmour" block color="#ea212e" class="white--text">
                <img src="/images/underarmour.png" height="20" alt="Under Armour" class="mr-2">
                <span style="font-size:80%;" class="mr-1">Log In with</span>
                <strong style="font-size:80%; text-transform:uppercase;">Under Armour</strong>
              </v-btn>
            </div>
            <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('coros'), display: providerDisplay('coros')}">
              <v-btn v-if="providerUrls" :href="providerUrls.coros" block color="#F8283B" class="white--text">
                <span style="font-size:70%;">Connect with</span>
                <img src="/images/coros.png" height="16" alt="Coros" class="ml-2">
              </v-btn>
            </div>
            <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('eventscom'), display: providerDisplay('eventscom')}">
              <v-btn v-if="providerUrls" :href="providerUrls.eventscom" block color="#00BCDA" class="white--text">
                <span style="font-size:70%;">Connect with</span>
                <img src="/images/eventscom-white.png" height="16" alt="Evens.com" class="ml-2">
              </v-btn>
            </div>
            <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('concept2'), display: providerDisplay('concept2')}">
              <v-btn v-if="providerUrls" :href="providerUrls.concept2" block color="#033B78" class="white--text">
                <span style="font-size:80%;" class="mr-1">Connect with</span>
                <strong style="font-size:80%; text-transform:uppercase;">Concept2</strong>
              </v-btn>
            </div>
            <div class="ma-4 mb-0 provider provider-connect" :style="{order:providerOrder('asdeporte'), display: providerDisplay('asdeporte')}">
              <v-btn v-if="providerUrls" :href="providerUrls.asdeporte" block color="#FF6A00" class="white--text">
                <span style="font-size:80%;" class="mr-1">Connect with</span>
                <strong style="font-size:80%; text-transform:uppercase;">Asdeporte</strong>
              </v-btn>
            </div>

            <v-card-text class="provider pb-0" :style="{order:providerOrder('sodisp'), display: providerDisplay('sodisp')}">
              <v-alert v-if="error" type="error">{{error}}</v-alert>
              <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              >
              <v-text-field
                  v-model="name"
                  :label="$t('account.register.displayname')"
                  prepend-icon="fa-user"
                  type="name"
              ></v-text-field>

              <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  :label="$t('account.register.email')"
                  prepend-icon="fa-envelope"
                  type="email"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :label="$t('account.register.password')"
                  required
                  prepend-icon="fa-lock"
                  :append-icon="passwordShow ? 'far fa-eye-slash' : 'far fa-eye'"
                  :type="passwordShow ? 'text' : 'password'"
                  @click:append="passwordShow = !passwordShow"
              ></v-text-field>
              </v-form>
              <v-btn :disabled="!valid" block color="primary" @click="validate">{{$t('account.register.submit')}}</v-btn>
            </v-card-text>
          </div>
          <v-divider class="mt-0"/>
          <i18n path="account.register.terms_privacy" tag="v-card-text" class="text-muted">
            <router-link to='termsofservice'>{{$t('account.register.terms_privacy_terms')}}</router-link>
            <router-link to='privacypolicy'>{{$t('account.register.terms_privacy_privacy')}}</router-link>
          </i18n>
          <v-divider />
          <v-card-text class="text-center">
            <p>{{$t('account.register.login_text')}}</p>
            <v-btn outlined block color="primary" :to="{ name: 'login', query: $route.query }">{{$t('account.register.login_action')}}</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from "vuex";
import axios from "axios";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import profileService from "@/services/profileService";
import EventHeader from '@/components/EventHeader.vue';
import eventService from "@/services/eventService";
import tenants from '@/data/tenants.config'
import i18n from '@/i18n'
const tenant = tenants.current();

export default {
  components: {
    EventHeader,
  },
  props: {
  },
  data: () => ({
    tenant: tenant,
    providers: tenant.accountProviders,
    providerUrls: null,
    event: null,
    passwordShow: false,
    confirmPasswordShow: false,
    valid: true,
    error: null,
    name: null,
    email: '',
    emailRules: [
      v => !!v || i18n.t('account.register.email-is-required'),
      v => /.+@.+/.test(v) || i18n.t('account.register.email-must-be-valid')
    ],
    password: '',
    confirmPassword: '',
    passwordRules: [
      v => !!v || 'Password and Confirm password Required'
    ]
  }),

  async mounted() {
    await this.redirectToProfileWhenLoggedIn();

    if (this.$route.query.providers) {
      this.providers = this.$route.query.providers.replace(' ', '').split(',');
    }
    if (this.$route.query.event) {
      this.event = (await eventService.get(this.$route.query.event)).data;
      if (this.event && this.event.allowed_providers && this.event.allowed_providers.length > 0 && !this.$route.query.providers) {
        const customProviders = this.event.allowed_providers;
        customProviders.push('sodisp');
        this.providers = customProviders;
      }
    }
    
    const options = { 
      referral: this.$route.query.referral, 
      eventId: this.$route.query.event, 
      verificationCode: this.$route.query.verificationCode, 
      view: this.viewMode
    };
    this.providerUrls = this.$oauth.getProviderUrls(options, this.event);

    EventBus.$on('login-state-change', async user => {
      await this.redirectToProfileWhenLoggedIn();
    });
  },

  methods: {

    providerOrder(provider) {
      return this.providers.indexOf(provider);
    },
    providerDisplay(provider) {
      return this.providers.includes(provider) ? '' : 'none';
    },

    async redirectToProfileWhenLoggedIn() {
      if (this.user) {
        this.redirect();
      }
    },

    async validate () {
      if (this.$refs.form.validate()) {
        this.snackbar = true
        await this.registerWithFirebase()
      }
    },

    reset () {
      this.$refs.form.reset()
    },

    redirect() {
      var redirect = this.$route.query.redirect;
      var eventId = this.$route.query.event;
      var verificationCode = this.$route.query.verificationCode;
      if (eventId) {
        this.$router.push({ name: 'eventWelcome', params: { id: eventId }, query: {verificationCode:verificationCode} });
        return;
      }
      if (redirect) {
        if (redirect === 'create-event'){
          this.$router.replace({ name: "eventmanagerCreate" });
          return;
        }
        else {
          window.location.href = redirect;
        }
      }
      this.$router.replace({ name: "profile" });
    },

    async registerWithFirebase () {
      const user = {
        email: this.email,
        password: this.password,
        name: this.name,
      }
      try {
        var response = await firebase.auth().createUserWithEmailAndPassword(user.email, user.password);
        if (this.name) {
            await response.user.updateProfile({ displayName: user.name });
        }
        var ip = null;
        try {
          ip = (await axios.get('https://api.ipify.org?format=json')).data.ip;
        }
        catch {  }
        await this.$store.dispatch('setUser', response.user);
        await profileService.post({ referral: this.$route.query.referral, ip: ip }); 
        this.$gtag.event('login', { method : 'Email' });

        var eventId = this.$route.query.event;
        if (eventId) {
          await profileService.joinEvent(eventId);
        }

        this.redirect();
      }
      catch (ex) {
        try{
          // try to login with these credentials, might be someone trying to login
          var loginResponse = await firebase.auth().signInWithEmailAndPassword(this.email, this.password);
          if (loginResponse && loginResponse.user) {
            await this.$store.dispatch("setUser", loginResponse.user);
            this.$gtag.event('login', { method : 'Email' });
            this.redirect();
            return;
          }
        }
        catch (ex){}

        this.error = ex.message;
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }), 
    viewMode() {
      // note: somehow vue doesn't correctly read it from route.query so get it manually
      const viewFromUrl = new URLSearchParams(window.location.search).get("view");
      return viewFromUrl || this.$route.query.view || this.$store.state.view;
    },

  },
}
</script>
<style lang="scss">
  .fullscreen-bg {
    margin-top: 60px;
    padding-top: 40px;
    background-image: url(https://sodisp.imgix.net/hero-cycling-1.jpg?w=2000&h=2000&fit=crop);
    background-size: cover;
    background-position: 50%;

    .row { margin: 0!important; }
  }
  .view--embedded .fullscreen-bg { margin-top: 0;}

  .provider-container {
    display: flex;
    flex-direction: column;

  }
</style>

