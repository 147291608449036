<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <Header title="Create a new event"/>

      <div v-if="user">
        <v-card-title class="subtitle">Step 1: Name your event</v-card-title>
        <v-card-text>      
          <v-alert v-if="error" type="error">{{error}}</v-alert>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field 
              v-model="name" 
              :rules="nameRules" 
              label="Event name (required)" 
              prepend-icon="fa-pencil-alt"
              required>
            </v-text-field>
            
            <v-btn class="mt-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">Create event</v-btn>
          </v-form>
        </v-card-text>   
      </div>    
      <div v-else>
        <v-card-title class="subtitle">Your virtual event on {{tenant.name}}?</v-card-title>
        <v-card-text>
          <p>Want to feature your (virtual) event or challenge on {{tenant.name}}? We support a wide range of features to build unique virtual experiences.</p>
          <ul class="mb-4">
            <li>One click join for participants using Strava, Garmin, Polar, Fitbit and more</li>
            <li>Badges and virtual course maps</li>
            <li>Individual and team rankings</li>
            <li>8 different challenge modes: best result, total distance, total elevation and many more</li>
            <li>Focus on social distancing to assure participants, sponsors and government bodies</li>
            <li><router-link to="/contact">Free for small events, simple and fair pricing thereafter</router-link></li>
          </ul>
          <v-btn outlined color="accent" class="mr-4" :to="{name: 'features' }">Features</v-btn>
          <v-btn outlined color="accent" :to="{name: 'contact' }">Contact us</v-btn>
        </v-card-text>
        <v-alert type="info" tile>You'll need to create an account first.</v-alert>
        <v-card-text>
          <p>Please Login or Register before you create your event.</p>
          <v-btn color="accent" class="mr-4" :to="{ name: 'register', query: {referral: this.$route.query.referral, redirect:'create-event' }}">Register</v-btn>
          <v-btn outlined color="accent" :to="{name: 'login', query: { referral: this.$route.query.referral, redirect:'create-event' } }">Login</v-btn>
        </v-card-text>
      </div>  
    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment-timezone'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      breadcrumbItems: [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Create Event', disabled: true },
      ],
      valid: true,
      error: null,
      timeZoneOlson: null,
      name: "",
      nameRules: [
        //v => !!v || "Please enter the event name",
      ],
    };
  },
  async mounted() {
    await this.getProfile();
      this.timeZoneOlson = moment.tz.guess();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async udateAvatar() {
      await this.getProfile();
    },

    async submit() {
      if (this.$refs.form.validate()) {
        try {
          var response = await eventManagerService.create({
            name: this.name,
            timezone: this.timeZoneOlson,
            referral: this.$route.query.referral,
          });
          this.$router.push({ name: 'eventmanagerWizard', params: { id: response.data.id } });
        }
        catch (ex) {
          this.error = ex.response.data.msg;
          //console.log('ERR', ex, ex.response);
        }
      }
    },

    async getProfile() {
      if (this.user) {
        //var response = await profileService.get();
        //this.profile = response.data;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

