<template>
  <div>
    <p>
      
    </p>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <p class="grey--text">
        Are you a participant in a virtual challenge? You can skip this part as it only applies for hosting events on sodisp. Joining a challenge on sodisp is always free. You might need to pay to register for the event with the event organizer.
      </p>

      <h1>Simple pricing</h1>
      <p>
        We like to keep things simple. At sodisp you pay a small fee for each participant in your challenge. 
        The exact fee depends on the size of your challenge. Please <router-link to="/contact">contact us</router-link> to get a quote specific to your situation.
      </p>
      <h3>Free for small events and challenges</h3>
      <p>
        We offer a free version for smaller events and challenges. Please <router-link to="/contact">reach out</router-link> to us to see if this applies to your challenge. The free version is ad-supported and contains all the cool features sodisp offers.
      </p>
      <v-alert prominent type="success" icon="fa-lightbulb">
      <h3>Introduction offer: your first challenge for free</h3>
      <p>
        Want to see for yourself how sodisp can help your event to pivot into a virtual version? For events or challenges under 100 participants we offer the first virtual challenge for free. Some conditions apply, please contact us for all details.
      </p>
      </v-alert>
    </div>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Schedule a call to discuss pricing for <u>your</u> challenge</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" to="/request-demo">Request Demo</v-btn>
              <v-btn class="mb-4 mr-4" color="white" outlined to="/contact">Explore features</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <iframe src="https://cdn.forms-content.sg-form.com/bad6a860-8ea4-11ea-90d0-222d5e1f3e65" style="width:100%;border:0; height:400px;" noborder border="0" />

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>

export default {
  name: "Contact",
  props: {
  },
  data: function() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>