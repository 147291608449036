<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="group">
      <v-card-title class="display-2">
        <v-row>
          <v-col v-if="event && group" class="pa-0 pl-4 grow">{{ group.name }} @ {{ event.name }}</v-col>
          <v-btn v-if="me" outlined color="primary" @click="leaveGroup">Leave Group</v-btn>
        </v-row>
      </v-card-title>

      <div v-if="event && status">
        <EventFriendReferral :event="event" :race="status.races && status.races.length == 1 ? status.races[0] : null" />
        <v-card-title class="subtitle">Connection status for {{event.name}}</v-card-title>
        <v-alert v-if="status.connected" type="success" class="mx-4">
          <p class="mb-0">This group is connected to the race(s): <strong>{{ status.races.map(x => x.name).join(', ') }}</strong>.</p>
          <p v-if="false && status.verification_code" class="mb-0">Group verification code is {{ status.verification_code}}.</p>
        </v-alert>      
        <v-card-text>
          <EventGrid :items="[event]"/>
        </v-card-text>

        <div v-if="group.type == 'CUSTOM_TEAM' && group.join_code" style="background-color: #ececec;">
          <v-card-text >
            <h2><v-icon>fal fa-lock</v-icon> Invite others to join</h2>
            <p>Please share this join code only with people you trust. Only you (as group owner) can see this.</p>
            Url:
            <div class="join-url">
              {{`${tenant.homeUrl}events/${event.id}`}}
            </div>
            Join Code:
            <div class="join-code">
              {{group.join_code}}
            </div>
          </v-card-text>  
        </div>

        <div v-if="status.results && status.results.length">
          <v-card-title class="subtitle">Team Results</v-card-title>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Race</th>
                  <th class="text-left">Position</th>
                  <th class="text-left">Score</th>
                  <th class="text-left">Details</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in status.results" :key="'act'+idx">
                  <td>{{ event.races.find(x => x.id == item.race_id).name }} </td>
                  <td><span v-if="item.pos">#{{ item.pos  }}</span></td>
                  <td>{{ $helpers.getValueForDisplay($options, event.races.find(x => x.id == item.race_id), item.score_value, event.unit) }} </td>
                  <td><v-btn text color="primary" @click="openResultDetailsPopup(item)">Details</v-btn> </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <RaceResultDetailsDialog :event="event" ref="detailsPopup"/>
        </div>

        <v-card-title class="subtitle">Member status</v-card-title>
        <v-card-text>Use this table to verify the status for each team member.</v-card-text>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Status</th>
                <th class="text-left">Member</th>
                <th v-if="hasFullAccess" class="text-left">Code</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in status.members" :key="'act'+idx">
                <td>
                  <v-icon v-if="item.connected" small color="green" class="mr-1">fa-check-circle</v-icon>
                  <v-icon v-if="!item.connected" small color="red" class="mr-1">fa-times-circle</v-icon>
                  <span v-if="!item.connected && !item.id">Not yet joined</span>
                </td>
                <td>
                  {{ item.name }} 
                  <span v-if="item.captain" class="font-weight-bold">(team captain)</span>
                </td>
                <td v-if="hasFullAccess">{{ item.verification_code }} </td>
                <td><v-btn v-if="hasFullAccess" text color="primary" @click="removeMember(item)">Remove</v-btn> </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div v-if="slots && slots.length > 0">
          <v-card-title class="subtitle">Relay Slots</v-card-title>
          <v-card-text>Assign team members to each relay slot. Please note that all slots must be assigned before the event starts. No changes can be made afterwards.</v-card-text>
          <v-card-text v-if="race && race.relay_join_instructions">
            <v-alert type="info">{{race.relay_join_instructions}}</v-alert>
          </v-card-text>
          <v-toolbar v-if="hasFullAccess" :elevation="0" class="px-4">
            <v-spacer/>
            <v-btn color="primary" @click="saveSlots">Save</v-btn>
          </v-toolbar>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-if="hasSlotSports" class="text-left">Sport</th>
                  <th class="text-left">Slot</th>
                  <th v-if="hasSlotTimes" class="text-left">Time (your local time) *</th>
                  <th class="text-left">Team member</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(slotsInRace, race_id) in slotsGroupedByRace">
                  <tr :key="'race'+race_id"><td colspan="5">Race {{event.races.find(x => x.id == race_id).name}}</td></tr>
                <tr v-for="(item, idx) in slotsInRace" :key="'slot'+idx">
                  <td v-if="hasSlotSports">
                    <v-icon v-if="item.slot_type" :title="item.slot_type">{{$helpers.getActivityIcon(item.slot_type)}}</v-icon>
                    <span v-else>Any</span>
                  </td>
                  <td>{{ item.slot_name }}</td>
                  <td v-if="hasSlotTimes">{{ item.slot_start | moment("L LT") }} - {{ item.slot_end | moment("LT") }}</td>
                  <td>
                    <v-select
                      v-if="hasFullAccess"
                      :items="$helpers.prefixSelectListWith(profiles, {id: null, name:'Unassigned'})"
                      v-model="item.profile_id" 
                      item-value="id"
                      item-text="name"
                      placeholder="- Not yet assigned -"
                      solo
                      flat
                      hide-details
                    />
                    <span v-else>{{ (profiles.find(x => x.id == item.profile_id)||{}).name }}</span>
                    <v-btn v-if="!hasFullAccess && me && !item.profile_id" small @click="assignToMe(item)">Assign to me</v-btn>
                    <v-btn v-if="!hasFullAccess && me && item.profile_id == me.id" text color="primary" @click="unassign(item)">Unassign</v-btn>
                  </td>
                </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventService from "@/services/eventService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import siteData from '@/data/site.json'
import EventGrid from "@/components/EventGrid.vue";
import EventCard from "@/components/EventCard.vue";
import EventFriendReferral from "@/components/EventFriendReferral.vue";
import RaceResultDetailsDialog from "@/components/RaceResultDetailsDialog";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileGroupsView",
  components: {
    EventGrid,
    EventCard,
    EventFriendReferral,
    RaceResultDetailsDialog,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      groupId: null,
      group: null,
      event: null,
      //race: null,
      status: null,
      slots: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async loadData(groupId, eventId) {
      this.groupId = groupId;
      //try {
      //  this.group = (await profileService.getGroup(groupId)).data;
      //}
      //catch { }
      this.event = (await eventService.get(eventId)).data;
      this.status = (await profileService.getGroupEventStatus(groupId, eventId)).data;
      /* event organizer can't read the group so we get it from the status instead */
      this.group = this.status.group;
      if (this.status && this.status.races && this.status.races.length == 1) {
        //this.race = this.status.races[0];
        this.slots = this.status.slots;
      }
      this.slots = [];
      if (this.status && this.status.slots) {
        this.slots = this.status.slots;
      }
    },

    openResultDetailsPopup(result) {
      this.$refs.detailsPopup.race = this.event.races.find(x => x.id == result.race_id);
      this.$refs.detailsPopup.open(result);
    },

    async leaveGroup() {
      if (confirm(`Are you sure you want to leave this group?\n\nIf you want to re-join later you must disconnect from the event first and then re-connect using your original verification code/link.`)) {
        const response = (await profileService.disconnectMeFromGroupAndEvent(this.groupId, this.event.id)).data;
        this.$helpers.toastResponse(this, response, `Successfully left group`);
        if (response.status == 'OK') {
          this.$router.replace({ name: "profileGroups" });
        }
      }
    },
    async removeMember(member) {
      if (confirm(`Are you sure you want to remove ${member.name} from this group and the results?`)) {
        const response = (await profileService.disconnectGroupAndEvent(this.groupId, member.id, this.event.id)).data;
        this.$helpers.toastResponse(this, response, `Successfully removed ${member.name} from this group`);
        await this.getProfile();
      }
    },

    async assignToMe(slot) {
      slot.profile_id = this.me.id;
      this.saveSlots();
      await this.getProfile();
    },

    async unassign(slot) {
      slot.profile_id = null;
      this.saveSlots();
      await this.getProfile();
    },

    async saveSlots() {
      const response = (await profileService.putGroupSlotAssignment(this.groupId, this.event.id, this.slots)).data;
      this.$helpers.toastResponse(this, response, 'Successfully saved slot configuration');
    },

    async getProfile() {
      if (this.user) {
        await this.loadData(this.$route.params.id, this.$route.params.eventId);
      }
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.group || !this.event ? [] : [
        { text: 'Profile', exact: true, to: { name: 'profile' } },
        { text: 'Groups', exact: true, to: { name: 'profileGroups' } },
        { text: this.group.name, exact: true, to: { name: 'profileGroupsView', params: { id: this.group.id } } },
        { text: this.event.name, disabled: true },
      ];
    },

    me() {
      return !this.status ? null : this.status.me;
    },

    isAutoTeam() {
      return this.group && (this.group.type == 'AUTO_TEAM');
    },

    isAdminForTeam() {
      return this.isAutoTeam
        ? this.status.me && this.status.me.captain
        : this.group.is_owner || (this.status.me && this.status.me.captain)
        ;
    },

    hasFullAccess() {
      return !this.status ? false : (this.status.event_admin || this.isAdminForTeam);
    },

    profiles() {
      return !this.status || !this.status.members ? [] : this.status.members.filter(x => x.connected && x.id);
    },

    hasSlotSports() {
      return this.slots && this.slots.some(x => x.slot_type);
    },
    hasSlotTimes() {
      return this.slots && this.slots.some(x => x.slot_start || x.slot_end);
    },

    slotsGroupedByRace() {
      return this.slots ? this.slots.reduce((acc, item) => {
        (acc[item.race_id] = acc[item.race_id] || []).push(item)
        return acc
      }, {}) : [];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss" scoped>
.join-url {
  line-height: 2em;
  padding: 0 1em;
  font-weight: bold;
  border: dotted 3px #ccc;
  margin-bottom: 10px;
}
.join-code {
  font-size: 3em;
  letter-spacing: 1em;
  line-height: 2em;
  padding: 0 1em;
  font-weight: bold;
  text-transform: uppercase;
  border: dotted 3px #ccc;
}
</style>

