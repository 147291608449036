<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>

      <div >
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-card-text>      
          <p>
            By default only you have access to your event. You can optionally grant access to others as well. To do this you need to get their profile ID. They can easily find that by going to the 'Edit Profile' page and then scroll to the bottom of the page to copy their profile ID. 
          </p>
          <p>
            Please note that the persons you permit access will have the exact same permissions as you have and can therefor also invite others.
          </p>
        </v-card-text>


        <v-data-table
          :headers="headers"
          :items="permissions"
          sort-by="name"
          :items-per-page="25"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white" class="mx-4">
              <v-toolbar-title class="ml-0 subtitle">Permissions</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="accent" dark class="mb-2" @click="addPermissions">Add</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-4" @click="deletePermissions(item.name, item.id)">
              fa-trash
            </v-icon>
          </template>
          <template v-slot:no-data>
            <i>No extra permissions granted, only you will have access to this challenge.</i>
          </template>
        </v-data-table>

      </div>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import Header from './_Header.vue'

export default {
  name: "Permissions",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      permissions: [],
      headers: [
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name',},
        { text: 'Actions', align: 'start', sortable: false, filterable: false, value: 'actions',},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.permissions = (await eventManagerService.getPermissions(id)).data.data || [];
      console.log(this.permissions);
    },

    async addPermissions() {
      var profileId = prompt('Please enter the profile ID of the user you want to give permissions:');
      if (profileId) {
        const response = (await eventManagerService.addPermissions(this.event.id, profileId)).data;
        await this.getProfile();
        this.$helpers.toastResponse(this, response, 'Permission added successfully.');
      }
    },

    async deletePermissions(name, profileId) {
      if (confirm(`Are you sure you want to remove access by ${name}?`)) {
        const response = (await eventManagerService.deletePermissions(this.event.id, profileId)).data;
        await this.getProfile();
        this.$helpers.toastResponse(this, response, 'Permission revoked successfully.');
      }
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Permissions', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

