<template>
  <v-container class="fill-height fullscreen-bg" fluid> 
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card v-if="isProcessing" class="elevation-12 pa-4 text-center">
          <v-card-title class="headline d-block" style="">{{$t('account.preparing-title')}}</v-card-title>
          <v-card-text class="">
            <p>{{$t('account.preparing-text')}}</p>
            <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
          </v-card-text>

        </v-card>
        <v-card v-if="showPasswordReset" class="elevation-12 pa-4">
          <v-card-title class="headline">{{$t('account.recovery.title')}}</v-card-title>
          <v-card-text>
            <v-alert v-if="error" type="error">{{error}}</v-alert>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field 
                v-model="email" 
                :rules="emailRules" 
                :label="$t('account.register.email')" 
                prepend-icon="fa-envelope"
                readonly
                disabled
                required>
            </v-text-field>

            <v-text-field
              v-model="password"
              :rules="passwordRules"
              :label="$t('account.register.password')"
              required
              prepend-icon="fa-lock"
              :append-icon="passwordShow ? 'far fa-eye-slash' : 'far fa-eye'"
              :type="passwordShow ? 'text' : 'password'"
              @click:append="passwordShow = !passwordShow"
            ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-text>
            <v-btn block :disabled="!valid" color="primary" @click="updatePasswordAndLogin">{{$t('account.recovery.submit')}}</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  data: () => ({
    isProcessing: true,
    showPasswordReset: false,
    actionCode: null,
    passwordShow: false,
    valid: true,
    error: false,
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    password: '',
    confirmPassword: '',
    passwordRules: [
      v => !!v || 'Password and Confirm password Required'
    ]
  }),

  async mounted() {
    var mode = this.$route.query.mode;
    var actionCode = this.$route.query.oobCode;
    var continueUrl = this.$route.query.continueUrl;
    var lang = this.$route.query.lang || 'en';

    if (continueUrl) {
      // extract tenant from continueurl
      const urlParams = new URLSearchParams(new URL(continueUrl).search);
      const targetTenantId = urlParams.get('tenant');
      console.log('Issued for tenant', targetTenantId);
      if (targetTenantId && targetTenantId != tenant.id) {
        const targetTenant = tenants.get(targetTenantId);
        // redirect straight to correct tenant
        console.log('Redirecting to new tenant', targetTenantId);
        window.location.href = `${targetTenant.homeUrl}account/firebase${window.location.search}`
        return;
      }
    }

    console.log('Trying firebase action', mode, actionCode);

    var auth = firebase.auth();
    // Handle the user management action.
    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        this.handleResetPassword(auth, actionCode, continueUrl, lang);
        break;
      case 'recoverEmail':
        // Display email recovery handler and UI.
        this.handleRecoverEmail(auth, actionCode, lang);
        break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        this.handleVerifyEmail(auth, actionCode, continueUrl, lang);
        break;
      default:
        // Error: invalid mode.
    }
  },

  methods: {
    redirect() {
      this.$router.replace({ name: "profile" });
    },

    async handleResetPassword(auth, actionCode, continueUrl, lang) {
      console.log('Trying reset');
      // Verify the password reset code is valid.
      try {
      var email = await auth.verifyPasswordResetCode(actionCode);
        this.email = email;
        this.actionCode = actionCode;
        this.isProcessing = false;
        this.showPasswordReset = true;
      }
      catch(error) {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        this.error = true;
        console.log('Firebase error', error);
      }
      
    },
    async updatePasswordAndLogin() {
      var auth = firebase.auth();
  
      // Save the new password.
      await auth.confirmPasswordReset(this.actionCode, this.password);
      // Password reset has been confirmed and new password updated.

      // TODO: Display a link back to the app, or sign-in the user directly
      // if the page belongs to the same domain as the app:
      const response = await auth.signInWithEmailAndPassword(this.email, this.password);
      await this.$store.dispatch("setUser", response.user);
      this.redirect();
      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
      
    },

    async handleVerifyEmail(auth, actionCode, continueUrl, lang) {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      // Try to apply the email verification code.
      auth.applyActionCode(actionCode).then(function(resp) {
        // Email address has been verified.

        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
        this.redirect();
      }).catch(function(error) {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
      });
    },

  },
  computed: {
  },
};
</script>
<style lang="scss" scoped>
  .fullscreen-bg {
    margin-top: 60px;
    padding-top: 40px;
    background-image: url(https://sodisp.imgix.net/hero-cycling-1.jpg?w=2000&h=2000&fit=crop);
    background-size: cover;
    background-position: 50%;

    .row { margin: 0!important; }
  }
  .view--embedded .fullscreen-bg { margin-top: 0;}
</style>

