<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Reports for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Download Excel reports. These reports can be used for mail-merging and keeping an eye on the progress of your event. 
        </p>
      </v-card-text>

      <v-card-title class="subheader">Race Reports</v-card-title>

      <v-data-table
        :headers="headers"
        :items="races"
        sort-by="dist"
        :items-per-page="25"
        hide-default-footer
      >
        <template v-slot:item.actions="{ item }">
          <v-btn small color="primary" text class="" @click="downloadRaceReport(item.id, 'progress', `sodisp progress report for ${item.id}`)">
            Progress
          </v-btn>
          <v-btn v-if="item.team == 'RELAY_TIME_SLOTS' || item.team == 'RELAY_DISTANCE_SLOTS'" small color="primary" text class="" @click="downloadRaceReport(item.id, 'relay-slots', `sodisp relay slot report for ${item.id}`)">
            Relay Slots
          </v-btn>
        </template>
        <template v-slot:no-data>
          <i>No races created, add at least one race to this event.</i>
        </template>
      </v-data-table>

      <v-card-title class="subheader">Event Retention and Engagement</v-card-title>
      <v-row class="mx-2"> 
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Registrations</v-card-title>
            <v-card-text>Download this report to find a full list of registrations including their invite and join status information.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="downloadReport('registrations', `sodisp registrations ${event.name}`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Unconnected registrations</v-card-title>
            <v-card-text>Download this report to find participants who haven't connected on sodisp yet in order to get them to join as well. This will help you keep happy registrants and retain them towards future events.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="downloadReport('unconnected-registrations', `sodisp unconnected registrations ${event.name}`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Participants</v-card-title>
            <v-card-text>Download this report to find participants who have joined this event including their details.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="downloadReport('participants', `sodisp participants  ${event.name}`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Participants without result</v-card-title>
            <v-card-text>Download this report to find participants who have joined sodisp, but not yet created a result. This could indicate they have troubles uploading their results. This will help you keep happy registrants and retain them towards future events.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="downloadReport('participants-without-result', `sodisp participants without result ${event.name}`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Activity Breakdown</v-card-title>
            <v-card-text>Download this report to download a detailed breakdown of all activities connected with this event for further processing.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="downloadReport('participant-activities', `sodisp activities for ${event.name}`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Friend Referrals</v-card-title>
            <v-card-text>Download this report to download a list of all friend referrals.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="downloadReport('referrals', `sodisp referrals for ${event.name}`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import siteData from '@/data/site.json'
import Header from './_Header.vue'

export default {
  name: "Reports",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      provider: null,
      model: {},
      siteData: siteData,
      races: [],
      headers: [
        { text: 'Name', sortable: true, value: 'name',},
        { text: 'Actions', value: 'actions', sortable: false },
      ],

    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.races = this.event.races;
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async downloadRaceReport(raceId, reportName, filename) {
      const response = await eventManagerService.exportRaceReport(this.event.id, raceId, reportName);
      this.downloadReportData(response, filename);
    },

    async downloadReport(reportName, filename) {
      const response = await eventManagerService.exportReport(this.event.id, reportName);
      this.downloadReportData(response, filename);
    },
    async downloadReportData(response, filename) {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename.replace(".", "_")
        link.click()
        URL.revokeObjectURL(link.href)
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `Reports`, disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

