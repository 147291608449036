<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        outlined
        color="white"
        class="language-button ml-2"
        v-on="on"
      >
        <v-icon color="white" class="mr-2">fal fa-globe-americas</v-icon>
        {{ i18n.locale }}
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item v-for="(item, idx) in getSupportedLocales()" :key="idx" link @click="switchLanguage(item.code)">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>      
</template>

<script>
import i18n from '@/i18n'
import getSupportedLocales from "@/util/i18n/supported-locales"
import VueCookies from 'vue-cookies'

export default {
  name: "LanguageMenu",
  props: {
  },
  data() {
    return {
      i18n: i18n,
      getSupportedLocales: getSupportedLocales,
    };
  },
  mounted() {
  },
  methods: {
    async switchLanguage(code) {
      console.log('switching lang to ', code);
      i18n.locale = code;
      VueCookies.set('locale', code, '30d');
    },
  },
  computed: {
  },
};
</script>

<style scoped>
  .account-button {}
  .language-button::after { content: '\f078'; font-family: 'Font Awesome 5 Pro'; margin-left:0; margin-right: 2px; }
</style>