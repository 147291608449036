<template>
  <div class="home">
    <div id="page-hero" class="pt-6 pb-2 pt-md-12 mb-0 white--text container--fluid">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="12">
            <h1 class="display-2 mb-4">Virtual Challenge Platform</h1>
            <p>
              Use sodisp to create engaging and beautiful virtual events and challenges!
              Sodisp is the only platform available with a complete focus on virtual events and challenges. All our features are aimed at providing best-in-class user experiences for participants and event organizers.
            </p>
            <p>
              The name <strong>sodisp</strong> is short for <strong>so</strong>cial <strong>di</strong>stancing <strong>sp</strong>orts.
              Our goal is to encourage everyone to responsibly enjoy the great outdoors in this new post-corona era. 
              Our platform offers a solution to all events and challenges worldwide going virtual.
            </p>
            <p class="mb-0">
              <v-btn class="mr-4 mb-4" color="white" :to="{name:'events'}">Explore virtual events</v-btn>
              <v-btn class="mb-4" color="white" outlined :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
    <div id="page-customers" class="mt-0 white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by world-class event organizers</h1>
            <p>
              The Sodisp Platform is used by world-renowned event organizers and managements organizations all across the world. Some of our key partners are:
            </p>
            <v-row>
            <v-col v-for="(item, idx) in siteData.customers.filter(x => x.showcase)" :key="idx" cols="6" md="4">
              <a :href="item.url" target="_blank">
              <v-sheet :elevation="1" class="text-center pa-4" height="90" color="transparent" :title="item.name" style="background-color: rgba(255,255,255,0.1) !important; line-height:85px;">
                <img :src="`/images/customers/${item.id}-white.png`" :alt="item.name" class="d-inline-block" style="max-width:100%; max-height:60px;"/>
              </v-sheet>
              </a>
            </v-col>
            </v-row>

          </v-col>
        </v-row>
      </div>
    </div>

    <div class="grey lighten-4">
    <v-container class="pb-0">
      <v-row>
        <v-col class="pb-0">
          <h3 class="title">Integration with all major fitness trackers</h3>
          <v-row>
          <v-col 
            v-for="(img, idx) in ['strava-color.png', 'garmin-color.png', 'mapmyfitness-color.png', 'fitbit-color.jpg', 'polar-color.png', 'suunto-color.png', 'coros-color.png', 'apple-watch-samsung-health.png']"
            :key="idx"
            cols="6" 
            md="4">
            <v-sheet :elevation="1" class="text-center align-center pa-4" height="80">
              <img :src="`/images/${img}`" :alt="img" class="d-inline-block" style="max-width:100%; max-height:40px;"/>
            </v-sheet>
          </v-col>
          </v-row>
          <p class="mb-0 mt-4">
            <v-btn class="mr-4 mb-4" color="primary" outlined href="/features">Explore All Features</v-btn>
            <v-btn class="mb-4" color="primary" outlined href="/contact">Contact Us</v-btn>
          </p>
        </v-col>
      </v-row>
    </v-container>
    </div>

    <v-container>
      <v-row>
        <v-col>
          <h3 class="title">Upcoming virtual events</h3>
          <EventGrid :items="events" class=""></EventGrid>
          <!-- 
            <img style="max-width:100%;margin-bottom:-6px;" src="https://sodisp.imgix.net/hero-run-1.jpg?w=1080&h=500&fit=crop" alt="Running while respecting social distancing guidelines." /> 
          <img style="max-width:100%;margin-bottom:-6px;" src="https://sodisp.imgix.net/hero-cycling-1.jpg?w=1080&h=500&fit=crop" alt="Cycling while respecting social distancing guidelines." />
          -->
        </v-col>
      </v-row>
    </v-container>
 
    <div class="green white--text py-6 py-md-12">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Easily transition to virtual events and challenges</h2>
            <h1 class="subtitle">Use sodisp to create engaging and beautiful virtual experiences!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mr-4 mb-4" color="white" href="/features">Features</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-container v-if="tenant.showFeatures" class="features">
      <v-row>
        <v-col cols=12>
          <h3 class="title">Features </h3>
          <p>Explore the features sodisp has to offer to <strong>make a success</strong> of your virtual event or challenge.</p>
        </v-col>
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-watch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fab fa-strava</v-icon>
          <h4>Auto-sync with Garmin, Strava, Fitbit, Polar and more</h4>
          <p>
            Activities tracked with Garmin, Polar, Suunto, Fitbit or COROS devices and the Strava app are automatically synced and added to results making it extremely easy for your participants to make every km/mile count!
          </p>

          <p>
            Next to our native integrations we also support manual entry or upload of any other gpx file.  
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-stopwatch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-ruler</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-mountain</v-icon>
          <h4>Best result, total distance, total elevation and more</h4>
          <p>
            We support all popular challenge modes: best result, total distance or total elevation. Even better: you can combine them in the same virtual challenge!
          </p>
          <p> 
            Whether your virtual event/challenge runs for a day, a week or even multiple months, whether it is a single sport or a multi-sport event, you can host it on sodisp.
          </p>
        </v-col>
        

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-swimmer fa-flip-horizontal</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-bicycle</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-running</v-icon>
          <h4>All sports and combinations</h4>
          <p>
            We support all popular sports and you can combine them all in the same challenge.
          </p>
          <p> 
            Use our platform to host virtual triathlons, multi-sport events or any combinations of different activity types.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-map</v-icon>
          <h4>Progress on map</h4>
          <p>
            Make your challenge more fun by mapping the progress on a real map. Simply upload your virtual course map and we'll map the progress on the map.
          </p>
          <p>
            We show all participants on the same map and have a powerful search option to find your own or team members' results easily!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-shield</v-icon>
          <h4>Badges</h4>
          <p>
            Create your own intermediate goals based on distance or elevation completed and award badges to keep your participants motivated to make it to the (virtual) finish line! Use our consecutive challenge to motivate your participants to keep going and our location based badges for virtual treasure hunts or embed local content into your challenge.
          </p>
          <p>
            Easily customize the layout or even use custom images to make the badges unique to your challenge. You can optionally enable and customize email messages to be sent whenever a badge is awarded and view overall statistics of each badge on your race result page.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-bullseye</v-icon>
          <h4>Collective progress &amp; Participation Mode</h4>
          <p>
            We're into this together, so let's work together in reaching our common goals!
            Easily set a collective target and everyone's effort will count toward reaching this collective goal!
          </p>
          <p> 
            Enable our participation mode for non-competitive challenges to change the leaderboard into a ticker board where the latest activity 
            is shown at the top to give everyone their moment in the spotlights, regardless of distance, pace, or speed!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-user</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-users</v-icon>
          <h4>Individual or team races</h4>
          <p>
            Create both individual or team challenges, or combinations of both! With the team functionality any group can work together to reach their common goals and compete with the other registered teams for first place!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-list</v-icon>
          <h4>Connect with your official race registration data</h4>
          <p>
            Easily load your registration data to create official results and allow only registered participants to join your virtual event.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-people-arrows</v-icon>
          <h4>Unique social distancing tools</h4>
          <p> 
            Our unique social distancing scoring techniques makes it possible to reward your participant for their responsible behavior. The more social distance they keep, the more points they score!
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-paint-roller</v-icon>
          <h4>Customize design</h4>
          <p> 
            Make it yours by customizing the design of your virtual event/challenge. Easily upload your logo and artwork to make your virtual event look beautiful.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tools</v-icon>
          <h4>Powerful Management Tools</h4>
          <p> 
            Use our easy to use, yet powerful management tools to manage your virtual challenge and results. All features can be configured using our self-service tools. Need help? No worries, we have extensive knowledge base articles available or can help you with an onboarding trainings session.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tags</v-icon>
          <h4>Get started for free</h4>
          <p> 
            Get started with sodisp for free. <!-- See our <router-link to="/pricing">simple and fair pricing</router-link> for details.  --><router-link to="/contact">Contact us</router-link> to discuss how sodisp can help your virtual event or challenge.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Our mission:</h2>
            <h1 class="subtitle">"Encourage a responsible way to sport together while respecting social distancing guidelines."</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mr-4 mb-4" color="white" href="/about">About Us</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-container>
      <v-row>
        <v-col>
          <h3 class="title">Our unique scoring to encourage social distancing</h3>
          <p>
            Our unique <strong>sodisp Points</strong> system promotes social distancing while enjoying outdoor activities at the same time. We automatically analyse your track and calculate our <u>sodisp Score</u> based on the estimated people density of your route!
          </p>
          <ul class="chip-list">
            <li class="mb-4"><v-chip color="primary">1</v-chip> <router-link to="/register">Connect using Garmin or Strava</router-link> and use the app or device you already love!</li>
            <li class="mb-4"><v-chip color="primary">2</v-chip> Challenge yourself by joining one or more <router-link to="/events">virtual events</router-link></li>
            <li class="mb-4"><v-chip color="primary">3</v-chip> Every km you run, ride or walk is worth a point</li>
            <li class="mb-4"><v-chip color="primary">4</v-chip> Bonus points are automatically given when you avoid busy areas or go exercise early or late on the day.</li>
            <li class="mb-4"><v-chip color="primary">5</v-chip> The more <router-link to="/socialdistancing">social distance</router-link> you keep, the higher your score!</li>
          </ul>

          <img class="mt-10" style="max-width:100%;margin-bottom:-6px;" src="https://sodisp.imgix.net/running-bridge.jpg?w=1080&h=500&fit=crop" alt="Running while respecting social distancing guidelines." />
          <!-- 
            <img style="max-width:100%;margin-bottom:-6px;" src="https://sodisp.imgix.net/hero-run-1.jpg?w=1080&h=500&fit=crop" alt="Running while respecting social distancing guidelines." /> 
          <img style="max-width:100%;margin-bottom:-6px;" src="https://sodisp.imgix.net/hero-cycling-1.jpg?w=1080&h=500&fit=crop" alt="Cycling while respecting social distancing guidelines." />
          -->
        </v-col>
      </v-row>
    </v-container>
  </div>
  
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import eventService from "@/services/eventService";
import EventGrid from "@/components/EventGrid.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "home",
  components: {
    EventGrid,
  },
  data() {
    return {
      tenant: tenant,
      events: null,
      siteData: siteData,
    };
  },
  async mounted() {
    this.events = (await eventService.upcoming()).data.data;

  }, 
};
</script>

<style lang="scss" scoped>
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style> 