<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Gallery for {{event.name}}</v-card-title>

      <v-card-text>      
        <v-alert type="info">This is a BETA feature. Please contact us in case you have questions or find an issue.</v-alert>
        <p>
          Manage the image gallery for this event. You can hide unwanted photos from the public gallery. Please keep in mind that participants can delete their own photos at all time.
        </p>
        <p>
          <strong>Coming soon:</strong> we'll be adding an option to add event/sponsor overlays in the near future.
        </p>
      </v-card-text>

      <v-card-title class="subheader">Photo Overlays</v-card-title>
      <v-card-text>Note: currently only a single overlay is supported. Changes made to an overlay might take a few minutes to propagate.</v-card-text>
      <div class="photo-grid pa-4 grey lighten-4">
        <v-sheet v-for="(item, idx) in overlays" :key="idx" class="photo" elevation="1" @click="openDesignDialog(item)">
          <img :src="item.preview_img" />
        </v-sheet>
        <v-sheet v-if="!overlays || !overlays.length" height="177" width="177" elevation="1" color="white" class="placeholder" @click="addOverlay">
          <v-icon size="100" color="grey">fal fa-plus-circle</v-icon>
        </v-sheet>
      </div>

      <v-card-title class="subheader">Event Gallery</v-card-title>
      <v-btn large class="float-right mr-4 mb-4" color="primary" :to="{ name: 'profileUpload', query: { eventId: event.id, return: 'eventmanager' }}"><v-icon class="mr-2">fa-plus-circle</v-icon> Upload Photo</v-btn>
      <br style="clear:both;"/>

      <PhotoGallery :photos="photos" :eventId="event.id" adminMode @changed="loadData"/>

    </v-card>

    <v-dialog v-model="showDesignDialog" max-width="650px"  @input="v => v || closeDesignDialog()">
      <template v-slot:activator="{ on }">
      </template>
      <v-card>
        <v-card-title class="headline">Overlay designer</v-card-title>
        <v-card-text>
          <v-form v-model="valid" lazy-validation ref="designForm">
            <v-row>
              <v-col cols="6">
                <h3>Options</h3>
                <v-file-input filled prepend-icon="fa fa-camera" accept="image/*" :rules="uploadRules" label="Upload an image" v-model="uploadFile" v-on:change="uploadImage"></v-file-input>
                <v-select 
                  v-model="editedItem.alignment"
                  :items="alignments"
                  label="Alignment"
                  multiple
                  hide-details
                  />
                <DistanceTextArea 
                  v-model="editedItem.size"
                  :mode="$helpers.UnitType.NUMBER"
                  :multiplier="1/100"
                  label="Size (0-100%)"
                  hideDetails
                  />
                <DistanceTextArea 
                  v-model="editedItem.padding"
                  :mode="$helpers.UnitType.NUMBER"
                  label="Padding (pixels)"
                  hideDetails
                  />
              </v-col>
              <v-col cols="6">
                <img :src="editedItem.img" style="max-width:100%;max-height:220px;display:block;"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" text @click="deleteItem">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="closeDesignDialog">Cancel</v-btn>
          <v-btn color="secondary" :loading="$store.getters.isLoading" @click="saveDesignDialog">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import PhotoGallery from "@/components/PhotoGallery";
import DistanceTextArea from "@/components/DistanceTextArea";

export default {
  name: "Integrations",
  components: {
    Header,
    PhotoGallery,
    DistanceTextArea,
  },
  props: {
  },
  data() {
    return {
      event: null,
      photos: null,
      overlays: null,
      siteData: siteData,
      showDesignDialog: false,
      editedIndex: -1,
      editedItem: {},
      valid: true,
      previewUrl: null,
      uploadFile: null,
      uploadRules: [
        value => !value || value.size < 5000000 || 'Image size should be less than 5 MB.',
      ],
      alignments: [
        "TOP", "MIDDLE", "BOTTOM", "LEFT", "CENTER", "RIGHT"
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.photos = (await eventService.getPhotos(id)).data.data;
      this.overlays = this.event.overlays;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async addOverlay() {
      this.openDesignDialog({ id: this.$helpers.randomId(), padding: 10, size: 0.2 });
    },

    async openDesignDialog(item) {
      this.editedIndex = (this.overlays||[]).indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showDesignDialog = true;
      this.previewUrl = this.editedItem.img;
    },

    async deleteItem() {
      if (confirm('Are you sure you want to delete this overlay?')) {
        this.overlays.splice(this.editedIndex, 1);
        const response = (await eventManagerService.putPhotoOverlays(this.event.id, this.overlays)).data;
        this.$helpers.toastResponse(this, response, 'Overlay deleted successfully.');
        this.closeDesignDialog();
      }
    },

    async updatePreviewImage() {
    },

    async uploadImage(item) {
      if (this.uploadFile) {
        let formData = new FormData()
        formData.append("file", this.uploadFile, this.uploadFile.name);
        const response = await eventManagerService.uploadPhotoOverlay(this.event.id, formData);
        if (response.data.status == "OK") {
          this.previewUrl = response.data.msg;
          this.editedItem.img = response.data.msg;
          this.updatePreviewImage();
        }
        this.$helpers.toastResponse(this, response.data, 'Image is uploaded successful!');
      }
    },

    async saveOverlays() {
      if (this.editedIndex > -1) {
        Object.assign(this.overlays[this.editedIndex], this.editedItem);
      } else {
        if (!this.overlays) {
          this.overlays = [this.editedItem];  
        }
        else {
          this.overlays.push(this.editedItem);
        }
      }

      const response = (await eventManagerService.putPhotoOverlays(this.event.id, this.overlays)).data;
      this.$helpers.toastResponse(this, response, 'Overlays saved successfully.');
    },

    closeDesignDialog () {
      this.showDesignDialog = false
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1
      })
    },

    async saveDesignDialog() {
      await this.saveOverlays();
      await this.loadData();
      this.closeDesignDialog();
    },


  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `Gallery`, disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>


<style lang="scss" scoped>
  .photo-grid {
    /*line-height: 0px;
    column-count: 2;
    column-gap: 10px;  */
    overflow: hidden;

    .placeholder {
      padding: 3px; 
      float: left;
      cursor: pointer;

      * { min-width: 181px; min-height: 181px; text-decoration: none;;}
    }

    .photo {
      display: inline-block !important;
      padding: 1px; 
      margin: 0 3px 3px 0;
      line-height: 0;
      float: left;
      position: relative;

      img {
        max-height: 175px;
        margin-top: 1px;
      }
      
    }
  }
</style>