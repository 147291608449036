<template>
  <div v-if="badgeDetailsType">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.css"/>
    <script type="application/javascript" src="https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.js"></script>

    <v-dialog v-if="badgeDetailsType == 'PANORAMA'" scrollable fullscreen v-model="panoramaVisible">
      <template v-slot:activator="{ on }">
        <v-btn small text block :color="color" v-on="on">
          <v-icon class="mr-2">fadl fa fa-image</v-icon> {{$t('events.badges.view-panorama')}}
        </v-btn>
      </template>
      <v-card v-if="badge" color="black">
        <v-toolbar dark color="grey darken-4">
          <v-btn icon dark class="ml-2" @click="panoramaVisible = false">
            <v-icon>fa-times-circle</v-icon>
          </v-btn>
          <v-toolbar-title>{{badge.name}}</v-toolbar-title>
          <v-spacer/>
          <ShareButton :icon="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" />
        </v-toolbar>
        <div v-if="badge.description" style="position:absolute;z-index:10; left:0px; bottom: 00px; right: 0; background: rgba(0,0,0, .45); padding:20px; color:white;">
          {{ badge.description }}
        </div>
        <div id="panorama" ref="panorama" style="width:100vw;height:100vh;"></div>
      </v-card>
    </v-dialog>

    <v-dialog v-if="badgeDetailsType == 'STREET_VIEW'" scrollable fullscreen v-model="streetViewVisible">
      <template v-slot:activator="{ on }">
        <v-btn small text block :color="color" v-on="on">
          <v-icon class="mr-2">fadl fa fa-street-view</v-icon> {{$t('events.badges.street-view')}}
        </v-btn>
      </template>
      <v-card v-if="badge" color="black">
        <v-toolbar dark color="grey darken-4">
          <v-btn icon dark class="ml-2" @click="streetViewVisible = false">
            <v-icon>fa-times-circle</v-icon>
          </v-btn>
          <v-toolbar-title>{{badge.name}}</v-toolbar-title>
          <v-spacer/>
          <ShareButton :icon="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" />
        </v-toolbar>
        <div v-if="badge.description" style="position:absolute;z-index:10; left:0px; bottom: 00px; right: 0; background: rgba(0,0,0, .45); padding:20px; color:white;">
          {{ badge.description }}
        </div>
        <div ref="streetviewPlaceholder" class="full-dialog"/>
<!-- 
        <iframe v-if="badge.details_pano_id" width="600" height="450" class="full-dialog" style="border:0;" loading="lazy" allowfullscreen :src="`https://www.google.com/maps/embed/v1/streetview?pano=${badge.details_pano_id}&key=${apiKey}`"></iframe>
        <iframe v-else width="600" height="450" style="border:0;width:100vw;height:100vh" loading="lazy" allowfullscreen :src="`https://www.google.com/maps/embed/v1/streetview?location=${badge.coord_lat},${badge.coord_lng}&key=${apiKey}`"></iframe>
 -->        
      </v-card>
    </v-dialog>

    <v-dialog v-if="badgeDetailsType == 'IMAGE'" scrollable fullscreen v-model="photoVisible">
      <template v-slot:activator="{ on }">
        <v-btn small text block :color="color" v-on="on">
          <v-icon class="mr-2">fadl fa fa-image</v-icon> {{$t('shared.view-image')}}
        </v-btn>
      </template>
      <v-card v-if="badge" color="black">
        <v-toolbar dark color="grey darken-4">
          <v-btn icon dark class="ml-2" @click="photoVisible = false">
            <v-icon>fa-times-circle</v-icon>
          </v-btn>
          <v-toolbar-title>{{badge.name}}</v-toolbar-title>
          <v-spacer/>
          <v-btn-toggle v-model="photoFit" class="mr-2">
            <v-btn :value="true">
              <v-icon class="mr-1">fa-arrows</v-icon> Fit
            </v-btn>
          </v-btn-toggle>
          <ShareButton :icon="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" />
        </v-toolbar>
        <div v-if="badge.description" :class="photoFit ? '' : 'd-none'" style="position:absolute;z-index:10; left:0px; bottom: 00px; right: 0; background: rgba(0,0,0, .45); padding:20px; color:white;">
          {{ badge.description }}
        </div>
        <div class="full-dialog" style="overflow:auto;">
          <img :src="badge.details_img" :class="photoFit ? 'fit-image' : ''" @click="photoFit=!photoFit"/>
        </div>
      </v-card>
    </v-dialog>    
  </div>
</template>

<script>
import ShareButton from "@/components/ShareButton";
import eventService from "@/services/eventService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "BadgeDetailsDialog",
  components: {
    ShareButton,
  },
  props: {
    event: Object,
    race: Object,
    badgeId: String,
    detailsType: String,
    color: {
      type: String,
      default: 'primary',
    }
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      badge: null,
      panoramaVisible: false,
      streetViewVisible: false,
      photoVisible: false,
      photoFit: true,
    }
  },
  async mounted() {

  },
  methods: {
    async ensureBadgeLoaded() {
      if (this.badge == null) {
        await this.load();
      }
    },

    async load() {
      const badge = (await eventService.getRaceBadge(this.event.id, this.race.id, this.badgeId)).data;
      this.open(badge);
    },

    async open(badge) {
      this.badge = badge;
      if (this.badge.details_type == 'PANORAMA') {
        this.panoramaVisible = true;
      }
      if (this.badge.details_type == 'IMAGE') {
        this.photoVisible = true;
      }
      if (this.badge.details_type == 'STREET_VIEW' && this.validStreetView) {
        this.streetViewVisible = true;
      }
      const meta = {
        title: `Earn the badge ${this.badge.name} at the ${this.event.name}`,
        description: `Earn the badge ${this.badge.name} at the ${this.event.name} on ${tenant.title}`,
        image: this.badge.img,
      }; 
      EventBus.$emit('page-header-change', meta);
    },

    async loadStreetView() {
      var iframe = document.createElement('iframe');
      if (this.badge.details_pano_id) {
        iframe.src = `https://www.google.com/maps/embed/v1/streetview?pano=${this.badge.details_pano_id}&key=${this.apiKey}`;
      }
      else {
        iframe.src = `https://www.google.com/maps/embed/v1/streetview?location=${this.badge.coord_lat},${this.badge.coord_lng}&key=${this.apiKey}`;
      }
      iframe.class = 'full-dialog';
      iframe.style = 'border:0;width:100%;height:100%'
      iframe.allowfullscreen = 'allowfullscreen';
      this.$refs.streetviewPlaceholder.textContent = '';
      this.$refs.streetviewPlaceholder.appendChild(iframe);
//        <div ref="streetviewPlaceholder"/>
//        <iframe v-if="badge.details_pano_id" width="600" height="450" class="full-dialog" style="border:0;" loading="lazy" allowfullscreen :src="`https://www.google.com/maps/embed/v1/streetview?pano=${badge.details_pano_id}&key=${apiKey}`"></iframe>
//        <iframe v-else width="600" height="450" style="border:0;width:100vw;height:100vh" loading="lazy" allowfullscreen :src="`https://www.google.com/maps/embed/v1/streetview?location=${badge.coord_lat},${badge.coord_lng}&key=${apiKey}`"></iframe>
    },

    async openPano() {
      window.pannellum.viewer(this.$refs.panorama, {
          type: "equirectangular",
          //"panorama": "/images/content/mars-dune.png",
          panorama: this.badge.details_img,
          autoLoad: true,
          //haov: 180,
          //vaov: 90,
      });
    },

  },
  computed: {
    badgeDetailsType() {
      return this.detailsType || (this.badge ? this.badge.details_type : null);
    },
    validStreetView() {
      return this.badge && (this.badge.details_pano_id || (this.badge.coord_lat && this.badge.coord_lng));
    },
  },
  watch: {
    async photoVisible(visible) {
      if (visible) {
        await this.ensureBadgeLoaded();
      } 
    },
    async panoramaVisible(visible) {
      if (visible) {
        await this.ensureBadgeLoaded();
        this.$nextTick(async () => {
          await this.openPano();
        });
      } 
    },
    async streetViewVisible(visible) {
      if (visible) {
        await this.ensureBadgeLoaded();
        this.$nextTick(async () => {
          await this.loadStreetView();
        });
      } 
    },
  },
};
</script>

<style lang="scss">
  .full-dialog { width:100vw;height:100vh; }
  .fit-image { max-width:100vw;max-height:100vh; }
</style>