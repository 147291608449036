<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        icon
        outlined
        color="white"
        class="account-button ml-2"
        v-on="on"
      >
        <!-- Me
        <v-icon right color="white">fa fa-caret-down</v-icon> -->
        <v-icon color="white">fal fa-user</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list v-if="user">
        <v-subheader>{{$t('nav.account.welcome')}}</v-subheader>
        <v-divider></v-divider>
        <v-list-item v-for="(item, i) in $store.state.nav['account-menu-default']" :key="i" link :to="item.to" exact>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text_key ? $t('nav.account.' + item.text_key) : item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="signOut">
          <v-list-item-action>
            <v-icon>fal fa-sign-out</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{$t('nav.account.sign-out')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item link to="/register">
          <v-list-item-content>
            <v-list-item-title>
              {{$t('nav.account.register')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/login">
          <v-list-item-content>
            <v-list-item-title>
              {{$t('nav.account.login')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>      
</template>

<script>
import { mapGetters } from "vuex";
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: "AccountMenu",
  props: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    async signOut() {
      await firebase.auth().signOut();
      this.$store.dispatch('setUser', null);
      this.$router.replace({ name: "home" });
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style scoped>
  .account-button {}
  .account-button::after { content: '\f078'; font-family: 'Font Awesome 5 Pro'; margin-left:-7px; margin-right: 2px; }
</style>