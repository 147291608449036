<template>
  <v-card
    flat
    class="d-flex flex-column fill-height"
  >
    <v-card-text v-if="!photos || photos.length == 0" class="font-italic">{{$t('results.photos.no-photos-message')}}</v-card-text>
    <div v-else>
      <div ref="gallery" class="photo-grid pa-4 grey lighten-4" itemscope itemtype="http://schema.org/ImageGallery">

      <v-sheet v-for="(item, idx) in photos" :key="idx" class="photo" elevation="1">
        <figure itemprop="associatedMedia" :data-index="idx" itemscope itemtype="http://schema.org/ImageObject">
          <a :href="item.img" itemprop="contentUrl">
            <img :src="item.thumb" itemprop="thumbnail" :alt="item.msg" :class="item.status=='HIGHLIGHTED' ? 'highlighted': ''" />
            <v-btn v-if="adminMode" color="white" title="Highlight this photo in gallery" class="highlight-photo px-0" rounded @click.prevent.stop="moderatePhoto(item.id, 'highlight')"><v-icon color="yellow darken-3">{{item.status=='HIGHLIGHTED' ? 'fa fa-star': 'fal fa-star'}}</v-icon></v-btn>
            <v-btn v-if="adminMode" color="white" title="Hide this photo from the gallery" class="reject-photo px-0" rounded @click.prevent.stop="moderatePhoto(item.id, 'reject')"><v-icon color="red">fa-ban</v-icon></v-btn>
          </a>
          <figcaption itemprop="caption description">{{item.msg}}</figcaption>
        </figure>
      </v-sheet>
      <v-sheet v-if="eventId && !isEmbedded" height="177" width="177" elevation="1" color="white" class="placeholder">
        <router-link :to="{name: 'profileUpload', query: {eventId: eventId}}">
          <v-icon size="100" color="grey">fal fa-plus-circle</v-icon>
        </router-link>
      </v-sheet>
<!--
        <v-sheet v-for="(item, idx) in photos" :key="idx" class="photo" elevation="2">
          <img :src="item.thumb" alt="item.msg" style="max-height:200px;" />
        </v-sheet>
        -->
      </div>
    </div>

    <!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div ref="pspw" class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
        <!-- Background of PhotoSwipe.  It's a separate element as animating opacity is faster than rgba(). -->
        <div class="pswp__bg"></div>
        <!-- Slides wrapper with overflow:hidden. -->
        <div class="pswp__scroll-wrap">
            <!-- Container that holds slides. 
                PhotoSwipe keeps only 3 of them in the DOM to save memory.
                Don't modify these 3 pswp__item elements, data is added later on. -->
            <div class="pswp__container">
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
            </div>
            <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
            <div class="pswp__ui pswp__ui--hidden">
                <div class="pswp__top-bar">
                    <!--  Controls are self-explanatory. Order can be changed. -->
                    <div class="pswp__counter"></div>
                    <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
                    <button class="pswp__button pswp__button--share" title="Share"></button>
                    <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
                    <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
                    <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                    <!-- element will get class pswp__preloader--active when preloader is running -->
                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                          <div class="pswp__preloader__cut">
                            <div class="pswp__preloader__donut"></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip"></div> 
                </div>
                <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
                </button>
                <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
                </button>
                <div class="pswp__caption">
                    <div class="pswp__caption__center"></div>
                </div>
            </div>
        </div>
    </div>

  </v-card>

</template>

<script>

import { EventBus } from '@/plugins/eventbus.js';
import { mapGetters } from "vuex";
import firebase from 'firebase/app'
import 'firebase/auth'
import photoswipe from '@/util/photoswipe'
import eventManagerService from "@/services/eventManagerService";
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import PhotoSwipeCss from 'photoswipe/dist/photoswipe.css'
import PhotoSwipe_Skin from 'photoswipe/dist/default-skin/default-skin.css'

export default {
  name: "PhotoGallery",
  components: {
  },
  props: {
    photos: Array,
    adminMode: Boolean,
    eventId: String,
  },
  data: function() {
    return {
      gallery: null,
    };
  },
  created() {
  },
  mounted() {
    this.loadGallery();
  },
  updated() {
  },
  methods: {
    async loadGallery() {
      if (!this.gallery && this.photos && this.photos.length) {
        this.gallery = new photoswipe('.photo-grid .photo');
        //console.log('Creating gallery', this.gallery, document.querySelectorAll('.photo-grid .photo'));
      }
    },
    async moderatePhoto(photoId, action) {
      if (this.gallery && confirm(`Are you sure you want to ${action} this photo from the gallery?`)) {
        var response = await eventManagerService.moderatePhoto(this.eventId, photoId, action);
        this.$helpers.toastResponse(this, response.data, `Photo ${action}ed successfully.`);
        this.$emit('changed');
      }
    },
  },
  watch: {
    photos: function(val) {
      this.$nextTick(() => {
        this.loadGallery();
      });
    }
  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed';
    },
    ...mapGetters({
      user: "user"
    }),
  },
};
</script>

<style lang="scss" scoped>
  .photo-grid {
    /*line-height: 0px;
    column-count: 2;
    column-gap: 10px;  */
    overflow: hidden;

    .placeholder {
      padding: 3px; 
      float: left;
      cursor: pointer;

      * { min-width: 181px; min-height: 181px; text-decoration: none;;}
    }

    .photo {
      display: inline-block !important;
      padding: 1px; 
      margin: 0 3px 3px 0;
      line-height: 0;
      float: left;
      position: relative;

      img {
        max-height: 175px;
        margin-top: 1px;
      }
      img.highlighted {
        max-height: 356px;
      }

      figcaption { display: none;}

      .reject-photo {
        position: absolute;
        top: 10px;
        right: 10px;
        min-width: 36px !important;
      }
      .highlight-photo {
        position: absolute;
        top: 10px;
        right: 50px;
        min-width: 36px !important;
      }
    }
  }
</style>