<template>
  <div>
  <v-toolbar v-if="!event && title" :dark="$helpers.isDarkColor($vuetify.theme.themes.light.eventmanager)" elevation="1" color="eventmanager">
    <v-toolbar-title class="ml-4">{{title}}</v-toolbar-title>
  </v-toolbar>
  <v-toolbar v-if="event" :dark="$helpers.isDarkColor($vuetify.theme.themes.light.eventmanager)" elevation="1" color="eventmanager">
    <v-toolbar-title class="ml-4"><router-link class="no-decoration" :to="{name:'eventmanagerView', params: {id: event.id}}">{{event.name}}</router-link></v-toolbar-title>
    <v-spacer></v-spacer>
    <div>
      <v-btn text :href="tenant.eventManagerFaqUrl" target="_blank" class="mr-4">
        <v-icon class="mr-2">fadl fa fa-question-circle</v-icon>
        FAQ
      </v-btn>
      <v-btn v-if="!focused && event.published" :to="{name:'event', params: {id: event.id}}" class="mr-4">
        <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
        View
      </v-btn>
      <v-btn v-if="!focused" :to="{name:'eventmanagerEdit', params: {id: event.id}}" class="mr-0">
        <v-icon small class="mr-2">fadl fa fa-pencil</v-icon>
        Edit
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon title="More options" >fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="{name:'eventmanagerGallery', params: {id: event.id}}">
            <v-list-item-icon><v-icon>fal fa-images</v-icon></v-list-item-icon>
            <v-list-item-title>Photos &amp; selfies</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name:'eventmanagerReports', params: {id: event.id}}">
            <v-list-item-icon><v-icon>fal fa-file-spreadsheet</v-icon></v-list-item-icon>
            <v-list-item-title>Export reports</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name: 'eventmanagerCertificates', params: { id:event.id }}">
            <v-list-item-icon><v-icon>fa-file-certificate</v-icon></v-list-item-icon>
            <v-list-item-title>Virtual Bibs &amp; Certificates</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name:'eventmanagerActivityConversions', params: {id: event.id}}">
            <v-list-item-icon><v-icon>fal fa-pencil-ruler</v-icon></v-list-item-icon>
            <v-list-item-title>Activity Conversion Matrix</v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item :to="{name:'eventmanagerIntegrations', params: {id: event.id}}">
            <v-list-item-icon><v-icon>fal fa-key</v-icon></v-list-item-icon>
            <v-list-item-title>Integrations</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{name:'eventmanagerPermissions', params: {id: event.id}}">
            <v-list-item-icon><v-icon>fal fa-user-unlock</v-icon></v-list-item-icon>
            <v-list-item-title>Permissions</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <template v-slot:extension v-if="!focused">
        <v-tabs
          class="mx-0"
          background-color="eventmanager darken-1"
          slider-color="white"
        >
          <v-tab
            v-for="(tab, idx) in tabs"
            :key="idx"
            :to="tab.to"
          >
            {{ tab.text }} 
          </v-tab>
        </v-tabs>
      </template>    
  </v-toolbar>
  </div>
</template>

<script>
import eventManagerService from "@/services/eventManagerService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventManagerHeader",
  components: {
  },
  props: {
    event: Object,
    title: String,
    focused: Boolean,
  },
  data() {
    return {
      tenant: tenant,
    };
  },
  async mounted() {
  },
  methods: {

  },
  computed: {
    tabs() {
      return this.event == null ? [] : [
        { text: 'Leaderboards', to: {name:'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Participants', to: {name:'eventmanagerParticipants', params: {id: this.event.id}} },
        { text: 'Teams', to: {name:'eventmanagerTeams', params: {id: this.event.id}} },
        { text: 'Registrations', to: {name:'eventmanagerRegistrations', params: {id: this.event.id}} },
      ];
    }
  },
};
</script>
<style lang="scss" scoped>
  .v-toolbar {
    .v-toolbar__title a {
      color: black! important;
    }
  }
  .v-toolbar.theme--dark {
    .v-toolbar__title a {
      color: white! important;
    }
  }
</style>

