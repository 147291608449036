<template>
  <div>
    <div v-if="isConnected" class="mt-4 float-right">
      <v-btn x-large :color="event.color || 'primary'" class="white--text" :to="{name: 'eventWelcome', params: {id: this.event.id}}" :exact="true">
        <v-icon class="mr-2">fa-info-circle</v-icon>
        {{ $t('events.welcome.manage-connection') }}
      </v-btn>
      <p class="text-muted mt-2">
        <v-icon small :color="event.color || 'green'" class="mr-1">fa-check-circle</v-icon> You are connected with
        <span v-if="status.races">the race(s): {{ status.races.join(', ') }}.</span>
        <span v-else>this event.</span>
      </p>
    </div>
    <v-card v-if="!isConnected" class="" color="grey lighten-3">
      <v-card-title class="subtitle">{{$t('events.welcome.join-event')}}</v-card-title>
      <v-card-text v-if="event.verification">
        <v-icon small >fa-lock</v-icon> {{$t('events.welcome.join-event-verification-code-msg')}}
      </v-card-text>
      <v-card-text v-if="user">
        <v-btn block x-large :color="event.color || 'primary'" class="white--text" @click="joinEvent">
          {{$t('events.welcome.join-event-button')}}
        </v-btn>
      </v-card-text>
      <v-card-text v-else>
        <v-btn block x-large :color="event.color || 'primary'" class="white--text" :to="{name: 'register', query: { event: event.id}}">
          {{$t('events.welcome.connect-now')}}
        </v-btn>
      </v-card-text>
      <div v-if="event.external_url || event.url" class="grey lighten-5">
      <v-divider/>
      <v-card-text>
        <p>{{$t('events.register-msg')}}</p>
        <v-btn outlined block :color="event.color || 'primary'" class="" :href="event.external_url || event.url" target="_blank">
          {{$t('events.visit-website')}}
        </v-btn>
      </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventJoinCard",
  props: {
    event: Object
  },
  data: function() {
    return {
      status: null,
      providers: null,
    };
  },
  created() {
  },
  async mounted() {
    EventBus.$on('login-state-change', async user => {
      await this.getEventStatus();
    });
    await this.getEventStatus();
  },
  methods: {
    
    async joinEvent() {
      if (this.status && (this.status.verification_required || this.status.consent_required)){
        this.$router.replace({ name: "eventJoin", params: { event: this.event.id} });
        return;
      }
      const result = (await profileService.joinEvent(this.event.id)).data;
      if (result && result.status !== "OK") {
        // Go to join page for additional feedback when it couldn't join
        this.$router.replace({ name: "eventJoin", params: { event: this.event.id} });
        return;
      }
      await this.getEventStatus();
    },
    
    async disconnectEvent() {
      await profileService.disconnectEvent(this.event.id);
      await this.getEventStatus();
    },

    async getEventStatus() {
      if (this.user) {
        var response = await profileService.getEventStatus(this.event.id);
        this.status = response.data;
        this.providerUrls = this.$oauth.getProviderUrls({ profileId: this.status.id, eventId: this.event.id }, this.event);
      }
      else {
        this.providerUrls = this.$oauth.getProviderUrls({ eventId: this.event.id }, this.event);
        //this.profile = 'Not logged in';      
      }
    },
  },
  computed: {
    isConnected() {
      return this.status && this.status.connected;
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style scoped>
</style>