import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    impersonationProfileId: null,
    getConfig() {
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                "x-impersonation-profile-id": this.impersonationProfileId || '',
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    get(full) {
        return services.http.get(`/v1.0/profile?full=${full == undefined ? false : full}`, this.getConfig());
    },
    post(model) {
        return services.http.post(`/v1.0/profile/`, model, this.getConfig());
    },
    put(model) {
        return services.http.put(`/v1.0/profile/`, model, this.getConfig());
    },
    getFeed() {
        return services.http.get(`/v1.0/profile/feed`, this.getConfig());
    },
    deleteFeedItem(id) {
        return services.http.delete(`/v1.0/profile/feed/${id}`, this.getConfig());
    },
    getGeoRestrictedEvents(model) {
        return services.http.post(`/v1.0/profile/events/georestricted`, model, this.getConfig());
    },
    getEventStatus(eventId) {
        return services.http.get(`/v1.0/profile/event/${eventId}`, this.getConfig());
    },
    joinEvent(eventId, code, raceIds, consented, gender) {
        return services.http.post(`/v1.0/profile/event/${eventId}/join`, { code: code, race_ids: raceIds, consented: consented, gender: gender }, this.getConfig());
    },
    disconnectEvent(eventId, deleteResults) {
        return services.http.post(`/v1.0/profile/event/${eventId}/disconnect?deleteResults=${deleteResults||''}`, {}, this.getConfig());
    },
    joinRace(eventId, raceId) {
        return services.http.put(`/v1.0/profile/event/${eventId}/join/${raceId}`, {}, this.getConfig());
    },
    createGroupForEvent(eventId, request) {
        return services.http.put(`/v1.0/profile/event/${eventId}/groups`, request, this.getConfig());
    },
    joinGroupForEvent(eventId, request) {
        return services.http.post(`/v1.0/profile/event/${eventId}/groups/join`, request, this.getConfig());
    },
    uploadAvatar(model) {
        return services.http.post(`/v1.0/profile/avatar/upload`, model, this.getConfig());
    },
    uploadPhoto(model, uploadToWall) {
        return services.http.post(`/v1.0/profile/upload/photo?uploadToEventWall=${uploadToWall||false}`, model, this.getConfig());
    },
    pull(provider, daysInPast) {
        return services.http.post(`/v1.0/profile/connections/${provider}/pull?daysInPast=${daysInPast||''}`, {}, this.getConfig());
    },
    disconnect(provider) {
        return services.http.post(`/v1.0/profile/connections/${provider}/disconnect`, {}, this.getConfig());
    },
    myGroups() {
        return services.http.get(`/v1.0/profile/groups`, this.getConfig());
    },
    myManagedGroups() {
        return services.http.get(`/v1.0/profile/groups/managing`, this.getConfig());
    },
    getGroup(id) {
        return services.http.get(`/v1.0/profile/groups/${id}`, this.getConfig());
    },
    createGroup(model) {
        return services.http.post(`/v1.0/profile/groups`, model, this.getConfig());
    },
    editGroup(id, model) {
        return services.http.put(`/v1.0/profile/groups/${id}`, model, this.getConfig());
    },
    joinGroup(id) {
        return services.http.post(`/v1.0/profile/groups/${id}/join`, {}, this.getConfig());
    },
    disconnectGroup(id) {
        return services.http.post(`/v1.0/profile/groups/${id}/disconnect`, {}, this.getConfig());
    },
    disconnectMeFromGroupAndEvent(id, eventId) {
        return services.http.post(`/v1.0/profile/groups/${id}/event/${eventId}/disconnect`, {}, this.getConfig());
    },
    disconnectGroupAndEvent(id, profileId, eventId) {
        return services.http.post(`/v1.0/profile/groups/${id}/event/${eventId}/disconnect/${profileId}`, {}, this.getConfig());
    },
    getGroupEventStatus(id, eventId) {
        return services.http.get(`/v1.0/profile/groups/${id}/event/${eventId}`, this.getConfig());
    },
    linkPlanWorkout(eventId, raceId, model) {
        return services.http.post(`/v1.0/profile/plans/${eventId}/${raceId}/workouts`, model, this.getConfig());
    },
    unlinkPlanWorkout(eventId, raceId, workoutId) {
        return services.http.delete(`/v1.0/profile/plans/${eventId}/${raceId}/workouts/${workoutId}`, this.getConfig());
    },
    putGroupSlotAssignment(id, eventId, slots) {
        return services.http.put(`/v1.0/profile/groups/${id}/event/${eventId}/slots`, slots, this.getConfig());
    },
    uploadGroupImage(id, type, model) {
        return services.http.post(`/v1.0/profile/groups/${id}/upload/${type}`, model, this.getConfig());
    },

}