<template>
  <v-card
    flat
    class="d-flex flex-column fill-height"
    style="max-height: 500px;"
  >
    <div ref="commentList" class="flex-grow-1 overflow-y-auto grey lighten-4 pa-4">
      <div v-if="!comments || comments.length == 0" class="font-italic">{{$t('results.comments.no-comments-message')}}</div>
      <div v-for="(item, idx) in comments" :key="idx" :class="{ 'd-flex flex-row-reverse': selfId && item.sender.id.toUpperCase() == selfId.toUpperCase() }">
        <v-sheet class="d-inline-flex pa-2 mb-4" elevation="2" color="white">
          <v-avatar v-if="item.sender.img" left size="50" class="flex-shrink mr-4">
            <v-img :src="item.sender.img + '?w=100&h=100'"></v-img>
          </v-avatar>
          <div>
            <p class="ma-0 body-1">{{ item.msg }}</p>
            <p class="ma-0 text-muted text-small">
              {{item.sender.name}} • {{ item.timestamp | moment('L LT') }}
              <span v-if="currentUserId && item.sender.id.toUpperCase() == currentUserId.toUpperCase()">
                • <v-btn x-small text @click="deleteComment(item.id)">{{$t('shared.delete')}}</v-btn>
              </span>
            </p>
          </div>
        </v-sheet>
      </div>
    </div>
    <div class="flex-shrink-1 pa-4">
      <div v-if="user">
        <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          ref="commentField"
          v-model="newComment"
          :label="$t('results.comments.comment-label')"
          type="text"
          no-details
          outlined
          :append-outer-icon="posting ? 'fa fa-circle-notch fa-spin' : 'fa-paper-plane'"
          :rules="rules"
          :loading="posting"
          :disabled="posting"
          @keydown.enter.prevent="saveComment"
          @click:append-outer="saveComment"
          hide-details
        />
        </v-form>
        <div v-if="publicVisible" class="text-muted">{{$t('results.comments.publicly-visible-message')}}</div>
      </div>
      <div v-else>
        <p>{{$t('results.comments.login-or-register-message')}}</p>
        <v-btn color="accent" class="mr-4" :to="{ name: 'register', query: { redirect:redirectUrl }}">{{$t('nav.account.register')}}</v-btn>
        <v-btn outlined color="accent" :to="{name: 'login', query: { redirect:redirectUrl } }">{{$t('nav.account.login')}}</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>

import { EventBus } from '@/plugins/eventbus.js';
import { mapGetters } from "vuex";
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: "CommentBox",
  components: {
  },
  props: {
    comments: Array,
    selfId: String,
    publicVisible: Boolean,
  },
  data: function() {
    return {
      newComment: null,
      currentUserId: null,
      redirectUrl: null,
      posting: false,
      valid: false,
      rules: [
        v => !!v || "Please enter the comment you want to post.",
      ],
    };
  },
  created() {
  },
  mounted() {
    if (firebase.auth().currentUser !== null) {
      this.currentUserId = firebase.auth().currentUser.uid;
    }
    this.redirectUrl = window.location.pathname + window.location.search;
  },
  updated() {
    // whenever data changes and the component re-renders, this is called.
    this.$nextTick(() => this.scrollToEnd());
  },
  methods: {
    async saveComment() {
      if (this.$refs.form.validate() && this.user && this.newComment) {
        this.posting = true;
        await this.$emit('post', { comment: this.newComment, done: () => {
          this.newComment = null;
          this.$refs.form.reset();
          this.posting = false;
          }
        }); 
        //await this.emit('post', this.newComment);
      }
      else {
      }
    },
    async deleteComment(id) {
      if (this.user && confirm('Are you sure you want to delete this comment?')) {
        this.$emit('delete', id); 
      }
      else {
      }
    },
    emit (eventName, value) {
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value)
        this.$nextTick(resolve)
      })
    },
    scrollToEnd: function () {
      // scroll to the start of the last message
      //console.log('scrolling to end',this.$refs.commentList.lastElementChild.offsetTop);
      this.$el.firstElementChild.scrollTop = this.$refs.commentList.lastElementChild.offsetTop;
      //this.$el.firstElementChild.scrollToEnd();
      //this.$refs.commentList.$el.lastElementChild.scrollIntoView();
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
};
</script>

<style scoped>
</style>