import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default (selector) => {

    const parseThumbnailElements = (element) => {

        let items = [];
        const thumbs = element.parentNode.querySelectorAll('figure');

        for (let i = 0; i < thumbs.length; i++) {
                const figure = thumbs[i]; // <figure> element

                // include only element nodes
                if (figure.nodeType !== 1) continue;

                const link = figure.children[0]; // <a> element

                const width = link.children[0].width;
                const height = link.children[0].height;
                const ratio = width / height;

                //console.log('img', `${width} x ${height}`, ratio, `${width > height ? 1500 : 1500*ratio}x${width > height ? 1500/ratio : 1500}`);

                // create slide object
                const item = {
                    src: link.getAttribute('href'),
                    w: width > height ? 1500 : 1500*ratio,
                    h: width > height ? 1500/ratio : 1500,
                };

                if (figure.children.length > 1) {
                    // <figcaption> content
                    item.title = figure.children[1].innerHTML;
                }

                if (link.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = link.children[0].getAttribute('src');
                }

                item.el = figure; // save link to element for getThumbBoundsFn
                items.push(item);
            }
        return items;
    };

    // find nearest parent element
    const closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
    };

    // triggers when user clicks on thumbnail
    const onThumbnailsClick = (e) => {

        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        const eTarget = e.target || e.srcElement;
        // find root element of slide
        const clicked = closest(eTarget, function (element) {
            return (element.tagName && element.tagName.toUpperCase() === 'FIGURE');
        });

        if (!clicked) return;
        const clickedGallery = clicked.parentNode;
//console.log('clicked', clicked, childNodes);
        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
  /*      let index;
        let nodeIndex = 0;
        const childNodes = clicked.parentNode.childNodes;

        for (let i = 0; i < childNodes.length; i++) {
            if (childNodes[i].nodeType !== 1) {
                continue;
            }

            if (childNodes[i] === clicked) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }*/
        const index = parseInt(clicked.getAttribute('data-index'));

        if (index >= 0) {
            // open PhotoSwipe if valid index found
            //console.log(e.target)
            openPhotoSwipe(index, clickedGallery);
        }
        return false;
    };

    const openPhotoSwipe = (index, galleryElement, disableAnimation) => {

        const pswpElement = document.querySelectorAll('.pswp')[0];
        const items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        const options = {
            history: false,
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),
            getThumbBoundsFn(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                const thumbnail = items[index].el.getElementsByTagName('img')[0];
                const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                const rect = thumbnail.getBoundingClientRect();

                return {
                    x: rect.left,
                    y: rect.top + pageYScroll,
                    w: rect.width
                };
            }

        };

        // PhotoSwipe opened from URL
        options.index = parseInt(index, 10);

        // exit if index not found
        if (isNaN(options.index)) {
            return;
        }

        if (disableAnimation) {
            options.showAnimationDuration = 0;
        }
//console.log(pswpElement, items, options);
        // Pass data to PhotoSwipe and initialize it
        const Gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
        Gallery.init();
    };

    const galleryElements = document.querySelectorAll(selector);
    for (let i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
    }
}
