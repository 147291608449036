<template>
  <div v-if="race && race.route">
    <link rel="stylesheet" href="https://unpkg.com/leaflet.markercluster@1.3.0/dist/MarkerCluster.css">
    <link rel="stylesheet" href="https://unpkg.com/leaflet.markercluster@1.3.0/dist/MarkerCluster.Default.css">

    <script v-if="(race.custom_map && race.custom_map.globe)" type="application/javascript" src="https://www.webglearth.com/v2/api.js"></script>

    <!-- <v-card-title class="headline">Virtual Course Map</v-card-title> -->
    <v-card-text>
      {{$t('events.race.course-map')}}
    </v-card-text>

    <v-alert v-if="race.route_desc" type="info" tile class="mb-0">{{race.route_desc}}</v-alert>
    <div class="map-container d-flex">
      <div 
        id="map" 
        ref="map" 
        class="leaflet-map Zdynamic-content" 
        style="height: 400px; width: 100%; z-index: 2;"
        />
      <div v-if="false && race.scoring == 'ELEVATION'" class="elevation-bar d-flex flex-column">
        <p>{{ race.dist | elevation(event.unit) }}</p>
        <div class="grow bar-bg">
          <div class="position" ref="elevationBarHandle" ></div>
        </div>
        <p>{{ 0 | elevation(event.unit) }}</p> 
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/eventbus.js';
import eventService from "@/services/eventService";
import { latLng } from "leaflet";
import { markercluster } from "leaflet.markercluster";
import PolylineUtil from "@/plugins/Polyline.encoded.js";
import LeafletFullscreen from "@/plugins/Leaflet.fullscreen.js";

export default {
  name: "RaceResultMap",
  components: {
  },
  props: {
      //results: Array,
      //meta: Object,
      event: Object,
      race: Object,
      badges: Array,
  },
  data() {
    return {
      zoom: 11,
      routePolyDecoded: null,
      courseLatLngs: null,
      loadedRaceId: null,
      map: null,
      polyline: null,
      meta: null,
      results: null,
      markers: null,
      isGlobe: false,
      requestDistanceMarkersAfterMapIsLoaded: false,
      mapProvider: window.L,
      mapOptions: {
        zoomSnap: .25,
        maxZoom: 15,
        dragging: !window.L.Browser.mobile,
        tap: !window.L.Browser.mobile,
        touchZoom: true,
      },
      cluster: null,
      collectiveProgressMarker: null,
      markerIcon: window.L.icon({
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        // specify the path here
        iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
        shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
      }),
      collectiveProgressIcon: window.L.icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      }),
      customStartIconUrl: 'https://sodispstoragep.blob.core.windows.net/public/content/marker-start.png',
      customRunnerIconUrl: 'https://sodispstoragep.blob.core.windows.net/public/content/marker-runner.png',
    };
  },
  async mounted() {
    await this.initMap();
    
    window.EventBus = EventBus; // so we can access it from leaflet popup
    EventBus.$on('results-show-details', async id => {
      console.log('showing details of ', id);
      const result = (await eventService.getRaceResultDetails(this.event.id, this.race.id, id)).data;
      this.$emit('showResultDetails', result); 
    });
    EventBus.$on('badge-show-details', async id => {
      console.log('showing badge details ', id);
      const badge = (await eventService.getRaceBadge(this.event.id, this.race.id, id)).data;
      this.$emit('showBadgeDetails', badge); 
    });
    EventBus.$on('results-map-show-elevation', async data => {
      console.log('showing details of ', data);
      this.updateElevationBar(data); 
    });
  },
  methods: {
    async initMap() {
      /*if (!this.results && resultData){
        // weird property assignment in Vue, this doesn't propogate correctly on hard refresh, so force manually
        this.results = resultData;
      }*/
      if (this.race == null || this.event == null || this.map != null) {
        console.log('No data (yet) to load.', this.race, this.event, this.map);
        return;
      }
      console.log('Loading results on map now.');

      //this.routePolyDecoded = this.race.route == null ? null : PolylineUtil.decode(this.race.route);

      await this.$nextTick();
    
      console.log('Preparing map.');
      if (!this.$refs.map) {
        return;
      }
      if (this.race.custom_map && this.race.custom_map.globe) {
        if (!window.WE) {
          console.log('WE not yet loaded, returning and trying again in a little while.', window.WE);
          window.setTimeout(this.initMap, 1* 1000);
          return;
        }
        console.log('Preparing 3D globe.', window.WE);
        this.isGlobe = true;
        this.mapProvider = window.WE;
        this.map = new window.WE.map('map', {
          minZoom: 0,
          maxZoom: 16,
        });
        this.map.setView([30, -80], 3);
        window.WE.tileLayer('https://webglearth.github.io/webglearth2-offline/{z}/{x}/{y}.jpg', {
          tileSize: 256,
          bounds: [[-85, -180], [85, 180]],
          minZoom: 0,
          maxZoom: 16,
          //attribution: 'WebGLEarth',
          tms: true
        }).addTo(this.map);
        
        window.WE.polyline = window.WE.polygon;
        this.map.setMaxBounds = function(){};

        // Start a simple rotation animation
        var before = null;
        var earth = this.map;
        /*requestAnimationFrame(function animate(now) {
            var c = earth.getPosition();
            var elapsed = before? now - before: 0;
            before = now;
            earth.setCenter([c[0], c[1] + 0.1*(elapsed/30)]);
            requestAnimationFrame(animate);
        });*/
      }
      else if (this.race.custom_map && this.race.custom_map.img) {
        var w = this.race.custom_map.width;
        var h = this.race.custom_map.height;
        // calculate the edges of the image, in coordinate space

        this.map = window.L.map('map', {
            crs: window.L.CRS.Simple,
            minZoom:this.race.custom_map.min_zoom,
            maxZoom: this.race.custom_map.max_zoom,
            //zoomSnap: .5,
            zoom: this.race.custom_map.default_zoom,
        });
        var bounds = [[0,0], [h, w]];
        this.map.setMaxBounds(bounds);
        this.map.fitBounds(bounds);
        window.L.imageOverlay(this.race.custom_map.img, bounds).addTo(this.map);
      }
      else {
        this.map = window.L.map('map', this.mapOptions);
        if (this.race.scoring == 'ELEVATION'){
          this.addOpenTopoTileLayer(this.map);
        }
        else {
          this.addDefaultTileLayer(this.map);
        }
      }
      if (!this.isGlobe) {
        this.map.addControl(new L.Control.Fullscreen());
      }
      this.cluster = window.L.markerClusterGroup({
        showCoverageOnHover: false,
        chunkedLoading: true,
      });
      this.loadMap();
    },
    loadMap() {
      if (this.map == null) {
        console.log('Waiting for map to initialie.');
        return;
      }
      //console.log('Loading course map now.', this.mapProvider);

      this.routePolyDecoded = this.race.route == null ? null : PolylineUtil.decode(this.race.route);
      this.courseLatLngs = this.race.route == null ? null : this.routePolyDecoded.map(x => window.L.latLng(x));
      //console.log('Showing course map now:', this.courseLatLngs);

        //this.polyline = this.$refs.poly.mapObject;
      if (this.isGlobe) {
        this.mapProvider.marker(this.routePolyDecoded[0], this.customStartIconUrl, 32, 45).addTo(this.map);
        this.polyline = this.mapProvider.polygon(this.routePolyDecoded, { color: '#ffffff', opacity: 1, weight: 5, stroke: true, fill: true }).addTo(this.map);
      }
      else {
        this.mapProvider.polyline(this.routePolyDecoded, { color: '#ffffff', weight: 8, stroke: true, fill: false }).addTo(this.map);
        this.polyline = this.mapProvider.polyline(this.routePolyDecoded, { color: '#008AFF', weight: 5, stroke: true, fill: false }).addTo(this.map);
        if (!this.isCustomMap) this.map.setMaxBounds(null); // reset to prevent clipping when course is changed
        this.map.fitBounds(this.polyline.getBounds().pad(.1 /* 10% */));
        if (!this.isCustomMap) this.map.setMaxBounds(this.polyline.getBounds().pad(.5 /* 50% */));
      }
      this.ensureBadgesLoaded();
      this.loadedRaceId = this.race.id;
      if (true || this.requestDistanceMarkersAfterMapIsLoaded) {
        console.log('Delayed loading distance markers now.', this.mapProvider);
        this.loadDistanceMarkers();
        this.requestDistanceMarkersAfterMapIsLoaded = false;
      }
    },

    clear() {
      console.log('Clearing the map.');
      if (this.map) this.clearOverlays(this.map);
      //this.map = null;
    },
    addOpenTopoTileLayer(map) {
      window.L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      }).addTo(map);
    },
    addDefaultTileLayer(map) {
      window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);
    },

    updateElevationBar(data) {
      var handle = this.$refs.elevationBarHandle;
      if (handle) {
        console.log('Showing elevation', handle, 'data', data);
        handle.style.top = data.percentage + '%';
      }
    },
    ensureBadgesLoaded(badges) {
      this.badges = this.badges || badges;
      //console.log('Loading badges?', this.badges );
      if (this.badges && this.badges.length > 0) {
        //console.log('LOADING BADGES', this.badges );
        for (const element of this.badges.filter(x => x.show_map)) {
        console.log('LOADING BADGE', element, element.dist );
          var latLng = null;
          if (element.dist) {
            latLng = this.getLatLngAtDistance(this.polyline.getLatLngs(), element.dist);
          }
          if (element.coord_lat && element.coord_lng) {
            latLng = window.L.latLng(element.coord_lat, element.coord_lng);
          }
          if (latLng) {
            var popup = `<div style='text-align:center'>
                          <h3>${element.name} badge</h3>
                          <img src='${element.img}' class="mt-2" style='max-width:200px; max-height:200px;'/>`;
              
            if (element.details_type == 'PANORAMA') {
              popup += `<a onclick="EventBus.$emit('badge-show-details', '${element.id}');" class='mt-2 white--text v-btn v-btn--contained v-btn--rounded theme--light v-size--default primary'>View Panorama</a>`;
            }
            if (element.details_type == 'IMAGE') {
              popup += `<a onclick="EventBus.$emit('badge-show-details', '${element.id}');" class='mt-2 white--text v-btn v-btn--contained v-btn--rounded theme--light v-size--default primary'>View Image</a>`;
            }
            if (element.details_type == 'STREET_VIEW') {
              popup += `<a onclick="EventBus.$emit('badge-show-details', '${element.id}');" class='mt-2 white--text v-btn v-btn--contained v-btn--rounded theme--light v-size--default primary'>View Street View</a>`;
            }
            popup += `</div>`;

            const badgeIcon = window.L.icon({
              //iconSize: [50, 50],
              //iconAnchor: [25, 25],
              popupAnchor: [0, 150],
              className: 'badge-marker',
              // specify the path here
              iconUrl: element.img,
            });
            const markerOptions = {
              title: element.name + ' badge',
              icon: badgeIcon,
            };
            const marker = window.L.marker(latLng, markerOptions).bindPopup(popup);
            this.bindMarkerClick(marker, { percentage: 100 - ((element.dist / this.race.dist) * 100) });
            this.map.addLayer(marker);
          }
        }
      }
    },
    loadResults(resultData, meta) {
      this.meta = meta;
      this.markers = null;
      this.results = resultData;
      if (this.map == null) {
        console.log('Delaying loading distance markers.');
        this.requestDistanceMarkersAfterMapIsLoaded = true;
        return;
      }
      console.log('Loading new results on map now.');
      setTimeout(this.loadDistanceMarkers, 0);
      this.ensureBadgesLoaded();
    },
    loadMarkers(markerData, meta) {
      this.meta = meta;
      this.markers = markerData;
      this.results = null;
      if (this.map == null) {
        console.log('Delaying loading distance markers.');
        this.requestDistanceMarkersAfterMapIsLoaded = true;
        return;
      }
      console.log('Loading new markers on map now.');
      setTimeout(this.loadDistanceMarkers, 0);
      this.ensureBadgesLoaded();
    },
    bindMarkerClick(marker, data) {
      marker.on('click', () => EventBus.$emit('results-map-show-elevation', data));
    },
    showCollectiveProgress() {
      //temp
      this.meta = {collective_progress: 41000};
      if (this.race.scoring == 'TRACK') {
        return;
      }
      if (this.race.collective && this.meta && this.meta.collective_progress) {
        console.log('Loading collective progress now.', this.meta.collective_progress, this.collectiveProgressIcon);
        var latLng = this.getLatLngAtDistance(this.courseLatLngs, this.meta.collective_progress);
        if (latLng) {
          const progress = `Collective progress of all participants of this race is at ${this.$options.filters.distance(this.meta.collective_progress, this.event.unit)} of ${this.$options.filters.distance(this.race.collective_goal || this.race.dist, this.event.unit)}.`;
          const markerOptions = {
            title: progress,
            icon: this.collectiveProgressIcon,
          };
          if (this.collectiveProgressMarker) {
            this.collectiveProgress.setLatLng(latLng);
          }
          else {
            //const marker = this.mapProvider.marker(latLng, markerOptions).bindPopup(progress).addTo(this.map);
            var marker;
            if (this.isGlobe) {
              marker = this.mapProvider.marker(latLng, this.customRunnerIconUrl, 56, 79).bindPopup(progress).addTo(this.map);
            }
            else {
              marker = this.mapProvider.marker(latLng, markerOptions).bindPopup(progress).addTo(this.map);
            }
            this.bindMarkerClick(marker, { percentage: 100 - ((this.meta.collective_progress / (this.race.collective_goal || this.race.dist)) * 100) });
            this.collectiveProgress = marker;
          }
        }
      }
    },
    loadDistanceMarkers() {
      if (this.race.scoring == 'TRACK') {
        return;
      }
      if (!this.isGlobe) {
        this.cluster.clearLayers();
        this.map.removeLayer(this.cluster);
      }
      this.showCollectiveProgress();
      //var cluster = window.L.markerClusterGroup({
      //  showCoverageOnHover: false,
      //});
      /*for (const latLng of this.polyline.getLatLngs()) {
        cluster.addLayer(window.L.marker(latLng, {icon:this.markerIcon}));
      }*/
      const distanceDisplayFunc = this.race.scoring == 'DISTANCE' ? this.$options.filters.distance : this.$options.filters.elevation;
      if (this.results && this.race.dist) {
        //console.log('Loading results:', this.results);
        for (const element of this.results) {
          //console.log('Showing progress of', element.name);
          //console.log('Showing marker', element, element.dist, this.markerIcon);
          var latLng = this.getLatLngAtDistance(this.courseLatLngs, element.score_value);
          if (latLng) {
            //console.log(element.name, 'is at', latLng, element, this.event.unit, this.race.dist);
            const progress = `${element.pos ? '#' + element.pos :''} ${element.name} is at ${distanceDisplayFunc(element.score_value, this.event.unit)} of ${distanceDisplayFunc(this.race.dist, this.event.unit)}`;
            const popup = `${progress} <a onclick="EventBus.$emit('results-show-details', '${element.id}');"><i aria-hidden="true" class="v-icon notranslate fa fa-info-circle theme--light primary--text"></i></a>`;
            const markerOptions = {
              title: progress,
              icon: this.markerIcon,
            };
            const marker = this.mapProvider.marker(latLng, markerOptions).bindPopup(popup);
            this.bindMarkerClick(marker, { percentage: 100 - ((element.score_value / this.race.dist) * 100) });
            if (!this.isGlobe) {
              this.cluster.addLayer(marker);
            }
          }
        }
      }
      if (this.markers && this.race.dist) {
        //console.log('Loading markers:', this.markers);
        for (const element of this.markers) {
          //console.log('Showing progress of', element);
          //console.log('Showing marker', element, element.dist, this.markerIcon);
          var latLng = [element.lat, element.lon];
          if (latLng) {
            //console.log(element.t, 'is at', latLng, element, this.event.unit, this.race.dist);
            const progress = `${element.p ? '#'+element.p : ''} ${element.t} is at ${distanceDisplayFunc(element.d, this.event.unit)} of ${distanceDisplayFunc(this.race.dist, this.event.unit)}`;
            const popup = `${progress} <a onclick="EventBus.$emit('results-show-details', '${element.id}');"><i aria-hidden="true" class="v-icon notranslate fa fa-info-circle theme--light primary--text"></i></a>`;
            const markerOptions = {
              title: progress,
              icon: this.markerIcon,
            };
            const marker = this.mapProvider.marker(latLng, markerOptions).bindPopup(popup);
            this.bindMarkerClick(marker, { percentage: 100 - ((element.d / this.race.dist) * 100) });
            this.cluster.addLayer(marker);
          }
        }
      }
      if (!this.isGlobe) {
        this.map.addLayer(this.cluster);
      }
    },
    getLatLngAtDistance(latLngs, distance) {
      var distOverTrack = 0;
      var ratio = this.race.route_ratio || 1.0;
      var prev = latLngs[0];
      for (const latLng of latLngs) {
        const dist = prev.distanceTo(latLng);
        distOverTrack += dist;
        if (distOverTrack >= distance * ratio) {
          const overshoot = distOverTrack - (distance * ratio);
          const overshootRatio = dist == 0 ? 0 : 1-(overshoot / dist);
          //console.log('getLatLngAtDistance', Math.round(distance/1000), 'next:', Math.round(distOverTrack/1000), Math.round(overshoot/1000), 'of' , Math.round(dist/1000), overshootRatio);
          return this.midpoint(prev, latLng, overshootRatio);
//          return latLng;
        }
        prev = latLng;
      }
      return prev; // overshoot, return last valid point
    },

    midpoint(coord1, coord2, ratio) {
        return window.L.latLng(coord1.lat + (coord2.lat - coord1.lat) * ratio, coord1.lng + (coord2.lng - coord1.lng) * ratio);
    },

    clearOverlays: function(m) {
        for(const i in m._layers) {
            if(m._layers[i]._path != undefined) {
                try {
                    m.removeLayer(m._layers[i]);
                }
                catch(e) {
                    console.log("problem with " + e + m._layers[i]);
                }
            }
        }
    },

  },
  computed: {
    isCustomMap() {
      return this.race && this.race.custom_map && this.race.custom_map.img;
    },
    startCoord() {
      return this.routePolyDecoded == null ? null : this.routePolyDecoded[0];
    },

    stopCoord() {
      return this.routePolyDecoded == null ? null : this.routePolyDecoded[this.routePolyDecoded.length - 1];
    },

    routePoly() {
      return this.routePolyDecoded;
    },
  },
  watch: {
    async race(val, prevRace) {
      if (val.id != this.loadedRaceId) {
        console.log('Reloading course map ', prevRace.id, '->', this.race.id, this.loadedRaceId);
        if (prevRace && (prevRace.custom_map || val.custom_map)) {
          console.log('Custom map state changing, forcing re-init of map.');
          this.clear();
          this.map.off();
          this.map.remove();
          this.map = null;
          await this.initMap();
        }
        //this.clear();
        this.loadMap();
      }
    },

  },

};
</script>


<style lang="scss">
@import url(https://api.mapbox.com/mapbox.js/plugins/leaflet-fullscreen/v1.0.1/leaflet.fullscreen.css);
.map-container {
}
  .elevation-bar {
    background:transparent;
    min-width: 75px;
    p {white-space: nowrap; text-align: center; margin: 0px;}
    p:first-child { border-bottom: 3px solid #097a00; }
    p:last-child { border-top: 3px solid #af3905; }
    .bar-bg {
      margin: 0px 0px; 
      background: rgb(255,122,62);
      background: linear-gradient(0deg, rgba(255,122,62,1) 0%, rgba(255,235,0,1) 35%, rgba(18,255,0,1) 100%); 
      position: relative;
      .position {
        background-color: black;
        border: 3px solid white;
        /*outline: 2px solid black;*/
        position: absolute;
        margin-top: -4px;
        min-height: 8px;
        top: 100%;
        left:0; 
        right: 0;
      }
    }
  }
  .leaflet-marker-icon.badge-marker { width: 50px; margin-left: -25px; margin-top: -25px; }
  .leaflet-popup-tip-container { display: none; }
</style>

