<template>
  <div>
  <v-dialog v-model="visible">
    <v-card v-if="event && profile">  
      <v-card-title class="display-1">
        Profile Details
        <v-spacer/>
        <v-btn v-if="data.bib_img" outlined class="mr-2" @click="previewBibData=data.bib_img;previewBibDialog=true;" target="_blank">View Bib</v-btn>
        <v-btn v-else-if="event.bib_doc_id" outlined class="mr-2" @click="previewBib">Preview Bib</v-btn>
        <v-btn outlined class="mr-2" :to="{name: 'eventmanagerParticipant', params: {id: event.id, profileId: profile.id}}">Disconnect</v-btn>
        <v-btn dark target="_blank" :to="{name:'adminProfile', params: {profileId: profile.id}}">View Details</v-btn>
      </v-card-title>
      <v-card-text class="mt-4 pb-0">
        <p>
            name: {{ profile.name }}
            • source: {{ profile.provider || 'direct' }}
            • joined: {{ data.join_date | moment('L LTS') }}
            • dob: {{ profile.dob | moment('L') }}
            • gender: {{ profile.gender }}
            • id: {{ profile.id }}
            <span v-if="profile.providers && this.profile.providers.length > 0">• Connected using <strong>{{profile.providers.join(' + ')}}</strong>.</span>
            <span v-else>• No device connected</span>
            <span v-if="profile.manual" class="font-weight-bold">• MANUAL CONNECT</span>
        </p>
      </v-card-text>

      <v-card-title class="subtitle">Registration</v-card-title>
      <v-card-text class="mt-4 pb-0">
        <p>
            Code: <strong>{{ data.verification_code || 'n/a' }}</strong>
            • joined: {{ data.join_event_date | moment('L LTS') }}
            <span v-if="data.reg">
            • given name: {{ data.reg.gn }}
            • family name: {{ data.reg.fn }}
            • group/team: {{ data.reg.g }}
            </span>
            <span v-else>
            • not linked to registration record
            </span>
        </p>
        <p>
            Connected to race(s): {{ data.races ? data.races.join(', ') : 'all' }}
            <v-dialog v-model="connectedRacesDialog" scrollable max-width="400px">
              <template v-slot:activator="{ on }">
                <v-btn text small color="accent" v-on="on">
                  <v-icon small class="mr-2">fadl fa fa-pencil</v-icon> edit
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Connected race(s)</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 300px;">
                  <p>Select the race(s) this participant may participate in. Deselect all to connect with all races.</p>
                  <v-checkbox v-for="(item, idx) in event.races" :key="idx" v-model="connectedRaceIds" :label="item.name" :value="item.id"></v-checkbox>
                  <p>Saving changes will reprocess all activities of this participant to match them with the selected races.</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="accent" text @click="connectedRacesDialog = false">Close</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" @click="updateParticipant" :loading="$store.getters.isLoading">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog> 
             
        </p>
        
        <p v-if="data.race_ids && data.race_ids.some(id => event.races.find(x => x.id == id && x.team))">
          Connected to team(s): 
          <v-btn v-for="(group, idx) in data.groups" :key="idx" target="_blank" :to="{name: 'profileGroupsEvent', params: {id: group.id, eventId: event.id}}" text>
            <v-icon small class="mr-2">fadl fa fa-info-circle</v-icon> {{group.name}}
          </v-btn>
          <strong v-if="!data.groups || data.groups.length == 0"><v-icon small color="warning">fadl fa fa-exclamation-triangle</v-icon> Not connected to a team</strong>
          <strong v-if="data.reg && !data.reg.g"><v-icon small color="warning">fadl fa fa-exclamation-triangle</v-icon> No team name imported! Please re-import with a team name and sync team.</strong>
          <v-btn v-if="data.reg && data.reg.g" text color="accent" @click="resyncTeams(); "><v-icon small class="mr-2">fadl fa fa-sync</v-icon> Resync teams</v-btn>
        </p>

        <v-alert v-if="event.allowed_history_days || event.allowed_history_days === 0" type="info">
          Please note this event has back-dating limitation of {{event.allowed_history_days}} days. 
          Only activities <strong>AFTER</strong> {{ firstDayAllowed | moment('L LTS') }} may count!</v-alert>
      </v-card-text>
      <div v-if="data.race_results && data.race_results.length == 0">
        <v-card-text>
          <i>No results yet for this event.</i>
        </v-card-text>
      </div>
      <div v-if="data.race_results && data.race_results.length > 0">
        <MyRaceResultsOverview :results="data.race_results" title="Results for this event" adminMode :profileId="profile.id" @refresh="refresh"/>
      </div>

      <v-card-title class="subtitle">
        Last 25 activities
        <v-spacer/>
        <v-btn outlined color="secondary" class="mr-2" @click="showActivityEditorForAdd">Add</v-btn>
        <v-btn outlined color="primary" @click="reprocessActivities" :loading="$store.getters.isLoading">Reprocess activities</v-btn>
      </v-card-title>
      <v-simple-table fixed-header v-if="data.activities && data.activities.length > 0" >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Valid</th>
              <th class="text-left">{{$t('profile.activities.sport')}}</th>
              <th class="text-left">{{$t('profile.activities.source')}}</th>
              <th class="text-left">{{$t('profile.activities.date')}}</th>
              <th class="text-left">{{$t('profile.activities.time')}}</th>
              <th class="text-left">{{$t('profile.activities.distance')}}</th>
              <th class="text-left">Value(s)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in data.activities" :key="'act'+idx">
              <td class="pr-0" style="width:30px;">
                <v-icon small v-if="item.validation_for_event.status == 'OK'" color="success" title="Valid activity">fadl fa fa-check</v-icon>
                <v-icon small v-else color="warning" :title="item.validation_for_event.msg">fadl fa fa-exclamation-triangle</v-icon>
              </td>
              <td class="pr-0" style="width:30px;"><v-icon :title="item.type">{{$helpers.getActivityIcon(item.type)}}</v-icon></td>
              <td class="pr-0" style="width:30px;"><v-icon :title="item.prov">{{$helpers.getProviderIcon(item.prov)}}</v-icon></td>
              <td>{{ item.start | localDate('L LTS Z') }}</td>
              <td>{{ item.time_s | duration }} </td>
              <td v-if="item.type == 'STAIR_CLIMBING'">{{ item.custom }} stairs </td>
              <td v-if="item.type != 'STAIR_CLIMBING'">
                {{ item.dist | distance(event.unit) }} 
                <v-btn v-if="hasTrackChallengeOrGeofence" small icon title="View/approve the GPS track data" @click="showActivityDetails(item.id)"><v-icon small color="accent">fal fa-map</v-icon></v-btn>
                <v-btn v-if="item.prov!='result'" small icon @click="showActivityEditor(item.id)"><v-icon small color="accent">fal fa-pencil</v-icon></v-btn>
                <v-btn v-if="item.prov=='manual_entry'" small icon @click="deleteActivity(item.id)"><v-icon small color="accent">fal fa-trash</v-icon></v-btn>
              </td>
              <td v-if="item.values_for_event">
                <span v-for="(item, idx) in item.values_for_event" :key="idx">
                  <span v-if="idx != 0">•</span>
                  <span v-if="item === 0" class="text-muted">n/a</span>
                  <span v-else class="">{{ item | distance(event.unit)}}</span>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>      
      <div v-if="data.activities && data.activities.length == 0">
        <v-card-text>
          <i>No activities entered yet.</i>
        </v-card-text>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="visible = false">Close</v-btn>
      </v-card-actions>      
    </v-card>
  </v-dialog>
  <v-dialog v-model="activityDialogVisible">
    <v-card>  
      <v-card-title class="display-1">
        Activity Details
      </v-card-title>
      <v-card-text v-if="activity" class="mt-4 pb-0">
        Red line shows the track races in this event and blue line shows the actual activity. It should more or less overlap a red line to count.
      </v-card-text>
      <v-row v-if="activity" class="mx-4 my-0">
        <v-col class="grow">
        <v-select
          :items="event.races"
          v-model="selectedRaceId" 
          item-value="id"
          item-text="name"
          placeholder="Select Race"
          label="Select Race"
          />
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" :disabled="!selectedRaceId" @click="approveRaceForActivity">Approve</v-btn>
        </v-col>
      </v-row>
      <l-map
        ref="map"
        :zoom="11"
        style="height: 500px; width: 100%; z-index: 2;"
      >
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
        />
      </l-map>
      <v-card-actions>
        <v-btn text @click="activityDialogVisible=false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="activityEditDialogVisible" width="600">
    <v-card v-if="activity">  
      <v-card-title class="display-1">
        {{ activity.id ? 'Edit an activity' : 'Add an activity' }}
      </v-card-title>
      <v-card-text v-if="activity" class="mt-4 pb-0">
        <v-select
          :items="siteData.activities"
          v-model="activity.type" 
          item-value="type"
          item-text="text"
          label="Activity Type"
          />
        <DateAndTimeWithTimeZonePicker 
          v-model="activity.start" 
          :disabled="activity.provider != 'manual_entry'"
          :timeZone="event.timezone" 
          :label="$t('profile.activities.entry.activity-date-start')" 
          :timeLabel="$t('profile.activities.entry.activity-time-start')" 
          />
        <DistanceTextArea
          v-model="activity.dist" 
          :disabled="activity.provider != 'manual_entry'"
          icon="fa-ruler"
          :unit="event.unit"
          :label="$t('profile.activities.entry.distance-label', { unit: event.unit, requirement: $t('shared.required') } )" 
          />
        <DurationTextArea
          v-model="activity.time_s" 
          :disabled="activity.provider != 'manual_entry'"
          icon="fa-clock"
          :label="$t('profile.activities.entry.duration')" 
          />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="activityEditDialogVisible=false">Close</v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="saveActivity" :loading="$store.getters.isLoading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="previewBibDialog" width="600">
    <v-card>
      <v-progress-circular v-if="!previewBibData" indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
      <img v-if="previewBibData" :src="previewBibData" style="max-width:100%;" />
      <v-card-actions>
        <v-btn text @click="previewBibDialog=false">Close</v-btn>
        <v-spacer/>
        <span v-if="previewBibData" class="text-muted">Right click the image and select 'Save Image As...' to save this image.</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import eventManagerService from "@/services/eventManagerService";
import ActivityGrid from "@/components/ActivityGrid";
import MyRaceResultsOverview from "@/components/MyRaceResultsOverview";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";
import moment from 'moment-timezone'
import {
  LMap,
  LTileLayer,
  LPolyline,
  LCircle,
} from "vue2-leaflet";
import PolylineUtil from "@/plugins/Polyline.encoded.js";
import siteData from '@/data/site.json'

export default {
  name: "ProfileDetailsDialog",
  components: {
    ActivityGrid,
    MyRaceResultsOverview,
    LMap,
    LTileLayer,
    LPolyline,
    LCircle,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
  },
  props: {
      eventId: String,
      event: Object,
      profileId: String,
  },
  data() {
    return {
      siteData: siteData,
      visible: false,
      data: null,
      profile: null,
      connectedRacesDialog: false,
      connectedRaceIds: [],
      selectedRaceId: null,
      activityDialogVisible: false,
      activityEditDialogVisible: false,
      activity: null,
      previewBibDialog: false,
      previewBibData: null,
    };
  },
  async mounted() {

  },
  methods: {
    async open(profileId) {
        profileId = profileId || this.profileId;
        this.visible = true;
        let response = await eventManagerService.getProfile(this.eventId, profileId);
        this.data = response.data;
        this.connectedRaceIds = this.data.race_ids || [];
        this.profile = this.data.profile;
        this.teams = this.data.groups;
    },

    async reprocessActivities() {
        const response = (await eventManagerService.reprocessParticipant(this.eventId, this.profile.id)).data;
        this.$helpers.toastResponse(this, response, 'Reprocessed data successfully.');
        this.open(this.profile.id); 
    },

    async resyncTeams() {
        const response = (await eventManagerService.syncTeamsForParticipant(this.eventId, this.profile.id)).data;
        this.$helpers.toastResponse(this, response, 'Synced teams successfully.');
        this.open(this.profile.id); 
    },

    async refresh() {
        this.open(this.profile.id); 
    },

    async previewBib() {
      this.previewBibDialog = true;
      var response = await eventManagerService.getBibPreview(this.event.id, this.profile.id);
      let reader = new FileReader();
      reader.readAsDataURL(response.data); 
      reader.onload = () => {
          this.previewBibData = reader.result;
      };
    },

    async approveRaceForActivity() {
        const response = (await eventManagerService.approveParticipantActivityDetails(this.eventId, this.profile.id, this.activity.id, this.selectedRaceId)).data;
        this.activityDialogVisible = false;
        this.$helpers.toastResponse(this, response, 'Activity is approved for selected race.');
        this.open(this.profile.id); 
    },

    async saveActivity() {
      var response;
      if (this.activity.id) {
        response = (await eventManagerService.editParticipantActivity(this.eventId, this.profile.id, this.activity.id, this.activity)).data;
      }
      else {
        response = (await eventManagerService.addParticipantActivity(this.eventId, this.profile.id, this.activity)).data;
      }
      this.$helpers.toastResponse(this, response, 'Activity is saved successfully.');
      if (response.status == "OK") {
        this.activityEditDialogVisible = false;
        this.open(this.profile.id); 
      }
    },
    async deleteActivity(activityId) {
      if (confirm('Are you sure you want to DELETE this activity?')) {
        await eventManagerService.deleteParticipantActivity(this.eventId, this.profile.id, activityId);
        const response = { status: "OK"}
        this.$helpers.toastResponse(this, response, 'Activity is deleted successfully.');
        this.open(this.profile.id); 
      }
    },
    async showActivityEditorForAdd() {
        this.activity = {
        start: moment().startOf('day').toISOString(/*keep offset*/true),
        type: 'RUNNING',
        provider: 'manual_entry',
        time_s: 0,
      };
      this.activityEditDialogVisible = true;
    },
    async showActivityEditor(activityId) {
        const response = (await eventManagerService.getParticipantActivityDetails(this.eventId, this.profile.id, activityId)).data;
        this.activity = response;
        console.log('Loaded activity', this.activity);
        this.activityEditDialogVisible = true;
    },

    async showActivityDetails(activityId) {
        this.activityDialogVisible = true;
        const response = (await eventManagerService.getParticipantActivityDetails(this.eventId, this.profile.id, activityId)).data;
        this.activity = response;

        this.$nextTick(() => {
          if (this.$refs.map) {
            var map = this.$refs.map.mapObject // work as expected
            map.invalidateSize();
            var polyDecoded = this.activity.route_summary == null ? [] : PolylineUtil.decode(this.activity.route_summary);

            var polys = [];
            for (var race of this.event.races) {
              if (race.route) {
                //console.log('route match between', race.id, this.activity.route_summary, 'and', race.route);
                var racePolyDecoded = PolylineUtil.decode(race.route);
                polys.push(window.L.polyline(racePolyDecoded, {color: 'white', weight: 12, stroke: true, fill: false}));
                polys.push(window.L.polyline(racePolyDecoded, {color: 'red', weight: 8, stroke: true, fill: false}));
              }
              if (race.geofence) {
                const decoded = PolylineUtil.decode(race.geofence);
                // add nice fat outline
                window.L.polygon(decoded, { color: '#ffffff', opacity: 1, weight: 10, stroke: true, fill: false, dashArray: null }).addTo(map);
                window.L.polygon(decoded, { color: '#8bc34a', opacity: 1, weight: 5, stroke: true, fill: false, dashArray: null }).addTo(map);
              }
            }
            console.log('Detailed map', 'adding poly', polyDecoded.length);
            polys.push(window.L.polyline(polyDecoded, {color: 'white', weight: 5, stroke: true, fill: false}));
            polys.push(window.L.polyline(polyDecoded, {color: 'blue', weight: 3, stroke: true, fill: false}));

            var group = window.L.featureGroup(polys).addTo(map);
            map.fitBounds(group.getBounds());
          }
        })

    },

    async updateParticipant() {
      console.log('Connected with', this.connectedRaceIds);
      const response = (await eventManagerService.putParticipant(this.eventId, this.profile.id, this.connectedRaceIds)).data;
      this.$toast.success('Updated race settings');
      await this.open(this.profile.id);
      this.connectedRacesDialog = false;
    },

  },
  computed: {
    firstDayAllowed() {
      if (!this.event || (!this.event.allowed_history_days && this.event.allowed_history_days !== 0) || !this.data || !this.data.join_event_date) {
        return null;
      }
      return moment(this.data.join_event_date).add(-1*this.event.allowed_history_days, 'd');
    },
    hasTrackChallengeOrGeofence() {
      return this.event && this.event.races.some(x => x.scoring == 'TRACK' || x.geofence);
    }
  },

};
</script>
<style lang="scss">
</style>

