<template>
  <v-container class="fill-height fullscreen-bg" fluid> 
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12 pa-4">
          <v-card-title class="headline">{{$t('account.recovery.password-recovery')}}</v-card-title>
          <v-card-text>
            <v-alert v-if="error" type="error">{{error}}</v-alert>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field 
                v-model="email" 
                :rules="emailRules" 
                :label="$t('account.register.email')" 
                prepend-icon="fa-envelope"
                required>
            </v-text-field>

            </v-form>
          </v-card-text>
          <v-card-text>
            <v-spacer />
            <v-btn block :disabled="!valid" color="primary" @click="validate">{{$t('account.recovery.submit-request')}}</v-btn>

            <v-alert v-if="emailSend" type="success">{{$t('account.recovery.check-email-message')}}</v-alert>
          </v-card-text>
          <v-divider class="mt-8"/>

          <v-divider />
          <v-card-text class="text-center">
            <p>{{$t('account.login.create-account-intro')}}</p>
            <v-btn outlined block color="primary" :to="{ name: 'register', query: { referral: this.$route.query.referral } }">{{$t('account.login.create-an-account')}}</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  data: () => ({
    passwordShow: false,
    valid: true,
    error: null,
    emailSend: false,
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid"
    ],
  }),

  async mounted() {
  },

  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        this.passwordResetWithFirebase();
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    async passwordResetWithFirebase() {
      const actionCodeSettings = {
        url: `${tenant.homeUrl}account/firebase?tenant=${tenant.id}`
      };
      try {
        var response = await firebase
          .auth()
          .sendPasswordResetEmail(this.email, actionCodeSettings);

        this.emailSend = true;
        this.valid = false;
      } catch (ex) {
        this.error = "We couldn't reset your password. Please verify your email address and make sure you have created your sodisp account with a username and password. When you originally signed in using Garmin or Strava you cannot reset your password but login again using the same provider.";
      }
    }
  },
  computed: {
  },
};
</script>
<style lang="scss" scoped>
  .fullscreen-bg {
    margin-top: 60px;
    padding-top: 40px;
    background-image: url(https://sodisp.imgix.net/hero-cycling-1.jpg?w=2000&h=2000&fit=crop);
    background-size: cover;
    background-position: 50%;

    .row { margin: 0!important; }
  }
  .view--embedded .fullscreen-bg { margin-top: 0;}
</style>

