<template>
  <v-select 
    :items="event.races" 
    item-text="name" 
    item-value="id" 
    v-model="raceId" 
    hide-details
    solo
    rounded
    style="max-width:250px" 
    class="ml-4 mt-0 pt-0 d-inline-block" 
    @change="$router.push({ name: routeName, params: { id: event.id, raceId: raceId} })"
    />
</template>

<script>
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventManagerRaceSelector",
  components: {
  },
  props: {
    event: Object,
    race: Object,
    routeName: String,

  },
  data() {
    return {
      tenant: tenant,
      raceId: null,
    };
  },
  async mounted() {
    this.raceId = this.race.id;
  },
  methods: {

  },
  computed: {
    __raceId(){
      return this.race == null ? null : this.race.id;
    },
    __otherRacesInEvent(){
      return this.event == null || this.race == null ? [] : this.event.races.filter(x => x.id != this.race.id);
    },
  },
};
</script>
<style lang="scss" scoped>
  .v-toolbar {
    .v-toolbar__title a {
      color: black! important;
    }
  }
  .v-toolbar.theme--dark {
    .v-toolbar__title a {
      color: white! important;
    }
  }
</style>

