<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <v-card-title class="display-2">{{$t('profile.edit.title')}}</v-card-title>

      <div v-if="profile">

        <v-card-text>      
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field 
              v-model="name" 
              :rules="nameRules" 
              :label="$t('profile.edit.displayname')" 
              prepend-icon="fa-user"
              required>
          </v-text-field>
          <v-text-field 
            v-model="email" 
            :rules="emailRules" 
            :label="$t('profile.edit.email')" 
            prepend-icon="fa-envelope"
            dense
            >
          </v-text-field>
          <i18n path="profile.edit.privacy-message" tag="p" class="text-muted">
            <router-link to="/privacypolicy">{{$t('account.register.terms_privacy_privacy')}}</router-link>
          </i18n>

          <h3 class="subtitle">{{$t('profile.edit.preferences-title')}}</h3>
          <p class="mb-0">{{$t('profile.edit.unit-field')}}</p>
          <v-radio-group v-model="profile.unit" row class="mt-0">
            <v-radio :label="$t('profile.edit.unit-metric')" value="METRIC"></v-radio>
            <v-radio :label="$t('profile.edit.unit-imperial')" value="IMPERIAL"></v-radio>
          </v-radio-group>

          <v-switch v-model="manual_connecting" Zinset :false-value="true" :true-value="false" class="my-0" :label="$t('profile.edit.auto-post-label')" />
          <p v-if="manual_connecting" class="grey--text mt-n4">{{$t('profile.edit.auto-post-description')}}</p>

          <h3 class="subtitle">{{$t('profile.edit.about-title')}}</h3>
          <p class="mb-0">{{$t('profile.edit.about-intro')}}</p>
          <v-menu
            v-model="showDobPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedDob"
                :label="$t('profile.edit.birthdate')"
                persistent-hint
                prepend-icon="fa-calendar"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker 
              no-title 
              ref="dobPicker"
              v-bind:value="dob"
              v-on:input="handleDobChange"
              :max="new Date().toISOString().substr(0, 10)"
              min="1920-01-01"
              >
            </v-date-picker>
          </v-menu>

          <p class="mb-0">{{$t('profile.edit.gender')}}</p>
          <v-radio-group v-model="profile.gender" row class="mt-0" >
            <v-radio :label="$t('profile.edit.gender-notset')" value=""></v-radio>
            <v-radio :label="$t('shared.gender-male')" value="M"></v-radio>
            <v-radio :label="$t('shared.gender-female')" value="F"></v-radio>
          </v-radio-group>

          <v-btn class="mt-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">{{$t('profile.edit.submit')}}</v-btn>
          <v-snackbar v-model="profileSavedSnackbar" top color="success" :timeout="6000">{{$t('profile.edit.saved-message')}}</v-snackbar>
          </v-form>
        </v-card-text>   

        <v-card-title class="subtitle">{{$t('profile.edit.avatar-title')}}</v-card-title>
        <v-card-text>
          {{$t('profile.edit.avatar-intro')}}
          <AvatarUploadForm :img="profile.imgUrl" v-on:change="udateAvatar"></AvatarUploadForm>
        </v-card-text>

        <v-card-actions>      
          <span class="text-hint">{{$t('profile.edit.profile-id-message', { id : profile.id})}}</span>
        </v-card-actions>      
    
      </div>      

    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment-timezone'
import AvatarUploadForm from '@/components/AvatarUploadForm.vue';
import Header from './_Header.vue';

export default {
  name: "Profile",
  components: {
    AvatarUploadForm,
    Header,
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Edit', disabled: true },
      ],
      valid: true,
      showDobPicker: false,
      profile: null,
      profileSavedSnackbar: false,
      name: "",
      email: "",
      dob: null,
      manual_connecting: false,
      emailRules: [
        v => !v ||  /.+@.+/.test(v) || "E-mail must be valid",
      ],
      nameRules: [
        v => !!v || "Please enter your name",
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async udateAvatar() {
      await this.getProfile();
    },

    handleDobChange(e) {
      this.dob = e;
      this.showDobPicker = false;
    },

    async submit() {
      if (this.$refs.form.validate()) {
        console.log('this.profile.gender',this.profile.gender)
        await profileService.put({
          name: this.name,
          email: this.email,
          unit: this.profile.unit,
          gender: this.profile.gender === '' || this.profile.gender === 0 ? null : this.profile.gender,
          dob: this.dob,
          manual: this.manual_connecting,
        });
        this.profileSavedSnackbar = true;
        // reload
        await this.getProfile();
      }
    },

    async getProfile() {
      if (this.user) {
        var response = await profileService.get();
        this.profile = response.data;
        this.name = this.profile.name;
        this.email = this.profile.email;
        this.dob = this.profile.dob;
        this.manual_connecting = this.profile.manual;
      }
    },
  },
  computed: {
    formattedDob() {
      return !this.dob ? null : moment(this.dob).format('L');
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    showDobPicker (val) {
      val && setTimeout(() => (this.$refs.dobPicker.activePicker = 'YEAR'))
    },
  },
};
</script>
<style lang="scss">
</style>

