import services from "./index";
import qs from "querystring";

export default {
    me(ip) {
        return services.http.get(`/v1.0/tiles/me?ip=${ip}`);
    },
    getCountries() {
        return services.http.get(`/v1.0/tiles/countries`);
    },
    getCountry(countrySlug) {
        return services.http.get(`/v1.0/tiles/country/${countrySlug}`);
    },
    get(id) {
        return services.http.get(`/v1.0/tiles/${id}`);
    },
    getAround(id) {
        return services.http.get(`/v1.0/tiles/${id}/around`);
    },
    getRanking(id, type, ranking) {
        return services.http.get(`/v1.0/tiles/${id}/ranking?activity=${(type||'running').toLowerCase()}&key=${ranking||''}`);
    },
    getEventRanking(id, type, ranking) {
        return services.http.get(`/v1.0/tiles/${id}/ranking/events?activity=${(type||'running').toLowerCase()}&key=${ranking||''}`);
    },
    search(query) {
        return services.http.post(`/v1.0/tiles/search`,qs.stringify(query));
    },
}