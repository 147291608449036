import Vue from "vue";
import Vuex from "vuex";
import nav from '@/data/nav.json'

Vue.use(Vuex);

var httpStore = {
  state: {
    loading: 0,
    skipNext: false,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    isLoading(state) {
      return state.loading > 0;
    },
  },
  mutations: {
    START_LOADING(state) {
      // mutate state
      if (!state.skipNext) {
        state.loading++;
      }
    },
    IGNORE_LOADING(state) {
      // mutate state
      state.skipNext = true;
    },
    STOP_LOADING(state) {
      // mutate state
      if (!state.skipNext) {
        state.loading--;
      }
      state.skipNext = false;
    }
  }
};

var authStore = {
  state: {
    //impersonationProfileId: null,
    user: {
      loggedIn: false,
      data: null
    }
  },
  getters: {
    user(state){
      return state.user.data;
    },
    jwtToken(state){
      return state.user.data == null ? null : state.user.data.jwtToken;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    /*SET_IMPERSONATION_PROFILE_ID(state, data) {
      state.impersonationProfileId = data;
    }*/
  },
  actions: {
    async setUser ({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        var jwtToken = await user.getIdToken();
        //console.log('Update logged in user, ', user.displayName, jwtToken);
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          jwtToken: jwtToken,
        });
      } else {
        commit("SET_USER", null);
      }
    },
  },
};

export default new Vuex.Store({
  state: {
    nav: nav,
    ipCoord: null,
    view: null,
  },
  getters: {
  },
  mutations: {
    setViewMode(state, value) {
      console.log('Changing view mode to:', state, value);
      state.view = value;
    },
    setIpCoord(state, value) {
      state.ipCoord = value;
    },
    updatePageMeta(state, payload) {
      //console.log('changing page meta to', payload)
      state.currentPageMeta = payload;
    },
  },
  actions: {
  },
  modules: {
    auth: authStore,
    http: httpStore,
  }
});
