<template>
  <div>
    <v-skeleton-loader v-if="!results && $store.getters.loading" type="table"></v-skeleton-loader>
    <v-simple-table fixed-header v-if="race && results" >
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="(item, idx) in columns" :key="idx" :class="item.css_class" :data-type="item.type">
              {{ item.caption ? item.caption : (item.caption_key ? $t('results.cols.'+item.caption_key) : (item.caption || '') ) }}
              
              <TooltipDialog v-if="item.type == 'SCORE' && race.scoring_desc" title="Scoring for this race" :text="race.scoring_desc" />
            </th>
            <th><!-- icons and actions --></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showCollectiveProgress" class="grey lighten-4">
            <td :colspan="columns.findIndex(x => x.type == 'NAME')">&nbsp;</td>
            <td class="col-name" style="min-width:120px;">
              <div class="font-weight-bold">{{$t('results.collective-progress')}}</div>
              <i class="font-weight-light">{{ $t(race.team ? 'results.collective-progress-by-all-teams' : 'results.collective-progress-by-all-participants') }}</i>
            </td>
            <td class="pr-0" colspan="99">
              <v-chip outlined color="green" :title="$options.filters.distance(meta.collective_progress, event.unit)">
                <span v-if="race.collective_goal || race.distance">
                  {{ meta.collective_progress | distance(event.unit) }}
                  of
                  {{ race.collective_goal || race.distance | distance(event.unit) }}
                  ({{ Math.round((meta.collective_progress / (race.collective_goal || race.distance)) * 100) }}%)
                </span>
                <span v-else>{{ meta.collective_progress | distance(event.unit) }}</span>
                </v-chip>
            </td>
          </tr>
          <tr v-for="(item, idx) in results" :key="idx" @click="openResultPopup(item)">
            <template v-for="(column, col) in columns" :class="item.css_class">
              <td v-if="column.type == 'POSITION'" :key="col" :class="column.css_class">
                <v-chip v-if="item.pos" :color="filter && filter.gender ? 'gray' : 'accent'">{{ item.pos }}</v-chip>
              </td>
              <td v-if="column.type == 'BIB'" :key="col" :class="column.css_class">
                {{ item.bib }}
              </td>
              <td v-else-if="column.type == 'AVATAR'" :key="col" :column="item.css_class">
                <v-avatar size="32"><img v-if="item.img" :src="item.img+'?w=64&h=64'" onerror="this.style.display='none';"/></v-avatar>
              </td>
              <td v-else-if="column.type == 'NAME'" :key="col" :class="column.css_class">
                {{ item.name }}
                <a v-if="!race.team && meta.has_groups" class="text-muted d-block" @click.stop.prevent="search(item.group)">{{ item.group }}</a>
              </td>
              <td v-else-if="column.type == 'GENDER'" :key="col" :class="`pl-0 col-gender ${filter && filter.gender ? 'col-highlighted': ''}`">
                <span>{{$helpers.getGenderForDisplay(item.gender)}}</span>
                <v-chip v-if="item.gender_pos" outlined class="float-right" color="red darken-4">{{ item.gender_pos }}</v-chip>
              </td>
              <td v-else-if="column.type == 'CATEGORY'" :key="col" :class="`pr-0 col-category ${filter && filter.category ? 'col-highlighted': ''}`">
                <span>{{item.cat}}</span>
                <v-chip v-if="item.cat_pos" outlined class="float-right" color="blue darken-4">{{ item.cat_pos }}</v-chip>
              </td>
              <td v-else-if="column.type == 'COUNTRY'" :key="col" :class="column.css_class">
                {{ item.country }}
              </td>
              <td v-else-if="column.type == 'STATE'" :key="col" :class="column.css_class">
                {{ item.state }}
              </td>
              <td v-else-if="column.type == 'CITY'" :key="col" :class="column.css_class">
                {{ item.city }}
              </td>
              <td v-else-if="column.type == 'SCORE'" :key="col" :class="column.css_class">
                <v-chip outlined color="green" class="">
                  {{ $helpers.getValueForDisplay($options, race, item.score_value, event.unit) }}
                  <v-icon v-if="item.official" x-small color="green" class="ml-2" :title="$t('results.official-result')">fal fa-check-double</v-icon>
                  <v-icon v-if="item.verified" small color="green" class="ml-2" :title="$t('results.verified-result')">fal fa-location</v-icon>
                </v-chip>
              </td>
              <td v-else-if="column.type == 'SECONDARY_SCORE'" :key="col" :class="column.css_class">
                {{ $helpers.getSecondaryValueForDisplay($options, race, item.secondary_value, event.unit) }}
              </td>
              <td v-else-if="column.type == 'LAST_ACTIVITY_DATE'" :key="col">
                {{ item.last_activity | localDate('L') }}
              </td>
              <td v-else-if="column.type == 'SPEED'" :key="col">
                {{ item.speed | speed(event.unit, race.activity_types && race.activity_types.length == 1 ? race.activity_types[0] : null) }}
              </td>
              <td v-else-if="column.type == 'PACE'" :key="col">
                {{ item.pace | pace(event.unit, race.activity_types && race.activity_types.length == 1 ? race.activity_types[0] : null) }}
              </td>
              <td v-else-if="column.type == 'CUSTOM'" :key="col">
                {{ item.custom }} {{ race.custom }}
              </td>
              <td v-else-if="column.type == 'ACTIVE_DAYS'" :key="col" :class="column.css_class">
                {{ item.active_days }}
              </td>
              <td v-else-if="column.type == 'DISTANCE'" :key="col" style="min-width:100px;">
                {{ item.dist | distance(event.unit) }} 
              </td>
              <td v-else-if="column.type == 'ELEVATION'" :key="col" style="min-width:100px;">
                {{ item.elevation_gain | elevation(event.unit) }} 
              </td>
              <td v-else-if="column.type == 'TIME'" :key="col" style="min-width:100px;">
                {{ item.time | duration }} 
              </td>
              <td v-else-if="column.type == 'BONUS'" :key="col" class="px-0" style="width:32px;">
                <ActivityPenaltyScoreChip :base="item.base_pts" :penalty="item.base_pts - item.total_pts" />
                <ActivityBonusScoreChip :base="item.base_pts" :bonus="item.total_pts - item.base_pts" />
              </td>
              <td v-else-if="column.type == 'BADGE'" :key="col" :class="column.css_class">
                <span :set="badge = (item.badges||[]).find(x => x.id == column.badge_id)">
                  {{badge ? (badge.value ? $helpers.getBadgeValueForDisplay($options, badge.format, badge.value, event.unit) : '✔') : ''}}
                  <v-icon v-if="badge && badge.tags && badge.tags.some(x => x === 'IMPORTED')" small color="green" class="ml-2" :title="$t('results.official-result')">fal fa-check-double</v-icon>
                </span>
              </td>
              <td v-else-if="column.type == 'PROGRESS'" :key="col" :class="column.css_class">
                <span v-if="item.progress || item.progress === 0">{{ Math.round(item.progress * 100) }}%</span>
                <span v-else class="grey--text">{{$t('shared.n-a')}}</span>
              </td>
            </template>
            <td class="">
              <v-icon :color="event.color || 'primary'">fa-info-circle</v-icon>
              <v-icon v-if="item.photos" class="ml-2" color="grey">fadl fa fa-images</v-icon>
              <v-icon v-if="item.comments && false" class="ml-2" color="grey">fadl fa fa-comments</v-icon>
              <v-icon v-if="adminMode" color="primary" class="ml-2" title="Profile details" @click.stop="$emit('show-profile-details', item)">fa-user</v-icon>
              <v-icon v-if="adminMode" color="primary" class="ml-2" title="Recalculate result" @click.stop="recalculateResult(item)">fa-sync</v-icon>
              <v-icon v-if="adminMode" color="red" class="ml-2" title="Delete result from leaderboard" @click.stop="deleteResult(item)">fa-trash</v-icon>
            </td>
          </tr>
          <tr v-if="results && results.length == 0">
            <td colspan="6">
              <i v-if="isEventInFuture">{{$t('results.no-results-check-back-soon', { date: $options.filters.moment(event.from, "L")} )}}</i>
              <i v-else>{{$t('results.no-results-yet')}}</i>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <PointsInfoDialog ref="pointInfo"/>
    <RaceResultDetailsDialog ref="resultPopup" :event="event" :race="race" :badges="badges" :meta="meta" :filter="filter"/>
  </div>
</template>

<script>
import eventManagerService from "@/services/eventManagerService";
import PointsInfoDialog from "@/components/PointsInfoDialog.vue";
import ActivityScoreChip from "@/components/ActivityScoreChip";
import ActivityBonusScoreChip from "@/components/ActivityBonusScoreChip";
import ActivityPenaltyScoreChip from "@/components/ActivityPenaltyScoreChip";
import RaceResultDetailsDialog from "@/components/RaceResultDetailsDialog";
import TooltipDialog from "@/components/TooltipDialog";
import Vue from "vue";
import resultColumns from '@/util/resultColumns.js'

export default {
  name: "RaceResultGrid",
  components: {
    PointsInfoDialog,
    ActivityScoreChip,
    ActivityBonusScoreChip,
    ActivityPenaltyScoreChip,
    RaceResultDetailsDialog,
    TooltipDialog,
  },
  props: {
      results: Array,
      meta: Object,
      event: Object,
      race: Object,
      badges: Array,
      filter: Object,
      adminMode: Boolean,
  },
  data() {
    return {
      showPointInfo: false,
      columns: null,
    };
  },
  async mounted() {
    if (this.race && this.meta) {
      this.loadCustomColumns();
    }
  },
  methods: {
    async openResultPopup(result) {
      console.log ('opening popup', result);
      await this.$refs.resultPopup.open(result);
    },
    async search(query, e) {
      console.log ('requesting result search', query, e);
      this.$emit('search', query); 
    },
    async deleteResult(result) {
      if (this.adminMode && confirm(`Are you sure you want to delete the result ${result.name} from the leaderboard?`)) {
        var response = (await eventManagerService.deleteResult(this.event.id, this.race.id, result.id)).data;
        console.log('Saved', response);
        if (response.status == "OK") {
          this.$emit('refresh'); 
        }
      }
    },
    async recalculateResult(result) {
      if (this.adminMode && confirm(`Are you sure you want to recalculate the result ${result.name}? This may take a few seconds.`)) {
        var response = (await eventManagerService.recalculateResult(this.event.id, this.race.id, result.id)).data;
        console.log('Recalculation response', response);
        if (response.status == "OK") {
          this.$emit('refresh'); 
        }
      }
    },

    getInitials(name) {
      var initials = name.match(/\b\w/g) || [];
      initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
      console.log(name, initials)
      return initials.substring(0, 2);
    },

    loadCustomColumns() {
      if (this.race && this.meta) {
        this.columns = resultColumns.mergeAndFilterResultColumns(this.race, this.meta, this.race.cols);
        console.log('Column config:', this.columns, 'config', this.race.cols);
      }
    }
  },
  computed: {
    showCollectiveProgress() {
      return this.race.collective && this.meta.collective_progress;
    },
    showRanking() {
      return !this.race.participation;
    },
    isEventInFuture() {
      var from = Vue.moment(this.event.from);
      var now = Vue.moment();
      return from > now;
    },
  
  },
  watch: {
    race() {
      this.loadCustomColumns();
    },
    meta() {
      this.loadCustomColumns();
    },
  }
};
</script>
<style lang="scss">
.col-highlighted {background-color: #FFFDE7 !important;}
.col-avatar { margin: 0 !important; padding: 0 !important;}
.col-avatar.first { padding-left: 16px !important;}
.col-name {min-width:120px; font-weight: bold; padding-left: 8px !important ;}
.col-gender { padding: 0 8px!important; min-width:85px; max-width:100px;width: 85px; color: #B71C1C !important;}
.col-category { padding: 0 8px!important; min-width:120px; max-width:150px; width: 110px; color: #0D47A1 !important;}
.col-gender > span:not(.v-chip), .col-category > span:not(.v-chip) { line-height: 2rem /* center vertically */;;}
</style>

