<template>
  <div v-if="!$oauth.hasAllConnections(profile) && providerUrls">
    <v-card-title class="subtitle">{{$t('profile.connections.panel-header')}}</v-card-title>
    <v-card-text class="pb-0">{{$t('profile.connections.intro')}}</v-card-text>
    <v-card-text>      
      <v-btn v-if="!$oauth.isConnected(profile, 'strava')" :href="providerUrls.strava" color="#FC4C02" class="mr-4">
        <img src="/images/btn_strava_connectwith_orange.svg" style="height:42px"/>
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'garmin')" :href="providerUrls.garmin" color="#11BFED" class="mr-4 mb-4">
        <img src="/images/garmin-connect.png" width="26" height="26" alt="Garmin Connect">
        Connect with Garmin
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'fitbit')" :href="providerUrls.fitbit" color="#4cc2c4" class="mr-4 mb-4 white--text">
        <span style="font-size:70%;">Connect with</span>
        <img src="/images/fitbit.png" height="22" alt="Fitbit" class="ml-2">
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'polar')" :href="providerUrls.polar" color="#d10027" class="mr-4 mb-4 white--text">
        <span style="font-size:70%;">Connect with</span>
        <img src="/images/polar.png" height="22" alt="Polar" class="ml-2">
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'suunto')" :href="providerUrls.suunto" color="#147eab" class="mr-4 mb-4 white--text">
        <span style="font-size:70%;">Connect with</span>
        <img src="/images/suunto.png" height="24" alt="Suunto" class="ml-2">
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'coros')" :href="providerUrls.coros" color="#F8283B" class="mr-4 mb-4 white--text">
        <span style="font-size:70%;">Connect with</span>
        <img src="/images/coros.png" height="14" alt="Coros" class="ml-2">
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'underarmour')" :href="providerUrls.underarmour" color="#ea212e" class="mr-4 mb-4 white--text">
        <img src="/images/underarmour.png" height="20" alt="Under Armour" class="mr-2">
        <span style="font-size:80%;" class="mr-1">Connect with</span>
        <strong style="font-size:80%; text-transform:uppercase;">Under Armour</strong>
      </v-btn>

      <v-badge v-if="!$oauth.isConnected(profile, 'concept2')" class="mr-4 mb-4" content="beta" offset-x="40px" offset-y="10px" style="left:0px;">
        <v-btn v-if="!$oauth.isConnected(profile, 'concept2')" :href="providerUrls.concept2" color="#033B78" class="mr-4 mb-4 white--text">
          <span style="font-size:80%;" class="mr-1">Connect with</span>
          <strong style="font-size:80%; text-transform:uppercase;">Concept2</strong>
        </v-btn>
      </v-badge>

      <p>
      <v-btn outlined href="https://sodisp.freshdesk.com/support/solutions/folders/77000312005" target="_blank" color="info" class="mt-4">
        <v-icon small class="mr-2">far fa-lightbulb-on</v-icon> {{$t('shared.tips')}}
      </v-btn>
      <span class="ml-2 text-muted">{{$t('profile.connections.troubleshooting')}}</span>
      </p>
    </v-card-text>      

    <v-divider />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProfileConnectPanel",
  props: {
    profile: Object,
    providerUrls: Object,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style scoped>
</style>