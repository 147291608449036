<template>
  <div>
    <div v-if="tenant.id == 'eventscom'">
      <h1>About EveryChallenge</h1>
      <p>
        EveryChallenge is a Virtual Challenge Platform focused on creating engaging virtual endurance sports experiences. This tool was created to provide a responsible way for participants to enjoy the great outdoors, stay active, and connect. 
      </p>
      <p>
        EveryChallenge is not just another virtual challenge platform - it offers a solution to all kinds of virtual and hybrid events worldwide. With its powerful feature set, including the ability to auto-sync activities, seven different challenge types, customizable badges, and much more, EveryChallenge combines all the tools you need in one platform to host an event that gives participants a frictionless, fun, and gamified experience. 
      </p>

      <h2 class="subtitle">Powered by the <strong>sodisp.com</strong> Challenge Platform</h2>
      <p>
       <a href="https://sodisp.com" target="_blank">sodisp</a> (short for social distancing sports) is the most powerful platform to host virtual events and challenges. 
       We offer Strava, Garmin, Polar and Fitbit integration, 6 challenge modes and numerous powerful features to create engaging virtual experiences! 
       <a href="https://sodisp.com" target="_blank">Learn how »</a>
      </p>

      <h2 class="subtitle">Contact us</h2>
      <p>
        We'd love to have your feedback on our website, so feel free to drop us a line.
      </p>

      <p>
        <v-btn color="primary" to="/contact">Contact Us</v-btn>
      </p>
    </div>
    <div v-else>
      <h1>What's in the name</h1>
      <p>
        The name <strong>sodisp</strong> is short for <strong>so</strong>cial <strong>di</strong>stancing <strong>sp</strong>orts.
        Our goal is to encourage everyone to responsibly enjoy the great outdoors in this new post-corona era. 
      </p>
      <p>
        As this unprecedented time is full of new and unexpected challenges we want to bring some joy and fun to your fitness by encouraging social distancing best-practices using gamification techniques.
      </p>

      <h2 class="subtitle">Our mission:</h2>
      <blockquote class="mb-4">"Encourage a responsible way to sport together while respecting social distancing guidelines."</blockquote>
      <p>
        We strongly advise you to read our social distancing guidelines and rules to enjoy your outdoor activities responsibly. For yourself and others around you.
      </p>

      <p>
        <v-btn color="accent" to="/socialdistancing">Read our Social Distancing guidelines</v-btn>
      </p>

      <h2 class="subtitle">Who we are</h2>
      <p>
        We were the inventors of the first Dutch running app and platform more than 10 years ago.
      </p>
      <p>
        sodisp is proudly produced and maintained by Noctilucent BV.
      </p>
      <p>
        We'd love to have your feedback on our website, so feel free to drop us a line.
      </p>

      <p>
        <v-btn color="primary" to="/contact">Contact Us</v-btn>
      </p>
    </div>

  </div>
</template>
<script>
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "About",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>