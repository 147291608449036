<template>
    <div>
      <div v-if="type === 'RACE_RESULTS'">
        <DateWithTimeZonePicker  v-model="timestamp" :timeZone="event.timeZoneOlson" :minDate="event.from" :maxDate="event.till" label="In-person race date" class="pt-0 mt-0"/>
      </div>
      <v-file-input label="Select a .xlsx file" v-model="file" v-on:change="fileSelected"></v-file-input>
      <v-alert v-if="syncResult && syncResult.status == 'OK'" type="success"><strong>All good! Registration data is saved.</strong></v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'INFO'" type="info"><strong>{{ syncResult.msg }}</strong></v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'ERROR'" type="error">Unfortunately, we couldn't process your file: <strong>{{ syncResult.msg }}</strong></v-alert>
      <v-btn color="primary" outlined @click="uploadFile" :loading="$store.getters.isLoading">Upload file</v-btn>
      <span class="ml-4 text-muted">Please select a full import file, uploading a new file will <strong>replace</strong> all existing data.</span>
    </div>
</template>

<script>

import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";

export default {
  name: "ExcelUploadForm",
  components: {
    DateWithTimeZonePicker,
  },
  props: {
    event: Object,
    eventId: String,
    raceId: String,
    type: String,
  },
  data: function() {
    return {
      file: null,
      timestamp: null,
      syncResult: null,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async fileSelected() {
      this.syncResult = null;
    },

    async uploadFile() {
      this.syncResult = null;
      let formData = new FormData()
      if (this.file) {
        formData.append("file", this.file, this.file.name);
        formData.append("eventId", this.eventId);
        formData.append("raceId", this.raceId);
        if (this.timestamp) {
          formData.append("timestamp", this.timestamp);
        }
        if (this.type == 'PRELOADED_ACTIVITIES') {
          this.syncResult = (await eventManagerService.uploadPreloadedActivities(this.eventId, formData)).data;
        }
        else if (this.type == 'RACE_RESULTS') {
          this.syncResult = (await eventManagerService.uploadRaceResults(this.eventId, this.raceId, formData)).data;
        }

        if (this.syncResult.status == 'OK'){
          this.$helpers.toastResponse(this, this.syncResult, 'Data uploaded successfully.');
          this.$emit('uploaded'); 
        }
      }
      else {
        this.syncResult = {
          status: "ERROR",
          msg: "Please select a file to upload."
        };
      }
    },
  },
  computed: {
  },
};
</script>

<style scoped>
</style>