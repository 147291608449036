<template>
  <span>
    <v-btn v-if="nativeShare" :text="!icon" :icon="icon" :color="color" class="" aria-label="Share this page" title="Share this page" @click.stop="showNativeShareOptions"><v-icon :small="small">fa-share-alt</v-icon><span v-if="!icon" class="ml-2">{{$t('shared.share')}}</span></v-btn>
    <v-btn v-else :text="!icon" :icon="icon" :color="color" :outlined="outlined" class="" aria-label="Share this page" title="Share this page" @click="showShareDialog=true"><v-icon :small="small">fa-share-alt</v-icon><span v-if="!icon" class="ml-2">{{$t('shared.share')}}</span></v-btn>
    <v-dialog width="310" v-model="showShareDialog">
        <v-list>
            <v-subheader class="title">{{$t('shared.share-title')}}</v-subheader>
            <!-- <v-list-item :href="twitterUrl" target="_blank">
                <v-list-item-avatar><v-avatar size="32px" tile>
                    <v-icon color="#55acee" alt="Twitter">fab fa-twitter</v-icon>
                </v-avatar></v-list-item-avatar>
                <v-list-item-title>Twitter</v-list-item-title>
            </v-list-item> -->
            <v-list-item
                v-for="sharer in sharers"
                :key="sharer.title"
                @click="processShareClick(sharer.title.toLowerCase())"
                :href="sharer.url"
                target="_blank"
                >
                <v-list-item-avatar>
                    <v-avatar size="32px" tile>
                        <v-icon :color="sharer.color" :alt="sharer.title">{{ sharer.icon }}</v-icon>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>{{ sharer.title }}</v-list-item-title>
            </v-list-item>
        </v-list>            
    </v-dialog>
  </span>
</template>

<script>
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ShareButton",
  props: {
      icon: Boolean,
      outlined: Boolean,
      color: String,
      text: String,
      small: Boolean,
      route: Object,
      url: String,
  },
  data() {
    return {
      tenant: tenant,
      nativeShare: navigator.share,
      pageTitle: null,
      pageDescription: null,
      pageUrl: null, 
      sharers: null,
      showShareDialog: false,
    };
  },
  mounted() {
    // load initial state
    this.updatePageState(this.$store.state.currentPageMeta);
    // subscribe to nav updates
    EventBus.$on('page-header-change', this.updatePageState);
  },
  watch:{
    $route (to, from){
        // get nav updates from child component
        this.pageUrl = this.getPageUrl();
        this.sharers = this.createSharers();
    }
  },
  computed: {
    twitterUrl() {
        return 'https://twitter.com/intent/tweet?url='+encodeURIComponent(this.getPageUrl())+'&text='+encodeURIComponent(this.pageTitle)+'&hashtags=sodisp'
    },
  },
  methods: {
    createSharers() {
        return [
          //{ color: '#c32aa3', icon: 'fab fa-instagram', title: 'Instagram', url: this.getInstagamUrl() },
          { color: '#3b5998', icon: 'fab fa-facebook', title: 'Facebook', url: this.getFacebookUrl() },
          { color: '#55acee', icon: 'fab fa-twitter', title: 'Twitter', url: this.getTwitterUrl() },
          { color: '#25D366', icon: 'fab fa-whatsapp', title: 'Whatsapp', url: this.getWhatsappUrl() },
          { color: '#0077b5', icon: 'fab fa-linkedin', title: 'LinkedIn', url: this.getLinkedInUrl() },
          { color: '', icon: 'fas fa-envelope', title: 'Email', url: this.getEmailUrl() },
      ];
    },
    updatePageState(data) {
        console.log('Loading new data to share', data);
        this.pageTitle = data.title;
        this.pageDescription = data.description;
        this.sharers = this.createSharers();
    },
    getPageUrl() {
        if (this.route) {
            //console.log('loc', document.location);
            return document.location.origin + this.$router.resolve(this.route).href;
        }
        return this.url || (document.querySelector('link[rel=canonical]') ? document.querySelector('link[rel=canonical]').href : document.location.href);
    },
    getShareText() {
        return this.text || this.pageTitle;
    },
    processShareClick(sharer) {
        this.showShareDialog = false;
        //console.log('sharing', sharer);
        this.$gtag.event(sharer, { 'event_category': 'share', 'event_label': this.getPageUrl() });
    },
    showNativeShareOptions() {
        this.$gtag.event('native', { 'event_category': 'share', 'event_label': this.getPageUrl() });
        if (navigator.share) { 
            navigator.share({
                title: tenant.title,
                text: this.getShareText(),
                url: this.getPageUrl(),
            }).then(() => {
                //console.log('Thanks for sharing!');
            })
            .catch(ex => {
              console.error(ex);
              this.showShareDialog = true;
            });
        } else {
            this.showShareDialog = true;
            console.log('Native share not available');
            //shareDialog.classList.add('is-open');
        }
    },
    getTwitterUrl() {
        return 'https://twitter.com/intent/tweet?url='+encodeURIComponent(this.getPageUrl())+'&text='+encodeURIComponent(this.getShareText())+'&hashtags=sodisp'
    },
    getLinkedInUrl() {
        return 'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent(this.getPageUrl())+'&source=sodisp'
    },
    getFacebookUrl() {
        return 'https://www.facebook.com/sharer.php?u='+encodeURIComponent(this.getPageUrl())
    },
    getEmailUrl() {
        return 'mailto:?subject='+encodeURIComponent(this.getShareText())+ '&body='+encodeURIComponent(this.getPageUrl());
    },
    getWhatsappUrl() {
        return 'whatsapp://send?text='+encodeURIComponent(this.getShareText())+ '%20'+ encodeURIComponent(this.getPageUrl());
    },
    getInstagamUrl() {
        return null;
    },
    socialWindow(url) {
        var left = (screen.width - 570) / 2;
        var top = (screen.height - 570) / 2;
        var params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;
        window.open(url,"NewWindow",params);
    }

  },
};
</script>

<style scoped>

.share-button, .share-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.share-dialog {
  display: none;
  width: 95%;
  max-width: 500px;
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
  z-index: -1;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
}

.share-dialog.is-open {
  display: block;
  z-index: 2;
}

header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.targets {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.close-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.close-button svg {
  margin-right: 0;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
}

.pen-url {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>