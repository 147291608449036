import Vue from "vue";
import VueRouter from "vue-router";
import firebase from 'firebase/app'
import 'firebase/auth'
import store from "@/store";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();
//const tenant = tenants.current();

Vue.use(VueRouter);

const getTenantHomePostfix = function(){
  if (tenant.id == 'default' || tenant.customHomePostfix == null) {
    return '';
  }
  return `-${tenant.customHomePostfix}`;
};

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(`../views/Home${getTenantHomePostfix()}.vue`),
    meta: {
      noLayout: true,
      //title: 'sodisp - Virtual Challenge Platform',
      description: 'Platform to host and participate in engaging virtual events and challenges for endurance sports. Auto-sync with Garmin, Strava and Fitbit.',
      image: `${tenant.homeUrl}images/logo.png`,
      toolbarClass: 'transparent'
    }
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: `About Us`,
      description: `Background on who we are and why we started with ${tenant.name}`,
      illustration: 'about_sodisp.svg',
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Contact Us',  
      description: 'Contact us and let us know your thoughts. Use our contact form to send your comments and feedback. We love to hear from you!',
      illustration: 'contact_us.svg',
    }
  },
  {
    path: "/request-demo",
    name: "requestDemo",
    component: () => import("../views/RequestDemo.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Request a Demo',  
      description: 'Request a free 15-30 minute demo where we can show how easy it is to host your own virtual challenge. We love to hear from you!',
      illustration: 'contact_us.svg',
    }
  },
  {
    path: "/thankyou",
    name: "thankyou",
    component: () => import("../views/ThankYou.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Thanks!',  
      description: 'Thanks for your interest in sodisp. We will get back to you ASAP.',
      illustration: 'contact_us.svg',
    }
  },
  {
    path: "/checkout/pay",
    name: "checkoutPay",
    component: () => import("../views/checkout/Pay.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/checkout/success",
    name: "checkoutSuccess",
    component: () => import("../views/checkout/Success.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Thanks!',  
      description: 'Thanks! Your payment has been received successfully.',
      illustration: 'contact_us.svg',
    }
  },
  {
    path: "/checkout/cancelled",
    name: "checkoutCancelled",
    component: () => import("../views/checkout/Cancelled.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Payment Cancelled',  
      illustration: 'contact_us.svg',
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/Register.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/Login.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/account/passwordrecovery",
    name: "accountPasswordrecovery",
    component: () => import("../views/auth/PasswordRecovery.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/account/firebase",
    name: "accountFirebase",
    component: () => import("../views/auth/FirebaseAction.vue"),
    meta: {
      noLayout: true
    }
  },
  {
    path: "/admin/training-plan/:id",
    name: "adminTrainingPlan",
    component: () => import("../views/admin/TrainingPlan.vue"),
  },
  {
    path: "/admin/map",
    name: "adminMap",
    component: () => import("../views/admin/Map.vue"),
  },
  {
    path: "/admin/map/:country",
    name: "adminMapByCountry",
    component: () => import("../views/admin/Map.vue"),
  },
  {
    path: "/admin/rawmap",
    name: "adminRawMap",
    component: () => import("../views/admin/Map.development.vue"),
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Privacy Policy',
      description: 'We respect your privacy',
      illustration: 'privacypolicy.svg',
    }
  },
  {
    path: "/cookiepolicy",
    name: "cookiepolicy",
    component: () => import("../views/CookiePolicy.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Cookie Policy',
      description: 'We use cookies to help improve your experience of sodisp.com',
      illustration: 'cookiepolicy.svg',
    }
  },
  {
    path: "/termsofservice",
    name: "termsofservice",
    component: () => import("../views/TermsOfService.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Terms of Service',
      description: '',
      illustration: 'termsofservice.svg',
    }
  },
  {
    path: "/socialdistancing",
    name: "socialdistancing",
    component: () => import("../views/SocialDistancing.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Social Distancing',
      description: '',
      illustration: 'social_distancing.svg',
    }
  },
  {
    path: "/virtual-event",
    name: "virtualEvent",
    component: () => import("../views/StaticContent.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      slug: 'virtual-event',
      title: 'Organize your Virtual Event',
      description: '',
      illustration: 'finish_line.svg',
    }
  },
  {
    path: "/features",
    name: "features",
    component: () => import("../views/Features.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Features for your virtual event or challenge',
      description: `Explore the features  ${tenant.name} has to offer to make a success of your virtual event or challenge`,
      illustration: 'features.svg',
    }
  },
  {
    path: "/solutions",
    name: "solutions",
    component: () => import("../views/solutions/Index.vue"),
    meta: {
      contentLayout: false,
      toolbarClassZ: 'transparent',
      title: 'Solutions',
      description: `Use the Sodisp Platform to create engaging endurance sport challenges.`,
      illustration: 'features.svg',
    }
  },
  {
    path: "/solutions/corporate-challenges",
    name: "solutionsCorporateChallenges",
    component: () => import("../views/solutions/CorporateChallenges.vue"),
    meta: {
      contentLayout: false,
      toolbarClassZ: 'transparent',
      title: 'Engaging corporate challenges',
      description: `Use the Sodisp Challenge Platform to create motivating challenges to keep your employees fit and active.`,
      illustration: 'features.svg',
    }
  },
  {
    path: "/solutions/year-round-trails",
    name: "solutionsYearRoundTrails",
    component: () => import("../views/solutions/YearRoundTrails.vue"),
    meta: {
      contentLayout: false,
      noLayout: true,
      toolbarClassZ: 'transparent',
      title: 'Engaging year-round trails',
      description: `Take your on-course race and make it relevant 365 days a year! Allow participants to use their favorite app or GPS device to track their result. The sodisp platform will automatically analyze any uploaded activity and match it with your courses. Only tracks which followed the course from start to finish will qualify for the leaderboard.`,
      illustration: 'features.svg',
    }
  },
  { 
    path: "/solutions/events",
    redirect: "/solutions/hybrid-events",
  },
  {
    path: "/solutions/hybrid-events",
    name: "solutionsHybridEvents",
    component: () => import("../views/solutions/HybridEvents.vue"),
    meta: {
      contentLayout: false,
      noLayout: true,
      toolbarClassZ: 'transparent',
      title: 'Engaging hybrid and virtual events',
      description: `Whether you go virtual or hybrid, Sodisp offers challenge solutions to increase sponsorship value and participant engagement of your event!`,
      illustration: 'features.svg',
    }
  },
  {
    path: "/solutions/fitness-engine",
    name: "solutionsEngine",
    component: () => import("../views/solutions/Engine.vue"),
    meta: {
      contentLayout: false,
      toolbarClassZ: 'transparent',
      title: 'Engaging hybrid and virtual events',
      description: `Embed and connect with our fitness engine as a service to instantly add Strava, Fitbit, Garmin, Polar, and COROS integrations to any product! `,
      illustration: 'features.svg',
    }
  },
  {
    path: "/solutions/relay-races",
    name: "solutionsRelayRaces",
    component: () => import("../views/solutions/RelayRaces.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Engaging relay races',
      description: `Explore the features  ${tenant.name} has to offer to make a success of your virtual event or challenge`,
      illustration: 'features.svg',
    }
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("../views/Pricing.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Pricing for virtual events and challenges',
      description: 'We like to keep things simple. This is why we have fair and transparent pricing for hosting virtual events and challenges on the sodisp platform.',
      illustration: 'finish_line.svg',
    }
  },
  {
    path: "/articles/:id",
    name: "article",
    component: () => import("../views/Article.vue"),
    meta: {
    }
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/Blog.vue"),
    meta: {
    }
  },
  {
    path: "/blog/:id",
    name: "blogPost",
    component: () => import("../views/BlogPost.vue"),
    meta: {
    }
  },
  /* -------------- Events ------------------ */

  {
    path: "/events/:id/welcome",
    name: "eventWelcome",
    component: () => import("../views/EventWelcome.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/invite",
    name: "eventInvite",
    component: () => import("../views/EventInvite.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/gallery",
    name: "eventGallery",
    component: () => import("../views/EventGallery.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/join",
    name: "eventJoin",
    component: () => import("../views/EventJoin.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/quickentry",
    name: "eventQuickEntry",
    component: () => import("../views/EventQuickEntry.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/results",
    name: "raceResults",
    component: () => import("../views/RaceResults.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/invite",
    name: "raceInvite",
    component: () => import("../views/EventInvite.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/results/:resultId",
    name: "raceResult",
    component: () => import("../views/RaceResult.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/results/:resultId/claim",
    name: "raceClaimResult",
    component: () => import("../views/RaceClaimResult.vue"),
    meta: {
      requiresAuth: true,
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId/result",
    name: "raceResultByCode",
    component: () => import("../views/RaceResult.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id/:raceId",
    name: "race",
    component: () => import("../views/Race.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events/:id",
    name: "event",
    component: () => import("../views/Event.vue"),
    meta: {
      enableCustomTrackers: true,
    },
  },
  {
    path: "/events",
    name: "events",
    component: () => import("../views/Events.vue"),
    meta: {
      title: `Virtual Challenges and Events on ${tenant.name}`,
      description: 'Explore (or create your own) virtual event or challenges. Join using Strava or Garmin.',
      image: 'https://sodisp.imgix.net/running-bridge.jpg?w=1080&h=500&fit=crop',
    }
  },

  /* -------------- Tiles ------------------ */
  {
    path: "/tiles/map/:country?",
    name: "map",
    component: () => import("../views/tiles/Map.vue"),
    meta: {
      title: 'Tile map on sodisp',
      description: 'Explore local running, cycling and walking leaderboards worldwide. We have divided the world into hexagons (which we call tiles). Each activity you complete is automatically assigned to the correct tile(s) and participates in the local leaderboard.',
      image: null,
    }
  },
  {
    path: "/tiles/countries",
    name: "countries",
    component: () => import("../views/tiles/Countries.vue"),
    meta: {
      title: 'Lookup all tiles by country',
      description: 'Explore local running, cycling and walking leaderboards worldwide. We have divided the world into hexagons (which we call tiles). Each activity you complete is automatically assigned to the correct tile(s) and participates in the local leaderboard.',
      image: null,
    }
  },
  {
    path: "/tiles/:id/:type?",
    name: "tile",
    component: () => import("../views/tiles/Tile.vue"),
  },

  /* -------------- Groups, Orgs and Series ------------------ */
  {
    path: "/by/:id",
    name: "organizationView",
    component: () => import("../views/GroupView.vue"),
    meta: {
      groupType: 'ORGANIZATION',
    },
  },
  {
    path: "/series/:id",
    name: "seriesView",
    component: () => import("../views/GroupView.vue"),
    meta: {
      groupType: 'SERIES',
    },
  },

  /* -------------- Profile ------------------ */

  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/profile/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/edit",
    name: "profileEdit",
    component: () => import("../views/profile/ProfileEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/connections",
    name: "profileConnections",
    component: () => import("../views/profile/Connections.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/feed",
    name: "profileFeed",
    component: () => import("../views/profile/Feed.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/upload",
    name: "profileUpload",
    component: () => import("../views/profile/UploadPhoto.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/upload",
    name: "activityUpload",
    component: () => import("../views/profile/ActivityUpload.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/entry",
    name: "activityEntry",
    component: () => import("../views/profile/ActivityEntry.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/:id",
    name: "activity",
    component: () => import("../views/profile/Activity.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/edit/:id",
    name: "activityEdit",
    component: () => import("../views/profile/ActivityEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities/delete/:id",
    name: "activityDelete",
    component: () => import("../views/profile/ActivityDelete.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/activities",
    name: "activities",
    component: () => import("../views/profile/Activities.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/plans/:eventId/:raceId",
    name: "profilePlan",  
    component: () => import("../views/profile/plans/TrainingPlan.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/groups",
    name: "profileGroups",
    component: () => import("../views/profile/groups/MyGroups.vue"),
  },
  {
    path: "/profile/groups/create",
    name: "profileGroupsCreate",
    component: () => import("../views/profile/groups/Create.vue"),
  },
  {
    path: "/profile/groups/edit/:id",
    name: "profileGroupsEdit",
    component: () => import("../views/profile/groups/Edit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/groups/view/:id",
    name: "profileGroupsView",
    component: () => import("../views/profile/groups/View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/groups/event/:id/:eventId",
    name: "profileGroupsEvent",
    component: () => import("../views/profile/groups/Event.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/eventmanager/*",
    redirect: to => {
      console.log('to', to);
      return to.path.replace('/profile/eventmanager/', '/eventmanager/');
    }
  },
  {
    path: "/eventmanager",
    name: "eventmanager",
    component: () => import("../views/eventmanager/Index.vue"),
  },
  {
    path: "/eventmanager/recent",
    name: "eventmanagerRecent",
    component: () => import("../views/eventmanager/Recent.vue"),
  },
  {
    path: "/eventmanager/create",
    name: "eventmanagerCreate",
    component: () => import("../views/eventmanager/Create.vue"),
  },
  {
    path: "/eventmanager/wizard/:id",
    name: "eventmanagerWizard",
    component: () => import("../views/eventmanager/SetupWizard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/simulator",
    name: "eventmanagerSimulator",
    component: () => import("../views/eventmanager/Simulator.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/view/:id",
    name: "eventmanagerView",
    component: () => import("../views/eventmanager/View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/edit/:id",
    name: "eventmanagerEdit",
    component: () => import("../views/eventmanager/Edit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/allowlist/:id",
    name: "eventmanagerAllowlist",
    component: () => import("../views/eventmanager/Allowlist.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/blocklist/:id",
    name: "eventmanagerBlocklist",
    component: () => import("../views/eventmanager/Blocklist.vue"), 
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/registrations/:id",
    name: "eventmanagerRegistrations",
    component: () => import("../views/eventmanager/Registrations.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/participants/:id",
    name: "eventmanagerParticipants",
    component: () => import("../views/eventmanager/Participants.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/permissions/:id",
    name: "eventmanagerPermissions",
    component: () => import("../views/eventmanager/Permissions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/conversions/:id",
    name: "eventmanagerActivityConversions",
    component: () => import("../views/eventmanager/ActivityConversions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/oauthclients/:id/:provider",
    name: "eventmanagerOAuthClient",
    component: () => import("../views/eventmanager/OAuthClient.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/integrations/:id",
    name: "eventmanagerIntegrations",
    component: () => import("../views/eventmanager/Integrations.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/gallery/:id",
    name: "eventmanagerGallery",
    component: () => import("../views/eventmanager/Gallery.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/reports/:id",
    name: "eventmanagerReports",
    component: () => import("../views/eventmanager/Reports.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/:id/certificates",
    name: "eventmanagerCertificates",
    component: () => import("../views/eventmanager/Certificates.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/teams/:id",
    name: "eventmanagerTeams",
    component: () => import("../views/eventmanager/Teams.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/participants/:id/:profileId",
    name: "eventmanagerParticipant",
    component: () => import("../views/eventmanager/Participant.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/race/:id/:raceId",
    name: "eventmanagerRace",
    component: () => import("../views/eventmanager/Race.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/:id/:raceId/geofence",
    name: "eventmanagerRaceGeofence",
    component: () => import("../views/eventmanager/Geofence.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/:id/:raceId/columns",
    name: "eventmanagerRaceResultColumns",
    component: () => import("../views/eventmanager/ResultColumns.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/:id/:raceId/relayslots",
    name: "eventmanagerRaceRelaySlots",
    component: () => import("../views/eventmanager/RelaySlots.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventmanager/badges/:id/:raceId",
    name: "eventmanagerRaceBadges",
    component: () => import("../views/eventmanager/Badges.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/profile/:profileId?",
    name: "adminProfile",
    component: () => import("../views/admin/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/custommap",
    name: "adminCustomMap",
    component: () => import("../views/admin/CustomMap.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/streetview",
    name: "adminStreetview",
    component: () => import("../views/admin/Streetview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

if (process.env.NODE_ENV === 'development') {
  /*router.addRoutes([
    {
      path: "/admin/mappings",
      name: "admin-mappings",
      component: () => import("../views/AdminMappings.vue")
    }
  ]);*/
}
// Must be last (fall-back)
router.addRoute({ path: '*', name: '404', component: () => import("../views/NotFound.vue") });

function createMetaTag(name, value) {
  const tag = document.createElement('meta');
  tag.setAttribute(name.startsWith('og:') ? 'property' : 'name', name);
  tag.setAttribute('content', value);
  tag.setAttribute('data-vue-router-controlled', '');
  return tag;
}

function createSchemaTag(value) {
  const tag = document.createElement('script');
  tag.setAttribute('type', 'application/ld+json');
  tag.setAttribute('data-vue-router-controlled', '');
  tag.innerHTML = value;
  return tag;
}

// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !await firebase.getCurrentUser()){
    next({name:'login', query: to.query });// keep query
    return;
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithDescription = to.matched.slice().reverse().find(r => r.meta && r.meta.description);

  // Find the nearest route element with meta tags.
  //const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  var title = nearestWithTitle ? nearestWithTitle.meta.title : tenant.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
/*
  var metaTags = nearestWithMeta ? nearestWithMeta.meta.metaTags : [];

  // Turn the meta tag definitions into actual elements in the head.
  var tags = metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  });*/
  var description = null;
  if (nearestWithDescription) {
    description = nearestWithDescription.meta.description;
  }
  var image = null;
  if (nearestWithDescription) {
    image = nearestWithDescription.meta.image;
  }
  // Add the meta tags to the document head.
  //tags.forEach(tag => document.head.appendChild(tag));

  var meta = {
    title: title,
    description: description,
    image: image,
  };
  EventBus.$emit('page-header-change', meta);


  // store it for components to use
  //store.commit('updatePageMeta', meta);
  next();
});

router.afterEach((to, from) => {
  //store.commit('updateSearchExpanded', false);
  const enableCustomTrackersTo = to.matched.some(record => record.meta.enableCustomTrackers);
  const enableCustomTrackersFrom = from.matched.some(record => record.meta.enableCustomTrackers);
  if (!enableCustomTrackersTo) {
    router.app.$analytics.disableCustomTrackers(router.app);
  }
  //else ()
  //console.log('afterEach', router.app.$analytics, enableCustomTrackers);
})

EventBus.$on('page-schema-change', data => {
  Array.from(document.querySelectorAll('[data-vue-router-controlled][type="application/ld+json"]')).map(el => el.parentNode.removeChild(el));
  document.head.appendChild(createSchemaTag(JSON.stringify(data)))
});

EventBus.$on('page-header-change', data => {
  // store it for components to use when the component is created after the data is broadcasted
  // the share component will read if from the store and take it from there
  store.commit('updatePageMeta', data);
  document.title = data.title;
  Array.from(document.querySelectorAll('[data-vue-router-controlled][property="og:title"]')).map(el => el.parentNode.removeChild(el));
  document.head.appendChild(createMetaTag('og:title', data.title))

  Array.from(document.querySelectorAll('[data-vue-router-controlled][name="description"]')).map(el => el.parentNode.removeChild(el));
  Array.from(document.querySelectorAll('[data-vue-router-controlled][property="og:description"]')).map(el => el.parentNode.removeChild(el));
  if (data.description) {
    document.head.appendChild(createMetaTag('description', data.description))
    document.head.appendChild(createMetaTag('og:description', data.description))
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled][property="og:image"]')).map(el => el.parentNode.removeChild(el));
  if (data.image) {
    document.head.appendChild(createMetaTag('og:image', data.image))
  }
});

export default router;
