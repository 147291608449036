<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Integrations for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Advanced integration options to configure and automate interactions with your event.
        </p>
      </v-card-text>


      <v-card-title class="subheader">Result embedding</v-card-title>
      <v-card-text>
        <p>Select the race you'd like to embed:</p>
    <v-select 
      :items="event.races" 
      item-text="name" 
      item-value="id" 
      v-model="raceId" 
      hide-details
      solo
      rounded
      style="max-width:250px" 
      class="mr-4 mt-0 pt-0 d-inline-block" 
      />
        <p class="mt-8 mb-0">Copy and paste HTML code below and include it on your website:</p>
          <pre>&lt;iframe  
            width="100%" 
            height="600" 
            style="border:0;" 
            loading="lazy" 
            allowfullscreen 
            src="{{tenant.homeUrl}}events/{{event.id}}/{{raceId}}?view=embed"
            &gt;
&lt;/iframe&gt;</pre>        
      </v-card-text>
      <v-card-title class="subheader">API access</v-card-title>
      <v-card-text>      
        <p>
          Use our API to integrate directly with the platform. This allows you to automatically sync registration data from your website with our platform. 
          <a href="/api-docs" target="_blank">View API docs</a>.
        </p>
        <p>
          Use these API key(s) to connect to this event using our API. Keep these API keys secret and never expose them publicly.
        </p>
        <code>{{ event.api_keys.join(', ') }}</code>
      </v-card-text>


      <v-card-title class="subheader">External Integrations</v-card-title>
      <v-row class="mx-2"> 
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Strava Client</v-card-title>
            <v-card-text>Configure your own Strava client to prevent API limit issues.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :to="{name:'eventmanagerOAuthClient', params: {id: event.id, provider: 'strava'}}">Configure</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Webhooks</v-card-title>
            <v-card-text>Configure your own webhooks to be triggered on certain events.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="webhooksDialogVisible = true;">Configure</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>    
    </v-card>

    <v-dialog v-if="webhooks" v-model="webhooksDialogVisible" max-width="600px">
      <v-card>
        <v-card-title>Configure Webhooks</v-card-title>
        <v-card-text>
          Configure the webhooks for this event. When a specific url is configured a POST will be made whenever that event is triggered.
        </v-card-text>
        <v-card-text>
          <v-text-field 
            v-model="webhooks.participant"
            label="Participant webhook"
            persistent-hint
            hint="Url whenever a participant joins a challenge or updates its settings. Must accept a POST request."
            />
            <br/>
            <strong>Example payload:</strong>
          <pre>{
  "event_id": "your-event-id",
  "code": "42",
  "race_ids": null, // can also be string array: ["5k", "10k"]
  "email": "email@gmail.com", // can also be null
  "join_date":"2021-06-08T09:43:08.3799403+00:00"
}</pre>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="webhooksDialogVisible = false">{{$t('shared.cancel')}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="saveWebhooks">{{$t('shared.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>        
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Integrations",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      raceId: null,
      webhooks: null,
      siteData: siteData,
      webhooksDialogVisible: false,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.raceId = this.event.races ? this.event.races[0].id : null;
      this.webhooks = this.event.webhooks || {};
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async saveWebhooks() {
      const response = (await eventManagerService.putWebhooks(this.event.id, this.webhooks)).data;
      if (response.status == "OK") {
        this.webhooksDialogVisible = false;
      }
      this.$helpers.toastResponse(this, response, 'Data saved successfully.');
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `Integrations`, disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

