<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <Header title="Recently Created Events"/>
      <v-data-table v-if="events"
        :headers="headers"
        :items="events"
        :items-per-page="25"
        >
        <template v-slot:item.name="{ item }">
          <router-link :to="{name: 'eventmanagerView', params: {id: item.id}}">{{item.name}}</router-link>
        </template>
        <template v-slot:item.created_on="{ item }">
          {{ item.created_on | moment('L LT') }}
        </template>
        <template v-slot:item.from="{ item }">
          {{ item.from | moment('L') }}
        </template>
        <template v-slot:item.till="{ item }">
          {{ item.till | moment('L') }}
        </template>

      </v-data-table>

    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import 'firebase/auth'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Recent Events",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      events: null,
      headers: [
        //{ text: 'Id', value: 'id', sortable: true},
        { text: 'Created On', value: 'created_on', sortable: true},
        { text: 'Name', value: 'name', sortable: true},
        { text: 'Email (organizer)', value: 'email', sortable: true},
        { text: 'Is Published', value: 'published', sortable: true},
        { text: 'Active From', value: 'from', sortable: true},
        { text: 'Active Till', value: 'till', sortable: true},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        this.events = (await eventManagerService.getRecentEvents()).data.data;
      }
    },
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Event Manager', disabled: true },
        { text: 'Recent Event', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

