import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { sync } from 'vuex-router-sync'
import vuetify from "./plugins/vuetify";
import helpers from "./helpers.js";
import oauth from "./util/oauthProviders.js";
import analytics from "./util/analytics.js";
import services from "./services";
import VueGtag from "vue-gtag";
import VueCookies from 'vue-cookies'
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import * as firebase from 'firebase'
import { EventBus } from '@/plugins/eventbus.js';
import 'leaflet/dist/leaflet.css';
import getBrowserLocale from "@/util/i18n/browser-locales"
import i18n from './i18n'
import VuetifyToast from 'vuetify-toast-snackbar'
import { stringify } from "querystring";

Vue.config.productionTip = false;

const plugin = {
  install(Vue) {
    Vue.prototype.$helpers = helpers; // we use $ because it's the Vue convention
    Vue.prototype.$oauth = oauth; // we use $ because it's the Vue convention
    Vue.prototype.$analytics = analytics; // we use $ because it's the Vue convention
  }
};

const unsync = sync(store, router) // done. Returns an unsync callback fn

// note: this is serious performance penalty. Around 10-20 lighthouse points!
Vue.use(VueGtag, {
  config: { id: "UA-163164505-1" },
  appName: 'sodisp',
  enabled: true || process.env.NODE_ENV === 'production',
}, router);

const browserLocale = getBrowserLocale({ countryCodeOnly: true });
moment.locale(browserLocale);
console.log('Browser locale', browserLocale);

Vue.use(VueMoment, {moment});

Vue.use(VueCookies);
Vue.$cookies.config('30d');

var config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
firebase.initializeApp(config)
const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
  unsubscribe();
  await store.dispatch("setUser", user);
  EventBus.$emit('login-state-change', user);
});
firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
      }, reject);
  })
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),

  mounted() {
    services.enableInterceptor();
  },

  data: {
  },

  i18n,

  computed: {
    isLoading() {
      return services.isLoading;
    }
  }
}).$mount("#app");
/*
Vue.config.errorHandler = function(error, vm, info) {
  
  var message = `Error in ${info}: ${error.toString()}\nStack: ${error.stack}`;
  console.log('Error detected:', error.toString());
  if (error.toString() == 'Failed to load resource: net::ERR_FAILED' || error.toString() == 'Error: Network Error') {
    console.log('-- should have reloaded the page? --');
  }

  vm.$gtag.event('error', {
    'event_category': 'site',
    'event_label': message
  });
};
*/
Vue.use(plugin);

Vue.use(VuetifyToast, {
	x: 'right', 
	y: 'top', 
	color: 'info', // default
	icon: 'info',
	iconColor: '', // default
	classes: [
		'body-2'
	],
	timeout: 3000, // default
	dismissable: true, // default
	multiLine: false, // default
	vertical: false, // default
	queueable: false, // default
	showClose: false, // default
	closeText: '', // default
	closeIcon: 'fa-times-circle', // default
	closeColor: '', // default
	slot: [], //default
	shorts: {
		custom: {
			color: 'purple'
		}
	},
	property: '$toast' // default
});
//Vue.use(require('vue-moment'));

Vue.filter('striphtml', function (value) {
  if (!value) {
    return value;
  }
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});

Vue.filter('lowercase', function (value) {
  if (!value) {
    return value;
  }
  return value.toLowerCase();
});

Vue.filter('titleize', function (value) {
  if (!value) {
    return value;
  }
  return value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()).replace('_', ' ');
});

Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
  }

  return value
});

Vue.filter('replace', function (value, template) {
  if (!value) {
      return;
  }

  return value.replace(
    /{(\w+)}/g, 
    (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
    template.hasOwnProperty(placeholderWithoutDelimiters) ? 
    template[placeholderWithoutDelimiters] : placeholderWithDelimiters
  );
  
});

Vue.filter('distance', function (value, unitType, ignoreUnit) {
  var unitString = '';
  if (value === undefined) {
    return '0';
  }
  if (unitType === 'IMPERIAL') {
      value = (value/1609.344);
      if (!ignoreUnit) {
        unitString = ' mi';
      }
  }
  else {
    value = (value/1000);
    if (!ignoreUnit) {
      unitString = ' km';
    }
  }
  return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 2}) + unitString;
});

Vue.filter('elevation', function (value, unitType, ignoreUnit) {
  var unitString = '';
  if (value === undefined) {
    return '0';
  }
  if (unitType === 'IMPERIAL') {
      value = (value*3.2808399);
      if (!ignoreUnit) {
        unitString = ' ft';
      }
  }
  else {
    value = value;
    if (!ignoreUnit) {
      unitString = ' m';
    }
  }
  return value.toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + unitString;
});

Vue.filter('duration', function (value, compact) {
  if (!value) {
    return '-';
  }
  if (value < 0) {
    return '-';
  }
  const dayInS = 24*3600;
  if (value >= dayInS) {
    const days = Math.floor(value / dayInS).toFixed(0);
    var duration = moment.duration(value - (days*dayInS), 'seconds');
    var parts = [];
    parts.push(`${days}d`);
    //console.log('duration', compact, duration.minutes(), duration.hours());
    if (!compact || duration.minutes() != 0) {
      parts.push(`${duration.hours()}h`);
      parts.push(`${duration.minutes()}m`);
    }
    if (compact && duration.hours() != 0) {
      parts.push(`${duration.hours()}h`);
    }
    return parts.join(' ');
  }

  var duration = moment.duration(value, 'seconds');
  var parts = [];
  if (duration.hours() > 0) {
    parts.push(`${duration.hours()}h`);
  }
  if (!compact || duration.minutes() > 0) {
    parts.push(`${duration.minutes()}m`);
  }
  if (!compact || duration.seconds() != 0) {
    parts.push(`${duration.seconds()}s`);
  }
  return parts.join(' ');


  if (duration.hours() > 0) {
    return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  }
  if (duration.minutes() > 0) {
    return `${duration.minutes()}m ${duration.seconds()}s`;
  }
  return `${duration.seconds()}s`;
});

Vue.filter('pace', function (value, unitType, activityType) {
  if (value === undefined || !value || value == 0) {
    return '-';
  }
  var unitDisplay = 'min/km';
  if (unitType === 'IMPERIAL') {
    value = (value*1.609344);
    unitDisplay = 'min/mi';
  }
  if (activityType === 'ROWING') {
    value = (value/2.0);
    unitDisplay = 'min/500m';
  }
  var duration = moment.duration(value, 'seconds');
  if (duration.minutes() > 0) { 
    return `${duration.minutes()}:${(duration.seconds()+'').padStart(2, '0')} ${unitDisplay}`;
  }
  return `0:${duration.seconds()}`;
});

Vue.filter('speed', function (value, unitType, activityType) {
  if (value === undefined || !value || value == 0) {
    return '-';
  }
  if (activityType === 'ROWING') {
    return `${value} SPM`;
  }

  var unitDisplay = 'km/h';
  if (unitType === 'IMPERIAL') {
    value = (value/1.609344);
    unitDisplay = 'mi/h';
  }
  return `${value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1})} ${unitDisplay}`;
});

Vue.filter('trim', function (value, length) {
  if (value && value.length > length) {
    return value.substring(0, length) + "...";
  }
  return value;
});

Vue.filter('points', function (value) {
  if (value) {
    return (value/1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1});
  }
  return '-';
});

Vue.filter('localDate', function (value, format) {
  //var m = moment();
  //console.log(moment.parseZone, value, format); 
  //console.log('2', moment.parseZone(value)); 
  if (value) {
    return moment.parseZone(value).format(format);
  }
  return '-';
});

Vue.component('dynamic-content', {
  template: '<div></div>',
  props: {
    html: String
  },
  mounted() {
    let { render, staticRenderFns } = Vue.compile(`<div>${this.html}</div>`);
    new Vue({ el: this.$el, render, staticRenderFns, router })
  }
})
 