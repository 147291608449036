<template>
  <div>
    <v-dialog v-if="event" v-model="raceDialog" max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }} Leaderboard</span>
        </v-card-title>
        <v-form ref="raceForm">
          <v-card-text v-if="simple && !editedItem.id">
            <v-text-field 
              v-model="editedItem.name" 
              label="Name of your leaderboard" 
              :rules="nameRules"
              required
              />

            <v-radio-group v-model="editedItem.scoring" column label="Challenge Type">
              <v-radio v-for="(item, idx) in siteData.scoring_methods.filter(x => x.core)" :key="idx" :value="item.type">
                <template v-slot:label>
                  <span>
                  <strong>{{ item.text }}:</strong>
                  {{ item.description }}
                  </span>
                </template>
              </v-radio>
            </v-radio-group>

            <DistanceTextArea
              v-model="editedItem.dist" 
              :unit="event.unit"
              :label="goalLabel" 
              :mode="goalDistanceUnitType"
              :multiplier="goalDistanceMultiplier"
              />

            <v-select
              :items="siteData.activities"
              v-model="editedItem.activity_types" 
              multiple
              item-value="type"
              item-text="text"
              placeholder="All Sports"
              label="Allowed Sports"
            >
              <template v-slot:prepend-item>
                <v-list-item 
                  @click="checkAllActivities"
                  :class="allActivitiesSelected ? 'v-list-item--active':''"
                >
                  <v-list-item-action>
                    <v-icon color="primary">{{ allActivitiesSelected ? 'fa-check-square' : '' }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>All sports</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-card-text>
          <v-expansion-panels v-else  accordion tile v-model="initialPanel" style="max-height: 70vh; overflow-y:auto;">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
              Leaderboard <strong class="ml-1">{{editedItem.name}}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="editedItem.name" label="Leaderboard name" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="siteData.activities"
                    v-model="editedItem.activity_types" 
                    multiple
                    item-value="type"
                    item-text="text"
                    placeholder="All Sports"
                    label="Allowed Sports"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item 
                        @click="checkAllActivities"
                        :class="allActivitiesSelected ? 'v-list-item--active':''"
                      >
                        <v-list-item-action>
                          <v-icon color="primary">{{ allActivitiesSelected ? 'fa-check-square' : '' }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>All sports</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index <= 2" small>{{ item.text }}</v-chip>
                      <span v-else-if="index === 3" class="grey--text text-caption">
                        +{{ editedItem.activity_types.length - 3 }}
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <!-- <v-col cols="12" sm="12" md="12" class="pt-0">
                  <v-btn-toggle v-model="editedItem.team" mandatory>
                    <v-btn :value="false" small>Individual</v-btn>
                    <v-btn :value="true" small>Team/relay</v-btn>
                  </v-btn-toggle>
                </v-col> -->
                <v-col cols="4" class="pb-0">
                  <v-select
                    :items="siteData.scoring_methods"
                    v-model="editedItem.scoring" 
                    item-value="type"
                    item-text="text"
                    label="Challenge type"
                  ></v-select>
                </v-col>
                <v-col cols="4" sm="4" md="4" class="pb-0">
                  <DistanceTextArea
                    v-model="editedItem.dist" 
                    :unit="event.unit"
                    :label="goalLabel" 
                    :mode="goalDistanceUnitType"
                    :multiplier="goalDistanceMultiplier"
                    >
                  </DistanceTextArea>
                </v-col>
                <v-col v-if="editedItem.scoring == 'CUSTOM'" cols="4" sm="4" md="4" class="pb-0">
                  <v-text-field
                    v-model="editedItem.custom" 
                    label="Custom scoring unit" 
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="selectedScoringMethod" class="grey--text pt-0" style="font-size: 90%;">
                  <strong>{{selectedScoringMethod.text}}: </strong><span>{{selectedScoringMethod.description}}</span>
                </v-col>
                <v-col v-if="$helpers.isCumulativeScoring(editedItem) && editedItem.dist > 0" cols="4" sm="4" md="4">
                  <v-switch v-model="editedItem.overshoot" class="mt-0" label="Allow overshoot" hint="Allow more than 100% completion of goal." persistent-hint></v-switch>
                </v-col>
                <v-col v-if="editedItem.type !== 'EXTERNAL' && $helpers.isCumulativeScoring(editedItem)" cols="4">
                  <v-switch v-model="editedItem.collective" class="mt-0" label="Collective progress" hint="Enable to show the overall progress of all participants combined." persistent-hint></v-switch>
                </v-col>
                <v-col v-if="editedItem.type !== 'EXTERNAL' && editedItem.collective" cols="4">
                  <DistanceTextArea
                    v-model="computedCollectiveGoal" 
                    :unit="event.unit"
                    :mode="$helpers.UnitType.DISTANCE"
                    :label="`Collective goal (in ${unitTypeDiplay})`" 
                    hint="Leave at 0 to use the challenge goal"
                    >
                  </DistanceTextArea>
                </v-col>

              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="editedItem.type !== 'EXTERNAL' && !simple">
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-users</v-icon>
              Team
              <v-spacer/>
              <span v-if="editedItem.team" class="shrink mr-4">YES</span>
              <span v-if="!editedItem.team" class="shrink mr-4">NO</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn-toggle v-if="editedItem.type !== 'EXTERNAL'" v-model="computedTeam" class="multiline-btns">
                <v-btn v-for="(type, idx) in siteData.team_types" :key="idx" :value="type.type || '_'" small>
                  <span v-html="type.text.replace('\n', '<br/>')"/>
                </v-btn>
              </v-btn-toggle>
              <v-row v-if="editedItem.team" :key="editedItem.team">
                <v-col cols="12" class="pb-0">
                  <h3>Team configuration</h3>
                </v-col>
                <v-col cols="4">
                  <v-switch
                    v-model="editedItem.allow_joining_another_race" 
                    label="Participants may join individual leaderboard"
                    persistent-hint
                    hint="Enable this to allow participants to join an individual leaderboard next to their participation in the team leaderboard."
                  ></v-switch>
                </v-col>
                <v-col cols="4">
                  <v-text-field 
                      v-model.number="computedAggregatedResults" 
                      type="number"
                      min="0" 
                      step="1" 
                      label="Aggregated results" 
                      persistent-hint
                      hint="Enter the number of results to aggregate into the team result.">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-if="editedItem.scoring == 'RESULT'"
                    :items="siteData.team_gender_results_methods"
                    v-model="editedItem.team_gender_results" 
                    item-value="type"
                    item-text="text"
                    label="Gender sub-teams"
                    persistent-hint
                    hint="Select if and how you want gender based sub-teams."
                  ></v-select>
                </v-col>
              </v-row>
              <v-alert v-if="editedItem.team == 'RELAY_DISTANCE_SLOTS' || editedItem.team == 'RELAY_TIME_SLOTS'" type="info">
                Relay slots can be configured from the More menu in the leaderboard screen.
              </v-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="editedItem.type !== 'EXTERNAL' && !simple">
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-filter</v-icon>
              Activity Filter
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>Only activities matching <b>all</b> of these criteria may qualify for this leaderboard.</p>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <DistanceTextArea
                    v-model="editedItem.min_dist" 
                    :unit="event.unit"
                    :mode="distanceUnitType"
                    :label="`Min. distance (in ${unitTypeDiplay})`" 
                    >
                  </DistanceTextArea>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <DistanceTextArea
                    v-model="editedItem.max_dist" 
                    :unit="event.unit"
                    :mode="distanceUnitType"
                    :label="`Max. distance (in ${unitTypeDiplay})`" 
                    >
                  </DistanceTextArea>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <DistanceTextArea
                    v-model="editedItem.min_elevation" 
                    :unit="event.unit"
                    :mode="$helpers.UnitType.ELEVATION"
                    :label="`Min. elevation gain (in ${unitTypeDiplay})`" 
                    >
                  </DistanceTextArea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <p class="pa-0 text-muted">Optional date restriction. Only set when different from event dates:</p>
                </v-col>
                <v-col cols="6">
                  <DateWithTimeZonePicker v-model="editedItem.from" :timeZone="event.timeZoneOlson" clearable :minDate="event.from" :maxDate="event.till" label="Active from (included)" class="pt-0 mt-0" />
                </v-col>
                <v-col cols="6">
                  <DateWithTimeZonePicker v-model="editedItem.till" :timeZone="event.timeZoneOlson" clearable :disabled="!editedItem.from" :minDate="editedItem.from || event.from" :maxDate="moment(event.till).format('YYYY-MM-DD')" label="Active till (included)" class="pt-0 mt-0" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="!simple">
            <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-edit</v-icon>
              Customization
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="isRelayRace">
              <h3 class="mt-4">Relay Join Instructions</h3>
              <p class="mb-0">Short summary of what participants should take into account when joining a relay team or assigning slots.</p>
              <v-text-field
                v-model="editedItem.relay_join_instructions" 
                class="pt-0 mt-0"
                auto-grow
                rows="2"
                persistent-hint
                hint="Markdown formatting supported."
                />
              </div>
              <h3 class="mt-4">Goal Description</h3>
              <p class="mb-0">Short summary of the leaderboard goal. Leave empty to have it generated for you. This is displayed on the leaderboard card on the event home page.</p>
              <v-text-field
                v-model="editedItem.goal_desc" 
                class="pt-0 mt-0"
                rows="2"
                auto-grow
                persistent-hint
                hint="Markdown formatting supported."
                />

              <h3 class="mt-4">Scoring Rules Description</h3>
              <p class="mb-0">This description (markdown syntax supported) can be used to describe how custom scoring rules are configured for this leaderboard. This is displayed on the leaderboard card on the event home page.</p>
              <v-textarea
                v-model="editedItem.scoring_desc" 
                class="pt-0 mt-0"
                rows="2"
                auto-grow
                persistent-hint
                hint="Markdown formatting supported."
                />

              <h3 class="mt-4">Leaderboard Description</h3>
              <p class="mb-0">This message can be used to provide context to results or to add (sponsor) promotion on high value screen estate.</p>
              <v-textarea
                v-model="editedItem.result_desc" 
                class="pt-0 mt-0"
                rows="2"
                auto-grow
                persistent-hint
                hint="Markdown formatting supported."
                />

              <h3 class="mt-4">Completed Result Description</h3>
              <p class="mb-0">Custom text to congratulate participants on their result. This is displayed on the results page (only for completed results).</p>
              <v-textarea
                v-model="editedItem.result_completed_desc" 
                class="pt-0 mt-0"
                rows="2"
                auto-grow
                persistent-hint
                hint="Markdown formatting supported."
                />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="!simple">
            <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-envelope</v-icon>
              Emails
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="editedItem.type !== 'EXTERNAL'">
                <h3>Completion Email</h3>
                <p>Enable this option to trigger an automatic email on completion of the challenge goal. The email contains a Share option which helps to increase engagement of this challenge.</p>
                <v-btn v-if="editedItem.send_completed_email && editedItem.id" outlined color="secondary" class="float-right" @click="sendTestEmail">Send test email</v-btn>
                <v-switch v-model="editedItem.send_completed_email" class="mt-0" label="Send email on completion" hint="Enable to send a (customizable) email whenever this challenge is completed." persistent-hint></v-switch>
                <v-textarea
                  v-if="editedItem.send_completed_email"
                  v-model="editedItem.completed_email_text" 
                  class="mt-0"
                  label="Email text, leave empty to use default template."
                  persistent-hint
                  hint="Optional text to include in emails. HTML code is allowed."
                  >
                </v-textarea>
              </div>
              <div v-if="editedItem.certificate_doc_id">
                <h3>Certificate Email</h3>
                <p>Enable this option to trigger an automatic email when a certificate is generated. The email contains a Share option which helps to increase engagement of this challenge.</p>
                <v-btn v-if="editedItem.send_certificate_email && editedItem.id" outlined color="secondary" class="float-right" @click="sendCertificateTestEmail">Send test email</v-btn>
                <v-switch v-model="editedItem.send_certificate_email" class="mt-0" label="Send email" hint="Enable to send a (customizable) email whenever a certificate is generated." persistent-hint></v-switch>
                <v-textarea
                  v-if="editedItem.send_certificate_email"
                  v-model="editedItem.certificate_email_text" 
                  class="mt-0"
                  label="Email text, leave empty to use default template."
                  persistent-hint
                  hint="Optional text to include in emails. HTML code is allowed."
                  >
                </v-textarea>
              </div>
              <div>
                <h3>Refer-a-Friend Email</h3>
                <p>Customize the email template for the refer-a-friend email for this specific leaderboard. Leave empty to use the default template of the event.</p>
                <v-btn v-if="false && editedItem.send_certificate_email && editedItem.id" outlined color="secondary" class="float-right" @click="sendCertificateTestEmail">Send test email</v-btn>
                <v-textarea
                  v-model="editedItem.referral_email_template" 
                  :rows="editedItem.referral_email_template ? 6 : 2"
                  class="mt-0"
                  label="Email text, leave empty to use default template."
                  persistent-hint
                  hint="Optional text to include in emails. HTML code is allowed."
                  >
                </v-textarea>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="!simple">
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-tools</v-icon>
              Advanced Options
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-switch v-if="editedItem.type !== 'EXTERNAL'" v-model="editedItem.participation" class="mt-0" label="Participation Mode" hint="Check to disable ranking. Leaderboards are sorted by last activity date." persistent-hint></v-switch>

              <v-text-field
                v-model="editedItem.referral_msg" 
                class="mt-0"
                label="Refer-a-friend message"
                persistent-hint
                hint="Overrides the event default (when set). Promotional message to encourage participants to invite their friends."
                />

              <v-text-field
                v-model="editedItem.import_id" 
                class="my-4"
                label="Identifier for this leaderboard in the import file"
                persistent-hint
                hint="Enter the leaderboard name as it is used in the import file. Use comma's to separate multiple values when multiple import IDs should be mapped to this leaderboard."
                >
              </v-text-field>

              <v-text-field
                v-if="showCustomField"
                v-model="editedItem.custom" 
                label="Custom metric name" 
                >
              </v-text-field>

              <div v-if="editedItem.scoring === 'RESULT'">
              <h5>Type of challenge</h5>
              <v-btn-toggle v-model="computedType" class="multiline-btns">
                <v-btn v-for="(type, idx) in siteData.race_types" :key="idx" :value="type.type || '_'" small>
                  <span v-html="type.text.replace('\n', '<br/>')"/>
                </v-btn>
              </v-btn-toggle>
              </div>

              <v-select
                v-if="editedItem.type !== 'EXTERNAL'"
                :items="$helpers.prefixSelectListWith(siteData.secondary_scoring_methods, '- Default -')"
                v-model="editedItem.secondary_scoring" 
                item-value="type"
                item-text="text"
                label="Secondary Scoring Method (optional)"
                placeholder="- Default -"
              ></v-select>


              <v-text-field
                v-if="editedItem.scoring === 'CUSTOM'"
                v-model="editedItem.scoring_expr" 
                class="my-4"
                label="Custom scoring expression"
                persistent-hint
                hint="This is an advanced option. Please consult the FAQ before entering an expression."
                >
              </v-text-field>
              <v-select
                v-if="editedItem.scoring === 'CUSTOM'"
                :items="$helpers.prefixSelectListWith(siteData.scoring_aggregation_types, '- Default -')"
                v-model="editedItem.scoring_aggr" 
                item-value="type"
                item-text="text"
                label="Aggregation Method (advanced)"
                placeholder="- Default -"
              ></v-select>
              <v-switch v-model="editedItem.on_demand" class="mt-0" label="On-Demand Leaderboard" hint="Enable this to allow participants to decide when to start this (sub)challenge. Used by training plans but can be used for optional sub-challenges as well." persistent-hint></v-switch>
              <v-switch v-if="editedItem.on_demand" v-model="editedItem.on_demand_everyone" class="mt-0" label="Available for all participants to start On Demand" hint="When on: all participants in this event can start this on-demand leaderboard. When off: only participants who have this on-demand leaderboard in their registration data may start it." persistent-hint></v-switch>

            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeRaceDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveRace">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import DistanceTextArea from "@/components/DistanceTextArea";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment-timezone'
import siteData from '@/data/site.json'

export default {
  name: "Profile",
  components: {
    DistanceTextArea,
    DateWithTimeZonePicker,
  },
  props: {
    event: Object,
    activeRace: Object,
    simple: Boolean,
  },
  data() {
    return {
      siteData: siteData,
      moment: moment,
      valid: true,
      error: null,
      //races: [],
      initialPanel: 0,
      nameRules: [
        v => !!v || "Please enter the leaderboard name",
      ],
      numberRules: [
        v => v > 0 || "Please enter a positive number",
      ],
      raceDialog: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        //dist: 0,
        //min_dist: 0,
        //max_dist: 0,
      },
      defaultItem: {
        name: '',
        scoring: 'RESULT',
        //dist: 0,
        //min_dist: 0,
        //max_dist: 0,
      },
    };
  },
  async mounted() {
  },
  methods: {

    addRace() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.raceDialog = true
    },

    addRaceAndSave(race) {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, race);
      this.saveRace();
    },

    addInPersonRace() {
      this.addRace();
      this.editedItem.type = 'EXTERNAL';
      this.editedItem.scoring = 'RESULT';
    },
    
    editRace(item) {
      this.editedIndex = this.event.races.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.raceDialog = true
    },

    closeRaceDialog () {
      this.raceDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    saveRace() {
      if (this.$refs.raceForm && !this.$refs.raceForm.validate()) {
        this.error = 'Please fix all validation issues first';
        return;
      }
      if (this.editedItem && this.editedItem.dist) {
        this.editedItem.dist = Math.round(this.editedItem.dist);
      }
      if (this.editedIndex > -1) {
        Object.assign(this.event.races[this.editedIndex], this.editedItem);
      } else {
        if (!this.event.races) {
          this.event.races = [this.editedItem];  
        }
        else {
          this.event.races.push(this.editedItem);
        }
      }
      //this.event.races = this.races;
      this.closeRaceDialog();
      this.$emit('save');
    },
    
    async sendTestEmail() {
      // save RaceId first as saving will reset this (when closing the dialog)
      const raceId = this.editedItem.id;
      // save first to include textual changes!
      await this.saveRace();
      // now send the email
      const response = await eventManagerService.sendRaceCompletedEmail(this.event.id, raceId);
      this.$helpers.toastResponse(this, response.data, 'The test email is on its way! Please check your inbox in a few minutes from now.');
    },
    
    async sendCertificateTestEmail() {
      // save RaceId first as saving will reset this (when closing the dialog)
      const raceId = this.editedItem.id;
      // save first to include textual changes!
      await this.saveRace();
      // now send the email
      const response = await eventManagerService.sendRaceCertificateEmail(this.event.id, raceId);
      this.$helpers.toastResponse(this, response.data, 'The test email is on its way! Please check your inbox in a few minutes from now.');
    },

    checkAllActivities() {
      this.editedItem.activity_types = [];
    },

  },
  computed: {
    allActivitiesSelected() {
      return !this.editedItem.activity_types || this.editedItem.activity_types.length == 0;
    },
    goalLabel() {
      if (!this.editedItem || this.editedItem.scoring == null || this.editedItem.scoring == "RESULT" || this.editedItem.scoring == "TRACK") {
        return `Goal distance (in ${this.unitTypeDiplay})`;
      }
      else if (this.editedItem.scoring == "DISTANCE"){
        return `Total distance goal (in ${this.unitTypeDiplay})`;
      }
      else if (this.editedItem.scoring == "ELEVATION" || this.editedItem.scoring == "ELEVATION_LOSS"){
        return `Total elevation goal (in ${this.unitTypeDiplay})`;
      }
      else if (this.editedItem.scoring == "CALORIES"){
        return `Total calorie goal (in kcal)`;
      }
      else if (this.editedItem.scoring == "TIME"){
        return `Total time goal (in hours)`;
      }
      else if (this.editedItem.scoring == "BADGES"){
        return `Total badges to earn`;
      }
      else if (this.editedItem.scoring == "STAIRS"){
        return `Total number of stairs goal`;
      }
      else if (this.editedItem.scoring == "CUSTOM"){
        return `Total ${this.editedItem.custom || 'score'} goal`;
      }
      return null;
    },
    formattedFrom() {
      return this.event == null ? null : moment(this.event.from).format('L');
    },
    formattedTill() {
      return this.event == null ? null : moment(this.event.till).format('L');
    },
    formattedFromHint() {
      return this.event == null ? null : moment.tz(this.event.from, this.event.timezone).format('L Z z');
    },
    formattedTillHint() {
      return this.event == null ? null : moment.tz(this.event.till, this.event.timezone).format('L Z z');
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New' : 'Edit'
    },
    goalDistanceMultiplier() {
      if (this.editedItem.scoring == "TIME") {
        return 3600;
      }
      return null;
    },
    goalDistanceUnitType() {
      if (this.editedItem != null && (this.editedItem.scoring == "CALORIES"  || this.editedItem.scoring == "TIME" || this.editedItem.scoring == "STAIRS" || this.editedItem.scoring == "BADGES" || this.editedItem.scoring == "CUSTOM")){
        return this.$helpers.UnitType.NUMBER;
      }
      return this.distanceUnitType;
    },
    distanceUnitType() {
      if (this.editedItem != null && (this.isStairClimbing || this.editedItem.scoring == "BADGES" || this.editedItem.scoring == "CUSTOM")) {
        return this.$helpers.UnitType.NUMBER;
      }
      else if (this.editedItem != null && (this.editedItem.scoring == "ELEVATION" || this.editedItem.scoring == "ELEVATION_LOSS")){
        return this.$helpers.UnitType.ELEVATION;
      }
      return this.$helpers.UnitType.DISTANCE;
    },
    unitTypeDiplay() {
      if (this.editedItem != null && this.isStairClimbing) {
        return 'stairs';
      }
      if (this.editedItem != null && this.editedItem.scoring == "BADGES") {
        return 'badges';
      }
      else if (this.editedItem != null && (this.editedItem.scoring == "ELEVATION" || this.editedItem.scoring == "ELEVATION_LOSS")){
        return this.event == null || this.event.unit == 'METRIC' ? 'm' : 'ft';
      }
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    },
    isRelayRace() {
      return this.editedItem && (this.editedItem.team == 'RELAY_TIME_SLOTS' || this.editedItem.team == 'RELAY_DISTANCE_SLOTS')
    },
    showCustomField() {
      return this.editedItem && ((this.editedItem.activity_types || []).some(x => x === 'VOLUNTEERING' || x === 'OTHER'))
    },
    isStairClimbing() {
      return this.editedItem != null && this.editedItem.activity_types && this.editedItem.activity_types.length == 1 && this.editedItem.activity_types[0] == 'STAIR_CLIMBING';
    },
    selectedScoringMethod() {
      return this.editedItem == null || this.editedItem.scoring == null ? null : siteData.scoring_methods.find(x => x.type == this.editedItem.scoring);
    },
    computedTeam:{
      get () {
        return this.editedItem && this.editedItem.team ? this.editedItem.team : "_"
      },
      set (value) {
        this.editedItem.team = value === "_" ? null : value;
        this.$forceUpdate();
      }
    },
    computedType:{
      get () {
        return this.editedItem && this.editedItem.type ? this.editedItem.type : "_"
      },
      set (value) {
        this.editedItem.type = value === "_" ? null : value;
        this.$forceUpdate();
      }
    },
    computedAggregatedResults:{
      get () {
        return this.editedItem ? this.editedItem.aggregated : null
      },
      set (value) {
        this.editedItem.aggregated = value === /*must be triple!*/ 0 ? null : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },
    computedCollectiveGoal:{
      get () {
        return this.editedItem ? this.editedItem.collective_goal : null
      },
      set (value) {
        this.editedItem.collective_goal = value === /*must be triple!*/ 0 ? null : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    event (val) {
      //this.races = val.races;
      console.log('event', val);
    },
    raceDialog (val) {
      val || this.closeRaceDialog()
    },
  },
};
</script>
<style lang="scss">
.multiline-btns {
  width: 100%;
  display: flex;
  .v-btn { flex-grow: 1; height: 40px !important; letter-spacing: 0px;}
}
</style>

