import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    getConfig() {
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    get(id) {
        return services.http.get("/v1.0/events/" + id, this.getConfig());
    },
    getList(ids) {
        return services.http.post("/v1.0/events/list", ids, this.getConfig());
    },
    getTileScores(id, type) {
        return services.http.get(`/v1.0/events/${id}/tilescores`, this.getConfig());
    },
    getPhotos(id) {
        return services.http.get(`/v1.0/events/${id}/photos`, this.getConfig());
    },
    quickEntry(id, model, ignoreValidation) {
        return services.http.post(`/v1.0/events/${id}/quickentry?ignoreValidation=${ignoreValidation}`, model, this.getConfig());
    },
    verifyQuickEntry(id, model) {
        return services.http.post(`/v1.0/events/${id}/quickentry/verify`, model, this.getConfig());
    },
    getRaceResults(eventId, raceId, filter, page) {
        var data = this.filterToQs(filter);
        data = { ...data, q: filter ? filter.search : '', official: filter && filter.onlyOfficialResults ? true : null, page: page <= 1 ? null : page };
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/results?${qs.encode(data)}`, this.getConfig());
        //return services.http.get(`/v1.0/events/${eventId}/${raceId}/results?${qs.encode({ q: filter ? filter.search : '', gender: filter && filter.gender !== '*' ? (filter.gender||''): '', page: page})}`);
    },
    getRaceMarkers(eventId, raceId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/markers`);
    },
    getRaceBadges(eventId, raceId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/badges`);
    },
    getRaceTiles(eventId, raceId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/tiles`);
    },
    getRaceBadge(eventId, raceId, badgeId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/badges/${badgeId}`);
    },
    getRaceResultsMeta(eventId, raceId) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/meta`);
    },
    getRaceResultDetails(eventId, raceId, resultId, filter) {
        const data = this.filterToQs(filter);
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/results/${resultId}?${qs.encode(data)}`);
    },
    getRaceResultDetailsByCode(eventId, raceId, code) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/result?code=${code}`);
    },
    claimRaceResult(eventId, raceId, resultId, model) {
        return services.http.post(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/claim`, model, this.getConfig());
    },
    likeResult(eventId, raceId, resultId, filter) {
        const data = this.filterToQs(filter);
        return services.http.post(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/like?${qs.encode(data)}`, {}, this.getConfig());
    },
    commentResult(eventId, raceId, resultId, filter, message) {
        const data = this.filterToQs(filter);
        const model = { message: message };
        return services.http.post(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/comments?${qs.encode(data)}`, qs.encode(model), this.getConfig());
    },
    getRaceResultComments(eventId, raceId, resultId, filter) {
        const data = this.filterToQs(filter);
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/comments?${qs.encode(data)}`);
    },
    deleteResultComment(eventId, raceId, resultId, filter, commentId) {
        const data = this.filterToQs(filter);
        return services.http.delete(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/comments/${commentId}?${qs.encode(data)}`, this.getConfig());
    },
    getRaceResultPhotos(eventId, raceId, resultId, filter) {
        return services.http.get(`/v1.0/events/${eventId}/${raceId}/results/${resultId}/photos`);
    },
    inviteFriend(eventId, model) {
        return services.http.post(`/v1.0/events/${eventId}/invite`, model);
    },
    filterToQs(filter) {
        //console.log('result search filter', filter);
        const hasGender = filter && filter.gender !== '*';
        const hasCategory = filter && filter.category && filter.category !== '';
        const data = hasGender || hasCategory ? {
            gender: hasGender ? (filter.gender||'') : '',
            category: hasCategory ? (filter.category||'') : '',
        } : {};
        return data;
    },
    upcoming() {
        return services.http.post("/v1.0/events/all", null, this.getConfig());
    },
}