<template>
  <v-menu
    ref="till"
    v-model="showPicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formatted"
        :label="label"
        :hint="formattedHint"
        :disabled="disabled"
        persistent-hint
        prepend-icon="fa-calendar"
        readonly
        class="pt-0"
        :clearable="clearable"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker 
      no-title 
      v-bind:value="valueAsIsoDate"
      @input="handleChange"
      :show-current="false"
      :min="minDate"
      :max="maxDate"
      :disabled="disabled"
      > 
    </v-date-picker>
  </v-menu>
</template>



<script>
import moment from 'moment-timezone'

export default {
  name: "DateWithTimeZonePicker",
  components: {
  },
  props: {
    value: String,
    label: String,
    timeZone: String,
    clearable: Boolean,
    minDate: String,
    maxDate: String,
    disabled: Boolean,
  },
  data() {
    return {
      showPicker: false,
    };
  },
  async mounted() {
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e); 
      this.showPicker = false;
    },
  },
  computed: {
    valueAsIsoDate: {
      get() {
        console.log('DATE', this.value == null ? null : moment(this.value).format('YYYY-MM-DD'))
        return this.value == null ? null : moment(this.value).format('YYYY-MM-DD');
      },
      set(value) {
        // handled by handleChange
      }
    },
    formatted: {
      get() {
        return this.value == null ? null : moment(this.value).format('L');
      },
      set (value) {
        //this.value = value;
        this.$emit('input', value); 
      }
    },
    formattedHint() {
      return this.value == null || this.timeZone == null ? null : moment.tz(this.value, this.timeZone).format('L Z z');
    },
  },
};
</script>
<style lang="scss">
</style>

