<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>

      <div v-if="event">
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-alert tile v-if="!event.verification" type="warning">This event does not require verification codes, anyone can join. Click Edit to change this setting.</v-alert>
        <v-card-title class="subtitle pt-4">Manage registration data</v-card-title>
        <v-card-text>      
          <p v-if="tenant.isDefault">
            Please read our <a href="https://sodisp.freshdesk.com/support/solutions/folders/77000137491" target="_blank">Frequently asked questions</a> on creating and managing events for tips and tricks.
          </p>

          <v-expansion-panels class="mb-4">
          <v-expansion-panel>
            <v-expansion-panel-header class="v-card__title subtitle">Show Leaderboard Mapping Details</v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>Use these values to assign participants to specific leaderboards. Make sure your import file contains the <strong>exact</strong> value as it's shown in the 'Import ID' column.</p>
              <p>You can edit those values by Editing your event, open the leaderboard details and expand the Advanced panel.</p>
              <v-data-table 
                :headers="raceHeaders"
                :items="event.races"
                :items-per-page="15"
                >
                <template v-slot:item.import_id="{ item }">
                  {{ item.import_id || item.id }}
                </template>

              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="v-card__title subtitle">Preloaded activities</v-expansion-panel-header>
            <v-expansion-panel-content>
              <h3>Upload preloaded activities</h3>
              <p>
                When you are moving an active challenge over from another platform to {{tenant.name}} you can preload activity data from your current challenge so that all data will be available when a participants switches to {{tenant.name}}!
              </p>
              <v-dialog v-model="preloadActivitiesDialog" max-width="400px">
                <template v-slot:activator="{ on }">
                  <p><v-btn outlined color="red" v-on="on"><v-icon small class="mr-2">fa fa-upload</v-icon> Upload data</v-btn></p>
                </template>
                <v-card>
                  <v-card-title>Upload data from RunSignup</v-card-title>
                  <v-card-text>
                    You can download an Excel file with existing activities from your RunSignup page. This file can be selected here to have all preloaded.
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <ExcelUploadForm :event-id="event.id" @uploaded="preloadActivitiesDialog=false;loadData()" type="PRELOADED_ACTIVITIES"/>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="preloadActivitiesDialog = false">{{$t('shared.close')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>  
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
          <v-alert v-if="preloadedActivities && preloadedActivities.status == 'INFO'" type="info">{{ preloadedActivities.msg }}</v-alert>

          <h3>Upload registration data</h3>
          <p>
            Please upload a csv or Excel (.xlsx) file with valid data. Note: only full imports (not incremental) are supported currently. 
            Download a <a href="https://sodispstoragep.blob.core.windows.net/public/content/registration-import-template.xlsx">template Excel file</a> or a <a href="https://sodispstoragep.blob.core.windows.net/public/content/registration-import-sample.xlsx">Sample Excel file</a>.
          </p>
          <p>
            Optionally, you can manage <router-link v-if="event.verification" :to="{name:'eventmanagerAllowlist', params: {id: event.id}}">manage verification codes</router-link>
            to be used (in combination with) registration data.
          </p>
          <v-alert v-if="event.send_invite_email" tile outlined border="left" type="info">
            <p>New registrants will automatically receive an invite email to join this event. We will ensure the invites are only sent to new registration records compared to previous uploads. Click Edit Event to customize or test this email.</p>
            <v-btn color="info" outlined @click="reinviteUnconnected">
              <v-icon class="mr-2">fa-paper-plane</v-icon>
              Re-invite unconnected registrants
            </v-btn>
          </v-alert>
          <CsvUploadForm :event-id="event.id" @uploaded="loadData"/>
        </v-card-text>
          
        <v-card-title class="subtitle pt-4">
          {{registrations.length}} registrations
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table v-if="registrations"
          :headers="headers"
          :items="registrations"
          :items-per-page="15"
          :search="search"
          >
          <template v-slot:item.join_date="{ item }">
            {{ item.join_date | moment('L LT') }}
          </template>
          <template v-slot:item.e="{ item }">
            {{ item.e }}
            <v-icon v-if="item.e" small color="" title="Re-send invite email" @click="resendInvitation(item.v)">fal fa-envelope</v-icon>
            <v-icon v-if="item.e" small color="" title="Copy invite link to clipboard" class="ml-1" @click="copyInviteLink(item.v)">fal fa-copy</v-icon>
          </template>
          <template v-slot:item.g="{ item }">
            <v-icon small v-if="!item.g && item.r_id && (event.races.find(x => x.id == item.r_id) || {}).team" color="warning" title="Team name missing.">fadl fa fa-exclamation-triangle</v-icon>
            {{ item.g }}
          </template>
          <template v-slot:item.r="{ item }">
            <v-icon small v-if="item.r && !item.r_ids" color="warning" title="Could not map the value in the import file to a leaderboard. Please configure the Import ID value in the Leaderboard Edit screen.">fadl fa fa-exclamation-triangle</v-icon>
            <v-icon small v-if="item.r_ids && item.r_ids.length" color="success" title="This leaderboard is found and mapped.">fadl fa fa-check</v-icon>
            {{ item.r }}
            <span v-if="item.r_ids && item.r_ids.length" class="text-muted" :title="item.r_ids.join(', ')">({{item.r_ids.length}}x)</span>
            <span v-if="!item.r_ids" class="text-muted">All leaderboards</span>
          </template>

        </v-data-table>
        <br/>
      </div>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import CsvUploadForm from "@/components/CsvUploadForm";
import ExcelUploadForm from "@/components/ExcelUploadForm";
import { EventBus } from '@/plugins/eventbus.js';
import firebase from 'firebase/app'
import 'firebase/auth'
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    CsvUploadForm,
    ExcelUploadForm,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      registrations: [],
      preloadActivitiesDialog: false,
      preloadedActivities: null,
      search: null,
      headers: [
        { text: 'Code', align: 'start', sortable: true, value: 'v',},
        { text: 'Bib', align: 'start', sortable: true, value: 'b',},
        { text: 'Full name', align: 'start', sortable: true, value: 'n',},
        { text: 'First name', align: 'start', sortable: true, value: 'gn',},
        { text: 'Last name', align: 'start', sortable: true, value: 'fn',},
        { text: 'Team', align: 'start', sortable: true, value: 'g',},
        { text: 'Leaderboard(s)', align: 'start', sortable: true, value: 'r',},
        { text: 'Gender', align: 'start', sortable: true, value: 'ge',},
        { text: 'Category', align: 'start', sortable: true, value: 'ca',},
        { text: 'Email', align: 'start', sortable: true, value: 'e',},
        { text: 'Phone', align: 'start', sortable: true, value: 'p',},
        { text: 'City', align: 'start', sortable: true, value: 'ci',},
        { text: 'State', align: 'start', sortable: true, value: 'st',},
        { text: 'Country', align: 'start', sortable: true, value: 'cc',},
        { text: 'Captain?', align: 'start', sortable: true, value: 'tc',},
      ],
      raceHeaders: [
        { text: 'Leaderboard', value: 'name', sortable: true, },
        { text: 'Import ID', value: 'import_id', sortable: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;

      this.registrations = (await eventManagerService.getRegistrations(id)).data.data || [];
      this.preloadedActivities = (await eventManagerService.getPreloadedActivitiesInfo(id)).data;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async resendInvitation(code) {
      if (confirm(`Are you sure you want to resend the invitation to ${code}?`)) {
        const response = (await eventManagerService.sendInviteEventEmailToCode(this.event.id, code)).data;
        this.$helpers.toastResponse(this, response, 'Successfully sent re-invite.');
      }
    },
    async reinviteUnconnected() {
      if (confirm(`Are you sure you want to resend the invitation to ALL registrants who have not yet joined?`)) {
        const response = (await eventManagerService.reinviteUnconnected(this.event.id)).data;
        this.$helpers.toastResponse(this, response, 'Successfully sent re-invite.');
      }
    },

    async copyInviteLink(code) {
      prompt(`Select and copy this link to your clipboard:`, `https://${window.location.hostname}/register?event=${this.event.id}&verificationCode=${code}`);
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Registration data', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

